import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '../../components/GridModules/Row';
import { DynamicFormField } from '../../components/Dynamics/DynamicFormField';

const DayFormEntry = ({ day, onChange, errors, validationChain }) => {
  return (
    <Row MultiColumns>
      <DynamicFormField
        field='day'
        fieldType='number'
        value={day.day}
        errors={errors.day}
        translationKey='models.calendars.days'
        validators={['required']}
        validationChain={validationChain}
        onChange={x => onChange({ ...day, day: x })}
      />
      <DynamicFormField
        field='month'
        fieldType='number'
        value={typeof day.month === 'number' ? day.month + 1 : day.month}
        errors={errors.month}
        translationKey='models.calendars.days'
        validators={['required']}
        validationChain={validationChain}
        onChange={x => onChange({ ...day, month: x - 1 })}
      />
      <DynamicFormField
        field='year'
        fieldType='number'
        value={day.year}
        errors={errors.year}
        translationKey='models.calendars.days'
        onChange={x => onChange({ ...day, year: x })}
      />
    </Row>
  );
};

DayFormEntry.propTypes = {
  day: PropTypes.shape({
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number
  }),
  onChange: PropTypes.func,
  errors: PropTypes.object
};

DayFormEntry.defaultProps = {
  day: {},
  errors: {}
};

export default DayFormEntry;