import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import Tooltip from '@material-ui/core/Tooltip';
import { intervalsService, scriptsService } from '../../../lib/service';
import { TableBlock } from '../../../components/GridModules/ComponentBlock/TableBlock';
import { BlockHeader, BlockTitle, BlockActions, BlockBody } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import DataTable from '../../../components/Table/DataTable';
import CommonFormOptions from '../../components/CommonFormOptions';
import IntervalForm from './IntervalFormDialog';
import { Row } from '../../../components/GridModules/Row';
import ScriptListDialog from '../../Scripts/ScriptListDialog';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { handleAPIError } from '../../../util/forms';

const defaultInterval = {
  active: true
};

class IntervalsTableBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      intervals: [],
      loading: true,
      manageScripts: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    intervalsService.find().then((d) => this.fetchDataSuccess(d));
  }

  fetchDataSuccess(data) {
    let manageScripts = null;
    if (this.state.manageScripts != null) {
      manageScripts = data.data.find(i => i.timerName === this.state.manageScripts.timerName);
    }
    this.setState({ intervals: data.data, loading: false, manageScripts, editingInterval: undefined });
  }

  saveInterval() {
    this.fetchData();
  }

  removeInterval(interval) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    intervalsService.remove(interval.id)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.intervals', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  removeScript(scriptId) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    scriptsService.remove(scriptId)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.scripts', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  render() {
    const columns = [
      { data: 'active', sortable: true },
      { data: 'timerName', sortable: true },
      { data: 'milliseconds', sortable: true },
      { data: (row) => (row.scripts || []).length, columnKey: 'scripts', sortable: false },
      {
        data: row => (
          <CommonFormOptions
            onEdit={() => this.setState({ editingInterval: row })}
            onRemove={() => this.removeInterval(row)}
            extraOptions={[{
              icon: (<CodeIcon />),
              textKey: 'models.scripts.manage',
              action: () => this.setState({ manageScripts: row })
            }]}
          />)
      }];
    return (
      <Fragment>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="section.intervals" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={this.props.intl.formatMessage({ id: 'models.intervals.new' })} >
                  <IconButton onClick={() => this.setState({ editingInterval: defaultInterval })} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <DataTable
                translationKey="models.intervals"
                columns={columns}
                data={this.state.intervals}
                loading={this.state.loading}
              />
            </BlockBody>
          </TableBlock>
          {this.state.editingInterval &&
            <IntervalForm
              interval={this.state.editingInterval}
              onSave={newInterval => this.saveInterval(newInterval)}
              onCancel={() => this.setState({ editingInterval: null })}
            />
          }
          {this.state.manageScripts &&
            <ScriptListDialog
              titleKey='models.intervals.manageScripts'
              titleValues={{ name: this.state.manageScripts.timerName }}
              scripts={this.state.manageScripts.scripts || []}
              removeScript={id => this.removeScript(id)}
              onCancel={() => this.setState({ manageScripts: null })}
              newLink={'/scripts/new/event/Timer/Interval/' + this.state.manageScripts.timerName}
            />
          }
        </Row>
      </Fragment>
    );
  }
}

const IntervalsTable = injectIntl(IntervalsTableBase);

export default IntervalsTable;