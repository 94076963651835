import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import { injectIntl } from 'react-intl';
import SortableColumnHeader from './DataTableColumnHeader/SortableColumnHeader';
import SelectFilterColumnHeader from './DataTableColumnHeader/SelectFilterColumnHeader';
import TextFilterColumnHeader from './DataTableColumnHeader/TextFilterColumnHeader';


class DataColumnHeaderBase extends Component {

  shouldComponentUpdate(next) {
    return this.props.order !== next.order || this.props.filter !== next.filter || this.props.filterValue !== next.filterValue;
  }

  render() {
    const order = this.props.order ? this.props.order : false;
    const { children, onSortChange, columnKey, filter, filterValue, sortable, onFilterChange, cellMapFunction } = this.props;
    const filterOptions = Array.isArray(filter) && filter;
    const textFilter = typeof filter === 'string';
    return (
      <TableCell sortDirection={order}>
        <TextFilterColumnHeader
          key='text_header'
          enableFilter={textFilter}
          filterPattern={textFilter ? filter : undefined}
          filterValue={textFilter ? filterValue : undefined}
          onFilterChange={onFilterChange}
        >
          <SelectFilterColumnHeader
            key='select_header'
            filterOptions={filterOptions ? filterOptions : undefined}
            cellMapFunction={cellMapFunction}
            filterValue={!textFilter ? filterValue : undefined}
            onFilterChange={onFilterChange}
          >
            {sortable ?
              <SortableColumnHeader
                key='sortable_header'
                order={order}
                onSortChange={newOrder => onSortChange(columnKey, newOrder)}
              >
                {children}
              </SortableColumnHeader> :
              children
            }
          </SelectFilterColumnHeader>
        </TextFilterColumnHeader>
      </TableCell >
    );
  }
}

DataColumnHeaderBase.propTypes = {
  columnKey: PropTypes.string,
  sortable: PropTypes.bool,
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)]),
  filterValue: PropTypes.string,
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSortChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  cellMapFunction: PropTypes.func,
};

const DataColumnHeader = injectIntl(DataColumnHeaderBase);
export default DataColumnHeader;