import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

class SortableColumnHeaderBase extends React.Component {

  onSortClick() {
    const newOrder = this.props.order ? this.props.order === 'desc' ? 'asc' : false : 'desc';
    this.props.onSortChange(newOrder);
  }

  render() {
    const { onSortChange, intl } = this.props;
    const order = this.props.order ? this.props.order : false;
    if (typeof onSortChange === 'function') {
      return (
        <Tooltip
          key='column_sort_tooltip'
          title={intl.formatMessage({ id: 'tables.sort' })}
          enterDelay={300}
        >
          <TableSortLabel
            active={order !== false}
            direction={order ? order : 'desc'}
            onClick={() => this.onSortClick()}
          >
            {this.props.children}
          </TableSortLabel>
        </Tooltip>
      );
    } else {
      return this.props.children;
    }
  }
}

SortableColumnHeaderBase.propTypes = {
  onSortChange: PropTypes.func,
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

const SortableColumnHeader = injectIntl(SortableColumnHeaderBase);

export default SortableColumnHeader;