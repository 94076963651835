import { library } from '@fortawesome/fontawesome-svg-core';
import { faList, faCode, faHdd, faUpload, faCalendar, faExclamationTriangle, faChartArea, faCogs } from '@fortawesome/free-solid-svg-icons';

import VariablesView from './views/Variables/VariablesView';
import DevicesRouter from './views/Devices/DevicesRouter';
import DevicesConfigView from './views/Devices/DevicesConfigView';
import ExportDevicesRouter from './views/ExportDevices/ExportDevicesRouter';
import LoginView from './views/LoginView';
import SchedulerView from './views/Scheduler/SchedulerView';
import ScriptsRouter from './views/Scripts/ScriptsRouter';
import AlertsRouter from './views/Alerts/AlertsRouter';
import CollectionsView from './views/Collections/CollectionsView';
import { NetconfigView } from './views/System/NetconfigView';

import TestView from './views/TestView';
import AlertsIcon from './views/AlertsIcon';
import { BackupView } from './views/System/BackupView';
import { CloudView } from './views/System/CloudView';
import { StorageView } from './views/System/StorageView';
import { RebootView } from './views/System/RebootView';
import { GeneralView } from './views/System/GeneralView';
import { WifiView } from './views/System/WifiView';
import { ExpansionView } from './views/System/ExpansionView';
import { MqttServerView } from './views/System/MqttServerView';
import { DevicesServerView } from './views/System/DevicesServerView';

// Import FontAwesome Icons
library.add(
  faList,
  faHdd,
  faUpload,
  faCalendar,
  faCode,
  faExclamationTriangle,
  faChartArea,
  faCogs
);

// Routes with navigation=true need to set title and optionally icon
const routes = [
  { path: 'login', ViewComponent: LoginView, disableLayout: true },
  { path: '', title: 'variables', ViewComponent: VariablesView, icon: 'list', navigation: true },
  {
    path: 'devices', title: 'devices', icon: 'hdd', navigation: true, ViewComponent: DevicesRouter, isRouter: true, routes: [
      { path: 'devices', title: 'devices', ViewComponent: DevicesConfigView, params: { tab: 'devices' } },
      { path: 'modules', title: 'moduleInstances', ViewComponent: DevicesConfigView, params: { tab: 'modules' } }
    ]
  },
  { path: 'export', title: 'export', ViewComponent: ExportDevicesRouter, icon: 'upload', navigation: true, isRouter: true },
  {
    path: 'scheduler', title: 'scheduler', ViewComponent: SchedulerView, icon: 'calendar', navigation: true, routes: [
      { path: 'calendars', title: 'calendars', ViewComponent: SchedulerView, params: { tab: 'calendars' } },
      { path: 'schedules', title: 'schedules', ViewComponent: SchedulerView, params: { tab: 'schedules' } },
      { path: 'intervals', title: 'intervals', ViewComponent: SchedulerView, params: { tab: 'intervals' } }
    ]
  },
  { path: 'scripts', title: 'scripts', ViewComponent: ScriptsRouter, icon: 'code', navigation: true, isRouter: true },
  { path: 'alerts', title: 'alerts', ViewComponent: AlertsRouter, IconComponent: AlertsIcon, navigation: true, isRouter: true },
  { path: 'collections', title: 'collections', ViewComponent: CollectionsView, icon: 'chart-area', navigation: true },
  {
    path: 'system', icon: 'cogs', title: 'system', navigation: true, routes: [
      { path: 'general', title: 'general', ViewComponent: GeneralView },
      { path: 'devicesServer', title: 'devicesServer', ViewComponent: DevicesServerView },
      { path: 'network', title: 'network', ViewComponent: NetconfigView },
      { path: 'wifi', title: 'wifi', ViewComponent: WifiView },
      { path: 'mqttServer', title: 'mqttServer', ViewComponent: MqttServerView },
      { path: 'expansions', title: 'expansions', ViewComponent: ExpansionView },
      { path: 'storage', title: 'storage', ViewComponent: StorageView },
      { path: 'cloud', title: 'cloud', ViewComponent: CloudView },
      { path: 'backup', title: 'backupRestore', ViewComponent: BackupView },
      { path: 'reboot', title: 'reboot', ViewComponent: RebootView }
    ]
  },
  { path: 'test', title: 'test', ViewComponent: TestView, navigation: false }
];

export default routes;

/* pending routes to add
      <NavItem>
        <NavLink href="#/">
          <FontAwesomeIcon icon="chart-bar" />
          <NavLinkText>Dispositivos</NavLinkText>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#/">
          <FontAwesomeIcon icon="code" />
          <NavLinkText>Gestión de scripts</NavLinkText>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#/">
          <FontAwesomeIcon icon="calendar" />
          <NavLinkText>Horarios</NavLinkText>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#/">
          <FontAwesomeIcon icon="tachometer-alt" />
          <NavLinkText>Scadas</NavLinkText>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#/">
          <FontAwesomeIcon icon="sliders-h" />
          <NavLinkText>Escenas</NavLinkText>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#/">
          <FontAwesomeIcon icon="puzzle-piece" />
          <NavLinkText>Módulos</NavLinkText>
        </NavLink>
      </NavItem>
*/
