import eventBus, { eventBusTopics } from '../lib/eventBus';

export const handleAPIError = (response, translationsKey, intl, arrayAttributesTranslationKeys) => {
  console.error('API returned error', response);
  let errors = response.errors;
  if (errors && Object.keys(errors).length !== 0) {
    // Several form errors to print (common field missing, validation not pass...)
    errors = transformAPIErrors(response.errors);
    eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'form', errors, translationsKey });
  } else {
    eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: response.className, data: response.data });
  }
  return errors;
};

const transformAPIErrors = (errors) => {
  if (!errors) {
    return errors;
  }
  let object = {};
  Object.keys(errors).forEach(k => {
    let value = errors[k];
    if (Array.isArray(value)) {
      object[k] = value.map(err => transformError(err));
    } else if (typeof value === 'string') {
      object[k] = value;
    } else {
      let objectArray = {};
      Object.keys(value).forEach(index => {
        objectArray[index] = transformAPIErrors(value[index]);
      });
      object[k] = objectArray;
    }
  });
  return object;
};

export const getErrorsResume = (intl, errors, translationsKey, arrayAttributesTranslationKeys = {}) => {
  if (!errors) return [];
  const result = [];
  Object.entries(errors).forEach(([field, fieldErrors]) => {
    if (Array.isArray(fieldErrors)) {
      fieldErrors.forEach(error => {
        const fieldTextId = `${translationsKey}.${field}`;
        const fieldText = intl.messages[fieldTextId] ? intl.formatMessage({ id: fieldTextId }) : field;
        const [errorKey, ...errorParams] = error.split('|');
        const errorText = intl.formatMessage({ id: `validators.${errorKey}` }, errorParams);
        result.push(fieldText + ': ' + errorText);
      });
    } else {
      if (errors[field] && !Array.isArray(errors[field])) {
        let attributeTranslationsKey = arrayAttributesTranslationKeys[field];
        Object.entries(errors[field]).forEach(([index, fieldErrors]) => {
          const varText = intl.formatMessage({ id: `${translationsKey}.${field}` }) + ' #' + index;
          Object.entries(fieldErrors).forEach(([field, fieldErrors]) => {
            fieldErrors.forEach(error => {
              const fieldTextId = `${attributeTranslationsKey}.${field}`;
              const fieldText = intl.messages[fieldTextId] ? intl.formatMessage({ id: fieldTextId }) : field;
              const [errorKey, ...errorParams] = error.split('|');
              const errorText = intl.formatMessage({ id: `validators.${errorKey}` }, errorParams);
              result.push(`(${varText}) ${fieldText}: ${errorText}`);
            });
          });
        });
      }
    }
  });

  return result;
};

const transformError = (error) => {
  if (error.type === 'pattern' && error.params.pattern === '^[A-Za-z_][0-9A-Za-z_-]+$') {
    return 'identifier';
  } else if (error.type === 'type') {
    if (error.params.type === 'number') {
      return 'numeric';
    }
  }
  return error.type;
};

