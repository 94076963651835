import React, { Fragment } from 'react';
import { SmallIconButton } from '../../Icons';
import BuildIcon from '@material-ui/icons/Build';
import { InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DynamicFormField } from '../../Dynamics/DynamicFormField';
import { ValidationChain } from '../Validation/ValidationChain';
import { Row } from '../../GridModules/Row';

export class IPv4CIDRHelper extends React.Component {

  static defaultProps = {
    value: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      ip: '',
      mask: '',
      showDialog: false,
      errors: {}
    };
  }

  tryParse() {
    if (!this.props.value) return { ip: '', mask: '' };
    const parts = this.props.value.split('/');
    let bitCount = Number(parts[1] || 24);
    const mask = [0, 1, 2, 3].map(i => {
      const n = Math.min(bitCount, 8);
      bitCount -= n;
      return 256 - Math.pow(2, 8 - n);
    }).join('.');
    return { ip: parts[0], mask };
  }

  onConvert() {
    // let mask = (parts[1] || '').split('.').map(Number).filter(Number.isInteger);
    const errors = this.validationChain.getAndDisplayErrors('models.ip');
    if (!Object.keys(errors).length) {
      const { ip, mask } = this.state;
      const bits = mask.split('.').map(Number).map(i => i.toString(2)
        .lastIndexOf('1') + 1).reduce((sum, next) => next + sum, 0);
      this.props.onChange(`${ip}/${bits}`);
      this.setState({ showDialog: false });
    } else {
      this.setState({ errors });
    }
  }

  onShowDialog() {
    const { ip, mask } = this.tryParse();
    setImmediate(() => this.setState({ ip, mask, showDialog: true }));
  }

  render() {
    const { showDialog, errors } = this.state;
    this.validationChain = this.props.validationChain || new ValidationChain();
    let dialog;
    if (showDialog) {
      const { ip, mask } = this.state;
      dialog = (
        <Dialog
          open fullWidth disableBackdropClick
          aria-labelledby="bacnet-mac-helper"
          aria-describedby="bacnet-mac-helper"
        >
          <DialogTitle id="bacnet-mac-helper">
            <FormattedMessage id='helpers.IPv4CIDR' />
          </DialogTitle>
          <DialogContent>
            <Row>
              <DynamicFormField
                field='ip'
                fieldType='string'
                value={ip}
                errors={errors.ip}
                translationKey='models.ip'
                validators={['required', 'IPv4']}
                validationChain={this.validationChain}
                onChange={ip => this.setState({ ip: ip.split(',').join('.') })}
              />
            </Row>
            <Row>
              <DynamicFormField
                field='netmask'
                fieldType='string'
                value={mask}
                errors={errors.mask}
                translationKey='models.ip'
                validators={['required', 'netmask4']}
                validationChain={this.validationChain}
                onChange={mask => this.setState({ mask })}
              />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => this.setState({ showDialog: false })}>
              <FormattedMessage id="app.cancel" />
            </Button>
            <Button variant="contained" color="primary" onClick={() => this.onConvert()}>
              <FormattedMessage id={'app.convert'} />
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return <Fragment>
      <InputAdornment key='ipv4-adornment' position='end'>
        <SmallIconButton
          aria-owns='simple-popper'
          aria-haspopup="true"
          variant="contained"
          onClick={() => this.onShowDialog()}
        >
          <BuildIcon />
        </SmallIconButton>
      </InputAdornment>
      {showDialog && dialog}
    </Fragment>;
  }
}
