import React, { Fragment } from 'react';
import { identity } from 'lodash';
import { SmallIconButton } from '../../Icons';
import BuildIcon from '@material-ui/icons/Build';
import { InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DynamicFormField } from '../../Dynamics/DynamicFormField';
import { ValidationChain } from '../Validation/ValidationChain';
import { Row } from '../../GridModules/Row';

// const PORT_REGEX = /^[0-9a-zA-Z]{0-4}$/;

export class BacnetMACHelper extends React.Component {

  static defaultProps = {
    value: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      ip: '',
      port: '',
      showDialog: false,
      errors: {}
    };
  }

  tryParse() {
    const parts = this.props.value.split(':').filter(identity);
    const ip = parts.splice(0, 4)
      .map(hex => parseInt(hex, 16))
      .map(i => isNaN(i) ? 0 : i)
      .join('.');
    let port = parts.splice(0, 2).join('');
    if (port) port = '0x' + port;
    return { ip, port };
  }

  invalidPort() {
    return false;
  }

  onConvert() {
    const errors = this.validationChain.getAndDisplayErrors('models.ip');
    const { ip, port } = this.state;
    if (!Object.keys(errors).length) {
      let mac = ip.split('.').map(b => Number(b).toString(16).padStart(2, '0')).join(':');
      let portHex = (typeof port === 'string')
        ? port.substring(2, 6)
        : Number(port).toString(16);
      portHex = portHex.padStart(4, '0');
      mac += `:${portHex.substring(0, 2)}:${portHex.substring(2, 4)}`;
      this.props.onChange(mac.toUpperCase());
      this.setState({ showDialog: false });
    } else {
      this.setState({ errors });
    }
  }

  onShowDialog() {
    const { ip, port } = this.tryParse();
    setImmediate(() => this.setState({ ip, port, showDialog: true }));
  }

  render() {
    const { ip, port, showDialog, errors } = this.state;
    this.validationChain = new ValidationChain();
    return <Fragment>
      <InputAdornment key='bacnet-adornment' position='end'>
        <SmallIconButton
          aria-owns='simple-popper'
          aria-haspopup="true"
          variant="contained"
          onClick={() => this.onShowDialog()}
        >
          <BuildIcon />
        </SmallIconButton>
      </InputAdornment>
      {showDialog && <Dialog
        aria-labelledby="bacnet-mac-helper"
        aria-describedby="bacnet-mac-helper"
        open={true}
        fullWidth={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="bacnet-mac-helper">
          <FormattedMessage id='helpers.bacnetMAC' />
        </DialogTitle>
        <DialogContent>
          <Row>
            <DynamicFormField
              field='ip'
              fieldType='string'
              value={ip}
              errors={errors.ip}
              translationKey='models.ip'
              validators={['required', 'IPv4']}
              validationChain={this.validationChain}
              onChange={ip => this.setState({ ip: ip.split(',').join('.') })}
            />
          </Row>
          <Row>
            <DynamicFormField
              field='port'
              fieldType='binaryNumber'
              value={port}
              errors={errors.port}
              translationKey='models.ip'
              validators={['required']}
              validationChain={this.validationChain}
              onChange={port => this.setState({ port })}
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => this.setState({ showDialog: false })}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onConvert()}>
            <FormattedMessage id={'app.convert'} />
          </Button>
        </DialogActions>
      </Dialog>}
    </Fragment>;
  }
}
