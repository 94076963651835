export const timeFactors = [1, 1000, 60000, 3600 * 1000, 24 * 3600 * 1000].reverse();
export const timeUnits = ['ms', 's', 'm', 'h', 'd'].reverse();

export const msToNaturalTime = ms => {
  if (!ms) return { value: ms, unit: '' };
  let factorIdx = timeFactors.findIndex(f => ms % f === 0);
  if (factorIdx === -1) factorIdx = 1;
  const factor = timeFactors[factorIdx];
  const unit = timeUnits[factorIdx];
  return { value: ms / factor, unit };
};

export const msToLabel = ms => {
  if (!ms) return '';
  const time = msToNaturalTime(ms);
  return `${time.value} ${time.unit}`;
};
