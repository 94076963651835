import React from 'react';
import styled from 'styled-components';
import StyleVariables from '../theme/StyleVariables';
import { Badge, IconButton } from '@material-ui/core';
import { alertsService } from '../lib/service';
import { alertEvents } from '../lib/apiHelpers';
import { AppMenuIcon } from '../components/Layout/AppDrawer/components/AppMenu';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const StyledBadge = styled(Badge)`
  &.warning > span {
    background-color: #BA9C09;
  }
  > span {
    top: 0;
    right: 27px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    height: 20px;
    width: initial;
    min-width: 20px;
    padding: 0 4px;
    border-radius: 10px;
    font-weight: 500;
  }
`;

class AlertsIconBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alerts: []
    };
    this.alertEventHandler = ev => {
      const newAlerts = this.state.alerts.filter(a => a.id !== ev.alert.id);
      this.setState({
        alerts: ev.action === alertEvents.expire ? newAlerts : [ev.alert, ...newAlerts]
      });
      if (ev.action === alertEvents.new) {
        this.displayAlertSnack(ev.alert);
      } else if (ev.action === alertEvents.expire) {
        this.displayAlertExpiredSnack(ev.alert);
      }
    };
  }

  getMessageData(alert) {
    const messageData = { ...alert };
    if (typeof alert.payload === 'object') Object.assign(messageData, alert.payload);
    if (typeof alert.dynamicPayload === 'object') Object.assign(messageData, alert.dynamicPayload);
    return messageData;
  }

  displayAlertSnack(alert) {
    const { enqueueSnackbar, closeSnackbar, intl } = this.props;
    const customTranslationExists = !!intl.messages[`notifications.alert.${alert.type}`];
    const message = intl.formatMessage({
      id: customTranslationExists ? `notifications.alert.${alert.type}` : 'notifications.alert.default'
    }, this.getMessageData(alert));
    const key = `alert_${alert.id}`;
    if (alert.tags.includes('system')) {
      enqueueSnackbar(message, {
        key,
        variant: 'error',
        autoHideDuration: null,
        action: <SnackCloseAction id={key} closeSnackbar={closeSnackbar} />
      });
    } else {
      enqueueSnackbar(message, {
        key,
        variant: 'warning',
        action: [
          <Link key='view' to='/alerts'><Button size='small' style={{ color: StyleVariables.CountertypeTextColor }}><FormattedMessage id='app.see' /></Button></Link>,
          <SnackCloseAction key='close' id={key} closeSnackbar={closeSnackbar} />
        ]
      });
    }
  }

  displayAlertExpiredSnack(alert) {
    const { enqueueSnackbar, intl, closeSnackbar } = this.props;
    const expireTranslationExists = !!intl.messages[`notifications.alertExpiration.${alert.type}`];
    const message = intl.formatMessage({
      id: expireTranslationExists ? `notifications.alertExpiration.${alert.type}` : 'notifications.alertExpiration.default'
    }, this.getMessageData(alert));
    closeSnackbar(`alert_${alert.id}`);
    const closeKey = `alert_${alert.id}_finish`;
    enqueueSnackbar(message, {
      key: closeKey,
      variant: 'success',
      action: <SnackCloseAction id={closeKey} closeSnackbar={closeSnackbar} />
    });
  }

  componentDidMount() {
    alertsService.find({ query: { active: true } }).then(alerts => this.setState({ alerts: alerts.data }));
    alertsService.on('Alert', this.alertEventHandler);
  }

  componentWillUnmount() {
    alertsService.removeListener('Alert', this.alertEventHandler);
  }

  render() {
    if (this.state.alerts.length) {
      const cssClass = this.state.alerts.some(a => a.tags.includes('system')) ? 'error' : 'warning';
      return (
        <StyledBadge className={cssClass} badgeContent={this.state.alerts.length} color='secondary'>
          <AppMenuIcon icon='exclamation-triangle' />
        </StyledBadge>
      );
    } else {
      return <AppMenuIcon icon='exclamation-triangle' />;
    }
  }

}

AlertsIconBase.propTypes = {};

const SnackCloseAction = ({ id, closeSnackbar }) => <IconButton key='close' aria-label='Close' onClick={() => closeSnackbar(id)}><CloseIcon /></IconButton>;

const AlertsIcon = injectIntl(withSnackbar(AlertsIconBase));

export default AlertsIcon;