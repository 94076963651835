import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Dialog } from '../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions, Tooltip, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { BlockHeader, BlockTitle, BlockActions, BlockBody } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import DataTable from '../../components/Table/DataTable';
import CommonFormOptions from '../components/CommonFormOptions';

class ScriptListDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderRowOptions(row) {
    if (row.readOnly) {
      return <CommonFormOptions
        onView={() => this.props.history.push(`/scripts/edit/${row.id}`)}
      />;
    } else {
      return <CommonFormOptions
        onRemove={() => this.props.removeScript(row.id)}
        onEdit={() => this.props.history.push(`/scripts/edit/${row.id}`)}
      />;
    }
  }

  render() {
    const { intl, scripts, titleKey, titleValues, newLink } = this.props;
    const columns = [
      { data: 'enabled' },
      { data: 'name' },
      { data: this.renderRowOptions.bind(this) }
    ];

    return (
      <Dialog
        aria-labelledby="scripts-list-dialog-title"
        aria-describedby="scripts-list-dialog-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="scripts-list-dialog-title">
          <FormattedMessage id={titleKey} values={titleValues} />
        </DialogTitle>
        <DialogContent>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id='models.scripts.scripts' />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={intl.formatMessage({ id: 'models.scripts.new' })} >
                  <Link to={newLink}>
                    <IconButton >
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <DataTable
                translationKey="models.scripts"
                columns={columns}
                sortableColumns={true}
                data={scripts}
                loading={this.state.loading}
              />
            </BlockBody>
          </TableBlock>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ScriptListDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  removeScript: PropTypes.func.isRequired
};

export default withRouter(injectIntl(ScriptListDialog));