import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { injectIntl } from 'react-intl';
import { Tab } from '@material-ui/core';
import { StyledTabs, TabContainer } from '../../components/Tab/tabs';
import DevicesView from './DevicesView';
import ModuleInstancesView from './ModuleInstancesView';

const DevicesConfigView = withRouter(injectIntl(({ tab, intl, history }) => {
  const TabContent = tab === 'devices' ? DevicesView : ModuleInstancesView;
  return (
    <Fragment>
      <StyledTabs
        centered
        variant='fullWidth'
        indicatorColor='primary'
        value={tab}
        onChange={(_event, tab) => history.push(tab)}
      >
        <Tab label={intl.formatMessage({ id: 'section.devices' })} value='devices' />
        <Tab label={intl.formatMessage({ id: 'section.moduleInstances' })} value='modules' />
      </StyledTabs>
      <TabContainer>
        <TabContent />
      </TabContainer>
    </Fragment>
  );
}));

export default DevicesConfigView;
