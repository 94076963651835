import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fitsQuery } from '../../lib/apiHelpers';
import { Dialog } from '../../components/custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { BlockBody } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { IntlDataTable } from '../../intl-components/IntlDataTable';
import { isEqual } from 'lodash';

class VariablesSelectorDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataVersion: 0,
      filtered: this.props.availableVariables,
      selection: [],
      loading: false,
      query: {},
      pagination: { page: 0, rowsPerPage: 25 }
    };
  }

  onSave() {
    const selection = this.state.filtered.filter((e, i) => this.state.selection.includes(i));
    this.props.onSave(selection);
  }

  onSelectionChange(selection) {
    this.setState({
      selection
    });
  }

  onSimpleSelect(selected, value) {
    this.setState({
      selection: [selected]
    }, () => this.onSave());
  }

  onFilterChange(columnName, value) {
    const query = { ...this.state.query, [columnName]: value };
    const queryFn = fitsQuery(query);
    const filtered = this.props.availableVariables.map((e, i) => queryFn(e) && e).filter(i => i !== false);
    this.setState({
      filtered,
      query,
      selection: [],
      dataVersion: this.state.dataVersion + 1
    });
  }

  onPaginationChange({ page, rowsPerPage }) {
    this.setState({
      pagination: { page, rowsPerPage }
    });
  }

  shouldComponentUpdate(_nextProps, nextState) {
    if (!isEqual(this.state.pagination, nextState.pagination)) {
      return true;
    }
    if (this.state.dataVersion !== nextState.dataVersion) {
      return true;
    }
    if (this.state.filtered !== nextState.filtered) {
      return true;
    }
    if (this.state.loading !== nextState.loading) {
      return true;
    }
    return false;
  }

  render() {
    const { translationKey, allDataTypes, allDeviceCodes, enableMultipleSelection } = this.props;
    const { filtered, dataVersion, loading, query, pagination } = this.state;

    const columns = [
      { data: 'deviceCode', sortable: true, filter: allDeviceCodes },
      { data: 'variableCode', sortable: true, filter: '&*' },
      { data: 'dataType', filter: allDataTypes },
      { data: 'canWrite', sortable: true }
    ];

    const firstRow = pagination.page * pagination.rowsPerPage;
    const pageData = {
      total: filtered.length,
      limit: pagination.rowsPerPage,
      skip: firstRow,
      data: filtered.slice(firstRow, firstRow + pagination.rowsPerPage)
    };

    return (
      <Dialog fullWidth
        aria-labelledby="variables-selector-form-title"
        aria-describedby="variables-selector-form-title"
        open={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle id="variables-selector-form-title">
          <FormattedMessage id={`${translationKey}.title`} />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <TableBlock>
              <BlockBody>
                <IntlDataTable
                  key={`variables-selector-${dataVersion}`}
                  enableMultipleSelection={enableMultipleSelection}
                  onSelectionChange={this.onSelectionChange.bind(this)}
                  translationKey="models.variableSelector"
                  columns={columns}
                  filters={query}
                  loading={loading}
                  onFilterChange={(column, filter) => this.onFilterChange(column, filter)}
                  pageData={pageData}
                  onRowClick={enableMultipleSelection ? undefined : this.onSimpleSelect.bind(this)}
                  onPaginationChange={(ev) => this.onPaginationChange(ev)}
                />
              </BlockBody>
            </TableBlock>

          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          {enableMultipleSelection &&
            <Button variant="contained" color="primary" onClick={this.onSave.bind(this)}>
              <FormattedMessage id={'app.select'} />
            </Button>
          }
        </DialogActions>
      </Dialog >
    );
  }
}

VariablesSelectorDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  availableVariables: PropTypes.arrayOf(PropTypes.object).isRequired,
  enableMultipleSelection: PropTypes.bool.isRequired
};

export default injectIntl(VariablesSelectorDialog);