import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Tab } from '@material-ui/core';
import { StyledTabs, TabContainer } from '../../components/Tab/tabs';
import CalendarTable from './Calendars/CalendarTable';
import SchedulesTable from './Schedules/SchedulesTable';
import IntervalsTable from './Intevals/IntervalsTable';
import { withRouter } from 'react-router-dom';

const tabs = {
  calendars: CalendarTable,
  schedules: SchedulesTable,
  intervals: IntervalsTable
};

const SchedulerView = withRouter(injectIntl(({ tab = 'calendar', intl, history }) => {
  const TabContent = tabs[tab];
  return (
    <Fragment>
      <StyledTabs
        centered
        variant='fullWidth'
        indicatorColor='primary'
        value={tab}
        onChange={(_e, tab) => history.push(tab)}
      >
        <Tab label={intl.formatMessage({ id: 'section.calendars' })} value='calendars' />
        <Tab label={intl.formatMessage({ id: 'section.schedules' })} value='schedules' />
        <Tab label={intl.formatMessage({ id: 'section.intervals' })} value='intervals' />
      </StyledTabs>
      <TabContainer>
        <TabContent />
      </TabContainer>
    </Fragment>
  );
}));

export default SchedulerView;