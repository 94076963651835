import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AlertsTable from './AlertsTable';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import { BlockTitle, BlockHeader, BlockBody } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { injectQueryString } from '../../components/queryUtils';

const AlertsBase = ({ query, updateQuery }) => {
  return (
    <Row>
      <TableBlock>
        <BlockHeader>
          <BlockTitle>
            <FormattedMessage id="section.alerts" />
          </BlockTitle>
        </BlockHeader>
        <BlockBody>
          <AlertsTable
            query={query}
            onQueryChange={updateQuery}
          />
        </BlockBody>
      </TableBlock>
    </Row>
  );
};

AlertsBase.propTypes = {
  query: PropTypes.object
};

const AlertsView = injectQueryString(AlertsBase);

export default AlertsView;