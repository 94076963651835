import React from 'react';
import { omit } from 'lodash';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import styled from 'styled-components';

import { FormControlLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Switch } from '@material-ui/core';
import { Select, TextField } from '../../Form/Form';
import ObjectFormField from './ObjectFormField';
import ItemListFormField from './ItemListFormField';
import ValueConvertersFormField from './ValueConvertersFormField';
import VariableFormField from './VariableFormField';
import DatasourcesFormField from './DatasourcesFormField';
import { MillisecondsSelector } from '../../Form/MillisecondsSelector';
import { BinaryNumberField } from '../../Form/BinaryNumberField';
import { BacnetMACHelper } from '../../Form/helper/BacnetMACHelper';
import { DateTimeField } from './DateTimeField';
import { IPv4CIDRHelper } from '../../Form/helper/IPv4CIDRHelper';
import { IntlTextField } from '../../../intl-components/Form';
import JsFormField from './JsFormField';

const DisabledSpan = styled.span`
color: rgba(0, 0, 0, 0.38);
`;

const ReadOnlyIntlTextField = styled(IntlTextField)`
input {
  color: rgba(0, 0, 0, 0.87);
}
`;

class DynamicFormFieldSwitchElement extends React.Component {

  getLabelId() {
    return `${this.props.translationKey}.${this.props.field}`;
  }

  getLabel() {
    return this.props.intl.formatMessage({
      id: this.getLabelId()
    });
  }

  getHelper() {
    if (this.props.disabled) return;
    switch (this.props.type.helper) {
      case 'bacnetMAC':
        return <BacnetMACHelper key='bacnet-mac-helper' onChange={this.props.onChange} value={this.props.value} />;
      case 'IPv4CIDR':
        return <IPv4CIDRHelper key='cidr-helper' onChange={this.props.onChange} value={this.props.value} />;
      default:
    }
  }

  getHelperText() {
    if (this.props.tableMode) {
      // don't show helper text inside tables
      return;
    }
    let messageId = `${this.props.translationKey}.${this.props.field}HelperText`;
    if (this.props.intl.messages[messageId]) {
      return this.props.intl.formatMessage({ id: messageId });
    }
  }

  getTips(tips = this.props.tips, prefix = '') {
    if (tips == null) {
      return;
    }
    const tipsArray = Array.isArray(tips) ? tips : [tips];
    if (tipsArray.length) {
      return tipsArray.map(htk => {
        const [key, ...params] = htk.split('|');
        if (this.props.intl.messages[prefix + key]) {
          return this.props.intl.formatMessage({ id: prefix + key }, params);
        } else {
          return htk;
        }
      });
    }
    return;
  }

  renderLabel(value, disabled, translationKey) { //TODO: replace with better component
    if (value == null) {
      if (this.props.value == null) {
        value = '';
      } else {
        value = this.props.value;
      }
    }
    if (typeof value === 'function') {
      value = value();
    }
    if (translationKey) {
      const translationId = translationKey + '.' + value;
      if (this.props.intl.messages[translationId]) {
        value = this.props.intl.formatMessage({ id: translationId });
      }
    }

    if (this.props.readOnly || this.props.tableMode) {
      if (disabled) {
        return <DisabledSpan>{value}</DisabledSpan>;
      } else {
        return <span>{value}</span>;
      }
    } else {
      return <ReadOnlyIntlTextField
        label={this.getLabelId()}
        value={value}
        InputProps={{
          readOnly: true,
          disabled: true
        }}
      />;
    }
  }

  renderString() {
    if (this.props.readOnly) {
      return this.renderLabel();
    }
    const margin = this.props.tableMode ? 'dense' : 'normal';
    const { multiline, value, error, errors, onChange, type, InputProps, ...props } = this.props;
    const helper = this.getHelper();
    const inputProps = { endAdornment: helper, ...InputProps };
    return (
      <TextField
        {...omit(props, 'translationKey', 'tableMode', 'contextValue')}
        multiline={!!multiline}
        value={value || ''}
        placeholder={type.defaultValue}
        margin={margin}
        label={this.getLabel()}
        helperText={this.getHelperText()}
        tips={this.getTips()}
        errors={error && this.getTips(errors, 'validators.')}
        error={!!error}
        onChange={ev => onChange((type.emptyToNull && (ev.target.value === '')) ? undefined : ev.target.value)}
        InputProps={inputProps}
        {...type.props}
      />
    );
  }

  renderDateTime() {
    const { type, error, ...props } = this.props;
    return <DateTimeField
      {...omit(props, 'translationKey', 'tableMode', 'contextValue')}
      {...type.props}
      error={!!error}
      label={this.getLabel()}
      helperText={this.getHelperText()}
    />;
  }

  renderBoolean() {
    const margin = this.props.tableMode ? 'dense' : 'normal';
    const control = (
      <Switch
        {...this.props.type.props}
        inputProps={this.props.type.inputProps}
        disabled={this.props.disabled}
        checked={!!this.props.value}
        onChange={(ev, enabled) => this.props.onChange(enabled)}
        color="primary"
      />
    );
    if (this.props.tableMode) {
      return control;
    } else {
      return (
        <FormControlLabel
          label={this.getLabel()}
          margin={margin}
          control={control}
        />
      );
    }
  }

  renderNumber() {
    if (this.props.readOnly) {
      return this.renderLabel();
    }
    const margin = this.props.tableMode ? 'dense' : 'normal';
    const { error, onChange, type, ...props } = this.props;
    const value = (this.props.value || this.props.value === 0) ? this.props.value : '';
    return (
      <TextField
        {...omit(props, 'translationKey', 'tableMode', 'contextValue')}
        type='number'
        value={value}
        placeholder={type.defaultValue != null ? '' + type.defaultValue : undefined}
        margin={margin}
        label={this.getLabel()}
        helperText={this.getHelperText()}
        tips={this.getTips()}
        error={!!error}
        onChange={ev => onChange(((ev.target.value == null) || (ev.target.value === '')) ? undefined : Number(ev.target.value))}
      />
    );
  }

  renderBinaryNumber() {
    if (this.props.readOnly) {
      return this.renderLabel();
    }
    const margin = this.props.tableMode ? 'dense' : 'normal';
    const { error, onChange, type, ...props } = this.props;
    return (
      <BinaryNumberField
        {...omit(props, 'translationKey', 'tableMode', 'contextValue')}
        value={this.props.value}
        defaultValue={type.defaultValue}
        margin={margin}
        label={this.getLabel()}
        helperText={this.getHelperText()}
        tips={this.getTips()}
        error={!!error}
        onChange={onChange}
      />
    );
  }

  renderMillisecondsSelector() {
    if (this.props.readOnly) {
      return this.renderLabel();
    }
    const margin = this.props.tableMode ? 'dense' : 'normal';
    const { error, errors, onChange, type, ...props } = this.props;
    return (
      <MillisecondsSelector
        {...omit(props, 'translationKey', 'tableMode', 'contextValue')}
        value={this.props.value}
        defaultValue={type.defaultValue}
        margin={margin}
        label={this.getLabel()}
        helperText={this.getHelperText()}
        tips={this.getTips()}
        errors={error && this.getTips(errors, 'validators.')}
        error={!!error}
        onChange={ms => onChange(ms)}
      />
    );
  }

  renderItemList(datatype) {
    if (this.props.readOnly) {
      return this.renderLabel(`${(this.props.value || []).length} items`, true);
    }
    const margin = this.props.tableMode ? 'dense' : 'normal';
    return <ItemListFormField
      {...this.props}
      {...this.props.type.props}
      inputProps={this.props.type.inputProps}
      dataType={datatype}
      margin={margin}
      label={this.getLabel()}
      helperText={this.getHelperText()}
      tips={this.getTips()}
      value={this.props.value || []}
      error={!!this.props.error}
    />;
  }

  renderSelect(options, fieldKey) {
    const { field, intl, readOnly, translationKey, contextValue, disabled, error, tableMode } = this.props;
    let value = this.props.value || this.props.type.defaultValue;
    if (options === null) {
      console.log(`Empty options for key ${field}`);
      options = [];
    }
    if (typeof options == 'function') {
      options = options(contextValue);
    }
    if (readOnly) {
      const option = options.find(o =>
        typeof o === 'object'
          ? value === get(o, 'value')
          : value === o
      );

      let optionText;
      if (option != null) {
        const shortText = get(option, 'shortText');
        optionText = typeof option === 'object'
          ? shortText || get(option, 'text')
          : '' + option;
        const translationId = get(option, 'translationId', `${translationKey}.form.${fieldKey || field}.${shortText || value}`);
        if (intl.messages[translationId]) {
          optionText = intl.messages[translationId];
        }
      }
      return this.renderLabel(optionText || value);
    }

    const margin = tableMode ? 'dense' : 'normal';
    return (
      <Select
        value={value}
        margin={margin}
        disabled={disabled}
        label={this.getLabel()}
        helperText={this.getHelperText()}
        tips={this.getTips()}
        error={!!error}
        onChange={ev => this.props.onChange(ev.target.value)}
      >
        {options.map((option, index) => this.getSelectItem({ key: index, option }))}
      </Select>
    );
  }

  getSelectItem({ key, option }) {
    const { translationKey, field, intl } = this.props;
    let optionValue, optionText;
    if (typeof option === 'object') {
      optionValue = get(option, 'value');
      optionText = get(option, 'text') || optionValue;
    } else {
      optionValue = option;
      optionText = (option != null) ? `${option}` : '';
    }
    const translationId = get(option, 'translationId', `${translationKey}.form.${field}.${optionValue}`);
    if (intl.messages[translationId]) {
      optionText = intl.messages[translationId];
    }
    return (
      <MenuItem key={key} value={optionValue === undefined ? '' : optionValue}>
        {optionText || ''}
      </MenuItem>
    );
  }

  renderObject() {
    if (this.props.readOnly) {
      return this.renderLabel(`${Object.keys(this.props.value || {}).length} items`, true);
    }
    return <ObjectFormField
      {...this.props}
    />;
  }

  renderJsField() {
    if (this.props.readOnly) {
      return this.renderLabel(this.props.value && this.props.value.length ? '<js content>' : '<empty>', true);
    }
    return <JsFormField
      {...this.props}
    />;
  }

  renderVariable(variables, deviceCodes, dataTypes) {
    if (this.props.readOnly) {
      return this.renderLabel(`${this.props.value}`, false);
    }
    return <VariableFormField
      {...this.props}
      label={this.getLabel()}
      helperText={this.getHelperText()}
      tips={this.getTips()}
      availableVariables={variables}
      allDeviceCodes={deviceCodes}
      allDataTypes={dataTypes}
    />;
  }

  renderValueConverters(availableConverters, isImportValueConverter) {
    if (this.props.readOnly) {
      return this.renderLabel(`${(this.props.value || []).length} items`, true);
    }
    return <ValueConvertersFormField
      {...this.props}
      label={this.getLabel()}
      helperText={this.getHelperText()}
      tips={this.getTips()}
      availableConverters={availableConverters}
      isImportValueConverter={isImportValueConverter}
    />;
  }

  renderDatasources(specByType) {
    if (this.props.readOnly) {
      return this.renderLabel(`${(this.props.value || []).length} items`, true);
    }
    return <DatasourcesFormField
      {...this.props}
      label={`${this.props.translationKey}.${this.props.field}`}
      helperText={this.getHelperText()}
      tips={this.getTips()}
      translationKey={`${this.props.translationKey}.datasource`}
      specByType={specByType}
    />;
  }

  render() {
    const { type, value } = this.props;
    if (type.type === 'label') {
      return this.renderLabel(undefined, undefined, type.translationKey);
    } else if (type.type === 'object') {
      return this.renderObject();
    } else if (type.type === 'jsFunctionContent') {
      return this.renderJsField();
    } else if (type.type === 'string') {
      return this.renderString();
    } else if (type.type === 'boolean') {
      return this.renderBoolean();
    } else if (type.type === 'number') {
      return this.renderNumber();
    } else if (type.type === 'binaryNumber') {
      //Allows decimal, '0x' hex and '0' octal
      return this.renderBinaryNumber();
    } else if (type.type === 'milliseconds') {
      return this.renderMillisecondsSelector();
    } else if (type.type === 'select') {
      return this.renderSelect(type.values, type.translationKey);
    } else if (type.type === 'datetime') {
      return this.renderDateTime(type.type);
    } else if (type.type === 'variable') {
      return this.renderVariable(type.availableVariables, type.deviceCodes, type.dataTypes);
    } else if (type.type === 'array' && ((type.items === 'number') || (type.items === 'integer') || (type.items === 'string'))) {
      return this.renderItemList(type.items);
    } else if (type.type === 'array' && (type.items === 'exportValueConverter')) {
      return this.renderValueConverters(type.availableConverters, false);
    } else if (type.type === 'array' && (type.items === 'importValueConverter')) {
      return this.renderValueConverters(type.availableConverters, true);
    } else if (type.type === 'array' && (type.items === 'datasource')) {
      return this.renderDatasources(type.specByType);
    } else if (type.type instanceof Array) {
      return this.renderSelect(type.type);
    } else {
      // debugger;
      console.log(`Unknown type of field '${this.props.field}': ${type.type}`);
      return <div>{value || ''}</div>;
    }
  }

}

const IntlDynamicFormFieldSwitchElement = injectIntl(DynamicFormFieldSwitchElement);
export default IntlDynamicFormFieldSwitchElement;
