import { lighten } from '../../node_modules/polished';

//
// Const
//

// Layout
const MarginSize = 24;                     //px
const HalfMarginSize = MarginSize / 2;     //px
const QuarterMarginSize = MarginSize / 4;  //px
const DoubleMarginSize = MarginSize * 2;   //px
const TripleMarginSize = MarginSize * 3;   //px

//Colors

const PrimaryColor = '#1e90ff';
const SecondaryColor = '#ed143d';

const HighlightColor = '#044a88';

const TextColor = '#444';
const PaleTextColor = '#999';
const CountertypeTextColor = '#FFF';
const LinkColor = HighlightColor;

const BgLightGreyColor = '#f5f7fd';
const BgMediumGreyColor = '#edf0fb';
const BgDarkColor = '#3f434e';
const BgDarkHoverColor = lighten(0.15, BgDarkColor);
const BgEditorGrayColor = '#f7f9f9';

const TableCellBorderColor = '#eaeefb';
const BorderColor = TableCellBorderColor;

//Sizes

const AppHeaderHeight = '60px';
const AppSidebarExpandedWidth = '240px';
const AppSidebarCollapsedWidth = '72px';
const NavSidebarListItemHeight = '40px';

const ScriptHelpWidth = '400px';

// Positions
const AppHeaderZindex = 50;
const NavSidebarZindex = 40;

// Appereance

const ComponentBlockShadow = '0 1px 3px 1px rgba(63, 67, 78, 0.1)';

const StyleVariables = {

  // Layout
  MarginSize: MarginSize + 'px',
  HalfMarginSize: HalfMarginSize + 'px',
  QuarterMarginSize: QuarterMarginSize + 'px',
  DoubleMarginSize: DoubleMarginSize + 'px',
  TripleMarginSize: TripleMarginSize + 'px',

  //Colors
  PrimaryColor,
  SecondaryColor,
  HighlightColor,
  TextColor,
  PaleTextColor,
  CountertypeTextColor,
  LinkColor,
  BgLightGreyColor,
  BgMediumGreyColor,
  BgDarkColor,
  BgDarkHoverColor,
  BgEditorGrayColor,
  TableCellBorderColor,
  BorderColor,

  //Sizes
  AppHeaderHeight,
  AppSidebarExpandedWidth,
  AppSidebarCollapsedWidth,
  NavSidebarListItemHeight,
  ScriptHelpWidth,

  // Positions
  AppHeaderZindex,
  NavSidebarZindex,

  // Appereance
  ComponentBlockShadow

};

export default StyleVariables;