import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Dialog } from '../custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';
import DynamicFormGroup from './DynamicFormGroup';
import { ValidationChain } from '../Form/Validation/ValidationChain';

class DynamicFormDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabledKeys: [],
      entry: props.entry
    };
  }

  onSave() {
    const errors = this.validationChain.getAndDisplayErrors(this.props.translationKey);
    if (!Object.keys(errors).length) {
      if (this.props.isMultipleEdition) {
        const data = this.state.enabledKeys.reduce((result, key) => Object.assign(result, { [key]: this.state.entry[key] }), {});
        this.props.onSave(data);
      } else {
        this.props.onSave(this.state.entry);
      }
    }
  }

  onSwitchEnabled = (key, isEnabled) => {
    if (isEnabled) {
      if (!this.state.enabledKeys.includes(key)) {
        this.setState({ enabledKeys: [...this.state.enabledKeys, key] });
      }
    } else {
      if (this.state.enabledKeys.includes(key)) {
        this.setState({ enabledKeys: this.state.enabledKeys.filter(k => k !== key) });
      }
    }
  }

  getEntry() {
    return this.props.onChange ? this.props.entry : this.state.entry;
  }

  onChange = (field, value) => {
    const entry = { ...this.getEntry(), [field]: value };
    if (this.props.onUncommitedChange != null) {
      this.props.onUncommitedChange(entry);
    }
    if (this.props.onChange) {
      this.props.onChange(entry);
    } else {
      this.setState({ entry });
    }
  }

  render() {
    const { translationKey, spec, onCancel, isMultipleEdition, title } = this.props;
    const { enabledKeys } = this.state;
    const entry = this.getEntry();
    this.validationChain = new ValidationChain();
    return (
      <Dialog
        aria-labelledby="form-title"
        aria-describedby="form-title"
        open={true}
        fullWidth={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="form-title">
          <FormattedMessage id={`${translationKey}.${title}`} />
        </DialogTitle>
        <DialogContent>
          <DynamicFormGroup
            spec={spec}
            data={entry}
            isMultipleEdition={isMultipleEdition}
            validationChain={this.validationChain}
            translationKey={translationKey}
            enabledKeys={enabledKeys}
            onChange={this.onChange}
            onChangeEnabledEntry={this.onSwitchEnabled}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id='app.save' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DynamicFormDialog.propTypes = {
  spec: PropTypes.object,
  entry: PropTypes.any,
  onSave: PropTypes.func,
  onUncommitedChange: PropTypes.func,
  onCancel: PropTypes.func,
  isMultipleEdition: PropTypes.bool
};

export default DynamicFormDialog;