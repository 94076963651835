import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SmallIconButton } from '../Icons';
import { faExternalLinkAlt, faQuestionCircle, faChevronLeft, faChevronRight, faQuestion, faTimes, faBook, faUser, faHdd } from '@fortawesome/free-solid-svg-icons';

export const FAIconButton = ({ icon, onClick }) => (
  <SmallIconButton
    variant="contained"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} />
  </SmallIconButton >
);

export const ICONS = {
  faExternalLinkAlt: faExternalLinkAlt.iconName,
  faQuestionCircle: faQuestionCircle.iconName,
  faChevronLeft: faChevronLeft.iconName,
  faChevromRight: faChevronRight.iconName,
  faQuestion: faQuestion.iconName,
  faTimes: faTimes.iconName,
  faBook: faBook.iconName,
  faUser: faUser.iconName,
  faHdd: faHdd.iconName
};

library.add([
  faExternalLinkAlt,
  faQuestionCircle,
  faChevronLeft,
  faChevronRight,
  faQuestion,
  faTimes,
  faBook,
  faUser,
  faHdd
]);