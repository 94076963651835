import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Dialog } from '../../custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ValidationChain } from '../../Form/Validation/ValidationChain';
import { Row } from '../../GridModules/Row';
import { debounce } from 'lodash';
import { BlockHeader, BlockTitle, BlockActions } from '../../GridModules/ComponentBlock/ComponentBlock';
import { IntlTextField, IntlFormHeaderIcon } from '../../../intl-components/Form';
import { DynamicFormField } from '../DynamicFormField';

class ObjectEditorDialog extends React.Component {

  constructor(props) {
    super(props);
    let valueObject = (typeof props.value === 'string') ? JSON.parse(props.value) : props.value;
    const object = this.getFormObject(valueObject);
    this.state = {
      object,
      json: JSON.stringify(this.getValueObject(object), null, 2),
      showJSON: false,
      jsonError: false,
      errors: undefined
    };

    this.debouncedUpdateJSON = debounce(json => {
      try {
        this.setState({ object: this.getFormObject(JSON.parse(json)), jsonError: false });
      } catch (err) {
        this.setState({ jsonError: true });
      }
    }, 300);
  }

  getValueObject(object) {
    let value = {};
    object.forEach(entry => value[entry.name] = entry.value);
    return value;
  }

  getFormObject(value) {
    return Object.entries(value).map(entry => {
      return { name: entry[0], value: entry[1] };
    });
  }

  onSave() {
    const errors = this.validationChain.getErrors();
    if (this.state.jsonError || Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      this.props.onSave(this.getValueObject(this.state.object));
    }
  }

  addItem() {
    this.changeObject([...this.state.object, { name: '', value: '' }]);
  }

  removeItem(index) {
    this.changeObject(this.state.object.filter((_, i) => i !== index));
  }

  updateName(index, name) {
    let newContent = [...this.state.object];
    newContent[index] = { ...newContent[index], name };
    this.changeObject(newContent);
  }

  updateValue(index, value) {
    let newContent = [...this.state.object];
    newContent[index] = { ...newContent[index], value };
    this.changeObject(newContent);
  }

  changeObject(object) {
    this.setState({
      object,
      json: JSON.stringify(this.getValueObject(object), null, 2),
      jsonError: false
    });
  }

  changeJSON(json) {
    if (json.trim().startsWith('[')) {
      // perhaps it is a EQUS mapping text
      try {
        let object = JSON.parse(json);
        if (Array.isArray(object) && object.every(item => item.hasOwnProperty('value') && item.hasOwnProperty('text'))) {
          let newObject = {};
          object.forEach(item => {
            newObject[`${item.value}`] = item.text;
          });
          json = JSON.stringify(newObject, null, 2);
        }
      } catch (err) {
        console.error('ObjectEditor changeJSON()', err);
      }
    }
    this.setState({ json });
    this.debouncedUpdateJSON(json);
  }

  toggleJson() {
    const { object, showJSON, jsonError } = this.state;
    if (showJSON && jsonError) {
      this.changeObject(object);
    }
    this.setState({ showJSON: !showJSON });
  }

  render() {
    const { label, translationKey } = this.props;
    const { object, json, jsonError, showJSON } = this.state;

    this.validationChain = new ValidationChain();
    return (
      <Dialog
        aria-labelledby="edit-object-form-title"
        aria-describedby="edit-object-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="edit-object-form-title">
          <FormattedMessage id={`${translationKey}.title`} />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <BlockHeader>
              {label &&
                <BlockTitle>
                  <FormattedMessage id={label} />
                </BlockTitle>
              }
              <BlockActions>
                <IntlFormHeaderIcon
                  icon={<AddIcon />}
                  tooltip={`${translationKey}.new`}
                  onClick={() => this.addItem()}
                />
              </BlockActions>
            </BlockHeader>
            {object.map(({ name, value }, index) =>
              <Row MultiColumns key={`object-item-${index}`}>
                <DynamicFormField
                  validationChain={this.validationChain}
                  margin="normal"
                  field='key'
                  value={name}
                  fieldType={{ type: 'string', validators: ['required'] }}
                  translationKey={`${translationKey}.form`}
                  onChange={value => this.updateName(index, value)}
                />
                <DynamicFormField
                  validationChain={this.validationChain}
                  margin="normal"
                  field='value'
                  value={value}
                  fieldType={{ type: 'string', validators: [] }}
                  translationKey={`${translationKey}.form`}
                  onChange={value => this.updateValue(index, value)}
                />
                <IconButton
                  aria-owns='simple-popper'
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => this.removeItem(index)}
                >
                  <DeleteIcon style={{ fontSize: '18px' }} />
                </IconButton>
              </Row>
            )}

            <Button
              key="showJSON_toggle"
              variant="outlined"
              onClick={() => this.toggleJson()}>
              <FormattedMessage id={showJSON ? 'app.hideJSON' : 'app.showJSON'} />
            </Button>

            {showJSON &&
              <Row key="form-advanced-json">
                <IntlTextField
                  multiline
                  key='json'
                  error={jsonError}
                  label="models.devices.JSON"
                  value={json}
                  onChange={ev => this.changeJSON(ev.target.value)}
                />
              </Row>}
          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ObjectEditorDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ObjectEditorDialog;