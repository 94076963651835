import React from 'react';
import styled from 'styled-components';

class ScriptBlock extends React.Component {

  render() {
    const { value } = this.props;
    return (
      <p className={this.props.className}>{value || ''}</p>
    );
  }

}

let StyledScriptBlock = styled(ScriptBlock)`
  padding: 10px 0 10px 50px;
  font-family: monospace;
  overflow: hidden;
`;

export default StyledScriptBlock;
