import React from 'react';
import { Dialog } from '@material-ui/core';

const CustomDialog = (props) => <Dialog
  maxWidth='md'
  fullWidth={false}
  {...props} >
</Dialog>;

export { CustomDialog as Dialog };
