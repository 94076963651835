import React, { Component } from 'react';
import DynamicFormDialog from '../Dynamics/DynamicFormDialog';
import { usersService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';

const PASS_PROPS = { type: 'password' };

export default class ChangePasswordDialog extends Component {

  state = {
    data: { id: this.props.user.id }
  }

  async onPasswordChange() {
    try {
      await usersService.changePassword(this.state.data);
      this.setState({ changePassword: null });
      eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'PasswordChanged', data: { login: this.props.user.login } });
      this.props.onClose();
    } catch (err) {
      console.error('Error changing password', err);
      eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'PasswordChangeError' });
    }
  }

  render() {
    const { expiredPassword } = this.props;
    const { data } = this.state;
    return (
      <DynamicFormDialog
        spec={{
          password: { uiSection: { row: 1 }, type: 'string', validators: ['required'], props: PASS_PROPS },
          repeatPassword: { uiSection: { row: 1 }, type: 'string', validators: ['required', v => v !== data.password && 'passwordDontMatch'], props: PASS_PROPS },

          oldPassword: { uiSection: { row: 0 }, type: 'string', validators: ['required'], props: PASS_PROPS }
        }}
        entry={data}
        title={expiredPassword ? 'expired' : 'title'}
        translationKey='app.changePasswordForm'
        onChange={data => this.setState({ data })}
        onSave={() => this.onPasswordChange()}
        onCancel={this.props.onClose}
      />
    );
  }

}