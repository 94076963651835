
function sortObjectKeys(obj) {
  if (obj == null) {
    return null;
  } else if (typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj).sort().reduce(function (result, key) {
    let value = obj[key];
    if (Array.isArray(value)) {
      value = value.map(i => sortObjectKeys(i));
    } else if (typeof value === 'object') {
      value = sortObjectKeys(value);
    }
    result[key] = value;
    return result;
  }, {});
}

const stringSorter = (a = '', b = '', factor = 1) => factor * a.localeCompare(b, undefined, { sensitivity: 'base' });
const genericSorter = (a, b, factor = 1) => a == b ? 0 : a < b ? 1 * factor : -1 * factor; // eslint-disable-line eqeqeq
const getObjectFieldSortFunction = (field, order = 'asc') => (a, b) => {
  a = a && a[field];
  b = b && b[field];
  const factor = order === 'asc' ? -1 : 1;
  if (typeof a === 'string' || typeof b === 'string') return stringSorter(a, b, factor);
  else return genericSorter(a, b, factor);
};

export { sortObjectKeys, getObjectFieldSortFunction };