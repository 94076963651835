import styled from 'styled-components';
import { clearFix, ellipsis } from 'polished';
import { Grid } from '@material-ui/core';

export const ComponentGrid = styled(Grid)`
border-bottom: 0.2em solid grey;
padding: 0.6em;
`;

export const BlockGrid = styled(Grid)`
padding: 0.4em;
`;

export const ComponentBlock = styled.div`
  ${clearFix()};
  position: relative;
  float: left;
  min-width: 0;
  margin-bottom: ${props => props.theme.MarginSize};
`;

export const BlockHeader = styled.div`
  position: relative;
  float: left;
  width: 100%;
  min-height: 78px; /* To match the element with icons */
  padding: ${props => props.theme.MarginSize} 0 ${props => props.theme.QuarterMarginSize};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BlockTitle = styled.h1`
  font-size: 20px;
  min-height: 20px;
  ${ellipsis()};
`;

export const BlockActions = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: ${props => props.theme.HalfMarginSize} !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
`;

export const BlockBody = styled.div`
  position: relative;
  float: left;
  width: 100%;
  padding: ${props => props.theme.MarginSize};
  background-color: white;
  box-shadow: ${props => props.theme.ComponentBlockShadow};
`;

export const BlockFooter = styled.div`
  position: relative;
  float: left;
  width: 100%;
  margin-top: ${props => props.theme.MarginSize};
`;
