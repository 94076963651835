import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { LinearProgress, TableRow } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DataTablePaginationActions from './DataTablePaginationActions';
import AnimatedTableRow from './AnimatedTableRow';
import { TableCell, TablePagination, Table, TableBody, TableHead, TableScrollerWrapper, TableActionsWrapper } from './Table';
import DataTableColumnHeader from './DataTableColumnHeader';
import { DataTableCell } from './DataTable/DataTableCell';
import { ColorCircle, COLORS } from '../../components/ColorCircle';
import IntlTooltip from '../../intl-components/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import StyleVariables from '../../theme/StyleVariables';
import { DropdownMenu } from '../DropdownMenu';
import { MenuItem, ListItemText, ListItemIcon } from './Actions';
import { getObjectFieldSortFunction } from '../../util/object-utils';
import { IntlFormHeaderIcon } from '../../intl-components/Form';
import { BlockActions, BlockHeader, BlockTitle } from '../GridModules/ComponentBlock/ComponentBlock';

const RowStatusValues = {
  OK: 1,
  ERROR: 2,
  WARNING: 3,
  OFF: 4,
  UNKNOWN: 5
};

const StyledAnimatedTableRow = styled(AnimatedTableRow)`
  &:hover {
    background-color: #9fcaff;
    cursor: pointer;
  }
`;

class DataTableBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selection: []
    };
  }

  onCheckAll(newState) {
    const { pageData, data } = this.props;
    const selection = [];
    if (newState) {
      // Note: current behavior is selecting all items in all pages, but this could not be valid in all contexts
      const totalRows = get(pageData, 'total') || data.length;
      for (let i = 0; i < totalRows; i++) {
        selection.push(i);
      }
    }
    this.setState({ selection }, () => this.props.onSelectionChange(selection));
  }

  onCheckOne(index, selected) {
    const selectionSet = new Set(this.state.selection);
    if (selected) {
      selectionSet.add(index);
    } else {
      selectionSet.delete(index);
    }
    const selection = Array.from(selectionSet);
    this.setState({ selection }, () => this.props.onSelectionChange(selection));
  }

  renderRow(row, idx) {
    const { rowActions, enableMultipleSelection, onRowClick, pageData } = this.props;
    const skip = (pageData ? pageData.skip : 0) || 0;
    const mappedRow = this.props.rowMapFunction(row);
    const cellMapFunctions = this.props.cellMapFunctions;
    const rowId = row.id || ('idx_' + idx);
    const CustomRow = (onRowClick == null) ? AnimatedTableRow : StyledAnimatedTableRow;
    return (
      <CustomRow key={rowId} indicator={row} onClick={() => { if (onRowClick != null) { onRowClick(skip + idx, row); } }}>
        {enableMultipleSelection &&
          <TableCell key='dynamic-check-row'>
            <Checkbox
              checked={this.state.selection.includes(skip + idx)}
              onChange={(_ev, enabled) => this.onCheckOne(skip + idx, enabled)}
            />
          </TableCell>
        }
        {this.props.rowStatus && this.renderStatusColumn(rowId, this.props.rowStatus(row))}
        {this.props.columns.map((column, i) => {
          const data = column.data;
          if (typeof data === 'function') {
            return (
              <TableCell key={`${rowId}-column-${i}`}>
                {data(row, idx)}
              </TableCell>
            );
          } else {
            let value = mappedRow[data];
            if ((column.translationKey != null) && (typeof value === 'string')) {
              value = (<FormattedMessage id={`${column.translationKey}.${value}`} />);
            }
            return (
              <DataTableCell
                key={`${rowId}_${data}`}
                value={value}
                isEditable={this.props.isEditable(data, row)}
                cellMapFunction={cellMapFunctions[i]}
                onValueEdit={newValue => this.props.onCellEdit(data, row, newValue)}
                dataType={row.dataType}
              />
            );
          }
        })}
        {(rowActions) &&
          this.renderRowActions(rowActions, row)
        }
      </CustomRow>
    );
  }

  renderRowActions(actions = [], row) {
    const inMenuActions = actions.filter(t => !t.outMenu);
    const outMenuActions = actions.filter(t => !!t.outMenu);

    return (
      <TableCell>
        {outMenuActions.map((action, i) => (
          <IntlFormHeaderIcon
            key={i}
            icon={action.icon}
            tooltip={action.textKey}
            onClick={() => action.action(row)}
          />
        ))}
        {inMenuActions.length !== 0 &&
          <DropdownMenu>
            {inMenuActions.map((action, i) => (
              <MenuItem key={i} onClick={() => action.action(row)}>
                <ListItemIcon>
                  {action.icon}
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id={action.textKey} />
                </ListItemText>
              </MenuItem>
            ))}
          </DropdownMenu>
        }
      </TableCell>
    );
  }

  renderColumnHeader(column, idx) {
    const { data, columnKey, sortable, filter } = column;
    const { translationKey, onFilterChange, filters, cellMapFunctions } = this.props;
    const sort = this.state.sort || this.props.sort || {};
    if ((typeof data !== 'string') && (columnKey === undefined)) {
      return (
        <TableCell key={idx} ></TableCell >
      );
    } else if (sortable || filter !== undefined) {
      return (
        <DataTableColumnHeader
          key={columnKey || data}
          columnKey={columnKey || data}
          filter={filter}
          filterValue={filters && filters[data]}
          sortable={sortable}
          order={sort[data]}
          cellMapFunction={cellMapFunctions[idx]}
          onFilterChange={value => onFilterChange(data, value)}
          onSortChange={this.props.onSortChange || this.getDefaultOnSortChange()}
        >
          <FormattedMessage id={`${translationKey}.${data}`} />
        </DataTableColumnHeader>
      );
    } else {
      return (
        <TableCell key={columnKey || data}>
          <FormattedMessage id={`${translationKey}.${columnKey || data}`} />
        </TableCell>
      );
    }
  }

  handleChangePage(ev, newPage) {
    this.props.onPaginationChange({ page: newPage, rowsPerPage: this.props.pageData.limit });
  }

  handleChangeRowsPerPage(ev) {
    this.props.onPaginationChange({ page: this.props.pageData.skip / this.props.pageData.limit, rowsPerPage: ev.target.value });
  }

  renderPaginationIfEnabled() {
    if (this.props.pageData) {
      const { intl, pageData } = this.props;
      const { total, limit, skip } = pageData;
      const StyledTablePagination = withStyles({
        select: {
          paddingRight: StyleVariables.MarginSize,
        },
      })(TablePagination);
      return (
        <StyledTablePagination
          labelDisplayedRows={({ from, to, count }) => intl.formatMessage({ id: 'tables.labelDisplayedRows' }, { from, to, count })}
          labelRowsPerPage={intl.formatMessage({ id: 'tables.labelRowsPerPage' })}
          count={total}
          rowsPerPage={limit}
          page={skip / limit}
          onChangePage={this.handleChangePage.bind(this)}
          onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
          ActionsComponent={DataTablePaginationActions}
          rowsPerPageOptions={[25, 50, 100, 500]}
          component="div"
        />
      );
    }
  }

  getDefaultOnSortChange() {
    return function (column, newOrder) {
      if (newOrder) {
        const sorter = getObjectFieldSortFunction(column, newOrder);
        this.setState({ ...this.state, sort: { [column]: newOrder }, sorter });
      } else {
        this.setState({ ...this.state, sort: {}, sorter: undefined });
      }
    }.bind(this);
  }

  componentWillUnmount() {
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
    }
  }

  // Debounce loading change so during first 100 ms the loading bar is not shown
  handleLoadingState() {
    const isLoading = this.props.loading;
    const wasLoading = this.state.loading;
    if (isLoading) {
      if (!wasLoading && !this.loadingTimer) {
        this.loadingTimer = setTimeout(
          () => this.setState({ loading: true }),
          100);
      }
    } else {
      if (this.loadingTimer) {
        clearInterval(this.loadingTimer);
        delete this.loadingTimer;
      }
      if (wasLoading) {
        setTimeout(
          () => this.setState({ loading: false }),
          0);
      }
    }
  }

  handleInitialSort() {
    if (!this.props.data || !this.props.data.length || this.state.sort) return;
    if (!this.props.sort || this.props.onSortChange) return;
    setImmediate(() => {
      const sorter = this.getDefaultOnSortChange();
      Object.entries(this.props.sort).forEach(([col, order]) => sorter(col, order));
    });
  }

  renderStatusCircle(status) {
    switch (status) {
      case RowStatusValues.ERROR:
        return <ColorCircle color={COLORS.RED} />;
      case RowStatusValues.OK:
        return <ColorCircle color={COLORS.GREEN} />;
      case RowStatusValues.WARNING:
        return <ColorCircle color={COLORS.ORANGE} />;
      case RowStatusValues.OFF:
        return <ColorCircle color={COLORS.BLACK} />;
      default:
        return <ColorCircle color={COLORS.GRAY} />;
    }
  }

  renderStatusColumn(rowId, rowStatus) {
    return (
      <TableCell key={`${rowId}-column-status`}>
        <IntlTooltip title={rowStatus.title} relativeDate={rowStatus.relativeDate} >
          {this.renderStatusCircle(rowStatus.status)}
        </IntlTooltip>
      </TableCell>
    );
  }

  render() {
    const { titleKey, sortableColumns, columns, tableActions = [], rowStatus, rowActions, enableMultipleSelection, pageData } = this.props;
    this.handleLoadingState();
    this.handleInitialSort();

    let data;
    if (pageData) {
      data = pageData.data;
    } else {
      data = this.props.data;
      if (typeof this.state.sorter === 'function') {
        data = Array.from(this.props.data).sort(this.state.sorter);
      }
    }
    const totalRows = get(pageData, 'total') || data.length;
    const allRowsChecked = (this.state.selection.length === totalRows) && (totalRows > 0);

    if (sortableColumns === true) {
      columns.forEach(c => {
        if (c.sortable === undefined && typeof c.data !== 'function') {
          c.sortable = true;
        }
      });
    }
    const inMenuTableActions = tableActions.filter(t => !t.outMenu);
    const outMenuTableActions = tableActions.filter(t => !!t.outMenu);
    return (
      <Fragment>
        <TableActionsWrapper>
          {(this.props.pageData || titleKey || tableActions.length > 0) &&
            < BlockHeader >
              {titleKey &&
                <BlockTitle>
                  <FormattedMessage id={titleKey} />
                </BlockTitle>
              }
              {this.renderPaginationIfEnabled()}
              <BlockActions>
                {outMenuTableActions.map((action, i) => (
                  <IntlFormHeaderIcon
                    key={i}
                    icon={action.icon}
                    tooltip={action.textKey}
                    onClick={() => action.action()}
                  />
                ))}
                {inMenuTableActions.length > 0 &&
                  <DropdownMenu>
                    {inMenuTableActions.map((action, i) => (
                      <MenuItem key={i} onClick={() => action.action()}>
                        <ListItemIcon>
                          {action.icon}
                        </ListItemIcon>
                        <ListItemText>
                          <FormattedMessage id={action.textKey} />
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </DropdownMenu>
                }
              </BlockActions>
            </BlockHeader>
          }
        </TableActionsWrapper>
        { this.state.loading && <LinearProgress />}
        <TableScrollerWrapper>
          <Table key="table">
            <TableHead>
              <TableRow>
                {enableMultipleSelection &&
                  <TableCell key='check-all'>
                    <Checkbox
                      checked={allRowsChecked}
                      onChange={(_event, value) => this.onCheckAll(value)}
                    />
                  </TableCell>}
                {rowStatus && <TableCell key='status-column' />}
                {columns.map((column, i) => this.renderColumnHeader(column, i))}
                {rowActions && <TableCell key='actions-column' />}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idx) => this.renderRow(row, idx))}
            </TableBody>
          </Table>
        </TableScrollerWrapper>
        <TableActionsWrapper>
          {this.renderPaginationIfEnabled()}
        </TableActionsWrapper>
      </Fragment >
    );
  }
}

export const actionShape = PropTypes.shape({
  icon: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
  outMenu: PropTypes.boolean
});

DataTableBase.propTypes = {
  titleKey: PropTypes.string,
  rowStatus: PropTypes.func,
  enableMultipleSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  rowActions: PropTypes.arrayOf(actionShape),
  tableActions: PropTypes.arrayOf(actionShape),
  translationKey: PropTypes.string,
  isEditable: PropTypes.func,
  onPaginationChange: PropTypes.func,
  filters: PropTypes.object,
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSortChange: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    sortable: PropTypes.bool,
    filter: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  })),
  sort: PropTypes.object,
  pageData: PropTypes.shape({
    total: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    data: PropTypes.array
  }),
  rowMapFunction: PropTypes.func,
  cellMapFunctions: PropTypes.arrayOf(PropTypes.func),
  loading: PropTypes.bool
};

DataTableBase.defaultProps = {
  isEditable: () => false,
  enableMultipleSelection: false,
  rowMapFunction: d => d,
  cellMapFunctions: []
};

const DataTable = injectIntl(DataTableBase);
DataTable.propTypes = DataTableBase.propTypes;
export default DataTable;
export { RowStatusValues };