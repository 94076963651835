import styled from 'styled-components';
import { ComponentBlock, BlockBody } from './ComponentBlock';

export const TableBlock = styled(ComponentBlock)`
  ${BlockBody} {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    overflow-x: auto;
    padding: 3px; /* Fix cropped box-shadow */
  }
`;

export const ColumnFilterFieldWrapper = styled.div`
  display: flex;
`;