
export const getInputProtocolDataType = (targetDataType, converters, availableConverters) => {
  let dataType = targetDataType;
  if (converters) {
    converters.find(c => {
      const converterCode = c.code;
      const converter = (availableConverters || []).find(converter => converter.code === converterCode);
      if (converter && (c.inputDataType != null)) {
        dataType = c.inputDataType;
        return true;
      }
      return false;
    });
  }
  return dataType;
};

export const getOutputProtocolDataType = (sourceDataType, converters, availableConverters) => {
  let dataType = sourceDataType;
  if (converters) {
    [...converters].reverse().find(c => {
      const converterCode = c.code;
      const converter = (availableConverters || []).find(converter => converter.code === converterCode);
      if (converter && (c.outputDataType != null)) {
        dataType = c.outputDataType;
        return true;
      }
      return false;
    });
  }
  return dataType;
};

export const validateValueConverters = (sourceDataType, targetDataType, converters = [], availableConverters = []) => {

  if (!targetDataType) {
    return false;
  }
  let result = false;
  let currentDataType = targetDataType;

  for (let i = converters.length - 1; i >= 0; i--) {
    const converter = converters[i];
    if (!result) {
      const converterCode = converter.code;
      const converterInfo = availableConverters.find(c => c.code === converterCode);
      if (converterInfo == null) {
        result = 'invalid_converter';
      } else {
        if (converter.outputDataType != null && converter.outputDataType !== currentDataType) {
          result = 'invalid_chain';
        }
        currentDataType = converter.inputDataType || currentDataType;
      }
    }
  }
  if (!result && currentDataType !== sourceDataType) {
    result = 'converter_mismatch';
  }
  return result;
};

const CONVERTER_REGEX = /^\s*([a-zA-Z0-9_-]+)(\s*\(\s*([0-9.-]+)?\s*\))?\s*$/;
const CAST_CONVERTER_REGEX = /^\s*cast\s*\(\s*([a-z0-9.-]+)?\s*,\s*([a-z0-9.-]+)?\s*\)\s*$/;

export const getValueConvertersStringArray = (object, availableConverters) => {
  return object.map(entry => {
    let converter = availableConverters.find(c => c.code === entry.code);
    if (converter) {
      if (converter.requiresParam) {
        return entry.code + '(' + (entry.params || '') + ')';
      } else if (entry.code === 'cast') {
        return entry.code + '(' + (entry.inputDataType || '') + ',' + (entry.outputDataType || '') + ')';
      }
    }
    return entry.code;
  });
};

export const getValueConvertersObject = (value) => {
  return value.map(entry => {
    if (entry.includes('#')) {
      // legacy format
      entry = entry.replace('#', '(') + ')';
    }
    let match = CAST_CONVERTER_REGEX.exec(entry);
    if (match) {
      return { code: 'cast', inputDataType: match[1], outputDataType: match[2] };
    } else {
      match = CONVERTER_REGEX.exec(entry);
      if (match) {
        return { code: match[1], params: match[3] };
      } else {
        return { code: entry.split('(')[0].trim(), params: null };
      }
    }

  });
};