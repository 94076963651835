import React from 'react';
import { TextField } from './Form';
import { omit } from 'lodash';

export class BinaryNumberField extends React.Component {

  constructor(props) {
    super(props);
    const defValue = props.defaultValue !== undefined
      ? typeof props.defaultValue === 'number'
        ? '0x' + props.defaultValue.toString(16).toUpperCase()
        : props.defaultValue
      : undefined;
    const stringValue = typeof props.value === 'string'
      ? props.value
      : typeof props.value === 'number'
        ? props.value.toString()
        : '';
    this.state = {
      defValue,
      value: stringValue,
      valid: true
    };
  }

  onChange(value) {
    const intValue = parseInt(value);
    if (isNaN(intValue)) {
      this.setState({ value, valid: false });
    } else {
      const newValue = value.startsWith('0x') || value.startsWith('0X')
        ? '0x' + intValue.toString(16).toUpperCase()
        : intValue;
      this.setState({ value: newValue.toString(), valid: true },
        () => this.props.onChange(newValue));
    }
  }

  render() {
    const { error, ...props } = omit(this.props, 'value', 'defaultValue');
    const { valid, value, defValue } = this.state;
    return (
      <TextField
        {...props}
        value={value}
        placeholder={defValue}
        error={error || !valid}
        onChange={ev => this.onChange(ev.target.value)}
      />
    );
  }

}