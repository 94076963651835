import React, { Component } from 'react';
import { systemService } from '../lib/service';

class Version extends Component {

  constructor(props) {
    super(props);
    this.state = {
      version: null
    };
  }

  async updateVersions() {
    try {
      const versions = await systemService.get('version');
      this.setState({ version: versions.data.package });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.updateVersions();
  }

  render() {
    const { version } = this.state;
    if (version != null) {
      return (
        <span>{'v' + version}</span>
      );
    }
    return null;
  }
}

export default Version;
