import React, { Component } from 'react';
import { timeService } from '../../../lib/service';

// Import FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FormattedDate } from 'react-intl';
import { UserZoneLabel } from './UserZoneLabel';
import * as moment from 'moment';
import 'moment-timezone';
import { timeZoneAware } from '../../TimeZoneAware';

// Import FontAwesome Icons
library.add(
  faClock
);

class Clock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      time: null,
      currentTime: null,
      offset: null
    };
  }

  componentDidMount() {
    timeService.get(null).then(({ timezone, time }) => {
      this.setState({ time: +new Date(), timezone, offset: time - new Date() });
      this.props.updateTimezone(timezone);
      moment.tz.setDefault(timezone);
    });
    this.updateTimer = setInterval(() => this.setState({ time: +new Date() }), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  render() {
    const { time, timezone, offset } = this.state;
    if (time === null || timezone === null) {
      return null;
    }
    return (
      <UserZoneLabel
        icon='clock'
      >
        <FormattedDate
          value={time + offset}
          timeZone={timezone}
          year='numeric'
          month='numeric'
          day='2-digit'
          hour='numeric'
          minute='numeric'
        />
      </UserZoneLabel>
    );
  }
}

export default timeZoneAware(Clock);
