import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import AlertsView from './AlertsView';
import AutomaticAlertsView from './AutomaticAlertsView';
import { StyledTabs, TabContainer } from '../../components/Tab/tabs';
import { injectIntl } from 'react-intl';
import { Tab } from '@material-ui/core';

const AlertsTabView = ({ match, intl, history }) => {
  const tab = match.params.tab;
  const TabContent = tab === 'config' ? AutomaticAlertsView : AlertsView;
  return (
    <Fragment>
      <StyledTabs
        centered
        variant='fullWidth'
        indicatorColor='primary'
        value={match.params.tab}
        onChange={() => history.push(tab === 'config' ? '/alerts/list' : '/alerts/config')}
      >
        <Tab label={intl.formatMessage({ id: 'section.alerts' })} value='list' />
        <Tab label={intl.formatMessage({ id: 'section.automaticAlerts' })} value='config' />
      </StyledTabs>
      <TabContainer>
        <TabContent />
      </TabContainer>
    </Fragment>
  );
};


const ExportedAlertsRouter = injectIntl(withRouter(AlertsTabView));
export default ExportedAlertsRouter;