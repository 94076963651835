import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import { Popover, Select, TextField, MenuItem, InputAdornment, IconButton } from '@material-ui/core';
import { Switch } from '@material-ui/core';

const EditableContent = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.HalfMarginSize};
`;

const EditableCellPopover = ({ anchorEl, onClose, onChange, onSave, value, valueMappings, dataType }) => {
  const open = Boolean(anchorEl);
  const isBoolean = dataType && dataType.startsWith('bool');
  let textFieldRef;
  return (
    <Popover
      onEnter={() => {
        if (textFieldRef && typeof textFieldRef.select === 'function') {
          textFieldRef.select();
        }
      }}
      id="simple-popper"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onKeyPress={ev => ev.key === 'Enter' ? onSave() : undefined}
    >
      <EditableContent>
        {isBoolean ?
          <Switch
            checked={!!value}
            onChange={(_ev, enabled) => onChange(enabled)}
          /> :
          <TextField
            inputRef={ref => textFieldRef = ref}
            autoFocus={true}
            value={value}
            onChange={ev => onChange(ev.target.value)}
          />
        }
        {valueMappings &&
          <Select
            value={value}
            onChange={ev => onChange(ev.target.value)}
          >
            {Object.entries(valueMappings).map(([k, v]) => (
              <MenuItem key={k} value={k}>{v}</MenuItem>
            ))}
          </Select>
        }
        <InputAdornment key='cell-popover-adornment' position="end" >
          <IconButton onClick={onClose}>
            <Close style={{ fontSize: '18px' }} />
          </IconButton>
          <IconButton onClick={onSave}>
            <Done style={{ fontSize: '18px' }} />
          </IconButton>
        </InputAdornment >
      </EditableContent>
    </Popover>
  );
};

EditableCellPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  valueMappings: PropTypes.object,
  dataType: PropTypes.string
};

export { EditableCellPopover };