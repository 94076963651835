import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import KeyIcon from '@material-ui/icons/VpnKey';

import { UserZoneLabel } from './UserZoneLabel';

import { client } from '../../../lib/service';
import { ICONS } from '../../Table/FAIconButton';
import { FormattedMessage } from 'react-intl';
import ChangePasswordDialog from '../ChangePasswordDialog';

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

class UserZone extends Component {
  state = {
    anchorEl: null,
    changePassword: null,
    cancelExpiredPasswordChange: false
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, changePassword } = this.state;
    const { user } = this.props;
    return (
      <Fragment>
        <UserZoneLabel
          aria-owns={anchorEl ? 'dropper-menu' : null}
          aria-haspopup='true'
          onClick={this.handleClick}
          icon={ICONS.faUser}
          text={user.login}
        />
        <Menu
          id='dropper-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={() => this.setState({ changePassword: true })}>
            <StyledListItemIcon>
              <KeyIcon />
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='app.changePassword' />
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={client.logout}>
            <StyledListItemIcon>
              <PowerSettingsNew />
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='app.closeSession' />
            </ListItemText>
          </MenuItem>
        </Menu>
        {changePassword &&
          <ChangePasswordDialog
            user={user}
            onClose={() => this.setState({ changePassword: false })}
          />}
      </Fragment>
    );
  }
}

export default UserZone;
