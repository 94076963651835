import styled, { css } from 'styled-components';
//import { rgba } from 'polished';

export const Row = styled.div`
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: ${props => props.alignTop ? 'flex-start' : props.alignBottom ? 'flex-end' : 'center'};
  justify-content: center;
  clear: both;
  > * {
    flex-basis: 100%; /* OneColum Default */
    align-self: baseline;
  }
  ${props => props.ChildrenDefaultWidth && css`
    justify-content: initial;
     > * {
      flex-basis: initial;
     }
  `}
  ${props => props.MultiColumns && css`
    justify-content: initial;
    > * {
      flex-basis: initial;
      margin-right: ${props => props.theme.MarginSize} !important;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  `}
  ${props => props.TwoColumns && css`
    flex-wrap: nowrap;
    justify-content: space-between;
    > * {
      flex-basis: calc(50% - ${props => props.theme.HalfMarginSize});
    }
    ${props => props.FirstCheckbox && css`
      > * {
        &:first-child {
          flex-basis: calc(80px - ${props => props.theme.HalfMarginSize});
        }
        &:last-child {
          flex-basis: calc(100% - 80px - ${props => props.theme.HalfMarginSize});
        }
      }
    `}
    ${props => props.FirstBigger && css`
      > * {
        &:first-child {
          flex-basis: calc(75% - ${props => props.theme.HalfMarginSize});
        }
        &:last-child {
          flex-basis: calc(25% - ${props => props.theme.HalfMarginSize});
        }
      }
    `}
    ${props => props.LastBigger && css`
      > * {
        &:first-child {
          flex-basis: calc(25% - ${props => props.theme.HalfMarginSize});
        }
        &:last-child {
          flex-basis: calc(75% - ${props => props.theme.HalfMarginSize});
        }
      }
    `}
  `}
  ${props => props.ThreeColumns && css`
    flex-wrap: nowrap;
    justify-content: space-between;
    > * {
      flex-basis: calc(33.3% - ${props => props.theme.HalfMarginSize});
    }
  `}

  ${props => props.FourColumns && css`
    flex-wrap: nowrap;
    justify-content: space-between;
    > * {
      flex-basis: calc(25% - ${props => props.theme.HalfMarginSize});
    }
  `}
`;

export const ActionButtonsRow = styled(Row)`
  padding: ${props => props.theme.MarginSize} 0;
  align-items: center;
`;
