import React, { Fragment } from 'react';
import prettyBytes from 'pretty-bytes';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tooltip, IconButton, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SdStorageIcon from '@material-ui/icons/SdCard';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { systemService } from '../../lib/service';
import { Row } from '../../components/GridModules/Row';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { BlockHeader, BlockTitle, BlockBody, BlockActions } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { Disk } from './components/Disk';
import { DropdownMenu } from '../../components/DropdownMenu';
import { confirm } from '../../components/util';
import { showErrorAlert } from '../../lib/apiHelpers';
import { withRouter } from 'react-router-dom';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { SectionTitle } from '../../components/Layout/Page';
import { AlertParagraph } from '../../components/Form/Form';

class StorageViewBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = { data: [], isAdquioHardware: false, loading: true };
  }

  fetchData() {
    const { intl } = this.props;
    const loaderMessage = intl.formatMessage({ id: 'app.loading' });
    eventBus.publish(eventBusTopics.LOADING_START, { message: loaderMessage });
    const promises = [systemService.get('hardwareInfo'), systemService.get('storage')];

    Promise.all(promises).then(([hardwareInfo, storage]) => {
      const isAdquioHardware = !!get(hardwareInfo, 'data.model.isAdquioHardware');
      const data = storage;
      this.setState({ data, isAdquioHardware, loading: false });
    })
      .catch(err => showErrorAlert(err))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, { message: loaderMessage }));
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { intl } = this.props;
    const { data, isAdquioHardware, loading } = this.state;

    const Wrapper = ({ children }) => (
      <Fragment>
        <SectionTitle id='system.storage.title' />
        {children}
      </Fragment>
    );
    if (loading) {
      return (
        <Wrapper>
          <BlockBody>
          </BlockBody>
        </Wrapper>
      );
    } else if (!isAdquioHardware) {
      return (
        <Wrapper>
          <BlockBody>
            <AlertParagraph>
              <FormattedMessage id='app.disabledSection' />
            </AlertParagraph>
          </BlockBody>
        </Wrapper>
      );
    }

    const onPrepare = (device) => {
      confirm(intl, 'system.storage.disk.prepareConfirm', async () => {
        eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
        await systemService.operation('storagePrepare', { device })
          .catch(() => eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'UnknownError' }));
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' }));
        this.fetchData();
      });
    };
    const onStart = async (device) => {
      eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
      await systemService.operation('storageStart', { device })
        .catch(() => eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'UnknownError' }));;
      eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' }));
      this.fetchData();
    };

    const internal = data.find(disk => disk.partitions.some(p => p.use === 'system'));
    const external = data.filter(disk => disk !== internal);

    return (
      <Wrapper>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
              </BlockTitle>
              <BlockActions>
                <Tooltip title={intl.formatMessage({ id: 'app.refresh' })} >
                  <IconButton onClick={() => this.fetchData()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              {internal &&
                <Row>
                  <TableBlock>
                    <BlockHeader>
                      <BlockTitle>
                        <FormattedMessage id='system.storage.disk.internalLabel' values={{
                          size: prettyBytes(internal.size)
                        }} />
                      </BlockTitle>
                    </BlockHeader>
                    <BlockBody>
                      <Disk {...internal} />
                    </BlockBody>
                  </TableBlock>
                </Row>
              }
              {external.map(disk => (
                <Row key={disk.name}>
                  <TableBlock>
                    <BlockHeader>
                      <BlockTitle>
                        <FormattedMessage id='system.storage.disk.label' values={{
                          model: disk.model, name: disk.name, size: prettyBytes(disk.size)
                        }} />
                      </BlockTitle>
                      {disk.state !== 'working' &&
                        <BlockActions>
                          <DropdownMenu>
                            {(disk.state === 'notPrepared' || disk.state === 'prepared') &&
                              <MenuItem onClick={() => onPrepare(disk.name)}>
                                <ListItemIcon>
                                  <SdStorageIcon />
                                </ListItemIcon>
                                <ListItemText>
                                  <FormattedMessage id='system.storage.disk.prepare' />
                                </ListItemText>
                              </MenuItem>
                            }
                            {disk.state === 'prepared' &&
                              <MenuItem onClick={() => onStart(disk.name)}>
                                <ListItemIcon>
                                  <PlayIcon />
                                </ListItemIcon>
                                <ListItemText>
                                  <FormattedMessage id='system.storage.disk.start' />
                                </ListItemText>
                              </MenuItem>
                            }
                          </DropdownMenu>
                        </BlockActions>
                      }
                    </BlockHeader>
                    <BlockBody>
                      <Disk {...disk} />
                    </BlockBody>
                  </TableBlock>
                </Row>
              ))}
            </BlockBody>
          </TableBlock>
        </Row>
      </Wrapper>
    );
  }
}

export const StorageView = injectIntl(withRouter(StorageViewBase));
