import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const SmallIconButton = styled(IconButton)`
  && {
    padding: 10px;
    margin: 5px;
  }
  svg {
    width: 14px !important;
    height: 14px;
  }
`;

export {
  SmallIconButton
};