import React, { Fragment } from 'react';
import { noop } from 'lodash';

import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from '@material-ui/core/InputAdornment';
import JsEditorDialog from './JsEditorDialog';
import { DynamicFormField } from '../DynamicFormField';
import { SmallIconButton } from '../../Icons';

class JsFormField extends React.Component {

  state = {
    showingDialog: false
  }

  saveValue(value) {
    this.props.onChange(value);
    this.setState({ showingDialog: false });
  }

  render() {
    const margin = this.props.tableMode ? 'dense' : 'normal';
    const plainContent = this.props.value;
    let content, title;
    if (this.props.tableMode) {
      content = plainContent && plainContent.length ? '<js content>' : '<empty>';
      title = plainContent;
    } else {
      content = plainContent;
      title = undefined;
    }
    return (
      <Fragment>
        <DynamicFormField
          validationChain={this.validationChain}
          margin={margin}
          field={this.props.field}
          value={content}
          multiline={!this.props.tableMode ? 'true' : null}
          fieldType={{ type: 'string', validators: [] }}
          translationKey={this.props.translationKey}
          onChange={noop}
          disabled={this.props.disabled}
          onClick={() => this.setState({ showingDialog: true })}
          InputProps={{
            title,
            readOnly: true,
            disabled: true,
            endAdornment: (
              <InputAdornment key='js-form-adornment' position='end'>
                <SmallIconButton
                  disabled={this.props.disabled}
                  aria-owns='simple-popper'
                  aria-haspopup='true'
                  variant='contained'
                >
                  <EditIcon />
                </SmallIconButton>
              </InputAdornment>
            )
          }}
        />
        {this.state.showingDialog &&
          <JsEditorDialog
            value={this.props.value || ''}
            margin={margin}
            label={`components.jsEditor.form.${this.props.field}`}
            translationKey='components.jsEditor'
            onCancel={() => this.setState({ showingDialog: false })}
            onSave={(value) => this.saveValue(value)}
          />
        }
      </Fragment>
    );
  }
}

export default JsFormField;
