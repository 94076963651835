import React from 'react';
import { injectIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { formatRelativeOrLongDate } from '../util/time';

const IntlTooltip = injectIntl(({ intl, date, relativeDate, title, children, ...props }) => {
  title = title && intl.formatMessage({ id: title });
  if (relativeDate) {
    date = formatRelativeOrLongDate(intl, relativeDate);
  } else {
    date = date && intl.formatDate(date);
  }
  if (title && date) {
    title = `${title}: ${date}`;
  } else if (date) {
    title = date;
  }
  return (
    title ? <Tooltip {...props} title={title}>{children}</Tooltip> : children
  );
});

export default IntlTooltip;