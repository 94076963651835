import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { flatten } from 'lodash';
import eventBus, { eventBusTopics } from './lib/eventBus';
import routes from './routes';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { client, socket } from './lib/service';
import { IntlAlert } from './intl-components/IntlAlert';
import Layout from './views/Layout';
import { Root, PageWrapper } from './components/Layout/Page';
import { Row } from './components/GridModules/Row';
import PageLoader from './components/Layout/PageLoader';
import { getErrorsResume } from './util/forms';
import ChangePasswordDialog from './components/Layout/ChangePasswordDialog';
import { WrongRoute } from './views/errors/WrongRoute';

const LoadingWrapper = styled(Paper)`
  && {
    text-align: center;
    box-shadow: none;
    background-color: transparent;
  }
`;

const Loader = styled(CircularProgress)`
  margin-bottom: ${props => props.theme.MarginSize};
`;
const AppError = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  text-align: center;
  h3 {
    font-size: 150%;
    margin-bottom: 10px;
  }
`;


const parsedRoutes = flatten(routes.map(route => route.routes
  ? [...route.routes.map(inRoute => ({ ...inRoute, path: `${route.path}/${inRoute.path}` })), route]
  : route
)).filter(r => !!r.ViewComponent);

let alertCount = 0;
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    client.reAuthenticate()
      .then((() => { })) // on('authenticated') will already handle the auth
      .catch((ex) => {
        if (ex.name === 'NotAuthenticated') {
          client.logout().then(f => f, f => f);
          props.history.push('/login');
        } else {
          console.log('Could not authenticate', ex);
        }
      });
  }

  componentDidMount() {
    const history = this.props.history;
    history.listen(location => {
      if (location.pathname === '/login') return;
      this.lastPath = location.pathname;
    });

    this.alertSubscriptions = [
      eventBus.subscribe(eventBusTopics.DISPLAY_ALERT_MODAL,
        ({ alertId, errors, translationsKey, data }) => {
          alertCount++;
          if (alertId === 'form') {
            errors = getErrorsResume(this.props.intl, errors, translationsKey);
          }
          this.setState({ alertId, errors, errorData: data });
        }),
      eventBus.subscribe(eventBusTopics.DISABLE_UI_ERROR,
        ({ alertId }) => {
          this.setState({ fatalError: alertId });
        }
      )
    ];

    client.on('authenticated', async ({ user }) => {
      try {
        if (user.expiredPassword) {
          this.setState({ user, changePassword: true });
        } else {
          this.setState({ user });
        }
        if (history.location.pathname === '/login') {
          if (this.lastPath) {
            history.replace(this.lastPath);
          } else {
            history.replace('/');
          }
        }

      } catch (ex) {
        if (ex.name === 'NotAuthenticated') {
          this.props.history.push('/login');
        } else {
          console.log('Error authenticated', ex);
          this.setState({ ...this.state, alertId: 'invalidToken' });
        }
      }
    });

    socket.on('disconnect', () => eventBus.publish(eventBusTopics.SERVER_CONNECTION, false));

    socket.on('connect', () => eventBus.publish(eventBusTopics.SERVER_CONNECTION, true));

    client.on('logout', () => {
      if (history.location.pathname !== '/login') {
        history.push('/login');
      }
    });
  }

  componentWillUnmount() {
    eventBus.unsubscribe(this.alertSubscriptions);
  }

  renderRoute() {
    return [
      <Switch key="app-route-switch">
        {
          parsedRoutes.map(({ path, ViewComponent, disableLayout, isRouter, params = {} }) => {
            // keys in iteration are mandatory, but we use same key to prevent remount of children
            // (so we have more control of the Component lifecycle, this is actually engouraged in some github issues in react-router)
            if (disableLayout) {
              return (
                <Route key='route' exact={!isRouter} path={`/${path}`}>
                  <ViewComponent user={this.state.user} routeMatch={path} {...params} />
                </Route>
              );
            } else {
              return (
                <Route key='route' exact={!isRouter} path={`/${path}`}>
                  <Layout user={this.state.user}>
                    <ViewComponent routeMatch={path} {...params} />
                  </Layout>
                </Route>
              );
            }
          })
        }
        <Route key='route'>
          <Layout user={this.state.user}>
            <WrongRoute />
          </Layout>
        </Route>
      </Switch>,
      <PageLoader key="app-page-loader" />
    ];
  }

  renderLoading() {
    return (
      <PageWrapper key="app-loading">
        <Root>
          <LoadingWrapper>
            <Loader size={48} />
            <Row><FormattedMessage id="app.loading" /></Row>
          </LoadingWrapper>
        </Root>
      </PageWrapper>
    );
  }

  renderFatalError() {
    const alertId = this.state.fatalError;
    return (
      <PageWrapper key='fatal-error'>
        <AppError>
          <img alt="logo" src="img/corporative/adquio_logo_vertical.svg" width="130" style={{ marginBottom: '24px' }} />
          <h3><FormattedMessage id={`alerts.${alertId}.title`} /></h3>
          <FormattedMessage id={`alerts.${alertId}.message`} />
        </AppError>
      </PageWrapper>
    );
  }

  render() {
    const isLoading = !this.state.user && this.props.history.location.pathname !== '/login';
    if (this.state.fatalError) {
      return this.renderFatalError();
    }
    return [
      isLoading ? this.renderLoading() : this.renderRoute(),
      this.state.alertId &&
      <IntlAlert
        key={alertCount}
        alertId={this.state.alertId}
        errors={this.state.errors}
        data={this.state.errorData} />,
      this.state.changePassword &&
      <ChangePasswordDialog
        expiredPassword
        key='change-password'
        user={this.state.user}
        onClose={() => this.setState({ changePassword: false })}
      />
    ];
  }
}

export default withRouter(injectIntl(App));
