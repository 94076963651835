import styled from 'styled-components';
//import { clearFix } from 'polished';

export const FixedActionBar = styled.div`
  position: fixed;
  bottom: 0;
  left: ${props => props.theme.AppSidebarCollapsedWidth};
  z-index: 250;
  width: calc(100% - ${props => props.theme.AppSidebarCollapsedWidth});
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.MarginSize} ${props => props.theme.DoubleMarginSize};
  background-color: ${props => props.theme.BgMediumGreyColor};
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;
export const PrimaryActions = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: ${props => props.theme.HalfMarginSize} !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
`;
export const SecondaryActions = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: ${props => props.theme.HalfMarginSize} !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
`;
