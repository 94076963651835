import React from 'react';
import TextField from '@material-ui/core/TextField';

const allowedContentExpression = {
  'integer': /^[0-9, -]*$/,
  'number': /^[0-9., -]*$/,
  'string': /^.*$/
};

class ItemListFormField extends React.Component {

  constructor(props) {
    super(props);
    if (props.value) {
      this.state = {
        value: Array.isArray(props.value) ? props.value.join(', ') : [props.value]
      };
    } else {
      this.state = {
        value: ''
      };
    }
  }

  isNumberList() {
    return ['integer', 'number'].includes(this.props.dataType);
  }

  parseNumberList(string) {
    if (!string) {
      return null;
    }
    return string.trim().split(/\s*,\s*/).map(s => (s === '') ? NaN : Number(s));
  }

  parseStringsList(string) {
    if (!string) {
      return null;
    }
    return string.trim().split(/\s*,\s*/);
  }

  onChange(n) {
    if ((allowedContentExpression[this.props.dataType] != null) && allowedContentExpression[this.props.dataType].exec(n)) {
      this.setState({ value: n });
      if (this.isNumberList()) {
        this.props.onChange(this.parseNumberList(n));
      } else {
        this.props.onChange(this.parseStringsList(n));
      }
    }
  }

  render() {
    const margin = this.props.tableMode ? 'dense' : 'normal';
    return (
      <TextField
        value={this.state.value || ''}
        margin={margin}
        label={this.props.label}
        helperText={this.props.helperText}
        error={!!this.props.error}
        onChange={ev => this.onChange(ev.target.value)}
      />
    );
  }
}


export default ItemListFormField;