import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { scriptsService } from '../../lib/service';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import { BlockHeader, BlockTitle, BlockBody, BlockActions } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { FormattedMessage, injectIntl } from 'react-intl';
import DataTable, { RowStatusValues } from '../../components/Table/DataTable';
import CommonFormOptions from '../components/CommonFormOptions';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { handleAPIError } from '../../util/forms';

class ScriptsView extends Component {

  genericColumns = [
    { data: 'enabled' },
    { data: 'name' },
    { data: this.renderRowOptions.bind(this) }
  ];

  eventScriptsColumns = [
    { data: 'enabled' },
    { data: 'name' },
    { data: (row) => row.event.type + ' (' + row.event.object + ')', columnKey: 'event' },
    { data: (row) => row.event.objectName, columnKey: 'objectName' },
    { data: this.renderRowOptions.bind(this) }
  ];

  scriptModuleScriptsColumns = [
    { data: 'name' },
    { data: (row, index) => this.renderRowOptions(row, index, true) }
  ];

  constructor(props) {
    super(props);
    this.state = {
      scripts: [],
      loading: true
    };
  }

  componentDidMount() {
    scriptsService.on('ScriptStatusChange', this.onScriptStatusChange);
    this.fetchData();
  }

  componentWillUnmount() {
    scriptsService.removeListener('ScriptStatusChange', this.onScriptStatusChange);
  }

  onScriptStatusChange = ({ scriptId, newStatus, newStatusTimestamp } = {}) => {
    this.updateScriptsStatuses([{ scriptId, status: newStatus, statusTimestamp: newStatusTimestamp }]);
  }

  updateScriptsStatuses = (statuses) => {
    const scripts = Array.from(this.state.scripts);
    statuses.forEach(({ scriptId, status, statusTimestamp }) => {
      const index = scripts.findIndex(d => d.id === scriptId);
      if (index >= 0) {
        scripts[index] = { ...scripts[index], status, statusTimestamp: statusTimestamp || +new Date() };
      }
    });
    this.setState({ scripts });
  }

  async fetchData() {
    eventBus.publish(eventBusTopics.LOADING_START);
    this.setState({ loading: true });
    try {
      await scriptsService.find().then(d => this.fetchDataSuccess(d))
        .catch(response => handleAPIError(response, 'models.scripts', this.props.intl));
    } catch (err) {
      console.log(err);
    }
    eventBus.publish(eventBusTopics.LOADING_END);
    scriptsService.getStatuses()
      .then(statuses => {
        this.updateScriptsStatuses(statuses);
      }).catch(console.log);
  }

  fetchDataSuccess(data) {
    this.setState({ ...this.state, scripts: data, loading: false });
  }

  removeScript(code) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    scriptsService.remove(code)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.scripts', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  rowStatus(row) {
    const data = {
      relativeDate: row.statusTimestamp ? new Date(row.statusTimestamp) : undefined
    };
    const title = 'models.scripts.status.' + row.status;
    switch (row.status) {
      case 'WORKING':
        return Object.assign(data, {
          status: RowStatusValues.OK,
          title
        });
      case 'ERROR':
        return Object.assign(data, {
          status: RowStatusValues.ERROR,
          title
        });
      case 'STOPPED':
        return Object.assign(data, {
          status: RowStatusValues.OFF,
          title
        });
      default:
        return Object.assign(data, {
          status: RowStatusValues.UNKNOWN,
          title: 'app.unknown'
        });
    }
  }

  renderRowOptions(row, index, disableRemove) {
    if (row.readOnly) {
      return <CommonFormOptions
        onView={() => this.props.history.push(`/scripts/edit/${row.id}`)}
      />;
    } else {
      return <CommonFormOptions
        onRemove={disableRemove ? undefined : () => this.removeScript(row.id)}
        onEdit={() => this.props.history.push(`/scripts/edit/${row.id}`)}
      />;
    }
  }

  render() {
    const intl = this.props.intl;

    let sortByName = (a, b) => (a.name || '').localeCompare(b.name);
    let customScripts = this.state.scripts.filter(s => s.type === 'Custom').sort(sortByName);
    let externalScripts = this.state.scripts.filter(s => s.type === 'External').sort(sortByName);
    let eventScripts = this.state.scripts.filter(s => s.type === 'Event');
    let scriptModuleScripts = this.state.scripts.filter(s => s.type === 'ScriptModule').sort(sortByName);
    return [
      <Row key='CustomScripts'>
        <TableBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='models.scripts.customScripts' />
            </BlockTitle>
            <BlockActions>
              <Tooltip title={intl.formatMessage({ id: 'models.scripts.new' })} >
                <Link to='/scripts/new'>
                  <IconButton >
                    <AddIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <DataTable
              translationKey='models.scripts'
              columns={this.genericColumns}
              sortableColumns={true}
              rowStatus={this.rowStatus.bind(this)}
              data={customScripts}
              loading={this.state.loading}
            />
          </BlockBody>
        </TableBlock>
      </Row >,
      <Row key='ScriptModuleScripts'>
        <TableBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='models.scripts.scriptModuleScripts' />
            </BlockTitle>
            <BlockActions>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <DataTable
              translationKey='models.scripts'
              columns={this.scriptModuleScriptsColumns}
              sortableColumns={true}
              rowStatus={this.rowStatus.bind(this)}
              data={scriptModuleScripts}
              loading={this.state.loading}
            />
          </BlockBody>
        </TableBlock>
      </Row >,
      <Row key='ExternalScripts'>
        <TableBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='models.scripts.externalScripts' />
            </BlockTitle>
            <BlockActions>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <DataTable
              translationKey='models.scripts'
              columns={this.genericColumns}
              sortableColumns={true}
              rowStatus={this.rowStatus.bind(this)}
              data={externalScripts}
              loading={this.state.loading}
            />
          </BlockBody>
        </TableBlock>
      </Row >,
      <Row key='EventScripts'>
        <TableBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='models.scripts.eventScripts' />
            </BlockTitle>
            <BlockActions>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <DataTable
              translationKey='models.scripts'
              columns={this.eventScriptsColumns}
              sortableColumns={true}
              rowStatus={this.rowStatus.bind(this)}
              data={eventScripts}
              loading={this.state.loading}
            />
          </BlockBody>
        </TableBlock>
      </Row >
    ];
  }
}

const ScriptsViewIntl = withRouter(injectIntl(ScriptsView));
export { ScriptsViewIntl as ScriptsView };
