import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { IntlFormHeaderIcon } from '../../../intl-components/Form';
import { FormGroup } from '@material-ui/core';
import { BlockHeader, BlockTitle, BlockActions } from '../../GridModules/ComponentBlock/ComponentBlock';
import { FormattedMessage } from 'react-intl';
import { Row } from '../../GridModules/Row';
import { ValidationChain } from '../../Form/Validation/ValidationChain';
import { DynamicFormField } from '../DynamicFormField';

class DatasourcesFormField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      object: props.value || [],
      errors: undefined
    };
  }

  addItem() {
    this.changeObject([...this.state.object, { code: '' }]);
  }

  removeItem(index) {
    this.changeObject(this.state.object.filter((_, i) => i !== index));
  }

  updateField(index, fieldName, value) {
    const newObject = [...this.state.object];
    newObject[index] = { ...newObject[index], [fieldName]: value };
    this.changeObject(newObject);
  }

  changeObject(object) {
    this.setState({
      object
    });
    this.props.onChange(object);
  }

  render() {
    const { label, translationKey, specByType } = this.props;
    const { object } = this.state;
    this.validationChain = new ValidationChain();

    return (
      <FormGroup>
        <BlockHeader>
          {label &&
            <BlockTitle>
              <FormattedMessage id={label} />
            </BlockTitle>
          }
          <BlockActions>
            <IntlFormHeaderIcon
              icon={<AddIcon />}
              tooltip='app.datasources.new'
              onClick={() => this.addItem()}
            />
          </BlockActions>
        </BlockHeader>
        {object.map((entry, index) => {
          let spec = specByType[`${entry.type}`];
          return <Row MultiColumns key={`object-item-${index}`} data-id={index}>
            {Object.entries(spec).map(([fieldName, fieldType]) => {
              const value = entry[fieldName];
              return (
                <DynamicFormField key={`object-item-${index}-field-${fieldName}`}
                  validationChain={this.validationChain}
                  value={value}
                  field={fieldName}
                  fieldType={fieldType}
                  translationKey={translationKey}
                  onChange={(value) => this.updateField(index, fieldName, value)}
                />
              );
            })
            }
            <IntlFormHeaderIcon
              icon={<DeleteIcon />}
              tooltip='app.datasources.remove'
              onClick={() => this.removeItem(index)}
            />
          </Row>;
        })}
      </FormGroup>
    );
  }
}

DatasourcesFormField.propTypes = {
  specByType: PropTypes.object.isRequired
};

export default DatasourcesFormField;
