import React, { Fragment } from 'react';
import styled from 'styled-components';
import { scriptContext } from '../scripts-context';
import { injectIntl, FormattedMessage } from 'react-intl';
import { TypeLabel } from './TypeLabel';
import { MethodLabel } from './MethodLabel';
import { FieldLabel } from './FieldLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Divider } from '@material-ui/core';

const StyledListItem = styled(ListItem)`
  && {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledTypeDescr = styled.div`
  margin-top: 10px;
`;

export const TypeDocumentation = injectIntl(({ type, expanded, intl }) => {
  const spec = scriptContext.types[type];
  const descTranslationKey = `models.scripts.scriptsContext.types.${type}.desc`;
  const hasDesc = !!intl.messages[descTranslationKey];
  return (
    <div>
      <TypeLabel type={type} />
      {expanded && hasDesc && (
        <StyledTypeDescr>
          <FormattedMessage id={descTranslationKey} />
        </StyledTypeDescr>
      )}
      <List>
        {expanded && spec.methods && spec.methods
          .sort((s1, s2) => s1.name.localeCompare(s2.name))
          .map((spec, i) =>
            <Fragment key={i}>
              <StyledListItem>
                <MethodLabel
                  type={type}
                  spec={spec}
                />
              </StyledListItem>
              <Divider />
            </Fragment>
          )}
        {expanded && spec.fields && spec.fields
          .sort((f1, f2) => f1.name.localeCompare(f2.name))
          .map((spec, i) =>
            <Fragment key={i}>
              <StyledListItem>
                <FieldLabel
                  parentType={type}
                  spec={spec}
                />
              </StyledListItem>
              <Divider />
            </Fragment>
          )}
      </List>
    </div>
  );
});