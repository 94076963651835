import React from 'react';
import PropTypes from 'prop-types';

import { TableBody } from '../Table/Table';
import { DynamicFormTableRow } from './DynamicFormTableRow';
import { ValidationChain } from '../Form/Validation/ValidationChain';
import CustomSortable from '../CustomSortable';

export class DynamicFormTableBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      renderingVariables: props.entries.length > 50 ? props.entries.length - 5 : 0
    };
    props.onRenderingChange(!!this.state.renderingVariables);
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
  }

  updateOrder(order) {
    let newContent = order.map(idx => this.props.entries[Number(idx)]);
    this.props.onOrderChange(newContent);
  }

  render() {
    const { entries, filteredEntries, readOnly, spec, errors, selection, validationChain, translationKey, enableRowSorting,
      enableMultipleSelection, onChange, onRemove, onSelectChange, onEdit, onRenderingChange } = this.props;
    const { renderingVariables } = this.state;

    if (this.state.renderingVariables) {
      // Trick to display loader while react is updating the DOM in big tables
      this.updateTimer = setTimeout(() => {
        delete this.updateTimer;
        this.setState({ renderingVariables: Math.max(this.state.renderingVariables - 5, 0) });
        if (this.state.renderingVariables <= 5) {
          onRenderingChange(false);
        }
      }, 5);
    }

    const rows = filteredEntries
      .slice(0, Math.max(filteredEntries.length - renderingVariables, 0))
      .filter(i => entries[i])
      .map(i => (
        <DynamicFormTableRow
          key={`dynamic-${i}`}
          readOnly={readOnly}
          selected={selection.has(i)}
          data={entries[i]}
          index={i}
          spec={spec}
          validationChain={validationChain}
          translationKey={translationKey}
          extraRowActions={this.props.extraRowActions}
          onChange={onChange}
          onRemove={onRemove}
          onSelect={enableMultipleSelection && (checked => onSelectChange(i, checked))}
          enableRowSorting={enableRowSorting}
          onEdit={onEdit ? () => onEdit(i) : undefined}
          errors={errors && errors[`${i}`]}
        />
      ));

    if (enableRowSorting) {
      return (<CustomSortable
        options={{ handle: '.sort-item' }}
        onChange={this.updateOrder.bind(this)}
        tag={TableBody}>
        {rows}
      </CustomSortable>);
    } else {
      return (<TableBody>
        {rows}
      </TableBody>);
    }
  }
}

DynamicFormTableBody.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  filteredEntries: PropTypes.arrayOf(PropTypes.number).isRequired,
  readOnly: PropTypes.bool,
  selection: PropTypes.instanceOf(Set).isRequired,
  validationChain: PropTypes.instanceOf(ValidationChain).isRequired,
  spec: PropTypes.object.isRequired,
  errors: PropTypes.object,
  translationKey: PropTypes.string.isRequired,
  enableMultipleSelection: PropTypes.bool,
  enableRowSorting: PropTypes.bool,
  extraRowActions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onOrderChange: PropTypes.func,
  onRenderingChange: PropTypes.func
};