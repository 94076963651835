import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { IntlSwitch } from '../../../intl-components/Form';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import Button from '@material-ui/core/Button';
import { calendarsService } from '../../../lib/service';
import ScheduleTimeSelector from './ScheduleTimeSelector';
import { schedulesService } from '../../../lib/service';
import { getErrorsResume, handleAPIError } from '../../../util/forms';

const FIRE_ON_REBOOT_OPTIONS = ['no', 'allways', 'ifChange'];

class ScheduleForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      schedule: props.schedule,
      errors: undefined
    };
    this.fireOnRebootOptions = FIRE_ON_REBOOT_OPTIONS.map((o, index) => { return { value: String(index), text: o, translationId: `models.schedules.form.fireOnReboot.${o}` }; });
  }

  componentDidMount() {
    this.fetchCalendars();
  }

  fetchCalendars() {
    calendarsService.find().then((d) => this.fetchCalendarsSuccess(d));
  }

  fetchCalendarsSuccess(data) {
    this.setState({ calendars: data.data });
  }

  updateSchedule(field, value) {
    if (field === 'duration' && !value) {
      this.updateSchedule('fireOnReboot', 0);
    }
    this.setState({
      schedule: {
        ...this.state.schedule,
        [field]: value
      },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  async onSave() {
    const errors = this.validationChain.getErrors();
    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      let schedule = this.state.schedule;
      try {
        schedule.id
          ? await schedulesService.update(schedule.id, schedule)
          : await schedulesService.create(schedule);
        this.setState({ errors: undefined });
        this.props.onSave(this.state.schedule);
      } catch (response) {
        const errors = handleAPIError(response, 'models.schedules', this.props.intl);
        this.setState({ errors });
      } finally {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      }
    }
  }

  getErrorsResume(errors) {
    let translationsKey = 'models.schedules';
    let arrayAttributesTranslationKeys = {};
    return getErrorsResume(this.props.intl, errors, translationsKey, arrayAttributesTranslationKeys);
  }

  render() {
    const { schedule, errors } = this.state;
    const allCalendars = [{ value: 'none', translationId: 'app.none' }, ...(this.state.calendars || []).map(c => { return { value: c.id, text: c.name }; })];
    this.validationChain = new ValidationChain();

    return (
      <Dialog
        aria-labelledby="schedule-form-title"
        aria-describedby="schedule-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="schedule-form-title">
          {schedule.id ?
            <FormattedMessage id="models.schedules.edit" /> :
            <FormattedMessage id="models.schedules.new" />
          }
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row>
              <IntlSwitch
                label='models.schedules.active'
                checked={!!schedule.active}
                onChange={(_ev, enabled) => this.updateSchedule('active', enabled)}
              />
              <DynamicFormField
                field='timerName'
                fieldType={{ type: 'string', validators: ['required', 'identifier'] }}
                translationKey='models.schedules'
                value={schedule.timerName}
                validationChain={this.validationChain}
                errors={get(errors, 'timerName')}
                onChange={n => this.updateSchedule('timerName', n)}
              />
            </Row>
            <Row>
              <DynamicFormField
                validationChain={this.validationChain}
                value={schedule.calendarId || 'none'}
                field='calendarId'
                fieldType={{ type: 'select', validators: ['required'], values: allCalendars }}
                translationKey={'models.schedules'}
                onChange={value => this.updateSchedule('calendarId', value === 'none' ? undefined : value)}
              />
            </Row>
            <ScheduleTimeSelector
              validationChain={this.validationChain}
              time={schedule.time}
              onChange={time => this.updateSchedule('time', time)}
            />
            <Row>
              <DynamicFormField
                field='duration'
                fieldType='milliseconds'
                translationKey='models.schedules'
                value={schedule.duration}
                validationChain={this.validationChain}
                errors={get(errors, 'duration')}
                onChange={value => this.updateSchedule('duration', value)}
              />
              <DynamicFormField
                disabled={!schedule.duration}
                validationChain={this.validationChain}
                value={String(schedule.fireOnReboot || 0)}
                field='fireOnReboot'
                fieldType={{ type: 'select', validators: ['required'], values: this.fireOnRebootOptions }}
                translationKey={'models.schedules'}
                errors={get(errors, 'fireOnReboot')}
                onChange={value => this.updateSchedule('fireOnReboot', Number(value))}
              />
            </Row>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ScheduleForm.propTypes = {
  schedule: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default injectIntl(ScheduleForm);