import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { client } from '../lib/service';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { IntlAlert } from '../intl-components/IntlAlert';
import { Root, PageWrapper } from '../components/Layout/Page';
import eventBus, { eventBusTopics } from '../lib/eventBus';

const ConnectionAlert = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;
  background: repeating-linear-gradient(
    -45deg,
    ${props => props.theme.BgLightGreyColor},
    ${props => props.theme.BgLightGreyColor} 8px,
    #EEA 8px,
    #EEA 16px
  );
`;

class LoginViewBase extends Component {

  constructor(props) {
    super(props);
    this.state = { login: '', password: '', connected: true };
  }

  componentDidMount() {
    this.subscriptions = [
      eventBus.subscribe(eventBusTopics.SERVER_CONNECTION, connected => {
        connected !== this.state.connected && this.setState({ connected });
      })
    ];
  }

  componentWillUnmount() {
    eventBus.unsubscribe(this.subscriptions);
  }

  updateField(name) {
    return (event) => this.setState({ [name]: event.target.value });
  }

  handleSubmit() {
    const { login, password } = this.state;
    if (!password) return;
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.login' }));
    client
      .authenticate({
        strategy: 'local',
        login, password
      })
      .catch(error => {
        console.log('Login error', error);
        this.handleError(error);
        this.setState({ password: '' });
      })
      .then(() => {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.login' }));
      });
  }

  handleError(error) {
    if (error.message === 'Invalid login') {
      this.setState({ uniqueId: +new Date(), alertId: 'invalidLogin', alertRawError: undefined });
    } else {
      this.setState({ uniqueId: +new Date(), alertId: undefined, alertRawError: error });
    }
  }

  render() {
    const { login, password, connected } = this.state;
    return [
      <PageWrapper key="login-form">
        <Root>
          <img alt="logo" src="img/corporative/adquio_logo_vertical.svg" width="130" style={{ marginBottom: '24px' }} />
          {!connected &&
            <ConnectionAlert>
              <FormattedMessage id="app.connectionLost" />
            </ConnectionAlert>
          }
          <Paper className="paper">
            <FormGroup>
              <TextField
                autoFocus
                name="login"
                type="text"
                label="Login"
                onChange={this.updateField('login')} value={login} margin="dense" />
              <TextField
                name="password"
                type="password"
                label="Password"
                value={password}
                margin="dense"
                onKeyPress={ev => ev.key === 'Enter' ? this.handleSubmit() : undefined}
                onChange={this.updateField('password')}
                style={{ marginBottom: '36px' }} />
              <Button
                onClick={() => this.handleSubmit()}
                variant="contained"
                color="primary"
                disabled={!connected}>
                <FormattedMessage id="app.logIn" />
              </Button>
            </FormGroup>
          </Paper>
          {/* <div className="field_box type_checkbox">
                      <input type="checkbox" name="" id="remember">
                        <label for="remember">Recordar</label>
                </div> */}
          {/* <a href="">¿Has olvidado la contraseña?</a> */}
        </Root>
      </PageWrapper>,
      (this.state.alertId || this.state.alertRawError) &&
      <IntlAlert key={`login-alert_${this.state.uniqueId}`} alertId={this.state.alertId} rawError={this.state.alertRawError != null ? this.state.alertRawError + '' : ''} />
    ];
  }
}

const LoginView = injectIntl(LoginViewBase);
export default LoginView;
