import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { IntlFormHeaderIcon } from '../../intl-components/Form';
import { FormGroup } from '@material-ui/core';
import { BlockHeader, BlockTitle, BlockActions } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { FormattedMessage } from 'react-intl';
import { Row } from '../../components/GridModules/Row';
import { DynamicFormField } from '../../components/Dynamics/DynamicFormField';

const updateValue = (onChange, values, index, value) => {
  const copy = [...values];
  copy[index] = value;
  onChange(copy);
};

const DnsListFormField = props => {

  const values = props.value || [];
  const { validationChain, field } = props;
  return (
    <FormGroup>
      <BlockHeader>
        {props.label &&
          <BlockTitle>
            <FormattedMessage id={props.label} />
          </BlockTitle>
        }
        <BlockActions>
          <IntlFormHeaderIcon
            icon={<AddIcon />}
            tooltip='system.dns.add'
            onClick={() => props.onChange([...values, ''])}
          />
        </BlockActions>
      </BlockHeader>
      {values.map((value, index) => (
        <Row MultiColumns TwoColumns key={index}>
          <DynamicFormField
            validationChain={validationChain}
            value={value}
            field={field}
            translationKey='system.dns'
            fieldType={{ type: 'string', validators: ['required', 'IPv4'] }}
            onChange={(value) => updateValue(props.onChange, values, index, value)}
          />
          <IntlFormHeaderIcon
            icon={<DeleteIcon />}
            tooltip='app.remove'
            onClick={() => props.onChange(values.filter((_v, i) => i !== index))}
          />
        </Row>
      ))}
    </FormGroup>
  );
};

DnsListFormField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DnsListFormField;
