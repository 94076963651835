import { uiSection } from '../Devices/common-spec';

const modbusRegTypes = ['coil', 'discrete_input', 'holding_register', 'input_register'];
const modbusMemOrderTypes = ['big_endian', 'little_endian'];
const valueConverters = {
  type: 'array',
  items: 'exportValueConverter',
  availableConverters: null,
  validators: ['valueConverters'],
  hideInTable: false,
  ...uiSection('general', 5)
};

const variable = {
  type: 'variable',
  validators: ['required'],
  availableVariables: null,
  deviceCodes: null,
  dataTypes: null,
  ...uiSection('general', 0)
};

const baseVariable = {
  variable,
  canWrite: { type: 'boolean', validators: [], ...uiSection('general', 1) },
  dataType: { type: 'label', validators: ['required'], translationKey: 'models.variables.form.dataType', ...uiSection('general', 5) },
  valueConverters,
  protocolDataType: { type: 'label', validators: [], translationKey: 'models.variables.form.dataType', ...uiSection('general', 5) }, /* used to print in tables */
  outOfServiceValue: { type: 'string', description: 'Protocol value when variable out of service', ...uiSection('general', 20) },
  valueOffset: { type: 'number', validators: [], ...uiSection('general', 30) },
};

const modbusVariable = {
  ...baseVariable,
  modbusRegType: { type: modbusRegTypes, validators: ['required'], ...uiSection('protocol', 45) },
  modbusAddress: { type: 'string', validators: ['required', 'modbusAddress'], ...uiSection('protocol', 45) }
};

const modbusTcp = {
  tcpEnabled: { type: 'boolean', validators: [] },
  modbusSlave: { type: 'string', validators: ['required'] },
  modbusByteOrder: { type: modbusMemOrderTypes, validators: [], defaultValue: 'big_endian', ...uiSection(undefined, 5) },
  modbusWordOrder: { type: modbusMemOrderTypes, validators: [], defaultValue: 'little_endian', ...uiSection(undefined, 5) },
};

export const exportDevicesSpec = ({ valueConvertersArray, variables, deviceCodes, dataTypes }) => {
  valueConverters.availableConverters = valueConvertersArray;
  baseVariable.variable.availableVariables = variables;
  baseVariable.variable.deviceCodes = deviceCodes;
  baseVariable.variable.dataTypes = dataTypes;

  return {
    modbus: {
      modbusTcp,
      variables: modbusVariable
    }
  };
};

export const defaultVariable = {
  canWrite: true
};
