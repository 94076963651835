import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import DataTable from '../components/Table/DataTable';
import { CustomizableDataTable } from '../components/Table/CustomizableDataTable';
import { renderDateColumn } from '../util/time';

const getIntlCellMapper = (intl, columnTranslationKey) => value => {
  if (value && columnTranslationKey) {
    return intl.formatMessage({ id: `${columnTranslationKey}.${value}` });
  } else {
    return value;
  }
};

const IntlDataTableBase = ({ isCustomizable, rowActions, tableActions, intl, dateColumns = [], columnTranslationKeys = [],
  rowMapFunction, translationKey, ...dataTableProps }) => {
  const cellMappers = columnTranslationKeys.map(k => {
    if (k) {
      return getIntlCellMapper(intl, k.startsWith('.') ? `${translationKey}${k}` : k);
    } else return undefined;
  });
  let newRowMapFunction;
  if (dateColumns.length) {
    newRowMapFunction = row => {
      if (rowMapFunction) {
        row = rowMapFunction(row);
      }
      dateColumns.forEach(col => {
        if (row[col]) {
          row[col] = renderDateColumn(intl, new Date(row[col]));
        }
      });
      return row;
    };
  }

  if (tableActions) {
    tableActions = tableActions.map(({ textKey, ...other }) => ({ text: intl.formatMessage({ id: textKey }), textKey, ...other }));
  }
  if (rowActions) {
    rowActions = rowActions.map(({ textKey, ...other }) => ({ text: intl.formatMessage({ id: textKey }), textKey, ...other }));
  }

  const DataTableClass = isCustomizable ? CustomizableDataTable : DataTable;
  return (
    <DataTableClass
      {...dataTableProps}
      rowActions={rowActions}
      tableActions={tableActions}
      translationKey={translationKey}
      cellMapFunctions={cellMappers}
      rowMapFunction={newRowMapFunction || rowMapFunction}
    />
  );
};

IntlDataTableBase.propTypes = {
  enableMultipleSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onRowClick: PropTypes.func
};

const IntlDataTable = injectIntl(IntlDataTableBase);
export { IntlDataTable };