import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { calendarsService } from '../../../lib/service';
import { TableBlock } from '../../../components/GridModules/ComponentBlock/TableBlock';
import { BlockHeader, BlockTitle, BlockActions, BlockBody } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import CalendarForm from './CalendarFormDialog';
import DataTable from '../../../components/Table/DataTable';
import CommonFormOptions from '../../components/CommonFormOptions';
import { dayListToString, dayEntryToString } from '../SchedulerUiUtils';
import { Row } from '../../../components/GridModules/Row';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { handleAPIError } from '../../../util/forms';

class CalendarTableBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      calendars: [],
      loading: true,
      editingCalendar: undefined
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    calendarsService.find().then((d) => this.fetchDataSuccess(d));
  }

  fetchDataSuccess(data) {
    this.setState({ calendars: data.data, loading: false, editingCalendar: undefined });
  }

  saveCalendar() {
    this.fetchData();
  }

  removeCalendar(calendar) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    calendarsService.remove(calendar.id)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.calendars', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  mapRowToTable({ id, name, parentName, start, end, inclusions, exclusions }) {
    return {
      id, name, parentName,
      start: dayEntryToString(start),
      end: dayEntryToString(end),
      inclusions: dayListToString(inclusions),
      exclusions: dayListToString(exclusions)
    };
  }

  render() {
    const columns = [
      { data: 'name', sortable: true },
      { data: 'parentName', sortable: true },
      { data: 'start', sortable: true },
      { data: 'end', sortable: true },
      { data: 'inclusions' },
      { data: 'exclusions' },
      {
        data: row => (
          <CommonFormOptions
            onEdit={() => this.setState({ editingCalendar: row })}
            onRemove={() => this.removeCalendar(row)}
          />)
      }
    ];
    return (
      <Fragment>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="section.calendars" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={this.props.intl.formatMessage({ id: 'models.calendars.new' })} >
                  <IconButton onClick={() => this.setState({ editingCalendar: {} })} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <DataTable
                translationKey="models.calendars"
                columns={columns}
                data={this.state.calendars}
                rowMapFunction={this.mapRowToTable}
                loading={this.state.loading}
              />
            </BlockBody>
          </TableBlock>
          {this.state.editingCalendar &&
            <CalendarForm
              calendar={this.state.editingCalendar}
              allCalendars={this.state.calendars}
              onSave={newCalendar => this.saveCalendar(newCalendar)}
              onCancel={() => this.setState({ editingCalendar: null })}
            />
          }
        </Row>
      </Fragment>
    );
  }
}

const CalendarTable = injectIntl(CalendarTableBase);

export default CalendarTable;