import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ColorCircle } from './ColorCircle';
import IntlTooltip from '../intl-components/Tooltip';

const CustomColorCircle = styled(ColorCircle)`
  height: 16px;
  width: 16px;
  margin-right: 5px;
  vertical-align: text-bottom;
`;

class StatusMonitorBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { titleKey, color, date, messageKey, messageValues } = this.props;
    return (
      <div>
        <IntlTooltip title={titleKey} relativeDate={date} >
          <CustomColorCircle color={color} />
        </IntlTooltip>
        <FormattedMessage id={messageKey || titleKey} values={messageValues || []} />
      </div>
    );
  }
}

export const StatusMonitor = injectIntl(StatusMonitorBase);
StatusMonitor.propTypes = {
  titleKey: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  date: PropTypes.number,
  messageKey: PropTypes.string,
  messageValues: PropTypes.object
};