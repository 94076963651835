import React, { Fragment } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { confirm } from '../../components/util';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { systemService } from '../../lib/service';
import { Row } from '../../components/GridModules/Row';
import { BlockActions, BlockBody, BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { showErrorAlert } from '../../lib/apiHelpers';
import { COLORS } from '../../components/ColorCircle';
import { pick } from 'lodash';
import { SectionTitle } from '../../components/Layout/Page';
import { LabeledText } from '../../components/Form/Form';
import { StatusMonitor } from '../../components/StatusMonitor';
import { IntlSwitch } from '../../intl-components/Form';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { handleAPIError } from '../../util/forms';
import { IconButton, Tooltip } from '@material-ui/core';


const getStatusCircleData = (status) => {
  const title = 'system.cloud.genericStatus.' + status;
  switch (status) {
    case 'CONNECTED':
      return {
        color: COLORS.GREEN,
        title
      };
    case 'ERROR':
    case 'DISCONNECTED':
      return {
        color: COLORS.RED,
        title
      };
    case 'NOT_CONFIGURED':
    case 'DISABLED':
      return {
        color: COLORS.GRAY,
        title
      };
    default:
      return {
        color: COLORS.GRAY,
        title: 'app.unknown'
      };
  }
};


class CloudViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      adquioId: null,
      realtime: {
        status: null
      },
      secureAccess: {
        status: null,
        ip: null,
        netmask: null,
        server: null
      }
    };
  }

  componentDidMount() {
    this.fetchData();
    this.backgroundFetch = setInterval(() => {
      this.backgroundFetchData().catch(_ => { });
    }, 4000);
  }

  componentWillUnmount() {
    clearInterval(this.backgroundFetch);
  }

  backgroundFetchData() {
    return systemService.get('cloudInfo')
      .then(result => {
        const values = pick(result.data, ['adquioId', 'realtime', 'secureAccess']);
        this.setState({
          ...values,
          loading: false
        });
      });
  }

  fetchData() {
    const { intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));
    this.backgroundFetchData()
      .catch(err => showErrorAlert(err))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' })));
  }

  saveConfiguration(config) {
    const { intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
    systemService.update('cloud', config)
      .catch(response => handleAPIError(response, 'app', intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' }));
        this.fetchData();
      });
  }

  setSecureAccessEnabled(enabled) {
    const { intl } = this.props;
    if (enabled) {
      confirm(intl, 'system.cloud.secureAccess.enableConfirm', () => this.saveConfiguration({ enabled }));
    } else {
      confirm(intl, 'system.cloud.secureAccess.disableConfirm', () => this.saveConfiguration({ enabled }));
    }
  }

  render() {
    const { loading, adquioId, realtime, secureAccess } = this.state;
    const { intl } = this.props;

    const rtStatusData = getStatusCircleData(realtime.status);
    const rtStatusMonitor = (
      <StatusMonitor
        titleKey={rtStatusData.title}
        color={rtStatusData.color}
        messageKey={rtStatusData.title}
      />);

    const saStatusData = getStatusCircleData(secureAccess.status);
    const saConfigured = ['CONNECTED', 'DISCONNECTED', 'DISABLED'].includes(secureAccess.status);
    const saEnabled = ['CONNECTED', 'DISCONNECTED'].includes(secureAccess.status);
    const saStatusMonitor = (
      <StatusMonitor
        titleKey={saStatusData.title}
        color={saStatusData.color}
        messageKey={saStatusData.title}
      />);

    return (
      <Fragment>
        <SectionTitle id='system.cloud.title' />
        <Row>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='system.cloud.realtime.title' />
            </BlockTitle>
            <BlockActions>
              <Tooltip title={intl.formatMessage({ id: 'app.refresh' })} >
                <IconButton onClick={() => this.fetchData()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <LabeledText id='system.cloud.info.id' value={adquioId} />
            <LabeledText id='system.cloud.info.realTime' item={rtStatusMonitor} />
          </BlockBody>
        </Row>
        <Row>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='system.cloud.secureAccess.title' />
            </BlockTitle>
          </BlockHeader>
          <BlockBody>
            <IntlSwitch
              label='system.cloud.secureAccess.enabled'
              checked={saEnabled}
              disabled={loading || !saConfigured}
              onChange={(_ev, enabled) => this.setSecureAccessEnabled(enabled)}
            />
            <LabeledText id='app.status' item={saStatusMonitor} />
            <LabeledText id='system.cloud.secureAccess.server' value={secureAccess.server} />
            <LabeledText id='system.cloud.secureAccess.ip' value={secureAccess.ip} />
            <LabeledText id='system.cloud.secureAccess.netmask' value={secureAccess.netmask} />
          </BlockBody>
        </Row>
      </Fragment >
    );
  }
}

export const CloudView = injectIntl(withRouter(CloudViewBase));