import React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

export class DateTimeField extends React.Component {

  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.value && state.value !== props.value) {
      return { ...state, value: props.value };
    } else if (!props.value && !state.value) {
      const now = new Date();
      props.onChange(now);
      return { ...state, value: now };
    }
    return state;
  }

  onChange(newMoment) {
    let valid = newMoment.isValid();
    if (this.props.minutesStep) {
      valid = valid && !(newMoment.valueOf() % (this.props.minutesStep * 60000));
    }
    this.setState({ value: valid ? newMoment.valueOf() : newMoment });
    this.props.onChange(valid ? newMoment.valueOf() : undefined);
  }

  render() {
    return (
      <KeyboardDateTimePicker
        {...this.props}
        variant="inline"
        ampm={false}
        value={this.state.value}
        onChange={m => this.onChange(m)}
        format="YYYY/MM/DD HH:mm:ss"
      />
    );
  }

}