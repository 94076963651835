import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { variablesService, devicesService, unitsService, variableTypesService, dataTypesService } from '../../lib/service';
import { formatSortParam } from '../../lib/apiHelpers';

import { RowStatusValues } from '../../components/Table/DataTable';
import { IntlDataTable } from '../../intl-components/IntlDataTable';

const OUT_OF_SERVICE = 'OUT_OF_SERVICE';
const WORKING = 'WORKING';
const STOPPED = 'STOPPED';
const INIT = 'INIT';

const variableKey = (deviceCode, variableCode) => `${deviceCode}#${variableCode}`;

class VariablesTableBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageData: {
        total: 0,
        limit: 50,
        skip: 0,
        data: []
      },
      dataTypes: [],
      units: [],
      variableTypes: [],
      query: props.query
    };
    this.updateVariables = {};
  }

  static getDerivedStateFromProps(props) {
    return {
      query: props.query
    };
  }

  componentDidMount() {
    this.onPaginationChange({ page: 0, rowsPerPage: 50 });
    this.fetchConstants();
    this.variableChangeHandler = data => this.onVariableChange(data);
    this.variableStatusChangeHandler = data => this.onVariableStatusChange(data);
    variablesService.on('VariableChange', this.variableChangeHandler);
    variablesService.on('VariableStatusChange', this.variableStatusChangeHandler);
    devicesService.find({ $include: 'code' }).then(devices => this.setState({ deviceCodes: devices.map(d => d.code) }));
    this.subscriptions = [
      eventBus.subscribe(eventBusTopics.SERVER_CONNECTION, connected => connected && this.fetchData())
    ];
  }

  componentWillUnmount() {
    if (this.variableChangeThrottle) {
      clearTimeout(this.variableChangeThrottle);
    }
    variablesService.removeListener('VariableChange', this.variableChangeHandler);
    variablesService.removeListener('VariableStatusChange', this.variableStatusChangeHandler);
    eventBus.unsubscribe(this.subscriptions);
  }

  componentDidUpdate(prevProps) {
    const prevQuery = prevProps.query || {};
    const queryChange =
      Object.keys(prevQuery).length !== Object.keys(this.props.query).length ||
      Object.entries(this.props.query).some(([key, value]) => value !== prevQuery[key]);
    if (queryChange) {
      this.fetchData();
    }
  }

  async fetchConstants() {
    const units = await unitsService.find();
    const dataTypes = await dataTypesService.find();
    const variableTypes = await variableTypesService.find();
    this.setState({ units, dataTypes, variableTypes });
  }

  fetchData() {
    const query = { $limit: this.state.pageData.limit, $skip: this.state.pageData.skip, $sort: formatSortParam(this.state.sort) };
    Object.assign(query, this.props.query);
    Object.keys(this.props.query).forEach(k => {
      if (query[k] === '') {
        query[k] = undefined;
      }
    });
    variablesService.find({ query }).then((d) => this.fetchDataSuccess(d));
  }

  fetchDataSuccess(data) {
    this.setState({ pageData: data, loading: false });
  }

  onVariableChange(data) {
    if (this.state.loading) return;
    const key = variableKey(data.deviceCode, data.variableCode);
    this.updateVariables[key] = { ...this.updateVariables[key], value: data.newValue };
    this.updateVariablesState();
  }

  onVariableStatusChange(data) {
    if (this.state.loading) return;
    const key = variableKey(data.deviceCode, data.variableCode);
    this.updateVariables[key] = { ...this.updateVariables[key], status: data.newStatus, statusTimestamp: data.newStatusTimestamp, value: data.newValue };
    this.updateVariablesState();
  }

  updateVariablesState() {
    if (!this.variableChangeThrottle) {
      this.variableChangeThrottle = setTimeout(() => {
        const variableData = this.state.pageData.data;
        const variables = variableData.map(vd => {
          const newData = this.updateVariables[variableKey(vd.deviceCode, vd.variableCode)];
          if (newData === undefined) {
            return vd;
          } else {
            return {
              ...vd,
              ...newData
            };
          }
        });
        this.updateVariables = {};
        this.setState({ ...this.state, pageData: { ...this.state.pageData, data: variables } });
        delete this.variableChangeThrottle;
      }, 250);
    }
  }

  onPaginationChange({ page, rowsPerPage }) {
    this.setState({
      loading: true, pageData: Object.assign(this.state.pageData, {
        limit: rowsPerPage, skip: rowsPerPage * page
      })
    }, this.fetchData);
  }

  onSortChange(columnName, order) {
    this.setState({
      loading: true,
      sort: { [columnName]: order }
    }, this.fetchData);
  }

  onFilterChange(columnName, value) {
    const query = { ...this.state.query, [columnName]: value };
    this.setState({
      loading: true, pageData: { ...this.state.pageData, skip: 0 }
    }, () => this.props.onQueryChange(query));
  }

  onValueEdit(variableCode, newValue) {
    return variablesService.update(variableCode, { value: newValue });
  }

  rowStatus(row) {
    let status = RowStatusValues.UNKNOWN;
    switch (row.status) {
      case OUT_OF_SERVICE:
        status = RowStatusValues.ERROR;
        break;
      case INIT:
        status = RowStatusValues.WARNING;
        break;
      case WORKING:
        status = RowStatusValues.OK;
        break;
      case STOPPED:
        status = RowStatusValues.OFF;
        break;
      default:
    }
    return {
      status,
      title: `models.variables.status.${row.status}`,
      relativeDate: row.statusTimestamp && new Date(row.statusTimestamp)
    };
  }

  render() {
    const { variableTypes, dataTypes, units, deviceCodes } = this.state;
    return (
      <IntlDataTable
        isCustomizable
        tableId='variablesTable'
        translationKey='models.variables'
        columnTranslationKeys={[null, null, null, null, '.form.units', '.form.dataType', '.form.type']}
        columns={[
          { data: 'deviceCode', sortable: true, filter: deviceCodes },
          { data: 'variableCode', sortable: true, filter: '&*' },
          { data: 'name', sortable: true, filter: '&*' },
          { data: 'value' },
          { data: 'units', sortable: true, filter: units },
          { data: 'dataType', sortable: true, filter: dataTypes },
          { data: 'type', sortable: true, filter: variableTypes }
        ]}
        rowStatus={this.rowStatus}
        filters={this.state.query}
        isEditable={(column, row) => column === 'value' && row.status === WORKING && !!row.canWrite}
        pageData={this.state.pageData}
        sort={this.state.sort}
        rowMapFunction={({ value, valueMappings, ...other }) => ({ value: valueMappings ? { value, valueMappings } : value, ...other })}
        onPaginationChange={(ev) => this.onPaginationChange(ev)}
        onFilterChange={(column, filter) => this.onFilterChange(column, filter)}
        onSortChange={(column, order) => this.onSortChange(column, order)}
        onCellEdit={(col, row, val) => this.onValueEdit(row.id, val)}
        loading={this.state.loading} />
    );
  }

}

const VariablesTable = injectIntl(VariablesTableBase);
export default VariablesTable;
