import React from 'react';
import PropTypes from 'prop-types';
import { ValidationChain } from './ValidationChain';

class ValidableFormComponent extends React.Component {

  getValue() {
    return this.props.value;
  }

  getTips() {
    if (this.props.tips) {
      return this.props.tips;
    } else {
      return this.props.validators
        .filter(vt => typeof vt === 'string')
        .filter(vt => vt !== 'required')
        .map(vt => 'validators.' + vt);
    }
  }

  componentWillUnmount() {
    this.props.validationChain.unregisterComponent(this);
  }

  render() {
    const validationErrors = this.props.validationChain.getComponentErrors(this);
    const errors = [...this.props.errors, ...validationErrors];
    const value = this.getValue();
    const error = !!errors.length;
    const required = this.props.validators.includes('required');
    const child = this.props.children;
    this.props.validationChain.registerComponent(this);
    return React.cloneElement(child, {
      error, errors, required, value,
      field: this.props.field,
      tips: this.getTips()
    });
  }
}

ValidableFormComponent.propTypes = {
  value: PropTypes.any,
  validationChain: PropTypes.instanceOf(ValidationChain).isRequired,
  validators: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.string])),
  field: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string)
};

ValidableFormComponent.defaultProps = {
  errors: []
};

export default ValidableFormComponent;