import { Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import PageviewIcon from '@material-ui/icons/Pageview';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { BlockActions, BlockBody, BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import { injectQueryString } from '../../components/queryUtils';
import { RowStatusValues } from '../../components/Table/DataTable';
import { FAIconButton, ICONS } from '../../components/Table/FAIconButton';
import { IntlDataTable } from '../../intl-components/IntlDataTable';
import { filterByQuery } from '../../lib/apiHelpers';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { automaticAlertsService, devicesConfigService, devicesService } from '../../lib/service';
import CommonFormOptions from '../components/CommonFormOptions';
import { DEVICE_STATUS } from './devices-spec';
import { handleAPIError } from '../../util/forms';
import ScanDevicesDialog from './Scan/ScanDevicesDialog';


const DEVICE_STATUS_AUTOMATIC_ALERT = 'DeviceStatus';
const DEFAULT_ALERT_DELAY = 150000;

class DevicesView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      instances: [],
      deviceAlerts: [],
      scanningDevices: false,
      loading: true
    };
  }

  onDeviceStatusChange = data => {
    const devices = Array.from(this.state.devices);
    const index = devices.findIndex(d => d.code === data.deviceCode);
    if (index >= 0) {
      devices[index] = { ...devices[index], status: data.newStatus, statusTimestamp: data.statusTimestamp || +new Date() };
      this.setState({ devices });
    }
  };

  componentDidMount() {
    this.fetchData();
    devicesService.on('DeviceStatusChange', this.onDeviceStatusChange);
  }

  componentWillUnmount() {
    devicesService.removeListener('DeviceStatusChange', this.onDeviceStatusChange);
  }

  async fetchData() {
    eventBus.publish(eventBusTopics.LOADING_START);
    try {
      const { devices } = await devicesConfigService.find();
      const deviceAlerts = await automaticAlertsService.find({ query: { type: DEVICE_STATUS_AUTOMATIC_ALERT } });
      const instances = Array.from(new Set(devices.map(d => d.instance)));
      this.setState({ devices, instances, deviceAlerts, loading: false });
    } catch (e) {
      console.error(e);
    }
    eventBus.publish(eventBusTopics.LOADING_END);
  }

  removeDevice(code) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    devicesConfigService.remove(code)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.devices', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  renderRowOptions(row) {
    return <Fragment>
      <CommonFormOptions
        onRemove={() => this.removeDevice(row.code)}
        onEdit={() => this.props.history.push(`/devices/device/${row.code}`)}
      />
    </Fragment>;
  }

  renderDeviceAlerts(device) {
    const hasAlert = this.state.deviceAlerts.some(a => a.object === device.code);
    const tooltipText = this.props.intl.formatMessage({ id: hasAlert ? 'models.devices.alerts.disable' : 'models.devices.alerts.enable' });
    return <Tooltip title={tooltipText}>
      <Switch
        onClick={() => this.toggleDeviceAlert(device.code)}
        checked={hasAlert}
      />
    </Tooltip>;
  }

  async toggleDeviceAlert(deviceCode) {
    try {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      const aAlert = this.state.deviceAlerts.find(a => a.object === deviceCode);
      if (aAlert) {
        await automaticAlertsService.remove(aAlert.id);
      } else {
        await automaticAlertsService.create({ delay: DEFAULT_ALERT_DELAY, type: DEVICE_STATUS_AUTOMATIC_ALERT, object: deviceCode });
      }
      const deviceAlerts = await automaticAlertsService.find({ query: { type: DEVICE_STATUS_AUTOMATIC_ALERT } });
      this.setState({ deviceAlerts });
    } catch (response) {
      handleAPIError(response, 'models.devices', this.props.intl);
    } finally {
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
    }
  }

  renderVariablesCell(row) {
    return (
      <Fragment>
        <label>{`${(row.variables || []).length} - ${(row.virtualVariables || []).length}`}</label>
        <FAIconButton
          icon={ICONS.faExternalLinkAlt}
          onClick={() => this.props.redirect('/', { deviceCode: row.code })}
        />
      </Fragment>
    );
  }

  onFilterChange(column, value) {
    const { updateQuery, query } = this.props;
    updateQuery({ ...query, [column]: value });
  }

  rowStatus(row) {
    const data = {
      relativeDate: row.statusTimestamp ? new Date(row.statusTimestamp) : undefined
    };
    switch (row.status) {
      case DEVICE_STATUS.WORKING:
        return Object.assign(data, {
          status: RowStatusValues.OK,
          title: 'models.devices.status.WORKING',
        });
      case DEVICE_STATUS.INIT:
        return Object.assign(data, {
          status: RowStatusValues.WARNING,
          title: 'models.devices.status.INIT'
        });
      case DEVICE_STATUS.OUT_OF_SERVICE:
        return Object.assign(data, {
          status: RowStatusValues.ERROR,
          title: 'models.devices.status.OUT_OF_SERVICE'
        });
      case DEVICE_STATUS.STOPPED:
        return Object.assign(data, {
          status: RowStatusValues.OFF,
          title: 'models.devices.status.STOPPED'
        });
      default:
        return Object.assign(data, {
          status: RowStatusValues.UNKNOWN,
          title: 'models.devices.status.UNKNOWN'
        });
    }
  }

  rowMapFunction({ disabled, ...other }) {
    return {
      ...other,
      enabled: !disabled
    };
  }

  render() {
    const { intl, query } = this.props;
    const columns = [
      { data: 'enabled' },
      { data: 'code' },
      { data: 'name' },
      { data: 'type' },
      {
        data: 'instance',
        filter: this.state.instances
      },
      {
        columnKey: 'variablesCount',
        data: this.renderVariablesCell.bind(this)
      },
      {
        columnKey: 'deviceAlerts',
        data: this.renderDeviceAlerts.bind(this)
      },
      { data: this.renderRowOptions.bind(this) }];
    return (
      <Fragment>
        <Row key="devices-table">
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="section.devices" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={intl.formatMessage({ id: 'models.devices.scan' })} >
                  <IconButton onClick={() => this.setState({ scanningDevices: true })}>
                    <PageviewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'models.devices.new' })} >
                  <Link to="/devices/device">
                    <IconButton >
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <IntlDataTable
                translationKey="models.devices"
                filters={query}
                onFilterChange={(column, value) => this.onFilterChange(column, value)}
                columnTranslationKeys={[null, null, null, '.types']}
                columns={columns}
                sortableColumns={true}
                sort={{ code: 'desc' }}
                rowStatus={this.rowStatus.bind(this)}
                data={filterByQuery(this.state.devices, query)}
                loading={this.state.loading}
                rowMapFunction={this.rowMapFunction}
              />
            </BlockBody>
          </TableBlock>
        </Row >
        {this.state.scanningDevices &&
          <ScanDevicesDialog
            key='scan-devices'
            onImport={(variables) => {
              this.setState({ scanningDevices: false });
              this.fetchData();
            }}
            onClose={() => this.setState({ scanningDevices: false })}
          />
        }
      </Fragment >
    );
  }
}

const DevicesViewIntl = withRouter(injectQueryString(injectIntl(DevicesView)));
export default DevicesViewIntl;
