import React from 'react';
import styled, { css } from 'styled-components';

import CustomSortable from '../CustomSortable';
import { Dialog } from '../custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { ellipsis } from 'polished';

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: space-between;
  width: 600px;
`;

const StyledSortable = styled(CustomSortable)`
  min-height: 50px;
  width: 100%;
`;

const SortableBlock = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 50px;
  min-height: 50px;
  border: 1px solid ${props => props.theme.BorderColor};
  &:first-of-type {
    margin-right: ${props => props.theme.MarginSize};
  }
  ${props => props.Used && css`
    background-color: ${props => props.theme.BgLightGreyColor};
  `}
  > ${StyledSortable} {
    flex-grow: 1;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    padding: ${props => props.theme.QuarterMarginSize} ${props => props.theme.HalfMarginSize};
  }
`;

const ColumnTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const SortHandle = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${props => props.theme.HalfMarginSize};
  cursor: all-scroll;
  ${ellipsis()};
  > svg {
    margin-right: ${props => props.theme.QuarterMarginSize};
    vertical-align: middle;
  }
`;

class CustomizeTableDialog extends React.Component {

  state = {
    currentSetup: undefined,
    hiddenFields: undefined
  }

  render() {
    const { columns, translationKey, onCancel, onSave } = this.props;
    const allColumns = columns.map((c, i) => typeof c.data === 'string' ? i : false).filter(c => c !== false);
    const currentSetup = this.state.currentSetup || this.props.currentSetup || allColumns;
    const hiddenColumns = allColumns.filter(idx => !currentSetup.includes(idx));
    return (
      <Dialog
        aria-labelledby="form-title"
        aria-describedby="form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle>
          <FormattedMessage id='app.customizeColumns.title' />
        </DialogTitle>
        <StyledDialogContent>
          <SortableBlock Used>
            <StyledTypography variant="subtitle1">
              <FormattedMessage id='app.customizeColumns.activeColumns' />
            </StyledTypography>
            <StyledSortable
              options={{
                handle: '.sort-handle',
                group: 'table-customize'
              }}
              onChange={list => this.setState({ currentSetup: list.map(Number) })}
            >
              {currentSetup.map(idx => (
                <SortableColumnTitle
                  key={idx}
                  idx={idx}
                  translationKey={translationKey}
                  column={columns[idx].data}
                />
              ))}
            </StyledSortable>
          </SortableBlock>
          <SortableBlock>
            <StyledTypography variant="subtitle1">
              <FormattedMessage id='app.customizeColumns.inactiveColumns' />
            </StyledTypography>
            <StyledSortable
              options={{
                handle: '.sort-handle',
                group: 'table-customize'
              }}
              onChange={f => f} // Dummy function making this sortable list managed by React (prevent SortableJS from changing DOM)
            >
              {hiddenColumns.map(idx => (
                <SortableColumnTitle
                  key={idx}
                  idx={idx}
                  translationKey={translationKey}
                  column={columns[idx].data}
                />
              ))}
            </StyledSortable>
          </SortableBlock>
        </StyledDialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => onSave(currentSetup)}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const SortableColumnTitle = ({ idx, column, translationKey }) => {
  if (typeof column !== 'string') return false;
  return (
    <ColumnTitleWrapper data-id={idx}>
      <SortHandle className='sort-handle'>
        <DragHandleIcon />
        <FormattedMessage id={`${translationKey}.${column}`} />
      </SortHandle>
    </ColumnTitleWrapper>
  );
};

export { CustomizeTableDialog };