import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

const QueryContext = React.createContext({});

export const QueryStringProvider = withRouter(({ location, history, children }) =>
  <QueryContext.Provider value={{
    query: queryString.parse(location.search),
    redirect: (path, query) => history.push({
      pathname: path,
      search: query ? queryString.stringify(query) : query
    }),
    updateQuery: query => history.push({
      pathname: location.pathname,
      search: queryString.stringify(query)
    })
  }}>
    {children}
  </QueryContext.Provider >
);

export const injectQueryString = Child => {
  // React linters doesn't like it when a component is anonymous JS code (it uses the name for debugging and refering to component)
  const injectedQueryChild = props => (
    <QueryContext.Consumer>
      {({ query, redirect, updateQuery }) => <Child {...props} query={query} redirect={redirect} updateQuery={updateQuery} />}
    </QueryContext.Consumer>
  );
  return injectedQueryChild;
};