import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import EditIcon from '@material-ui/icons/Edit';
import { TableRow, TableCell } from '../Table/Table';
import { MenuItem, ListItemText, ListItemIcon } from '../Table/Actions';
import { DropdownMenu } from '../DropdownMenu';
import { DynamicFormField } from './DynamicFormField';
import { ValidationChain } from '../Form/Validation/ValidationChain';
import { confirmRemove } from '../util';
import { IntlFormHeaderIcon } from '../../intl-components/Form';


const TwoIconsContainer = styled('div')`
  width: 80px;
`;

const DragHandle = styled('div')``;
class DynamicFormTableRow extends React.Component {

  shouldComponentUpdate(nextProps) {
    return ['data', 'readOnly', 'errors', 'selected'].some(p => nextProps[p] !== this.props[p])
      || JSON.stringify(Object.keys(nextProps.spec)) !== JSON.stringify(Object.keys(this.props.spec));
  }

  render() {
    const { data, readOnly, selected, index, spec, validationChain, translationKey, enableRowSorting, onChange, onRemove, onSelect, onEdit, errors, intl } = this.props;
    return (
      <TableRow data-id={index}>
        {typeof onSelect === 'function' &&
          <TableCell key='dynamic-check-row'>
            <Checkbox
              checked={selected}
              onChange={(_ev, enabled) => onSelect(enabled)}
            />
          </TableCell>
        }
        {enableRowSorting &&
          <TableCell key='sort-row'>
            <DragHandle className='sort-item'>
              <DragHandleIcon />
            </DragHandle>
          </TableCell>}
        {Object.entries(spec)
          .map(([field, fieldType]) =>
            <TableCell key={`dynamic-${index}-${field}`}>
              <DynamicFormField
                readOnly={readOnly}
                validationChain={validationChain}
                translationKey={translationKey}
                tableMode={true}
                fieldType={fieldType}
                field={field}
                value={data[field]}
                contextValue={data}
                errors={errors && errors[field]}
                onChange={value => onChange(index, field, value)}
              />
            </TableCell>
          )}
        <TableCell key={`dynamic-${index}-actions`}>
          <TwoIconsContainer>
            {onEdit &&
              <IntlFormHeaderIcon
                icon={<EditIcon />}
                tooltip='app.edit'
                onClick={() => onEdit(data)}
              ></IntlFormHeaderIcon>
            }
            <DropdownMenu>
              {onRemove &&
                <MenuItem onClick={() => confirmRemove(intl, () => onRemove(data))}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="app.remove" />
                  </ListItemText>
                </MenuItem>
              }
              {this.props.extraRowActions && this.props.extraRowActions.map((action, i) => (
                <MenuItem key={i} onClick={() => action.action(data)}>
                  <ListItemIcon>
                    {action.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id={action.textKey} />
                  </ListItemText>
                </MenuItem>
              ))}
            </DropdownMenu>
          </TwoIconsContainer>
        </TableCell>
      </TableRow>
    );
  }

}

DynamicFormTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  spec: PropTypes.object.isRequired,
  validationChain: PropTypes.instanceOf(ValidationChain).isRequired,
  translationKey: PropTypes.string.isRequired,
  extraRowActions: PropTypes.array,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  errors: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  readOnly: PropTypes.bool,
  enableRowSorting: PropTypes.bool
};

const DynamicFormTableRowIntl = injectIntl(DynamicFormTableRow);

export { DynamicFormTableRowIntl as DynamicFormTableRow };
