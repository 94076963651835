import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { runningInstancesService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { COLORS } from '../../components/ColorCircle';

import { handleAPIError } from '../../util/forms';
import { BaseStatusMonitor } from '../BaseStatusMonitor';

class ModuleInstanceStatusMonitor extends BaseStatusMonitor {

  componentDidMount() {
    runningInstancesService.on('RunningInstanceStatusChange', this.onStatusWatch);
    this.fetchData();
  }

  componentWillUnmount() {
    runningInstancesService.removeListener('RunningInstanceStatusChange', this.onStatusWatch);
  }

  async fetchData() {
    const { instanceId, intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));

    runningInstancesService.find().then(data => {
      const instanceData = data.find(d => d.code === instanceId);
      if (instanceData != null) {
        this.setState({
          status: instanceData.status,
          statusTimestamp: instanceData.statusTimestamp
        });
      }
    })
      .catch(response => handleAPIError(response, 'models.moduleInstances', this.props.intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' }));
      });
  }

  onStatusWatch = (data) => {
    const { instanceId } = this.props;
    if (data.code === instanceId) {
      this.setState({
        status: data.status,
        statusTimestamp: data.statusTimestamp
      });
    }
  }

  getStatusCircleData = (status) => {
    const title = 'models.moduleInstances.status.' + status;
    switch (status) {
      case 'running':
        return {
          color: COLORS.GREEN,
          title
        };
      case 'warning':
        return {
          color: COLORS.RED,
          title
        };
      case 'stopping':
      case 'restarting':
        return {
          color: COLORS.YELLOW,
          title
        };
      case 'stopped':
        return {
          color: COLORS.BLACK,
          title
        };
      default:
        return {
          color: COLORS.GRAY,
          title: 'app.unknown'
        };
    }
  }

}

ModuleInstanceStatusMonitor.propTypes = {
  instanceId: PropTypes.string.isRequired
};

const IntlScriptStatus = injectIntl(ModuleInstanceStatusMonitor);
export { IntlScriptStatus as ModuleInstanceStatusMonitor };
