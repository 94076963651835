import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { scriptsService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { COLORS } from '../../components/ColorCircle';

import { handleAPIError } from '../../util/forms';
import { BaseStatusMonitor } from '../BaseStatusMonitor';


class ScriptStatusMonitor extends BaseStatusMonitor {

  componentDidMount() {
    scriptsService.on('ScriptStatusChange', this.onStatusWatch);
    this.fetchData();
  }

  componentWillUnmount() {
    scriptsService.removeListener('ScriptStatusChange', this.onStatusWatch);
  }

  async fetchData() {
    const { scriptId, intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));
    scriptsService.getStatuses()
      .then(statuses => {
        const status = statuses.find(s => s.scriptId === scriptId);
        if (status) {
          this.setState({
            status: status.status,
            statusTimestamp: status.statusTimestamp
          });
        }
      })
      .catch(response => handleAPIError(response, 'models.moduleInstances', this.props.intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' }));
      });
  }

  onStatusWatch = (data) => {
    const { scriptId } = this.props;
    if (data.scriptId === scriptId) {
      this.setState({
        status: data.newStatus,
        statusTimestamp: data.newStatusTimestamp
      });
    }
  }

  getStatusCircleData = (status) => {
    const title = 'models.scripts.status.' + status;
    switch (status) {
      case 'WORKING':
        return {
          color: COLORS.GREEN,
          title
        };
      case 'ERROR':
        return {
          color: COLORS.RED,
          title
        };
      case 'STOPPED':
        return {
          color: COLORS.BLACK,
          title
        };
      default:
        return {
          color: COLORS.GRAY,
          title: 'app.unknown'
        };
    }
  }
}

ScriptStatusMonitor.propTypes = {
  scriptId: PropTypes.string.isRequired
};

const IntlScriptStatus = injectIntl(ScriptStatusMonitor);
export { IntlScriptStatus as ScriptStatusMonitor };
