import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Edit as EditIcon, ImportExport as ImportExportIcon, FormatListNumberedRtl as FormatListNumberedRtlIcon } from '@material-ui/icons';

import ImportPLCVariables from './ImportPLCVariables';
import ExportPLCVariables from './ExportPLCVariables';
import ExportModbusResume from './ExportModbusResume';
import { DynamicFormTable } from '../../../components/Dynamics/DynamicFormTable';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import PLCIcon from '@material-ui/icons/Kitchen';
import { actionShape } from '../../../components/Table/DataTable';
import { DropdownMenu } from '../../../components/DropdownMenu';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

class VariableFormGroup extends Component {

  state = {
    importingPLCVariables: false,
    exportingPLCVariables: false,
    exportingModbusResume: false,
    editableModeVariables: false,
    editableModeVirtualVariables: false
  };

  render() {
    const props = this.props;
    const { editableModeVariables, editableModeVirtualVariables } = this.state;
    const { extraTableActions = [] } = props;
    const deviceVariables = props.variables;
    const virtualVariables = props.virtualVariables;

    var actions = [];

    const isModbusClient = ['modbusrtu', 'modbustcp'].includes(props.deviceType);
    const isModbusServer = ['export_modbus'].includes(props.deviceType);
    if (isModbusClient) {
      actions.push({
        icon: <PLCIcon />,
        textKey: 'models.variables.plcst.import.action',
        action: () => this.setState({ importingPLCVariables: true })
      });
    }
    if (isModbusClient || isModbusServer) {
      actions.push({
        icon: <PLCIcon />,
        textKey: 'models.variables.plcst.export.action',
        action: () => this.setState({ exportingPLCVariables: true })
      });
      actions.push({
        icon: <FormatListNumberedRtlIcon />,
        textKey: 'models.variables.exportModbusResume.action',
        action: () => this.setState({ exportingModbusResume: true })
      });
    }

    if (actions.length) {
      extraTableActions.push(
        (<DropdownMenu key='import-export-dropdown'
          icon={<ImportExportIcon />}>
          {actions.map((action, i) => (
            <MenuItem key={i} onClick={() => action.action()}>
              <ListItemIcon>
                {action.icon}
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id={action.textKey} />
              </ListItemText>
            </MenuItem>
          ))}
        </DropdownMenu>)
      );
    }

    return (
      <Fragment>
        {props.spec &&
          <DynamicFormTable
            enableMultipleSelection
            readOnly={!editableModeVariables}
            key={'variables-version-' + props.dataVersion}
            label='models.devices.form.sections.variables'
            translationKey='models.variables'
            onChange={variables => props.onChangeVariables(variables, get(variables, 'length') !== get(deviceVariables, 'length'))}
            onUncommitedChange={this.props.onUncommitedChange}
            entries={deviceVariables}
            defaultEntry={props.defaultVariable}
            spec={props.spec}
            validationChain={props.validationChain}
            onNewClick={props.onNewClick}
            errors={get(props.errors, 'variables')}
            extraTableActions={extraTableActions}
            tableActions={[{
              icon: <EditIcon />,
              textKey: editableModeVariables ? 'app.editableTable.disable' : 'app.editableTable.enable',
              action: () => this.setState({ editableModeVariables: !editableModeVariables })
            }]}
            extraRowActions={this.props.variablesExtraRowActions}
          />
        }
        {(props.virtualVariableSpec) && (props.onChangeVirtualVariables != null) &&
          <DynamicFormTable
            enableMultipleSelection
            readOnly={!editableModeVirtualVariables}
            key={'virtual-variables-version-' + props.dataVersion}
            label="models.devices.form.sections.virtualVariables"
            translationKey='models.variables'
            onChange={variables => props.onChangeVirtualVariables(variables)}
            entries={virtualVariables}
            defaultEntry={props.defaultVariable}
            spec={props.virtualVariableSpec}
            validationChain={props.validationChain}
            onNewClick={props.onNewClick}
            errors={get(props.errors, 'virtualVariables')}
            tableActions={[{
              icon: <EditIcon />,
              textKey: editableModeVirtualVariables ? 'app.editableTable.disable' : 'app.editableTable.enable',
              action: () => this.setState({ editableModeVirtualVariables: !editableModeVirtualVariables })
            }]}
          />
        }
        {this.state.importingPLCVariables &&
          <ImportPLCVariables
            key='import-plc-variables'
            onImport={(variables) => {
              this.setState({ importingPLCVariables: false });
              setImmediate(() => props.onChangeVariables([...deviceVariables].concat([...variables]), true));
            }}
            onCancel={() => this.setState({ importingPLCVariables: false })}
          />
        }
        {this.state.exportingPLCVariables &&
          <ExportPLCVariables
            key='export-plc-variables'
            variables={deviceVariables}
            onClose={() => this.setState({ exportingPLCVariables: false })}
          />
        }
        {this.state.exportingModbusResume &&
          <ExportModbusResume
            key='export-modbus-resume'
            variables={deviceVariables}
            onClose={() => this.setState({ exportingModbusResume: false })}
          />
        }
      </Fragment>
    );
  }
}


VariableFormGroup.propTypes = {
  dataVersion: PropTypes.number.isRequired,
  variables: PropTypes.array,
  virtualVariables: PropTypes.array,
  onChangeVariables: PropTypes.func,
  onUncommitedChange: PropTypes.func,
  onChangeVirtualVariables: PropTypes.func,
  validationChain: PropTypes.instanceOf(ValidationChain),
  spec: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  defaultVariable: PropTypes.object,
  virtualVariableSpec: PropTypes.object,
  onNewClick: PropTypes.func,
  extraTableActions: PropTypes.array,
  variablesExtraRowActions: PropTypes.arrayOf(actionShape),
  errors: PropTypes.object
};

VariableFormGroup.defaultProps = {
  variables: [],
  virtualVariables: []
};

export { VariableFormGroup };
