
const getModbusVariableBits = (modbusDataType, modbusRegType) => {
  if ((modbusRegType === 'discrete_input') || (modbusRegType === 'coil')) {
    return 1;
  }
  switch (modbusDataType) {
    case 'int8':
    case 'uint8':
      return 8;
    case 'int16':
    case 'uint16':
      return 16;
    case 'int32':
    case 'uint32':
    case 'float32':
      return 32;
    case 'int64':
    case 'uint64':
    case 'float64':
      return 64;
    case 'bool':
      return 1;
    default:
      console.log(`error: getModbusVariableBits(${modbusDataType}) = 0`);
      return 0;
  }
};

/* updates the list of used memory bits with given variable */
const addUsedAddressBits = (usedAddressBits, variable, bitsPerAddress) => {
  // otherVariables are the variables of the same modbusRegType having address, we have to avoid colisions
  const modbusDataType = variable.protocolDataType();
  const modbusBits = getModbusVariableBits(modbusDataType, variable.modbusRegType);

  const [addrStr, bitInAddrStr = '0'] = `${variable.modbusAddress}`.split('.');
  let addr = parseInt(addrStr);
  let bitInAddr = parseInt(bitInAddrStr);
  for (let i = 0; i < modbusBits; i++) {
    if (bitsPerAddress === 1) {
      usedAddressBits.add(`${addr}`);
    } else {
      usedAddressBits.add(`${addr}.${bitInAddr}`);
    }
    addr += Math.floor((bitInAddr + 1) / bitsPerAddress);
    bitInAddr = (bitInAddr + 1) % bitsPerAddress;
  }
};

/* gets the list of used memory for given variables */
const getUsedAddressBits = (variables, bitsPerAddress) => {
  const usedAddressBits = new Set(); // we generate now the list of all bits already used by other variables
  variables.forEach(variable => {
    addUsedAddressBits(usedAddressBits, variable, bitsPerAddress);
  });
  return usedAddressBits;
};

const nextAlignAddress = (address, bits) => {
  address++;
  if (bits > 16) {
    while (address % (bits / 16) !== 0) {
      address++;
    }
  }
  return address;
};

/* returns the first memory address with enough space to hold the variable */
const getFirstFreeAddress = (variable, modbusBits, otherVariables, bitsPerAddress, minAddress, usedAddressBits) => {
  if (bitsPerAddress === 1) {
    // coil, discrete_input
    // 1 bit variables, 1 bit per address
    let startAddress = minAddress;
    const usedAddresses = otherVariables.map(variable => `${variable.modbusAddress}`.split('.')[0]);
    while (usedAddresses.includes(`${startAddress}`)) startAddress++;
    return `${startAddress}`;
  } else {
    // 3 cases:
    // - 1 bit of the 16bit address (address is <addr>.<addrInBit>)
    // - 8 bit of the 16 bit address (address is <addr>.<addrInBit>, with addrInBit in [0, 8])
    // - 1 to n full addresses (address is <addr>)
    let startAddress = nextAlignAddress(minAddress - 1, modbusBits);
    let bitInAddress = 0;

    // Now we try to find a free space to hold the variable
    let contiguousFreeBits = 0;
    let currentContiguosBlockAddress = '-1';
    while (contiguousFreeBits < modbusBits) {
      const fullAddress = `${startAddress}.${bitInAddress}`;
      if (usedAddressBits.has(fullAddress)) {
        contiguousFreeBits = 0;
        currentContiguosBlockAddress = '';
      } else {
        if (contiguousFreeBits > 0) {
          contiguousFreeBits++;
        } else if ((bitInAddress % modbusBits) === 0) {
          contiguousFreeBits = 1;
          currentContiguosBlockAddress = fullAddress;
        }
      }
      if (modbusBits > 16) {
        // 2 or more modbus addresses
        startAddress = nextAlignAddress(startAddress, modbusBits);
        bitInAddress = 0;
      } else {
        startAddress += Math.floor((bitInAddress + 1) / bitsPerAddress);
        bitInAddress = (bitInAddress + 1) % bitsPerAddress;
      }
    }
    if (modbusBits >= 16) {
      currentContiguosBlockAddress = currentContiguosBlockAddress.split('.')[0];
    }
    return currentContiguosBlockAddress;
  }
};

export { getFirstFreeAddress, getUsedAddressBits, getModbusVariableBits, addUsedAddressBits };
