import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormControl, FormHelperText, InputLabel, Paper, Select as MUISelect, TextField as MUITextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Warning';
import { Row } from '../GridModules/Row';
import { FormattedMessage } from 'react-intl';

const SmallInfoIcon = styled(InfoIcon)`
  && {
    font-size: 20px;
    cursor: pointer;
  }
`;

const SmallErrorIcon = styled(ErrorIcon)`
  && {
    color: #E44;
    font-size: 20px;
    cursor: pointer;
  }
`;

const StyledSelect = styled(MUISelect)`
  && {
    min-width: 100px;
    max-width: 300px;
  }
`;

const BottomMarginTextField = styled(MUITextField)`
  && {
    margin-bottom: 27px;
  }
`;

let selectIdCounter = 0;
const Select = ({ children, label, value, onChange, helperText, tips, error, margin, disabled }) => {
  const id = label && `select-label-ph-${label}-${selectIdCounter++}`;
  const inputProps = label ? {
    name: label,
    id,
  } : undefined;
  return (
    <FormControl margin={margin} error={error} disabled={disabled}>
      {label &&
        <InputLabel htmlFor={id}>{label}</InputLabel>
      }
      <StyledSelect
        value={value != null ? value : ''}
        onChange={onChange}
        inputProps={inputProps}
      >
        {children}
      </StyledSelect>
      {tips && tips.length &&
        getInfoAdornment(tips)
      }
      <FormHelperText>{helperText || ''}</FormHelperText>
    </FormControl>
  );
};

const TextField = (props) => {
  const { InputProps = {}, InputLabelProps = {}, tips, errors, helperText, ...others } = props;
  const adornments = [];
  if (Array.isArray(errors) && errors.length) {
    adornments.push(getInfoAdornment(errors, SmallErrorIcon));
  }
  if (tips) {
    adornments.push(getInfoAdornment(tips, SmallInfoIcon));
  }
  if (InputProps.endAdornment) {
    if (Array.isArray(InputProps.endAdornment)) {
      adornments.push(...InputProps.endAdornment);
    } else {
      adornments.push(InputProps.endAdornment);
    }
  }
  if (props.placeholder !== undefined) {
    InputLabelProps.shrink = true;
  }
  const Component = helperText ? MUITextField : BottomMarginTextField;
  return (
    <Component
      {...others}
      InputLabelProps={InputLabelProps} // Disable label overlap with text input
      helperText={helperText}
      InputProps={{ ...InputProps, endAdornment: adornments }}
    />
  );
};

const LabeledText = (props) => {
  const { value, item, id } = props;
  return (
    <Row MultiColumns>
      <FormFieldLabel>
        <FormattedMessage id={id} />:
      </FormFieldLabel>
      {value &&
        <SimpleFormField>{value}</SimpleFormField>
      }
      {item}
    </Row >
  );
};

// We need a key because adornment can be in an array... but... why use a always changing key?
// We don't have any other proper key here, text could be the same and is a log string (CPU intensive comparing)
let adornmentKey = 0;
const getInfoAdornment = (text, Icon = SmallInfoIcon) => {
  if (Array.isArray(text)) {
    text = text.map(t => '· ' + t + '.').join('\n');
  }
  return (
    <InputAdornment key={adornmentKey++} position='end'>
      <Tooltip title={text}>
        <Icon />
      </Tooltip>
    </InputAdornment>
  );
};

Select.propTypes = {
  // children: MUISelect.propTypes.children,
  label: PropTypes.string,
  // value: MUISelect.propTypes.value,
  // onChange: MUISelect.propTypes.onChange,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  // error: MUISelect.propTypes.error,
  // margin: FormControl.propTypes.margin
};

const FormFieldLabel = styled.span`
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
`;

const SimpleFormField = styled.span`
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
`;

const AlertPaperStype = styled(Paper)`
  >span[role=img] {
    margin-left: -22px;
    position: fixed;
  }
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px 8px 26px;
  background-color: #ffffe8 !important;
  border-left: 5px solid #ffd500;
`;

const AlertParagraph = ({ children }) =>
  <AlertPaperStype><span role="img" aria-label="alert">⚠️</span>{children}</AlertPaperStype>;

export { Select, LabeledText, TextField, MUISelect as BasicSelect, FormFieldLabel, SimpleFormField, AlertParagraph };