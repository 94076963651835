import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { Row } from '../../../components/GridModules/Row';
import { IntlMonospacedTextField, IntlSelect } from '../../../intl-components/Form';
import jsonexport from 'jsonexport';
import EasyTable from 'easy-table';

const regTypesOrder = {
  'coil': 1,
  'discrete_input': 2,
  'holding_register': 3,
  'input_register': 4
};

const dataTypeSize = {
  'bool': 1,
  'uint8': 8,
  'int8': 8,
  'int16': 16,
  'uint16': 16,
  'int32': 32,
  'uint32': 32,
  'int64': 64,
  'uint64': 64,
  'float32': 32,
  'float64': 64
};

const getVariableOrder = (variable) => {
  const regTypeOrder = regTypesOrder[variable.modbusRegType] || 0;
  const modbusAddress = variable.modbusAddress || '0.0';
  const addressOrder1 = parseInt(modbusAddress.split('.')[0]);
  const addressOrder2 = parseInt(modbusAddress.split('.')[1] || '0');
  return [regTypeOrder, addressOrder1, addressOrder2];
};

const getModbusResume = (variables) => {
  return [...variables].sort((v1, v2) => {
    const v1Order = getVariableOrder(v1);
    const v2Order = getVariableOrder(v2);
    for (let i = 0; i < 3; i++) {
      if (v1Order[i] < v2Order[i]) {
        return -1;
      } else if (v1Order[i] > v2Order[i]) {
        return 1;
      }
    }
    return 0;
  }).map(variable => {
    const { modbusRegType, code, modbusAddress, modbusValueMask } = variable;
    const protocolDataType = variable.protocolDataType();
    const bits = dataTypeSize[protocolDataType];
    return {
      modbusRegType,
      code: code || variable.variable,
      modbusAddress,
      bits,
      dataType: protocolDataType,
      modbusValueMask
    };
  });
};

class ExportModbusResume extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: 'text',
      value: {
        text: '',
        json: '',
        csv: ''
      }
    };
  }

  componentDidMount() {
    const resume = getModbusResume(this.props.variables);
    jsonexport(resume, { textDelimiter: '"' })
      .then(csv => this.setState({ value: { ...this.state.value, csv } }))
      .catch(err => console.log(err));

    var textTable = new EasyTable();

    resume.forEach(function (v) {
      textTable.cell('Register', v.modbusRegType);
      textTable.cell('Code', v.code);
      textTable.cell('Address', v.modbusAddress);
      textTable.cell('Bits', v.bits);
      textTable.cell('DataType', v.dataType);
      textTable.cell('Mask', v.modbusValueMask);
      textTable.newRow();
    });

    this.setState({
      value: {
        text: textTable.toString(),
        json: JSON.stringify(resume, null, 2)
      }
    });
  }

  render() {
    const { mode } = this.state;

    return (
      <Dialog
        aria-labelledby="export-modbus-resume-form-title"
        aria-describedby="export-modbus-resume-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="export-modbus-resume-form-title">
          <FormattedMessage id="models.variables.exportModbusResume.title" />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row>
              <IntlSelect
                value={mode}
                onChange={ev => this.setState({ ...this.state, mode: ev.target.value })}
                label="models.variables.exportModbusResume.form.mode"
                margin="normal">
                {['text', 'json', 'csv'].map(d =>
                  <MenuItem key={`mode-${d}`} value={d}>{d}</MenuItem>
                )}
              </IntlSelect>
            </Row>
            <Row>
              <IntlMonospacedTextField
                label="models.variables.exportModbusResume.form.output"
                multiline
                rows="20"
                margin="normal"
                value={this.state.value[mode]}
                onChange={ev => this.setState({ ...this.state, text: ev.target.value })}
              />
            </Row>
          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onClose}>
            <FormattedMessage id="app.close" />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ExportModbusResume.propTypes = {
  onClose: PropTypes.func
};

export default ExportModbusResume;