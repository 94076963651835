import { scriptContext } from './scripts-context';
// import jsHint from 'codemirror/addon/hint/javascript-hint';

const getPath = (editor, cursor) => {
  let line = cursor.line - 1;
  let result = getLineEfectiveTokens(editor, cursor.line).filter(t => t.start < cursor.ch);
  let pathFirstItem = findPathFirstItem(result);

  while (line >= 0 && pathFirstItem < 0) {
    result = [...getLineEfectiveTokens(editor, line), ...result];
    pathFirstItem = findPathFirstItem(result);
    line--;
  }

  if (pathFirstItem >= 0) {
    result = result.slice(pathFirstItem);
  }
  return result.filter(t => t.string !== '.');
};

const findPathFirstItem = tokenList => {
  const last = tokenList[tokenList.length - 1];
  if (tokenList.length > 0 && last.string === '.') {
    tokenList.push({ start: last.end, end: last.end, string: '', type: 'variable' });
  }
  for (let i = tokenList.length - 1; i >= 0; i -= 2) {
    const type = tokenList[i].type;
    if (type !== 'variable' && type !== 'property') {
      return i + 1;
    }
    if (i === 0) {
      return -1;
    }
    const prev = tokenList[i - 1];
    if (prev.string !== '.') {
      return i;
    }
  }
  return -1;
};

const getType = (path, type = scriptContext) => {
  if (path.length <= 1) return type;
  const token = path.shift().string;
  const typeString = type.objects[token];
  if (typeString) {
    if (scriptContext.types[typeString]) {
      return getType(path, scriptContext.types[typeString]);
    } else {
      return type;
    }
  }
  return type.methods[token];
};

const getSuggestions = (token = { string: '' }, type = scriptContext) => {
  let result = { objects: [], methods: [] };
  if (type.objects) {
    result.objects = Object.keys(type.objects)
      .filter(o => o.startsWith(token.string))
      .map(o => ({ name: o, type: type.objects[o] }));
  }
  if (type.methods) {
    result.methods = type.methods.filter(m => m.name.startsWith(token.string));
  }
  return result;
};

const getLineEfectiveTokens = (editor, line) => editor.getLineTokens(line).filter(t => t.type !== 'comment');

const mapObject = o => ({
  text: o.name,
  // hint: (...params) => console.log(...params)
});
const mapMethod = m => m.name;

export const adquioJsHint = async editor => {
  const cursor = editor.getCursor();
  const tokenPath = getPath(editor, cursor);
  const lastToken = tokenPath[tokenPath.length - 1];
  const prevType = getType(tokenPath);
  const suggestions = getSuggestions(lastToken, prevType);
  // TODO: add variables like this ->
  // Note that variables in the root scope won't be detected (why?)
  // const token = editor.getTokenAt(cursor);
  // for (var v = token.state.localVars; v; v = v.next) maybeAdd(v.name);
  // for (var v = token.state.globalVars; v; v = v.next) maybeAdd(v.name);
  const list = [
    ...suggestions.objects.map(mapObject),
    ...suggestions.methods.map(mapMethod)
  ];
  return {
    list,
    from: lastToken
      ? { line: cursor.line, ch: lastToken.start }
      : cursor,
    to: lastToken
      ? { line: cursor.line, ch: lastToken.end }
      : cursor
  };
};