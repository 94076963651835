import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import { BlockHeader, BlockTitle, BlockBody, BlockActions } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import { TableBlock } from '../../../components/GridModules/ComponentBlock/TableBlock';
import { schedulesService, scriptsService } from '../../../lib/service';
import DataTable from '../../../components/Table/DataTable';
import CommonFormOptions from '../../components/CommonFormOptions';
import { scheduleTimeToString } from '../SchedulerUiUtils';
import ScheduleForm from './ScheduleFormDialog';
import { Row } from '../../../components/GridModules/Row';
import ScriptListDialog from '../../Scripts/ScriptListDialog';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { handleAPIError } from '../../../util/forms';

const defaultSchedule = {
  active: true
};

class SchedulesTableBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      schedules: [],
      loading: true,
      manageScripts: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    schedulesService.find().then((d) => this.fetchDataSuccess(d));
  }

  fetchDataSuccess(data) {
    let manageScripts = null;
    if (this.state.manageScripts != null) {
      manageScripts = data.data.find(i => i.timerName === this.state.manageScripts.timerName);
    }
    this.setState({ schedules: data.data, loading: false, manageScripts, editingSchedule: undefined });
  }

  saveSchedule() {
    this.fetchData();
  }

  removeSchedule(schedule) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    schedulesService.remove(schedule.id)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.intervals', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  removeScript(scriptId) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    scriptsService.remove(scriptId)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.scripts', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  mapRowToTable({ active, time, timerName, calendarName, fireOnReboot, duration }) {
    return {
      active, timerName, calendarName, duration,
      fireOnReboot: fireOnReboot !== null ? this.props.intl.formatMessage({ id: `models.schedules.form.fireOnReboot.${fireOnReboot}` }) : '',
      time: (typeof time === 'string' ? time : scheduleTimeToString(time))
    };
  }

  render() {
    const columns = [
      { data: 'active', sortable: true },
      { data: 'timerName', sortable: true },
      { data: 'calendarName', sortable: true },
      { data: 'time' },
      { data: 'fireOnReboot', sortable: true },
      { data: 'duration', sortable: true },
      { data: (row) => (row.scripts || []).length, columnKey: 'scripts', sortable: false },
      {
        data: row => (
          <CommonFormOptions
            onEdit={() => this.setState({ editingSchedule: row })}
            onRemove={() => this.removeSchedule(row)}
            extraOptions={[{
              icon: (<CodeIcon />),
              textKey: 'models.scripts.manage',
              action: () => this.setState({ manageScripts: row })
            }]}
          />)
      }];
    return (
      <Fragment>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="section.schedules" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={this.props.intl.formatMessage({ id: 'models.schedules.new' })} >
                  <IconButton onClick={() => this.setState({ editingSchedule: defaultSchedule })} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <DataTable
                translationKey="models.schedules"
                columns={columns}
                data={this.state.schedules}
                rowMapFunction={this.mapRowToTable.bind(this)}
                loading={this.state.loading}
              />
            </BlockBody>
          </TableBlock>
          {this.state.editingSchedule &&
            <ScheduleForm
              schedule={this.state.editingSchedule}
              onSave={data => this.saveSchedule(data)}
              onCancel={() => this.setState({ editingSchedule: null })}
            />
          }
          {this.state.manageScripts &&
            <ScriptListDialog
              titleKey='models.schedules.manageScripts'
              titleValues={{ name: this.state.manageScripts.timerName }}
              scripts={this.state.manageScripts.scripts || []}
              removeScript={id => this.removeScript(id)}
              onCancel={() => this.setState({ manageScripts: null })}
              newLink={'/scripts/new/event/Timer/Schedule/' + this.state.manageScripts.timerName}
            />
          }
        </Row>
      </Fragment >
    );
  }

}

SchedulesTableBase.propTypes = {};

const SchedulesTable = injectIntl(SchedulesTableBase);

export default SchedulesTable;