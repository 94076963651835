import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';

import { Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { EditorContext } from '../ScriptEditor';

const MethodAndObjectType = styled('span')`
  color: ${props => props.theme.LinkColor};
  cursor: pointer;
`;

export const TypeLabel = injectIntl(({ type, template, intl }) => {
  const descTranslationKey = 'models.scripts.scriptsContext.types.' + type + '.desc';
  const desc = intl.messages[descTranslationKey] ? intl.formatMessage({ id: descTranslationKey }) : undefined;

  const label = (
    <EditorContext.Consumer>
      {({ editorId }) => (
        <MethodAndObjectType
          onClick={() => eventBus.publish(eventBusTopics.SCRIPTS_HELP_SHOW_TYPE, { showType: type, editorId })}
        >
          {template.replace('&', type)}
        </MethodAndObjectType>
      )}
    </EditorContext.Consumer>
  );
  if (desc) {
    return (
      <Tooltip
        title={desc}
      >
        {label}
      </Tooltip>
    );
  } else {
    return label;
  }
});

TypeLabel.propTypes = {
  type: PropTypes.string.isRequired,
  template: PropTypes.string,
};

TypeLabel.defaultProps = {
  template: '&'
};