import styled from 'styled-components';
import { clearFix } from 'polished';

export const AppPageContent = styled.div`
  ${clearFix()};
  position: relative;
  width: 100%;
  height: calc(100vh - ${(props) => props.theme.AppHeaderHeight});
  overflow: auto;
  top: ${props => props.theme.AppHeaderHeight};
  padding-top: ${props => props.theme.MarginSize};
  padding-left: ${props => props.theme.DoubleMarginSize};
  padding-right: ${props => props.theme.DoubleMarginSize};
  &::after {
    content: '';
    position: relative;
    float: left;
    width: 100%;
    height: 168px; /* Fix Firefox overlapping */
  }
`;
