import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Pause';
import StartIcon from '@material-ui/icons/ArrowRight';
import RestartIcon from '@material-ui/icons/Loop';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import { injectQueryString } from '../../components/queryUtils';
import { moduleInstancesService, runningInstancesService } from '../../lib/service';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import { BlockHeader, BlockTitle, BlockBody, BlockActions } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlDataTable } from '../../intl-components/IntlDataTable';
import { RowStatusValues } from '../../components/Table/DataTable';
import CommonFormOptions from '../components/CommonFormOptions';
import eventBus, { eventBusTopics } from '../../lib/eventBus';

import { FAIconButton, ICONS } from '../../components/Table/FAIconButton';
import { handleAPIError } from '../../util/forms';

class ModuleInstancesView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      moduleInstances: [],
      runningInstances: {},
      loading: true
    };
  }

  componentDidMount() {
    this.fetchAll();
    this.statusChangeHandler = data => this.onInstanceStatusChange(data);
    runningInstancesService.on('RunningInstanceStatusChange', this.statusChangeHandler);
  }

  componentWillUnmount() {
    runningInstancesService.removeListener('RunningInstanceStatusChange', this.statusChangeHandler);
  }

  fetchAll() {
    this.fetchInstances();
    this.fetchRunning();
  }

  fetchInstances() {
    moduleInstancesService.find().then(data => this.fetchInstancesSuccess(data))
      .catch(response => handleAPIError(response, 'models.moduleInstances', this.props.intl));
  }

  fetchRunning() {
    runningInstancesService.find().then(data => this.fetchRunningSuccess(data))
      .catch(response => handleAPIError(response, 'models.moduleInstances', this.props.intl));
  }

  fetchInstancesSuccess(data) {
    this.setState({ moduleInstances: data.instances, loading: false });
  }

  onInstanceStatusChange(data) {
    let runningInstances = { ...this.state.runningInstances };
    runningInstances[data.code] = data;
    this.setState({ runningInstances });
  }

  fetchRunningSuccess(data) {
    const running = data.reduce((result, item) => {
      result[item.code] = item;
      return result;
    }, {});
    this.setState({ runningInstances: running });
  }

  removeInstance(code) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    moduleInstancesService.remove(code)
      .then(() => this.fetchInstances())
      .catch(response => handleAPIError(response, 'models.moduleInstances', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  controlInstance(code, operation) {
    let promise;
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.applying' }));
    switch (operation) {
      case 'start':
        promise = runningInstancesService.start(code);
        break;
      case 'stop':
        promise = runningInstancesService.stop(code);
        break;
      case 'restart':
        promise = runningInstancesService.restart(code);
        break;
      default:
    }
    if (promise != null) {
      promise
        .catch(response => handleAPIError(response, 'models.moduleInstances', this.props.intl))
        .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.applying' })));
    }
  }

  renderRowOptions(row) {
    return <CommonFormOptions
      onRemove={() => this.removeInstance(row.code)}
      onEdit={() => this.props.history.push(`/devices/module/${row.code}`)}
      extraOptions={[{
        icon: (<StartIcon />),
        textKey: 'models.moduleInstances.control.start',
        action: () => this.controlInstance(row.code, 'start')
      },
      {
        icon: (<StopIcon />),
        textKey: 'models.moduleInstances.control.stop',
        action: () => this.controlInstance(row.code, 'stop')
      },
      {
        icon: (<RestartIcon />),
        textKey: 'models.moduleInstances.control.restart',
        action: () => this.controlInstance(row.code, 'restart')
      }]}
    />;
  }

  rowStatus(row) {
    let instanceStatus = this.state.runningInstances[row.code] || { status: 'stopped' };
    let status = RowStatusValues.ERROR, title = '', relativeDate;
    if (instanceStatus !== undefined) {
      title = `models.moduleInstances.status.${instanceStatus.status}`;
      switch (instanceStatus.status) {
        case 'running':
          status = RowStatusValues.OK;
          break;
        case 'warning':
        case 'stopping':
        case 'restarting':
          status = RowStatusValues.WARNING;
          break;
        case 'stopped':
          status = RowStatusValues.OFF;
          break;
        default:
          title = '';
      }
      relativeDate = instanceStatus.statusTimestamp && new Date();
    }
    return {
      status,
      title,
      relativeDate
    };
  }

  rowMapFunction({ autoStart, ...other }) {
    return {
      ...other,
      autoStart: !!autoStart
    };
  }

  renderDevicesInfo(row) {
    return (
      <Fragment>
        <label>{row.devicesCount}</label>
        <FAIconButton
          icon={ICONS.faExternalLinkAlt}
          onClick={() => this.props.redirect('devices', { instance: row.code })}
        />
      </Fragment>
    );
  }

  render() {
    const intl = this.props.intl;
    const columns = [
      { data: 'autoStart' },
      { data: 'code' },
      { data: 'name' },
      { data: 'type' },
      { data: 'port' },
      {
        columnKey: 'devicesCount',
        data: this.renderDevicesInfo.bind(this)
      },
      { data: this.renderRowOptions.bind(this) }];
    return (
      <Fragment>
        <Row key="module-instances-table">
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="section.moduleInstances" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={intl.formatMessage({ id: 'models.moduleInstances.new' })} >
                  <Link to="/devices/module/">
                    <IconButton >
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <IntlDataTable
                onChange={() => this.setState({ hasChanges: true })}
                translationKey="models.moduleInstances"
                columnTranslationKeys={[null, null, null, 'models.devices.types']}
                columns={columns}
                rowStatus={this.rowStatus.bind(this)}
                sortableColumns={true}
                sort={{ code: 'desc' }}
                data={this.state.moduleInstances}
                loading={this.state.loading}
                rowMapFunction={this.rowMapFunction}
              />
            </BlockBody>
          </TableBlock>
        </Row >
      </Fragment>);
  }
}

const ModuleInstancesViewIntl = withRouter(injectQueryString(injectIntl(ModuleInstancesView)));
export default ModuleInstancesViewIntl;
