import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { validators } from './validation';

export class ValidationChain {
  constructor() {
    this.components = [];
    this.watchers = [];
  }

  getComponentErrors(component) {
    const asFunctions = component.props.validators
      .map(validator => typeof validator === 'string' ? validators[validator] : validator);
    return asFunctions.map(validator => validator(component.getValue())).filter(r => r);
  }

  getErrors() {
    let errors = {};
    this.components
      .filter(c => c.props.validators)
      .forEach(component => {
        const componentErrors = this.getComponentErrors(component);
        if (componentErrors.length) {
          errors[component.props.field] = componentErrors;
        }
      });
    return errors;
  }

  getAndDisplayErrors(translationsKey) {
    const errors = this.getErrors();
    if (Object.keys(errors).length !== 0) {
      eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { translationsKey, alertId: 'form', errors });
    }
    return errors;
  }

  registerComponent(component) {
    this.components.push(component);
  }

  unregisterComponent(component) {
    this.components = this.components.filter(c => c === component);
  }

}
