import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { pick } from 'lodash';

import AddIcon from '@material-ui/icons/Add';
import Checkbox from '@material-ui/core/Checkbox';

import { ListItemIcon, ListItemText, LinearProgress } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { BlockActions, BlockBody, BlockHeader, BlockTitle } from '../GridModules/ComponentBlock/ComponentBlock';
import { TableBlock } from '../GridModules/ComponentBlock/TableBlock';
import { Table, TableCell, TableHead, TableRow, TableActionsWrapper } from '../Table/Table';
import { IntlFormHeaderIcon } from '../../intl-components/Form';
import { ValidationChain } from '../Form/Validation/ValidationChain';
import CommonFormOptions from '../../views/components/CommonFormOptions';
import DynamicFormDialog from './DynamicFormDialog';
import { DropdownMenu } from '../DropdownMenu';
import { MenuItem } from '../Table/Actions';
import { CustomizeTableDialog } from '../Table/CustomizeTableDialog';
import SelectFilterColumnHeader from '../Table/DataTableColumnHeader/SelectFilterColumnHeader';
import TextFilterColumnHeader from '../Table/DataTableColumnHeader/TextFilterColumnHeader';
import { fitsQuery } from '../../lib/apiHelpers';
import { actionShape } from '../Table/DataTable';
import { DynamicFormTableBody } from './DynamicFormTableBody';

const TEXT_FILTER_PATTERN = '&*';

class DynamicFormTableBase extends React.Component {

  constructor(props) {
    super(props);
    // Simple and fast checksum, so differente "types" for the same table will no collide, if this causes problems we should start using MD5 or better
    this.tableKey = `dft_${props.translationKey}_${Object.keys(props.spec).reduce((s, k) => s + k.length, 0)}_${Object.keys(props.spec).length}`;
    this.state = {
      selection: new Set(),
      showCustomizeModal: false,
      columnSetup: JSON.parse(localStorage.getItem(this.tableKey)),
      filters: {},
      editionEntry: undefined,
      editionEntryIndex: undefined,
      filteredEntries: props.entries.map((_e, i) => i),
      loading: true
    };
  }

  removeEntry(entry) {
    const { entries, onChange } = this.props;
    const { filteredEntries } = this.state;
    const removeIndex = entries.indexOf(entry);
    const newSelection = Array.from(this.state.selection)
      .filter(i => i !== removeIndex)
      .map(i => i < removeIndex ? i : i - 1);
    this.setState({
      selection: new Set(newSelection),
      filteredEntries: filteredEntries.filter(e => e !== entry)
    });
    onChange(entries.filter(e => e !== entry));
  }

  updateEntryField(entryIndex, field, value) {
    const newEntries = [...this.props.entries];
    newEntries[entryIndex] = { ...newEntries[entryIndex], [field]: value };
    if (this.props.onUncommitedChange != null) {
      this.props.onUncommitedChange(newEntries[entryIndex]);
    }
    this.props.onChange(newEntries);
  }

  onCheckAll(filteredEntries, newState) {
    const selection = new Set(this.state.selection);
    filteredEntries.forEach(i => {
      if (newState) {
        selection.add(i);
      } else {
        selection.delete(i);
      }
    });
    this.setState({ selection });
  }

  onCheckOne(index, selected) {
    const selection = new Set(this.state.selection);
    if (selected) {
      selection.add(index);
    } else {
      selection.delete(index);
    }
    this.setState({ selection });
  }

  onRemoveSelected() {
    const { onChange, entries } = this.props;
    const { filteredEntries, selection } = this.state;
    const visibleSelection = filteredEntries.filter(i => selection.has(i));
    onChange(entries.filter((e, i) => !visibleSelection.includes(i)));
    this.setState({ filteredEntries: filteredEntries.filter(i => !selection.has(i)) });
  }

  onEditSelected(visibleEntries) {
    const selected = visibleEntries
      .filter((e, i) => this.state.selection.has(i))
      .map(i => this.props.entries[i]);
    const reduced = selected.reduce((merge, item) => {
      Object.keys(item).forEach(key => {
        if (merge[key] === undefined) {
          merge[key] = item[key];
        } else if (merge[key] !== item[key]) {
          merge[key] = null;
        }
      });
      return merge;
    }, {});
    Object.keys(reduced).forEach(k => {
      if (reduced[k] === null) {
        delete reduced[k];
      }
    });
    this.setState({ editionEntry: reduced, editionEntryIndex: undefined });
  }

  onDialogSave(data, filteredEntries) {
    const newEntries = Array.from(this.props.entries);
    if (this.state.editionEntryIndex !== undefined) {
      newEntries[this.state.editionEntryIndex] = data;
    } else {
      const visibleSelection = filteredEntries.filter(i => this.state.selection.has(i));
      Object.keys(data).forEach(k =>
        [...visibleSelection].forEach(i => {
          newEntries[i] = { ...newEntries[i], [k]: data[k] };
        })
      );
    }
    this.props.onChange(newEntries);
    this.setState({ editionEntry: undefined, editionEntryIndex: undefined }, () => this.onFiltersChange());
  }

  onNewClick() {
    if (this.props.onNewClick) {
      this.props.onNewClick();
      return;
    }

    const newData = { ...this.props.defaultEntry };
    if (this.props.enableEdit) {
      this.setState({
        editionEntry: newData,
        editionEntryIndex: this.props.entries.length
      });
    } else {
      const newEntries = Array.from(this.props.entries);
      newEntries.push(newData);
      this.props.onChange(newEntries);
      // Next line seems to do nothing but it is important to execute onFilterChange after the state changed made on this.props.onChange call.
      this.setState({ editionEntry: undefined, editionEntryIndex: undefined }, () => this.onFiltersChange());
    }
  }

  handleNewColumns(columns) {
    localStorage.setItem(this.tableKey, JSON.stringify(columns));
    this.setState({ columnSetup: columns, showCustomizeModal: false });
  }

  renderTableActions() {
    const columns = Object.keys(this.props.spec).map(k => ({ data: k }));
    const selectedColumns = this.state.columnSetup || Object.values(this.props.spec)
      .map((s, i) => !s.hideInTable && i).filter(i => i !== false);
    return (
      <TableActionsWrapper>
        <DropdownMenu>
          <MenuItem onClick={() => this.setState({ showCustomizeModal: true })}>
            <ListItemIcon>
              <ViewColumnIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id='app.customizeColumns.title' />
            </ListItemText>
          </MenuItem>
          {this.props.tableActions && this.props.tableActions.map((action, i) => (
            <MenuItem key={i} onClick={() => action.action()}>
              <ListItemIcon>
                {action.icon}
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id={action.textKey} />
              </ListItemText>
            </MenuItem>
          ))}
        </DropdownMenu>
        {
          this.state.showCustomizeModal &&
          <CustomizeTableDialog
            columns={columns}
            currentSetup={selectedColumns}
            translationKey={this.props.translationKey}
            onSave={columns => this.handleNewColumns(columns)}
            onCancel={() => this.setState({ showCustomizeModal: false })}
          />
        }
      </TableActionsWrapper>
    );
  }

  onFiltersChange(column, v) {
    const filters = { ...this.state.filters, [column]: v };
    const filterFn = fitsQuery(filters);
    const filteredEntries = this.props.entries.map((e, i) => filterFn(e) && i).filter(i => i !== false);
    this.setState({ filters, filteredEntries });
  }

  renderColumnHeader(column) {
    const { intl, translationKey, enableColumnFilters } = this.props;
    const spec = this.props.spec[column];
    const filter = this.state.filters[column];
    let content = <FormattedMessage id={`${this.props.translationKey}.${column}`} />;
    if (enableColumnFilters) {
      if (spec.type === 'string') {
        content = (
          <TextFilterColumnHeader
            key='text_header'
            enableFilter
            filterPattern={TEXT_FILTER_PATTERN}
            filterValue={filter}
            onFilterChange={v => this.onFiltersChange(column, v)}
          >{content}</TextFilterColumnHeader>
        );
      } else if (spec.type === 'select') {
        const mapFn = translationKey && (value => {
          const translationId = `${translationKey}.form.${column}.${value}`;
          return intl.messages[translationId]
            ? intl.formatMessage({ id: translationId })
            : value;
        });
        let filterOptions;
        if (Array.isArray(spec.values)) {
          filterOptions = spec.values.map(v => typeof v === 'object' ? v.value : v);
        }
        content = (
          <SelectFilterColumnHeader
            key='select_header'
            filterOptions={filterOptions}
            cellMapFunction={mapFn}
            filterValue={filter}
            onFilterChange={v => this.onFiltersChange(column, v)}
          >{content}</SelectFilterColumnHeader>
        );
      }
    }
    return (
      <TableCell key={column}>
        {content}
      </TableCell>
    );
  }

  render() {
    const { label, spec, translationKey, extraTableActions, enableEdit, enableMultipleSelection, enableRowSorting, readOnly, entries, validationChain, errors } = this.props;
    const { selection, editionEntry, editionEntryIndex, columnSetup, filteredEntries, loading } = this.state;
    const extraRowActions = this.props.extraRowActions || [];
    const columns = columnSetup
      ? columnSetup.map(i => Object.keys(spec)[i])
      : Object.entries(spec).filter(([_, s]) => !s.hideInTable).map(([k, _]) => k);

    const allRowsChecked = !!filteredEntries.length && filteredEntries.every(i => selection.has(i));
    return [
      <TableBlock key='dynamic-form-table'>
        <BlockHeader>
          {label &&
            <BlockTitle>
              <FormattedMessage id={label} />
            </BlockTitle>
          }
          <BlockActions>
            {extraTableActions}
            <IntlFormHeaderIcon
              icon={<AddIcon />}
              tooltip={`${translationKey}.new`}
              onClick={() => this.onNewClick()}
            />
            {this.renderTableActions()}
          </BlockActions>
        </BlockHeader>
        <BlockBody>
          {!!loading && <LinearProgress />}
          <Table key="table">
            <TableHead>
              <TableRow>
                {enableRowSorting &&
                  <TableCell key='sort-row'>
                  </TableCell>}
                {enableMultipleSelection &&
                  <TableCell key='check-all'>
                    <Checkbox
                      checked={allRowsChecked}
                      onChange={(_event, value) => this.onCheckAll(filteredEntries, value)}
                    />
                  </TableCell>}
                {columns.map(column => this.renderColumnHeader(column))}
                <TableCell key='dynamic-table-options'>
                  {enableMultipleSelection && selection.size > 0 && filteredEntries.some(i => selection.has(i)) &&
                    <CommonFormOptions
                      onRemove={() => this.onRemoveSelected()}
                      onEdit={() => this.onEditSelected(filteredEntries)}
                      extraOptions={extraRowActions.map(
                        era => ({
                          ...era,
                          action: () => era.action(entries.filter((_e, index) => Array.from(selection).includes(index)))
                        }))}
                      forceAllInsideMenu
                    />
                  }
                </TableCell>
              </TableRow>
            </TableHead>
            <DynamicFormTableBody
              key={filteredEntries.length}
              readOnly={readOnly}
              selection={selection}
              entries={entries}
              filteredEntries={filteredEntries}
              validationChain={validationChain}
              spec={pick(spec, columns)}
              errors={errors}
              translationKey={translationKey}
              enableMultipleSelection={enableMultipleSelection}
              enableRowSorting={enableRowSorting}
              extraRowActions={extraRowActions}
              onChange={this.updateEntryField.bind(this)}
              onOrderChange={this.props.onChange.bind(this)}
              onRemove={this.removeEntry.bind(this)}
              onSelectChange={this.onCheckOne.bind(this)}
              onEdit={!enableEdit ? undefined : i => this.setState({ editionEntry: entries[i], editionEntryIndex: i })}
              onRenderingChange={loading => this.setState({ loading })}
            />
          </Table>
        </BlockBody>
      </TableBlock>,
      editionEntry &&
      <DynamicFormDialog
        key='edit-dialog'
        spec={spec}
        entry={editionEntry}
        isMultipleEdition={editionEntryIndex === undefined}
        title={editionEntryIndex === undefined ? 'editMultiple' : 'edit'}
        translationKey={translationKey}
        onSave={entry => this.onDialogSave(entry, filteredEntries)}
        onUncommitedChange={this.props.onUncommitedChange}
        onCancel={() => this.setState({ editionEntry: undefined, editionEntryIndex: undefined })}
      />
    ];
  }
}


DynamicFormTableBase.defaultProps = {
  entries: [],
  tableActions: [],
  enableEdit: true,
  enableColumnFilters: true
};

export const DynamicFormTable = injectIntl(DynamicFormTableBase);

DynamicFormTable.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onUncommitedChange: PropTypes.func,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultEntry: PropTypes.object.isRequired,
  spec: PropTypes.object.isRequired,
  translationKey: PropTypes.string.isRequired,
  validationChain: PropTypes.instanceOf(ValidationChain),
  errors: PropTypes.object,
  tableActions: PropTypes.arrayOf(actionShape),
  extraTableActions: PropTypes.any,
  extraRowActions: PropTypes.arrayOf(actionShape),
  enableMultipleSelection: PropTypes.bool,
  enableColumnFilters: PropTypes.bool,
  enableRowSorting: PropTypes.bool,
  readOnly: PropTypes.bool,
  onNewClick: PropTypes.func,
};
