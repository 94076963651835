import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import { BlockTitle, BlockHeader, BlockBody, BlockActions } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { injectQueryString } from '../../components/queryUtils';
import DynamicFormGroup from '../../components/Dynamics/DynamicFormGroup';
import { collectionsService } from '../../lib/service';
import { IntlDataTable } from '../../intl-components/IntlDataTable';
import { ValidationChain } from '../../components/Form/Validation/ValidationChain';
import { parseToSeconds, roundDate } from '../../util/time';
import { timeZoneAware } from '../../components/TimeZoneAware';
import eventBus, { eventBusTopics } from '../../lib/eventBus';

class CollectionsViewBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addDialog: false,
      newCollection: {},
      pageData: {
        total: 0,
        limit: 50,
        skip: 0,
        data: []
      }
    };
  }

  componentDidMount() {
    this.onPaginationChange({ page: 0, rowsPerPage: 50 });
  }

  componentDidUpdate(prevProps) {
    const prevQuery = prevProps.query || {};
    const queryChange =
      Object.keys(prevQuery).length !== Object.keys(this.props.query).length ||
      Object.entries(this.props.query).some(([key, value]) => value !== prevQuery[key]);
    if (queryChange) {
      this.fetchData();
    }
  }

  fetchData() {
    // Sort is not implemented currently in BE, only filtering
    const query = { $limit: this.state.pageData.limit, $skip: this.state.pageData.skip };
    Object.assign(query, this.props.query);
    Object.keys(this.props.query).forEach(k => {
      if (query[k] === '') {
        query[k] = undefined;
      }
    });
    collectionsService.find({ query }).then((d) => this.fetchDataSuccess(d));
  }

  fetchDataSuccess(data) {
    const periods = Array.from(new Set([...data.collectionPeriods, ...data.asyncCollectionPeriods, ...data.aggregationPeriods]));
    this.setState({ pageData: { ...data, periods, data: data.collections }, loading: false });
  }

  onPaginationChange({ page, rowsPerPage }) {
    this.setState({
      loading: true, pageData: Object.assign(this.state.pageData, {
        limit: rowsPerPage, skip: rowsPerPage * page
      })
    }, this.fetchData);
  }

  onFilterChange(columnName, value) {
    const query = { ...this.state.query, [columnName]: value };
    this.props.updateQuery(query);
  }

  async queueNewCollection() {
    const errors = this.validationChain.getAndDisplayErrors('models.collections');
    if (Object.keys(errors).length) return;
    const newCollection = {
      ...this.state.newCollection,
      date: +this.state.newCollection.date
    };
    try {
      await collectionsService.create(newCollection);
      this.setState({ newCollection: {}, addDialog: false });
      this.fetchData();
    } catch (err) {
      eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: err.className });
      console.error(err);
    }
  }

  updateNewCollection(field, value) {
    const { newCollection } = this.state;
    const data = { ...newCollection, [field]: value };
    if (field === 'period') {
      data.date = roundDate(value, data.date);
    }
    this.setState({ newCollection: data });
  }

  renderDialog() {
    if (!this.state.addDialog) return false;
    const { newCollection, pageData } = this.state;
    const { asyncCollectionPeriods, aggregationPeriods } = pageData;
    const periodStep = newCollection.period && parseToSeconds(newCollection.period);
    this.validationChain = new ValidationChain();
    const periods = newCollection.type
      ? newCollection.type === 'aggregation'
        ? aggregationPeriods : asyncCollectionPeriods
      : undefined;
    const types = asyncCollectionPeriods && asyncCollectionPeriods.length ? ['aggregation', 'asyncCollection'] : ['aggregation'];

    return (
      <Dialog
        aria-labelledby="add-collection-title"
        aria-describedby="add-collection-title"
        open={true}
        fullWidth={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="add-collection-title">
          <FormattedMessage id='models.collections.new' />
        </DialogTitle>
        <DialogContent>
          <DynamicFormGroup
            spec={{
              type: { type: 'select', values: types, validators: ['required'], translationKey: 'type' },
              period: periods && { type: 'select', values: periods, validators: ['required'] },
              date: newCollection.period && {
                type: 'datetime',
                validators: ['required'],
                props: {
                  disableFuture: true,
                  minutesStep: periodStep / 60
                }
              }
            }}
            data={this.state.newCollection}
            validationChain={this.validationChain}
            translationKey='models.collections'
            onChange={(f, v) => this.updateNewCollection(f, v)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => this.setState({ newCollection: {}, addDialog: false })}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.queueNewCollection()}>
            <FormattedMessage id={'app.create'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }

  render() {
    const { intl, timezone } = this.props;
    const { periods } = this.state.pageData;
    return (
      <Fragment>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="section.collections" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={intl.formatMessage({ id: 'models.collections.new' })} >
                  <IconButton onClick={() => this.setState({ addDialog: true })} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <IntlDataTable
                translationKey='models.collections'
                columns={[
                  { data: 'id' },
                  {
                    columnKey: 'date', data: ({ date }) => <Fragment>
                      <FormattedDate timeZone={timezone} value={date} year='numeric' month='2-digit' day='2-digit' hour='2-digit' minute='2-digit' second='2-digit' />
                    </Fragment>
                  },
                  {
                    columnKey: 'insertion_date', data: ({ insertion_date }) => !!insertion_date && <Fragment>
                      <FormattedDate timeZone={timezone} value={insertion_date * 1000} year='numeric' month='2-digit' day='2-digit' hour='2-digit' minute='2-digit' second='2-digit' />
                    </Fragment>
                  },
                  { data: 'period', filter: periods },
                  { data: 'aggregated' }
                ]}
                rowStatus={this.rowStatus}
                filters={this.props.query}
                pageData={this.state.pageData}
                onPaginationChange={(ev) => this.onPaginationChange(ev)}
                onFilterChange={(column, filter) => this.onFilterChange(column, filter)}
                loading={this.state.loading} />
            </BlockBody>
          </TableBlock>
        </Row>
        {this.renderDialog()}
      </Fragment>
    );
  }

}

CollectionsViewBase.propTypes = {
  query: PropTypes.object
};

const CollectionsView = injectIntl(injectQueryString(timeZoneAware(CollectionsViewBase)));

export default CollectionsView;
