import React, { Fragment } from 'react';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { devicesConfigService } from '../lib/service';
import { injectIntl, FormattedMessage } from 'react-intl';
import Check from '@material-ui/icons/Check';
import Restore from '@material-ui/icons/Restore';
import { ICONS } from '../components/Table/FAIconButton';
import UnsavedChangesLabel from './UnsavedChangesLabel';
import { confirmCancel } from '../components/util';
import eventBus, { eventBusTopics } from '../lib/eventBus';
import { handleAPIError } from '../util/forms';

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

class UnsavedChangesBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      unsavedChanges: false,
      anchorEl: null
    };
    this.pendingChangesEventHandler = ev => {
      this.setState({
        unsavedChanges: ev.hasPendingChanges
      });
    };
  }
  checkPendingConfigurationChanges() {
    devicesConfigService.checkPendingConfigurationChanges().then(this.pendingChangesEventHandler);
  }

  componentDidMount() {
    this.checkPendingConfigurationChanges();
    devicesConfigService.on('PendingConfigurationChanges', this.pendingChangesEventHandler);
  }

  componentWillUnmount() {
    devicesConfigService.removeListener('PendingConfigurationChanges', this.pendingChangesEventHandler);
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  saveChanges() {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.applying' }));
    devicesConfigService.apply()
      .then((() => this.handleClose()))
      .catch(response => handleAPIError(response, 'app', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.applying' })));
  }

  revertChanges() {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.reverting' }));
    devicesConfigService.revert()
      .then((() => this.handleClose()))
      .catch(response => handleAPIError(response, 'app', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.reverting' })));
  }

  render() {
    const { anchorEl, unsavedChanges } = this.state;
    const { intl } = this.props;
    const text = intl.formatMessage({ id: 'app.unsavedChanges' });
    if (!unsavedChanges) {
      return null;
    }
    return <Fragment>
      <UnsavedChangesLabel
        aria-owns={anchorEl ? 'dropper-menu-changes' : null}
        aria-haspopup='true'
        onClick={this.handleClick}
        icon={ICONS.faHdd}
        text={text}
      />
      <Menu
        id='dropper-menu-changes'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        <MenuItem onClick={() => this.saveChanges()} >
          <StyledListItemIcon>
            <Check />
          </StyledListItemIcon>
          <ListItemText>
            <FormattedMessage id='app.applyChanges' />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { confirmCancel(intl, () => this.revertChanges()); }}>
          <StyledListItemIcon>
            <Restore />
          </StyledListItemIcon>
          <ListItemText>
            <FormattedMessage id='app.discard' />
          </ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>;
  }

}

UnsavedChangesBase.propTypes = {};

const UnsavedChanges = injectIntl(UnsavedChangesBase);

export default UnsavedChanges;