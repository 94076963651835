import React from 'react';
import { omit } from 'lodash';
import 'web-animations-js';

import RootRef from '@material-ui/core/RootRef';
import { TableRow } from './Table';

class AnimatedTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  shouldComponentUpdate(newProps) {
    if (this.ref.current && this.props.indicator !== newProps.indicator) {
      this.ref.current.animate([
        { background: '#ffffaf' },
        { background: '#ffffff' }
      ], { duration: 750 });
    }
    return true;
  }

  render() {
    return (
      <RootRef rootRef={this.ref}>
        <TableRow {...omit(this.props, 'indicator')} />
      </RootRef>
    );
  }
}

export default AnimatedTableRow;