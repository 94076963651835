import React, { Fragment } from 'react';

import { Row } from '../../../components/GridModules/Row';
import { IntlCheckbox } from '../../../intl-components/Form';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';

const ScheduleTimeSelector = ({ time, onChange, validationChain }) => {
  let content;
  if (typeof time === 'string') {
    content = (
      <Row>
        <DynamicFormField
          field='time'
          fieldType='string'
          translationKey='models.schedules'
          validators={['required', 'cron']}
          validationChain={validationChain}
          value={time}
          onChange={value => onChange(value)}
        />
      </Row>
    );
  } else {
    content = <ScheduleTimeStructSelector
      onChange={onChange}
      time={time}
      validationChain={validationChain} />;
  }
  return (
    <Fragment>
      <Row>
        <IntlCheckbox
          label='models.schedules.form.useCronLike'
          checked={typeof time === 'string'}
          onChange={(_ev, enabled) => onChange(enabled ? '' : {})}
        />
      </Row>
      {content}
    </Fragment>
  );
};

const IntegerListEntry = (props) => <DynamicFormField
  {...props}
  fieldType={{ type: 'array', items: 'integer', validators: ['integerArray'] }}
  translationKey='models.schedules.form.timeSelector'
/>;

const ScheduleTimeStructSelector = ({ time, onChange, validationChain }) => {
  if (!time) time = {};

  return (
    <Fragment>
      <Row MultiColumns>
        <IntegerListEntry
          field='hour'
          value={time.hour}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, hour: n === null ? undefined : n })}
        />
        <IntegerListEntry
          field='minute'
          value={time.minute}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, minute: n === null ? undefined : n })}
        />
        <IntegerListEntry
          field='second'
          value={time.second}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, second: n === null ? undefined : n })}
        />
      </Row>
      <Row MultiColumns>
        <IntegerListEntry
          field='day'
          value={time.day}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, day: n === null ? undefined : n })}
        />
        <IntegerListEntry
          field='month'
          value={time.month && time.month + 1}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, month: n === null ? undefined : n - 1 })}
        />
        <IntegerListEntry
          field='year'
          value={time.year}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, year: n === null ? undefined : n })}
        />
      </Row>
      <Row>
        <IntegerListEntry
          field='dayOfWeek'
          value={time.dayOfWeek}
          validationChain={validationChain}
          onChange={n => onChange({ ...time, dayOfWeek: n === null ? undefined : n })}
        />
      </Row>
    </Fragment>
  );
};

export default ScheduleTimeSelector;
