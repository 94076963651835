import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { PageTitle } from '../GridModules/Typography';

const PageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Root = styled.div`
  position: relative;
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${props => props.theme.TripleMarginSize};
  > .logo {
    width: 160px;
    margin-bottom: ${props => props.theme.MarginSize};
  }
  > .paper {
    width: 100%;
    padding: ${props => props.theme.MarginSize};
    box-shadow: ${props => props.theme.ComponentBlockShadow};
  }
`;

const SectionTitle = (props) => (
  <PageTitle variant="h5" gutterBottom>
    <FormattedMessage id={props.id} />
  </PageTitle>
);

export { PageWrapper, Root, SectionTitle };