import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AlertsTabView from './AlertsTabView';

const AlertsRouter = ({ routeMatch }) => {
  return (
    <Switch>
      <Route key='route_redirect' exact path={`/${routeMatch}/`}>
        <Redirect to={`/${routeMatch}/list`} />
      </Route>
      <Route key='route_tabview' path={`/${routeMatch}/:tab`} component={AlertsTabView} />
    </Switch >
  );
};
export default AlertsRouter;