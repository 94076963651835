import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserZoneIcon = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.CountertypeTextColor};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  > svg {
    box-sizing: content-box;
    padding: 8px
    border-radius: 3px;
    ${props => props.onClick && 'background-color: ' + lighten(.1, props.theme.BgDarkColor)};
  }
  > span, > label {
    margin-left: ${props => props.theme.HalfMarginSize};
    margin-right: ${props => props.theme.HalfMarginSize};
    cursor: inherit;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export const UserZoneLabel = ({ text, icon, children, ...other }) => (
  <UserZoneIcon {...other}>
    {icon && <FontAwesomeIcon icon={icon} />}
    {text && <label>{text}</label>}
    {children}
  </UserZoneIcon >
);