import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ExportDeviceView } from './simple/ExportDeviceView';

const ExportDevicesRouter = ({ routeMatch }) =>
  <Switch>
    <Route key='devices_route_root' exact path={`/${routeMatch}/`}>
      <Redirect to={`/${routeMatch}/modbus`} />
    </Route>
    <Route key='devices_route_modbus' path={`/${routeMatch}/:code?`} component={ExportDeviceView} />
  </Switch>;

ExportDevicesRouter.isRouter = true;

const ExportedExportDevicesRouter = withRouter(ExportDevicesRouter);
export default ExportedExportDevicesRouter;