import React from 'react';
import { TextField, FormGroup, FormControlLabel, Switch, Tooltip, IconButton, Checkbox } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Select } from '../components/Form/Form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const IntlTextField = injectIntl((props) => {
  const helperText = props.helperText ? props.intl.formatMessage({ id: props.helperText }) : props.helperText;
  const label = props.intl.formatMessage({ id: props.label });
  return <TextField
    {...props}
    value={props.value || ''}
    label={label}
    helperText={helperText}
  />;
});

const IntlSelect = injectIntl((props) => {
  const label = props.label ? props.intl.formatMessage({ id: props.label }) : props.label;
  const helperText = props.helperText ? props.intl.formatMessage({ id: props.helperText }) : props.helperText;
  return <Select
    {...props}
    label={props.required ? label + ' *' : label}
    helperText={helperText}
  />;
});

const IntlSwitch = injectIntl(({ intl, disabled, label, checked, onChange }) =>
  <FormGroup>
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          color="primary"
        />
      }
      label={intl.formatMessage({ id: label })}
    />
  </FormGroup>
);

const IntlFormHeaderIcon = injectIntl(({ intl, tooltip, icon, link, onClick }) =>
  <Tooltip title={intl.formatMessage({ id: tooltip })}>
    {link ?
      <Link to={link}>
        <IconButton>
          {icon}
        </IconButton>
      </Link>
      :
      <IconButton onClick={onClick}>
        {icon}
      </IconButton>
    }

  </Tooltip>
);

const IntlCheckbox = injectIntl(({ intl, label, ...props }) => (
  <FormControlLabel
    control={
      <Checkbox {...props} />
    }
    label={intl.formatMessage({ id: label })}
  />
));

const IntlMonospacedTextField = styled(IntlTextField)`
  textarea {
    font-family: monospace;
    min-width: 780px;
  }
`;

export { IntlTextField, IntlSelect, IntlSwitch, IntlCheckbox, IntlFormHeaderIcon, IntlMonospacedTextField };
