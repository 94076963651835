import * as minimatch from 'minimatch';
import eventBus, { eventBusTopics } from './eventBus';

export function formatSortParam(sort) {
  if (sort === undefined)
    return undefined;
  let result = {};
  Object.keys(sort).forEach(k => {
    if (sort[k]) {
      result[k] = sort[k] === 'asc' ? -1 : 1;
    }
  });
  return result;
}
export const alertEvents = {
  new: 'New',
  update: 'Update',
  expire: 'Expire'
};
export const filterByQuery = (list, query) => {
  let result = Array.from(list);
  Object.keys(query).forEach(field => {
    if (!query[field])
      return;
    if (query[field].indexOf('*') >= 0) {
      const re = minimatch.makeRe(query[field], { nocase: true });
      result = result.filter(item => typeof item[field] === 'string' && item[field].match(re));
    }
    else {
      result = result.filter(item => query[field] == item[field]); // eslint-disable-line eqeqeq
    }
  });
  return result;
};
export const fitsQuery = query => {
  const discardFunctions = Object.entries(query).map(([key, value]) => {
    if (!value)
      return null;
    if (typeof value === 'string' && value.indexOf('*') >= 0) {
      const re = minimatch.makeRe(value, { nocase: true });
      return item => typeof item[key] !== 'string' || !item[key].match(re);
    }
    else {
      return item => item[key] != value; // eslint-disable-line eqeqeq
    }
  }).filter(f => f);
  return item => !discardFunctions.some(df => df(item));
};

export const showErrorAlert = e => {
  if (e.className) {
    eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: e.className, data: e.data });
  } else {
    eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'UnknownError' });
  }
};
