import PubSub from 'pubsub-js';

const validators = {};

const publish = (topic, data, validator) => {
  if (validator) {
    validators[topic] = validator;
  }
  PubSub.publish(topic, data);
};

const subscribe = (topic, callbackForTopicData) => {
  return PubSub.subscribe(topic, (_msg, data) => {
    if (validators[topic]) {
      callbackForTopicData(validators[topic](data));
    } else {
      callbackForTopicData(data);
    }
  });
};

const unsubscribe = subscribeTokens => {
  if (!Array.isArray(subscribeTokens)) subscribeTokens = [subscribeTokens];
  return subscribeTokens.forEach(PubSub.unsubscribe);
};

export const eventBusTopics = {
  LOADING_START: 'LOADING_START',
  LOADING_END: 'LOADING_END',
  SCRIPTS_HELP_SHOW_TYPE: 'SCRIPTS_HELP_SHOW_TYPE',
  DISPLAY_ALERT_MODAL: 'DISPLAY_ALERT_MODAL',
  SERVER_CONNECTION: 'SERVER_CONNECTION',
  DISABLE_UI_ERROR: 'DISABLE_UI_ERROR'
};

const eventBus = {
  publish,
  subscribe,
  unsubscribe
};

export default eventBus;
