import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import FormGroup from '@material-ui/core/FormGroup';
import { get } from 'lodash';

import { Row } from '../../../components/GridModules/Row';
import { IntlSwitch } from '../../../intl-components/Form';
import { BlockBody, BlockHeader, BlockTitle, ComponentBlock } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import DynamicFormGroup from '../../../components/Dynamics/DynamicFormGroup';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import { deviceIdentificationFields } from '../devices-spec';

const DevicesFormIdentification = ({ device, formView, validationChain, typeSpec, errors }) =>
  <ComponentBlock>
    <BlockHeader>
      <BlockTitle>
        <FormattedMessage id="models.devices.form.sections.id" />
      </BlockTitle>
    </BlockHeader>
    <BlockBody>
      <FormGroup>
        <Row>
          <IntlSwitch
            label="models.devices.form.enabled"
            checked={!device.disabled}
            onChange={(_ev, enabled) => formView.changeDeviceField('disabled', !enabled)}
          />
        </Row>
        <Row MultiColumns>
          <DynamicFormField
            validationChain={validationChain}
            value={device.type}
            field='type'
            fieldType={deviceIdentificationFields.type}
            translationKey={'models.devices'}
            errors={get(errors, 'type')}
            onChange={value => formView.changeDeviceField('type', value)}
          />
          <DynamicFormField
            validationChain={validationChain}
            value={device.code}
            field='code'
            fieldType={deviceIdentificationFields.code}
            translationKey={'models.devices'}
            errors={get(errors, 'code')}
            onChange={value => formView.changeDeviceField('code', value)}
          />
          <DynamicFormField
            validationChain={validationChain}
            value={device.name}
            field='name'
            fieldType={deviceIdentificationFields.name}
            translationKey={'models.devices'}
            errors={get(errors, 'name')}
            onChange={value => formView.changeDeviceField('name', value)}
          />
        </Row>
        <DynamicFormGroup
          validationChain={validationChain}
          translationKey="models.devices"
          data={device}
          onChange={(field, value) => formView.changeDeviceField(field, value)}
          spec={typeSpec.general}
          errors={errors}
        />
      </FormGroup>
    </BlockBody>
  </ComponentBlock>;

DevicesFormIdentification.propTypes = {
  device: PropTypes.shape({
    disabled: PropTypes.bool,
    type: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    extra: PropTypes.object,
    pollInterval: PropTypes.number
  }).isRequired,
  formView: PropTypes.shape({
    changeDeviceField: PropTypes.func
  }),
  validationChain: PropTypes.instanceOf(ValidationChain),
  errors: PropTypes.object
};

export default DevicesFormIdentification;