
export const confirmRemove = (intl, yesCallback, noCallback) => {
  confirm(intl, 'app.confirmDelete', yesCallback, noCallback);
};

export const confirmCancel = (intl, action) => confirm(intl, 'app.confirmCancel', action);

export const confirm = (intl, translationKey, yesCallback, noCallback) => {
  if (window.confirm(intl.formatMessage({ id: translationKey }))) {
    if (typeof yesCallback === 'function') {
      yesCallback();
    }
  } else {
    if (typeof noCallback === 'function') {
      noCallback();
    }
  }
};
