import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ScriptEditor } from '../../../views/Scripts/ScriptEditor';
import ScriptBlock from '../../../views/Scripts/ScriptBlock';
import { Dialog } from '../../custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { ValidationChain } from '../../Form/Validation/ValidationChain';
import { BlockHeader, BlockTitle, BlockActions } from '../../GridModules/ComponentBlock/ComponentBlock';
import { IntlTextField } from '../../../intl-components/Form';
import ValidableFormComponent from '../../Form/Validation/ValidableFormComponent';

const CODE_PREFIX = 'function encode(payload) {';
const CODE_SUFIX = '}';

class JsEditorDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      code: props.value,
      errors: [],
      testValue: '',
      testResult: ''
    };
  }

  executeTest() {
    const code = '(payload) => {\n' + this.state.code + '\n}';
    let result;
    try {
      // eslint-disable-next-line no-eval
      const fn = eval(code);
      result = fn(this.state.testValue);
    } catch (err) {
      result = 'Error: ' + err;
    }
    this.setState({ testResult: '' + result });
  }

  onSave() {
    const errors = this.validationChain.getAndDisplayErrors(`${this.props.translationKey}.form`);
    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      this.props.onSave(this.state.code);
    }
  }

  render() {
    const { translationKey } = this.props;
    const { code, testValue, testResult, errors } = this.state;

    this.validationChain = new ValidationChain();
    return (
      <Dialog
        aria-labelledby='edit-js-form-title'
        aria-describedby='edit-js-form-title'
        open={true}
        disableBackdropClick={true}
        maxWidth='lg'
        fullWidth={true}
      >
        <DialogTitle id='edit-js-form-title'>
          <FormattedMessage id={`${translationKey}.title`} />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <ScriptBlock value={CODE_PREFIX}></ScriptBlock>
            <ValidableFormComponent
              field='code'
              value={code}
              validationChain={this.validationChain}
              validators={['jsFunctionContent']}
              errors={get(errors, 'code')}
            >
              <ScriptEditor
                editorId='editor-id'
                blockKey='key'
                onChange={code => this.setState({ code })}
                value={code || ''}
                minHeight='150px'
                allowHelp={false}
              /></ValidableFormComponent>
            <ScriptBlock value={CODE_SUFIX}></ScriptBlock>


            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id={`${translationKey}.form.tester`} />
              </BlockTitle>
            </BlockHeader>
            <BlockHeader>
              <IntlTextField
                multiline
                fullWidth={true}
                label={`${translationKey}.form.testValue`}
                value={testValue}
                onChange={ev => this.setState({ testValue: ev.target.value })}
              />
              <BlockActions>
                <Button variant='outlined' onClick={() => this.executeTest()}>
                  <FormattedMessage id={`${translationKey}.test`} />
                </Button>
              </BlockActions>
            </BlockHeader>
            <IntlTextField
              multiline
              fullWidth={true}
              label={`${translationKey}.form.testResult`}
              value={testResult}
              onChange={_ => { }}
            />
          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant='outlined' onClick={this.props.onCancel}>
            <FormattedMessage id='app.cancel' />
          </Button>
          <Button variant='contained' color='primary' onClick={() => this.onSave()}>
            <FormattedMessage id='app.save' />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

JsEditorDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default JsEditorDialog;