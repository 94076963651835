import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditableCellPopover } from './EditableCellPopover';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';

const EditableCellContent = styled.div`
  display: flex;
  align-items: center;
`;

const Data = styled.span`
  margin-right: ${props => props.theme.HalfMarginSize};
`;

class EditableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      newValue: undefined
    };
  }

  onCancelClick() {
    this.setState({
      error: false,
      newValue: undefined
    });
  }

  async onSaveChangeClick() {
    const newValue = this.state.newValue === undefined ? this.props.value : this.state.newValue;
    if (newValue !== undefined) {
      try {
        this.setState({ change: true });
        await this.props.onValueEdit(newValue);
        this.setState({
          change: false,
          error: false
        });
      } catch (err) {
        eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'ValueWrite', err });
        this.setState({
          change: false,
          error: true
        });
      }
    }
    this.handleClosePopover();
  }

  handleClickEdit(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClosePopover() {
    this.setState({
      anchorEl: undefined,
      newValue: undefined
    });
  }

  render() {
    const { valueMappings, value, dataType } = this.props;
    const { error } = this.state;
    const style = error ? { color: 'red' } : {};
    const mappedValue = valueMappings && valueMappings[value] ? `${valueMappings[value]} (${value})` : value;
    const newValue = this.state.newValue === undefined ? value : this.state.newValue;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <TableCell style={style}>
        <EditableCellContent>
          <Data>{mappedValue}</Data>
          {this.state.change ?
            <CircularProgress size={20} /> :
            <IconButton
              aria-owns={open ? 'simple-popper' : null}
              aria-haspopup="true"
              variant="contained"
              onClick={ev => this.handleClickEdit(ev)}
            >
              <Edit style={{ fontSize: '18px' }} />
            </IconButton>
          }
          {!this.state.change && anchorEl &&
            <EditableCellPopover
              anchorEl={anchorEl}
              onClose={ev => this.handleClosePopover(ev)}
              onChange={val => this.setState({ newValue: val })}
              onSave={ev => this.onSaveChangeClick(ev)}
              valueMappings={valueMappings}
              value={newValue}
              dataType={dataType}
            />
          }
        </EditableCellContent>
      </TableCell>
    );
  }

}

EditableCell.propTypes = {
  value: PropTypes.node.isRequired,
  onValueEdit: PropTypes.func.isRequired,
  updateTimeout: PropTypes.number,
  valueMappings: PropTypes.object,
  dataType: PropTypes.string
};

export default EditableCell;