import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import VariablesTable from './VariablesTable';
import { Row } from '../../components/GridModules/Row';
import { injectQueryString } from '../../components/queryUtils';
import { SectionTitle } from '../../components/Layout/Page';

const VariablesBase = ({ query, updateQuery }) => {
  return (
    <Fragment>
      <SectionTitle id="section.variables" />
      <Row>
        <VariablesTable
          query={query}
          onQueryChange={updateQuery}
        />
      </Row>
    </Fragment>
  );
};

VariablesBase.propTypes = {
  query: PropTypes.object
};

const VariablesView = injectQueryString(VariablesBase);

export default VariablesView;
