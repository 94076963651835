import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { ScriptsView } from './ScriptsView';
import { ScriptForm } from './ScriptForm';

const DevicesRouter = ({ routeMatch }) =>
  <Switch>
    <Route key='scripts_route_root' exact path={`/${routeMatch}/`}>
      <ScriptsView />
    </Route>
    <Route key='scripts_route_new_event' path={`/${routeMatch}/new/event/:type/:object/:objectName`} component={ScriptForm} />
    <Route key='scripts_route_new' path={`/${routeMatch}/new`} component={ScriptForm} />
    <Route key='scripts_route_edit' path={`/${routeMatch}/edit/:code`} component={ScriptForm} />
  </Switch>;

DevicesRouter.isRouter = true;

const ExportedDevicesRouter = withRouter(DevicesRouter);
export default ExportedDevicesRouter;