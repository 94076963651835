import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Row } from '../../../components/GridModules/Row';

const DiskBox = styled.div`
  border-radius: 10px;
  height: 50px;
  line-height: 40px;
  border: 1px solid black;
  width: 100%;
  padding: 4px;
  text-align: center;
`;

const PartitionBlock = styled.div`
  float: left;
  position: relative;
  height: 100%;
  width: ${({ width }) => width}%;
  border-radius: 5px;
  border: 1px solid black;
  .label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
`;

const PartitionUsedSpace = styled.div`
  background: ${props => props.theme.PrimaryColor};
  width: ${({ width }) => width}%;
  height: 38px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Disk = ({ size, partitions }) => {
  const partitionsSize = partitions.map(p => p.size).reduce((a, b) => a + b, 0);
  return (
    <Row>
      <DiskBox>
        {partitions.map(partition => (
          <Partition key={partition.name} width={Math.floor((partition.size / partitionsSize) * 100)} {...partition} />
        ))}
        {!partitions.length &&
          <FormattedMessage id='system.storage.disk.notReady' />
        }
      </DiskBox>
    </Row>
  );
};

const Partition = ({ width, use, free, size }) => (
  <PartitionBlock width={width}>
    <PartitionUsedSpace width={Math.round(((size - free) / size) * 100)} />
    <span className='label'><FormattedMessage id={'system.storage.uses.' + use} /></span>
  </PartitionBlock>
);
