import React, { Fragment } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { DynamicFormField } from './DynamicFormField';
import { Row } from '../GridModules/Row';
import { Typography, Checkbox } from '@material-ui/core';
import { ValidationChain } from '../Form/Validation/ValidationChain';
import { BlockHeader, BlockTitle } from '../GridModules/ComponentBlock/ComponentBlock';

/*
 * Sample of groupedFields format.
 * Each section prints a header
 * All fields in a row are printed as columns
 *
  const groupedFields = [
    {
      section: {
        code: 'general',
        order: 1
      },
      rows: [
        {
          number: 1,
          fields: [
            {
              fieldName: 'field',
              fieldType: { type: 'select', values: ['v1', v2]}
            }
          ]
        }
      ]
    }
  ];

 */

const DEFAULT_UI_SECTION = { code: null, order: 0 };

const sortFields = (groupedFields) => {
  groupedFields.sort((item1, item2) => item1.order - item2.order);
  groupedFields.forEach(field => {
    field.rows.sort((row1, row2) => row1.number - row2.number);
  });
};

const DynamicFormGroup = (props) => {
  const { isMultipleEdition } = props;

  let missingRowValue = Number.MIN_SAFE_INTEGER;
  const groupedFields = [];
  Object.entries(props.spec)
    .filter(([, fieldType]) => !!fieldType)
    .forEach(([fieldName, fieldType]) => {
      const cfgSection = get(fieldType, 'uiSection.section') || DEFAULT_UI_SECTION;
      let cfgRow = (!isMultipleEdition && get(fieldType, 'uiSection.row')) || ++missingRowValue; // if no row configure, keeps original order
      let section = groupedFields.find(item => item.section.code === cfgSection.code);
      if (section == null) {
        // add new section
        section = { section: cfgSection, rows: [] };
        groupedFields.push(section);
      }
      let row = section.rows.find(r => r.number === cfgRow);
      if (row == null) {
        // add new row
        row = { number: cfgRow, fields: [] };
        section.rows.push(row);
      }
      const field = { fieldName, fieldType };
      row.fields.push(field);
    });
  sortFields(groupedFields);

  return (
    <FormGroup>
      {props.label &&
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage id={`${props.translationKey}.form.${props.label}`} />
        </Typography>
      }
      {groupedFields
        .map(({ section, rows }, index) => {
          return (<Fragment key={index}>
            {section.code &&
              <BlockHeader>
                <BlockTitle>
                  <FormattedMessage id={`components.dynamicForms.sections.${section.code}`} />
                </BlockTitle>
              </BlockHeader>
            }
            {rows.map(({ fields }, index) => {
              return (<Row
                MultiColumns={isMultipleEdition || fields.length > 1}
                TwoColumns={(isMultipleEdition && fields.length === 1) || fields.length === 2}
                ThreeColumns={fields.length === 3}
                FourColumns={fields.length >= 4}
                LastBigger={!isMultipleEdition}
                FirstCheckbox={isMultipleEdition}
                key={index}>
                {fields.map(({ fieldName, fieldType }, index) => {
                  const value = props.data[fieldName];
                  return (<Fragment key={index}>
                    {isMultipleEdition &&
                      <Checkbox
                        checked={props.enabledKeys.includes(fieldName)}
                        onChange={(_e, state) => props.onChangeEnabledEntry(fieldName, state)}
                      />
                    }
                    <DynamicFormField
                      validationChain={props.validationChain}
                      value={value}
                      contextValue={props.data}
                      disabled={isMultipleEdition && !props.enabledKeys.includes(fieldName)}
                      field={fieldName}
                      fieldType={fieldType}
                      translationKey={props.translationKey}
                      errors={get(props.errors, fieldName)}
                      onChange={(value) => props.onChange(fieldName, value)}
                    />
                  </Fragment>);
                })
                }
              </Row>);
            })
            }
          </Fragment>);


        })
      }
    </FormGroup >
  );
};

DynamicFormGroup.propTypes = {
  validationChain: PropTypes.instanceOf(ValidationChain).isRequired,
  translationKey: PropTypes.string.isRequired,
  spec: PropTypes.object.isRequired,
  label: PropTypes.string,
  data: PropTypes.any,
  onChange: PropTypes.func,
  isMultipleEdition: PropTypes.bool,
  enabledKeys: PropTypes.array,
  onChangeEnabledEntry: PropTypes.func
};

export default DynamicFormGroup;
