import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dialog } from '../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

class Alert extends React.Component {

  constructor(p) {
    super(p);
    this.state = { open: true };
  }

  close() {
    this.setState({ ...this.state, open: false });
  }

  translateDataFields(data = {}) {
    const { intl } = this.props;
    return Object.keys(data).reduce((r, key) => {
      if (key.endsWith('Entity')) {
        return { ...r, [key]: intl.formatMessage({ id: `entities.${data[key]}` }) };
      } else {
        return { ...r, [key]: data[key] };
      }
    }, {});
  }

  render() {
    const { alertId, rawError, errors, data } = this.props;
    const values = this.translateDataFields(data);
    return (
      <Dialog
        open={this.state.open}
        onClose={this.close.bind(this)}
        aria-labelledby="alert-title"
        aria-describedby="alert-description"
      >
        <DialogTitle id="alert-title">
          <FormattedMessage id={`alerts.${alertId}.title`} values={values} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-description">
            <FormattedMessage id={`alerts.${alertId}.message`} values={values} />
          </DialogContentText>
          {rawError && String(rawError)}
          {errors && errors.map((e, i) => <p key={`msg_${i}`}>{e}</p>)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.close.bind(this)} >
            <FormattedMessage id="alerts.okButton" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

Alert.propTypes = {
  alertId: PropTypes.string,
  rawError: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object
};

Alert.defaultProps = {
  alertId: 'undefined'
};

export const IntlAlert = injectIntl(Alert);