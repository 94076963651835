import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import DevicesConfigView from './DevicesConfigView';
import { DevicesFormView } from './DevicesForm/DevicesFormView';
import { ModulesFormView } from './ModulesForm/ModuleInstanceFormView';

const DevicesRouter = ({ routeMatch }) =>
  <Switch>
    <Route key='devices_route_form' path={`/${routeMatch}/device/:code?`} component={DevicesFormView} />
    <Route key='modules_route_form' path={`/${routeMatch}/module/:code?`} component={ModulesFormView} />
    <Route key='devices_route_root' path={`/${routeMatch}/:tab?`} component={DevicesConfigView} />
  </Switch>;

DevicesRouter.isRouter = true;

const ExportedDevicesRouter = withRouter(DevicesRouter);
export default ExportedDevicesRouter;