import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { acsService, systemService } from '../../lib/service';
import { Row } from '../../components/GridModules/Row';
import { BlockHeader, BlockTitle, BlockBody, ComponentGrid, BlockGrid } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { showErrorAlert } from '../../lib/apiHelpers';
import { Grid } from '@material-ui/core';
import { formatRelativeDate, formatLongDate } from '../../util/time';
import { get } from 'lodash';
import { SectionTitle } from '../../components/Layout/Page';
import { LabeledText } from '../../components/Form/Form';

const FixedWidthContainer = styled('div')`
  max-width: 400px;
`;

const VersionsTable = (props) => {
  const { translationKey, title, columnTitles, rows } = props;
  const translationPrefix = !!translationKey ? (translationKey + '.') : '';
  return (
    <Fragment>
      <BlockHeader>
        <BlockTitle>
          <FormattedMessage id={`${translationPrefix}${title}`} />
        </BlockTitle>
      </BlockHeader>
      <BlockBody>
        <FixedWidthContainer>
          <ComponentGrid container justify="space-evenly" alignItems="flex-start" >
            {columnTitles.map((title, index) => (
              <Grid key={index} item sm={6} >
                <FormattedMessage id={`${translationPrefix}${title}`} />
              </Grid>
            ))}
          </ComponentGrid>
          {rows.map((item, index) => (
            <BlockGrid key={index} container >
              {item.map((value, index) => (
                <Grid key={'item' + index} item sm={6} >
                  {value}
                </Grid>
              ))}
            </BlockGrid>
          ))}
        </FixedWidthContainer>
      </BlockBody>
    </Fragment>);
};

class GeneralViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uptime: null,
      acsHwInfo: null,
      acsWatchdogInfo: null,
      systemInfo: null,
      hardwareInfo: {}
    };
  }

  componentDidMount() {
    this.getVersions();
    this.getHardwareInfo();
    this.getHardwareStatus();
    this.getAcsHardware();
    this.getAcsWatchdog();
  }

  getVersions() {
    systemService.get('version')
      .then(result => this.setState({ systemInfo: result.data }))
      .catch(err => showErrorAlert(err));
  }

  getHardwareInfo() {
    systemService.get('hardwareInfo')
      .then(result => this.setState({ hardwareInfo: result.data }))
      .catch(err => showErrorAlert(err));
  }

  getAcsHardware() {
    acsService.get('hardwareInfo')
      .then(result => this.setState({ acsHwInfo: result }))
      .catch(err => console.log(err));
  }

  getAcsWatchdog() {
    acsService.get('watchdogInfo')
      .then(result => this.setState({ acsWatchdogInfo: result }))
      .catch(err => console.log(err));
  }

  getHardwareStatus() {
    systemService.get('hardwareStatus')
      .then(result => this.setState({ uptime: result.data.uptime }))
      .catch(err => showErrorAlert(err));
  }

  getUptimeText(value) {
    const { intl } = this.props;
    if (value == null) {
      return '-';
    }
    return `${formatLongDate(intl, value)} (${formatRelativeDate(intl, new Date(value))})`;
  }

  getWatchdogText(watchdogInfo) {
    if (watchdogInfo == null || watchdogInfo.active == null) {
      return '-';
    }
    return (
      <FormattedMessage
        id={`system.general.info.wathdogStatus.${watchdogInfo.active ? 'enabled' : 'disabled'}`}
        values={{ reboots: watchdogInfo.rebootCounter ?? '?' }} />);
  }

  getActiveModules(modules) {
    return Object.keys(modules).map((key) => [key, modules[key] || '*']);
  }

  getActivePackages(packages) {
    return Object.entries(packages).filter(([_, value]) => value != null);
  }

  render() {
    const { systemInfo, hardwareInfo, uptime, acsHwInfo, acsWatchdogInfo } = this.state;

    const firmware = get(systemInfo, 'package', '-');
    const timezone = get(systemInfo, 'timezone', '-');
    const board = get(hardwareInfo, 'model.modelName', '-');
    const activeModules = this.getActiveModules(get(systemInfo, 'modules', []));
    const activePackages = this.getActivePackages(get(systemInfo, 'packages', []));
    const hardwareId = get(acsHwInfo, 'hardwareId', '-');
    const isWatchdogActive = this.getWatchdogText(acsWatchdogInfo);
    const _uptime = this.getUptimeText(uptime);

    return (
      <Fragment>
        <SectionTitle id='system.general.title' />
        <Row>
          <BlockBody>
            <LabeledText id='system.general.info.firmware' value={firmware} />
            <LabeledText id='system.general.info.board' value={board} />
            <LabeledText id='system.general.info.hardwareId' value={hardwareId} />
            <LabeledText id='system.general.info.timezone' value={timezone} />
            <LabeledText id='system.general.info.uptime' value={_uptime} />
            <LabeledText id='system.general.info.watchdog' value={isWatchdogActive} />
          </BlockBody>
        </Row >

        <Row>
          <VersionsTable
            translationKey='system.general'
            title='packages'
            columnTitles={['package', 'version']}
            rows={activePackages} />
        </Row >

        <Row>
          <VersionsTable
            translationKey='system.general'
            title='modules'
            columnTitles={['module', 'version']}
            rows={activeModules} />
        </Row>
      </Fragment >
    );
  }
}

export const GeneralView = injectIntl(withRouter(GeneralViewBase));