import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DynamicFormDialog from '../Dynamics/DynamicFormDialog';
import { rapUsersService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { handleAPIError } from '../../util/forms';
import { injectIntl } from 'react-intl';

const PASS_PROPS = { type: 'password' };

class EditUserDialog extends Component {

  state = {
    data: {
      ...this.props.data,
      repeatPassword: this.props.data.password
    },
    allowEdit: false
  }

  componentDidMount() {
    setTimeout(() => this.setState({ allowEdit: true }), 500);
  }

  async changePassword() {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.applying' }));
    try {
      await rapUsersService.changePassword(this.state.data);
      this.props.onClose();
    } catch (err) {
      console.error('Error changing password', err);
      handleAPIError(err, 'app', this.props.intl);
    }
    eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.applying' }));
  }

  async createUser() {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.applying' }));
    try {
      await rapUsersService.create(this.state.data);
      this.props.onClose();
    } catch (err) {
      console.error('Error changing password', err);
      handleAPIError(err, 'app', this.props.intl);
    }
    eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.applying' }));
  }

  async saveUser() {
    const { id } = this.state.data;
    if (id == null) {
      this.createUser();
    } else {
      this.changePassword();
    }
  }

  render() {
    const { data } = this.state;
    if (data.login === '  ') {
      setTimeout(() => this.setState({ data: { ...data, login: '', password: '', repeatPassword: '' } }), 1000);
    }
    const { id } = data;
    const isCreation = id == null;
    return (<><form>
      <DynamicFormDialog
        spec={{
          login: { uiSection: { row: 1 }, type: isCreation ? 'string' : 'label', validators: ['required', 'identifier'], props: { autoComplete: 'new-password' } },
          password: { uiSection: { row: 2 }, type: 'string', validators: ['required'], props: { ...PASS_PROPS, autoComplete: 'new-password' } },
          repeatPassword: { uiSection: { row: 2 }, type: 'string', validators: ['required', v => v !== data.password && 'passwordDontMatch'], props: PASS_PROPS },
        }}
        autoComplete={false}
        entry={data}
        title={isCreation ? 'new' : 'edit'}
        translationKey='models.users'
        onChange={data => this.state.allowEdit && this.setState({ data })}
        onSave={() => this.saveUser()}
        onCancel={this.props.onClose}
      />
    </form></>
    );
  }
}

EditUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default EditUserDialog = injectIntl(EditUserDialog);