import { validators as allValidators } from '../Form/Validation/validation';
import ValidableFormComponent from '../Form/Validation/ValidableFormComponent';

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DynamicFormFieldSwitchElement from './Fields/DynamicFormFieldSwitchElement';

const DynamicFormField = ({ field, readOnly, value, fieldType, validationChain, validators, disabled, errors, contextValue, ...other }) => {
  let type, fieldValidators;
  if (typeof fieldType === 'string' || Array.isArray(fieldType)) {
    type = { type: fieldType };
    fieldValidators = validators || [];
  } else {
    type = fieldType;
    fieldValidators = fieldType.validators || validators || [];
    if (fieldValidators.some(v => typeof v !== 'function' && !allValidators[v])) {
      throw new Error(`Invalid validators ${field}`);
    }
  }
  if ((fieldType != null) && (typeof fieldType.enabledCondition === 'function') && !fieldType.enabledCondition(contextValue)) {
    return <Fragment></Fragment>;
  }
  const fieldElement = (
    <DynamicFormFieldSwitchElement
      {...other}
      value={value}
      contextValue={contextValue}
      type={type}
      field={field}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
  if (!disabled && ((fieldValidators && fieldValidators.length) || (errors && errors.length))) {
    return (
      <ValidableFormComponent
        field={field}
        value={value}
        validationChain={validationChain}
        validators={fieldValidators}
        errors={errors}
      >
        {fieldElement}
      </ValidableFormComponent>
    );
  } else {
    return fieldElement;
  }
};

const propTypeTypeValue = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    shortText: PropTypes.string,
    translationId: PropTypes.string
  })
]);

DynamicFormField.propTypes = {
  fieldType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      values: PropTypes.oneOfType([PropTypes.arrayOf(propTypeTypeValue), PropTypes.func]),
      validators: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })]),
  validators: PropTypes.arrayOf(PropTypes.string),
  field: PropTypes.string,
  translationKey: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  tableMode: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool
};

DynamicFormField.defaultProps = {
  tableMode: false,
  readOnly: false
};

export { DynamicFormField };