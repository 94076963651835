import React from 'react';
import { SmallIconButton } from '../../../components/Icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Warning';
import { orderBy, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import Button from '@material-ui/core/Button';
import { moduleInstancesService, devicesConfigService } from '../../../lib/service';
import { handleAPIError } from '../../../util/forms';
import { SectionTitle } from '../../../components/Layout/Page';
import { IntlDataTable } from '../../../intl-components/IntlDataTable';
import { RowStatusValues } from '../../../components/Table/DataTable';
import TextareaDialog from '../../../components/TextareaDialog';

const INSTANCE_TYPES = ['adquio_client', 'script', 'casambi'];

const prepareDeviceToSave = (source, instance, type) => {
  const device = { ...source };
  device.instance = instance;
  device.type = type;
  if (device.code !== device.localDeviceCode) {
    const remoteCode = device.code;
    device.code = device.localDeviceCode;
    device.remoteDeviceCode = remoteCode;
  }
  delete device.localDeviceCode;
  return device;
};

const prepareSearchResults = (results) => {
  return orderBy([...results], [r => (r.code || '').toLowerCase()]);
};

class ScanDevicesDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      instances: [],
      selectedInstanceCode: null,
      scanResults: null,
      selection: [],
      saveResults: null
    };
  }

  componentDidMount() {
    this.fetchInstances();
  }

  componentWillUnmount() {
  }

  fetchInstances() {
    this.setState({ loading: true });
    moduleInstancesService.find().then(data => {
      let instances = data.instances.filter(i => INSTANCE_TYPES.includes(i.type));
      instances = sortBy(instances, ['code']);
      const selectedInstanceCode = instances.length ? instances[0].code : null;
      this.setState({ instances, selectedInstanceCode, loading: false });
    }).catch(response => {
      this.setState({ loading: false });
      handleAPIError(response, 'app', this.props.intl);
    });
  }

  searchDevices() {
    const { selectedInstanceCode } = this.state;
    this.setState({ scanResults: null, loading: true });
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.scanning' }));
    moduleInstancesService.scanDevices({ instanceCode: selectedInstanceCode }).then(data => {
      let scanResults = prepareSearchResults(data);
      this.setState({
        scanResults,
        loading: false
      });
    })
      .catch(response => {
        this.setState({ loading: false });
        handleAPIError(response, 'app', this.props.intl);
      })
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.scanning' }));
      });
  }

  rowStatus(row) {
    const { saveResults } = this.state;
    const result = saveResults[row.code];
    let status = RowStatusValues.UNKNOWN;
    if (result != null) {
      if (result.ok) {
        status = RowStatusValues.OK;
      } else {
        status = RowStatusValues.ERROR;
      }
    }
    return {
      status,
      title: `models.devices.scanner.resultStatus.${status === RowStatusValues.OK ? 'success' : 'error'}`
    };
  }

  renderRowOptions(row) {
    const { saveResults, selectedInstanceCode, instances } = this.state;
    const { intl } = this.props;
    const result = (saveResults || {})[row.code];
    const instance = instances.find(i => i.code === selectedInstanceCode);

    let errorInfo = null;
    if (result != null && !result.ok) {
      errorInfo = (<SmallIconButton
        aria-owns='simple-popper'
        aria-haspopup='true'
        variant='contained'
        onClick={() => handleAPIError(result.response, 'app', intl)}
      >
        <ErrorIcon />
      </SmallIconButton>);
    }

    return (<>
      <TextareaDialog
        titleKey='models.devices.scanner.resultsTable.infoDialog.title'
        fieldKey='models.devices.scanner.resultsTable.infoDialog.field'
        data={() => JSON.stringify(prepareDeviceToSave(row, selectedInstanceCode, instance.type), null, 2)}
        ItemGetter={(onClick) =>
        (<SmallIconButton
          aria-owns='simple-popper'
          aria-haspopup='true'
          variant='contained'
          onClick={onClick}
        >
          <InfoIcon />
        </SmallIconButton>)
        }
      />
      {errorInfo}
    </>);
  }

  renderVariables(row) {
    return (row.variables || []).length;
  }

  renderLocalDeviceCode(row, idx) {
    const { selection } = this.state;
    if (!selection.includes(idx)) {
      return null;
    }
    return (<DynamicFormField
      field='localDeviceCode'
      fieldType='string'
      errors={[]}
      value={row.localDeviceCode}
      translationKey='models.devices.scanner.resultsTable'
      validators={['required']}
      validationChain={this.validationChain}
      onChange={code => this.changeDeviceLocalCode(row.code, code)}
    />);
  }

  onSelectionChange(selection) {
    this.setState({ selection });
  }

  changeDeviceLocalCode(code, localDeviceCode) {
    const { scanResults } = this.state;
    const newSearchResults = scanResults.map(r => {
      if (r.code === code) {
        return {
          ...r,
          localDeviceCode
        };
      }
      return r;
    });

    this.setState({ scanResults: newSearchResults });
  }

  async onSave() {
    const { scanResults, selection, selectedInstanceCode, instances } = this.state;
    for (let idx = 0; idx < scanResults.length; idx++) {
      const device = scanResults[idx];
      if (selection.includes(idx)) {
        const loadingMessage = this.props.intl.formatMessage({ id: 'loading.creatingDevice' }, { device: device.code });
        eventBus.publish(eventBusTopics.LOADING_START, loadingMessage);

        const instance = instances.find(i => i.code === selectedInstanceCode);
        const deviceData = prepareDeviceToSave(device, selectedInstanceCode, instance.type);
        await devicesConfigService.create(deviceData).then(data => {
          this.setState({ loading: false, saveResults: { ...this.state.saveResults, [device.code]: { ok: 1 } } });
        })
          .catch(response => {
            this.setState({ loading: false, saveResults: { ...this.state.saveResults, [device.code]: { ok: 0, response } } });
            //handleAPIError(response, 'models.devices', this.props.intl);
          })
          .finally(() => {
            eventBus.publish(eventBusTopics.LOADING_END, loadingMessage);
          });
      }
    }
    const hasErrors = Object.values(this.state.saveResults).some(r => !r.ok);
    if (hasErrors) {
      eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'ErrorSavingRemoteDevices' });
    } else {
      this.props.onImport();
    }
  }

  render() {
    const { instances, selectedInstanceCode, scanResults, selection, saveResults, loading } = this.state;
    this.validationChain = new ValidationChain();
    const operationFinished = saveResults != null;
    const columns = [
      { data: 'code' },
      { columnKey: 'localDeviceCode', data: operationFinished ? 'localDeviceCode' : this.renderLocalDeviceCode.bind(this) },
      { data: 'name' },
      { data: 'type', translationKey: 'models.devices.form.type' },
      { columnKey: 'variables', data: this.renderVariables.bind(this) },
      { data: this.renderRowOptions.bind(this) }
    ];

    return (
      <Dialog
        aria-labelledby='scan-devices-title'
        aria-describedby='scan-devices-title'
        open={true}
        disableBackdropClick={true}
        maxWidth='lg'
        fullWidth={true}
      >
        <DialogTitle id='scan-devices-title'>
          <FormattedMessage id='models.devices.scanner.title' />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row MultiColumns>
              <DynamicFormField
                field='instance'
                fieldType={{ type: 'select', values: instances.map(i => i.code) }}
                value={selectedInstanceCode}
                translationKey='models.devices.scanner.form'
                onChange={x => this.setState({ selectedInstanceCode: x })}
                disabled={operationFinished}
              />
              <Button variant='contained' onClick={this.searchDevices.bind(this)} disabled={selectedInstanceCode == null || operationFinished}>
                <FormattedMessage id='app.scan' />
              </Button>
            </Row>
            {scanResults != null &&
              <Row>
                <SectionTitle id='app.results' />
                <IntlDataTable
                  key='scan-devices-table'
                  enableMultipleSelection={!operationFinished}
                  onSelectionChange={this.onSelectionChange.bind(this)}
                  translationKey='models.devices.scanner.resultsTable'
                  columns={columns}
                  loading={loading}
                  data={scanResults}
                  rowStatus={operationFinished ? this.rowStatus.bind(this) : undefined}
                  onRowClick={undefined}
                />
              </Row>
            }
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={this.props.onClose}>
            <FormattedMessage id='app.cancel' />
          </Button>
          <Button variant='contained' color='primary' onClick={() => this.onSave()} disabled={scanResults == null || operationFinished || selection.length === 0}>
            <FormattedMessage id={'app.import'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ScanDevicesDialog.propTypes = {
  onImport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default injectIntl(ScanDevicesDialog);