import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from '@material-ui/core/InputAdornment';
import ValueConvertersEditorDialog from './ValueConvertersEditorDialog';
import { IntlTextField } from '../../../intl-components/Form';
import { SmallIconButton } from '../../Icons';

class ValueConvertersFormField extends React.Component {

  state = {
    showingDialog: false
  }

  changeValue(value) {
    this.props.onChange(value);
    this.setState({ showingDialog: false });
  }

  render() {
    const { tableMode, value, contextValue, disabled, field, translationKey, isImportValueConverter, availableConverters } = this.props;
    const margin = tableMode ? 'dense' : 'normal';
    const plainContent = (value || []).join(', ');
    let content, title;
    if (tableMode) {
      content = `${(value || []).length} items`;
      title = plainContent;
    } else {
      content = plainContent;
      title = undefined;
    }

    return (
      <Fragment>
        <IntlTextField
          label={translationKey + '.' + field}
          value={content}
          margin={margin}
          onClick={() => this.setState({ showingDialog: true })}
          InputProps={{
            title,
            readOnly: true,
            disabled: true,
            endAdornment: (
              <InputAdornment key='value-converter-adornment' position='end'>
                <SmallIconButton
                  aria-owns='simple-popper'
                  aria-haspopup='true'
                  variant='contained'
                  disabled={disabled}
                >
                  <EditIcon />
                </SmallIconButton>
              </InputAdornment>
            )
          }}
        />
        {
          this.state.showingDialog &&
          <ValueConvertersEditorDialog
            value={value || []}
            dataType={contextValue.dataType}
            availableConverters={availableConverters}
            contextValue={contextValue}
            margin={margin}
            label={`components.valueConvertersEditor.form.${field}`}
            translationKey='components.valueConvertersEditor'
            onCancel={() => this.setState({ showingDialog: false })}
            onSave={(value) => this.changeValue(value)}
            isImportValueConverter={isImportValueConverter}
          />
        }
      </Fragment>
    );
  }
}

ValueConvertersFormField.propTypes = {
  availableConverters: PropTypes.array.isRequired,
  value: PropTypes.array,
  isImportValueConverter: PropTypes.bool.isRequired
};

export default ValueConvertersFormField;
