import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { UserZoneLabel } from './UserZoneLabel';
import { Menu, MenuItem } from '@material-ui/core';
import { getLanguage, setLanguage } from '../../../util/language';

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

const StyledMenu = styled(Menu)`
  a {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
`;

const LangIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const LanguageUserZoneLabel = styled(UserZoneLabel)`
  width: 29px;
  height: 29px;
  border-radius: 3px;
  background-color: #565b6a;
  padding: 3px;
`;

const langIcon = (language) => (<LangIcon alt="logo" src={`img/languages/${language}.svg`} />);

export class LanguageSelector extends Component {

  state = {
    anchorEl: null,
    language: getLanguage()
  }

  setLanguage(value) {
    if (setLanguage(value)) {
      this.setState({ language: value, anchorEl: null });
      window.location.reload();
    }
  }

  render() {
    const { anchorEl, language } = this.state;
    const Flag = langIcon(language);
    return (
      <Fragment>
        <LanguageUserZoneLabel
          aria-owns={anchorEl ? 'dropper-menu' : null}
          aria-haspopup='true'
          onClick={e => this.setState({ anchorEl: e.currentTarget })}
        >{Flag}</LanguageUserZoneLabel>
        <StyledMenu
          id='dropper-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          <MenuItem onClick={() => this.setLanguage('es')}>
            <StyledListItemIcon>
              {langIcon('es')}
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='languages.es' />
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => this.setLanguage('en')}>
            <StyledListItemIcon>
              {langIcon('en')}
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='languages.en' />
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => this.setLanguage('fr')}>
            <StyledListItemIcon>
              {langIcon('fr')}
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='languages.fr' />
            </ListItemText>
          </MenuItem>
        </StyledMenu>

      </Fragment >
    );
  }

}