import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import eventBus, { eventBusTopics } from '../lib/eventBus';

import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import routes from '../routes';
import classNames from 'classnames';
import { FixedActionBar } from '../components/GridModules/FixedActionBar';
import UserZone from '../components/Layout/UserZone/UserZone';
import { AppPageContent } from '../components/Layout/AppPageContent/AppPageContent';
import StyleVariables from '../theme/StyleVariables'; /* Import variables to use inside material ui theme styles */
import { AppMenu } from '../components/Layout/AppDrawer/components/AppMenu';
import Clock from '../components/Layout/UserZone/Clock';
import { UserManual } from '../components/Layout/UserZone/UserManual';
import UnsavedChanges from './UnsavedChanges';
import Version from './Version';
import { LanguageSelector } from '../components/Layout/UserZone/LanguageSelector';

const AppLogo = styled.div`
  width: ${props => 160 + props.theme.MarginSize.replace('px', '') * 2}px;
  height: 45px;
  img {
    margin-left: ${props => props.theme.MarginSize};
    margin-right: ${props => props.theme.MarginSize};
  }
  span {
    position: relative;
    top: -15px;
    float: right;
    margin-right: ${props => props.theme.MarginSize};
  }
`;

const UserZoneWrapper = styled.div`
  color: ${props => props.theme.CountertypeTextColor};
  position: absolute;
  display: inline-flex;
  right: ${props => props.theme.DoubleMarginSize};
  > * {
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.MarginSize};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${props => props.theme.CountertypeTextColor};
    &:hover {
      background-color: ${props => props.theme.BgDarkHoverColor};
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  ${props => props.open && css`
    ~ ${AppPageContent} ${FixedActionBar} {
      width: calc(100% - ${props => props.theme.AppSidebarExpandedWidth});
      left: ${props => props.theme.AppSidebarExpandedWidth};
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
  `}
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    height: '100vh',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    backgroundColor: StyleVariables.BgDarkColor,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    justifyContent: 'space-between',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: StyleVariables.AppSidebarExpandedWidth,
    width: `calc(100% - ${StyleVariables.AppSidebarExpandedWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    backgroundColor: StyleVariables.BgDarkColor,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: StyleVariables.AppSidebarExpandedWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

const ConnectionAlert = styled.div`
  padding: 10px;
  text-align: center;
  height: 100%;
  font-size: 120%;
  background: repeating-linear-gradient(
    -45deg,
    rgba(0,0,0,0),
    rgba(0,0,0,0) 8px,
    #880 8px,
    #880 16px
  );
`;

class Layout extends React.Component {

  componentDidMount() {
    this.subscriptions = [
      eventBus.subscribe(eventBusTopics.SERVER_CONNECTION, connected => {
        connected !== this.state.connected && this.setState({ connected });
      })
    ];
  }

  componentWillUnmount() {
    eventBus.unsubscribe(this.subscriptions);
  }

  state = {
    open: false,
    connected: true,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { user = {} } = this.props;
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          key="layout_header"
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              key="toolbar_open"
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <AppLogo>
              <img alt="logo" src="img/corporative/adquio_logo_horizontal_ct.svg" />
              <Version />
            </AppLogo>
            {!this.state.connected &&
              <ConnectionAlert>
                <FormattedMessage id="app.connectionLost" />
              </ConnectionAlert>
            }
            <UserZoneWrapper>
              <UnsavedChanges />
              <Clock />
              <UserManual />
              <LanguageSelector />
              <UserZone user={user} />
            </UserZoneWrapper>
          </Toolbar>
        </AppBar>
        <StyledDrawer
          variant="permanent"
          classes={{ paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose), }}
          open={open}
          key="layout_sidebar"
        >
          <div key="toolbar" className={classes.toolbar}>
            <StyledIconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </StyledIconButton>
          </div>
          <Divider />
          <AppMenu key="layout_sidebar" expanded={open} navigationLinks={routes.filter(r => r.navigation)} />
        </StyledDrawer>
        <AppPageContent key="layout_content">
          {this.props.children}
        </AppPageContent>
      </div>
    );
  }
}

Layout.propTypes = {
  user: PropTypes.any,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const RouterLayout = withRouter(Layout);
export default withStyles(styles, { withTheme: true })(RouterLayout);
