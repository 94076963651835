export const downloadFromBase64 = (b64, name) => {
  const link = document.createElement('a');
  link.href = 'data:application/octet-stream;base64,' + b64;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export const fileToBase64 = (file) => {
  const fr = new FileReader();
  fr.readAsDataURL(file);
  return new Promise((res, rej) => {
    fr.onload = () => res(fr.result.split(',')[1]);
    fr.onerror = rej;
  });
};