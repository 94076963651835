import styled from 'styled-components';
import PropTypes from 'prop-types';

const ColorCircle = styled.div`
  height: ${props => props.size ? props.size : 10}px;
  width: ${props => props.size ? props.size : 10}px;
  background-color: ${props => props.color ? props.color : COLORS.GRAY};
  opacity: .6;
  border-radius: 50%;
  display: inline-block;
`;

ColorCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

const COLORS = {
  RED: '#FF0000',
  GRAY: '#BBBBBB',
  GREEN: '#00FF00',
  YELLOW: '#F2EE5B',
  ORANGE: '#F9DA46',
  BLACK: '#000000'
};

export { ColorCircle, COLORS };
