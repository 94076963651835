import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Row } from '../GridModules/Row';
import { PageWrapper, Root } from './Page';
import { Modal } from '@material-ui/core';

const normalizeMessage = (data) => {
  if ((typeof data === 'string') || (data === undefined)) {
    return { message: data, showIcon: true };
  } else {
    return { message: data.message || '', showIcon: !!data.showIcon };
  }
};

class PageLoader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      messages: []
    };
  }

  componentDidMount() {
    this.subscriptions = [
      eventBus.subscribe(eventBusTopics.LOADING_START, data => {
        data = normalizeMessage(data);
        const messages = this.state.messages;
        if (!messages.find(m => isEqual(m, data))) {
          this.setState({ messages: [...messages, data] });
        }
        this.setState({ count: this.state.count + 1 });
      }),
      eventBus.subscribe(eventBusTopics.LOADING_END, data => {
        data = normalizeMessage(data);
        const messages = this.state.messages;
        this.setState({ messages: messages.filter(m => !isEqual(m, data)) });
        if (this.state.count > 0) {
          this.setState({ count: this.state.count - 1 });
        }
      })
    ];
  }

  componentWillUnmount() {
    eventBus.unsubscribe(this.subscriptions);
  }

  render() {
    if (this.state.count === 0) { return null; }
    const showIcon = this.state.messages.some(d => !!d.showIcon);
    return (
      <Modal
        open={true}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <PageWrapper>
          <ModalRoot>
            <LoadingWrapper>
              {showIcon && <Loader size={48} />}
              {this.state.messages.length === 0 &&
                <Row id="loading-modal-title"><FormattedMessage id="app.loading" /></Row>
              }
              {this.state.messages.map((data, i) => <Row key={'message_' + i}>{data.message}</Row>)}
            </LoadingWrapper>
          </ModalRoot>
        </PageWrapper>
      </Modal>
    );
  }
}

PageLoader.propTypes = {};

const ModalRoot = styled(Root)`
  background-color: white;
  padding: ${props => props.theme.MarginSize};
`;

const LoadingWrapper = styled(Paper)`
  && {
    text-align: center;
    box-shadow: none;
    background-color: transparent;
  }
`;

const Loader = styled(CircularProgress)`
  margin-bottom: ${props => props.theme.MarginSize};
`;

export default PageLoader;