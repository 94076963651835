const dayEntryToString = (dayEntry) => {
  if (!dayEntry) return '--';
  const { day, month, year } = dayEntry;
  return `${day}/${month + 1}/${year ? year : '****'}`;
};


const dayListToString = days => {
  if (!days) return '';
  if (days.length > 3) return `#${days.length}`;
  return sortDays(days).map(dayEntryToString).join(', ');
};


const scheduleTimeToString = time => {
  // TODO prepare better representation
  return JSON.stringify(time);
};

const zipArrayOfPairs = array =>
  array.reduce((res, [key, val]) => Object.assign(res, { [key]: val }), {});

const isNumber = string => string !== undefined && string !== null && Number.isFinite(Number(string));
export const sortDays = days => days.sort((day1, day2) => (day1.month * 100 + day1.day) - (day2.month * 100 + day2.day));
export const apiDaysToUI = days => days.map(i => ({ ...i, month: isNumber(i.month) ? i.month + 1 : i.month }));
export const uiDaysToAPI = days => days.map(i => ({ ...i, month: isNumber(i.month) ? i.month - 1 : i.month }));

export { dayEntryToString, dayListToString, scheduleTimeToString, zipArrayOfPairs };