import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { scriptContext } from './scripts-context';
import { TypeLabel } from './ScriptHelp/TypeLabel';
import { MethodLabel, DescriptionText } from './ScriptHelp/MethodLabel';
import { TypeDocumentation } from './ScriptHelp/TypeDocumentation';
import { Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';

const StyledListSubheader = styled(ListSubheader)`
  && {
    padding-left: 0;
    padding-right: 0;
    background-color: ${props => props.theme.BgEditorGrayColor};
  }
`;

const MethodAndObjectName = styled.span``;

const DropDownAnimation = keyframes`
  0% {
    top: -24px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    flex-wrap: wrap;
    align-items: flex-start;
    font-family: monospace;
    padding: ${props => props.theme.HalfMarginSize};
    > ${MethodAndObjectName} {
      margin-right: ${props => props.theme.QuarterMarginSize};
    }
    span + ul {
      width: 100%;
      > li {
        animation: ${DropDownAnimation} .3s ease;
      }
    }
  }
`;

export class ScriptHelp extends React.Component {
  render() {
    const { showType } = this.props;
    return (
      <Fragment>
        <div>
          <List subheader={
            <StyledListSubheader component="div">
              <FormattedMessage id='models.scripts.scriptsContext.methodsAndObjects' />
            </StyledListSubheader>
          }>
            {Object.entries(scriptContext.objects)
              .sort((o1, o2) => o1[0].localeCompare(o2[0]))
              .map(([name, type]) => (
                <Fragment key={name}>
                  <StyledListItem >
                    <div>
                      <MethodAndObjectName>
                        {name}
                      </MethodAndObjectName>
                      {type && <TypeLabel type={type} template=' <&>' />}
                    </div>
                    <DescriptionText>
                      <FormattedMessage id={'models.scripts.scriptsContext.objects.' + name} />
                    </DescriptionText>
                  </StyledListItem>
                  <Divider />
                </Fragment>
              ))}
            <Divider />
            {scriptContext.methods
              .sort((m1, m2) => m1.name.localeCompare(m2.name))
              .map((spec, i) =>
                <Fragment key={i}>
                  <StyledListItem>
                    <MethodLabel
                      spec={spec}
                      onChangeShowType={this.onShowType}
                    />
                  </StyledListItem>
                  <Divider />
                </Fragment>
              )}
          </List>
          <List subheader={
            <StyledListSubheader component="div">
              <FormattedMessage id='models.scripts.scriptsContext.types.title' />
            </StyledListSubheader>
          }>
            {Object.keys(scriptContext.types).map(t => <TypeDocumentation key={t} type={t} />)}
          </List>
        </div>
        {!!showType && !!scriptContext.types[showType] &&
          <TypeDocumentation expanded type={showType} />
        }
      </Fragment>
    );
  }
}