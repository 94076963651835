import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { CustomizeTableDialog } from './CustomizeTableDialog';

class CustomizableDataTable extends React.Component {

  constructor(props) {
    super(props);
    if (!props.tableId) {
      throw new Error('customizeColumns is enabled but no tableId has been set, DataTable relies on tableId to store configuration');
    }
    this.state = {
      showCustomizeModal: false,
      currentSetup: JSON.parse(localStorage.getItem(`cdt_${props.tableId}`))
    };
  }


  handleNewColumns(columns) {
    localStorage.setItem(`cdt_${this.props.tableId}`, JSON.stringify(columns));
    this.setState({ currentSetup: columns, showCustomizeModal: false });
  }

  render() {
    const { tableActions, columns, translationKey, cellMapFunctions, ...props } = this.props;
    const { currentSetup } = this.state;
    const currentColumns = currentSetup ? currentSetup.map(i => columns[i]) : columns;
    const currentCellMapFns = currentSetup ? currentSetup.map(i => cellMapFunctions[i]) : cellMapFunctions;
    const customizeAction = {
      icon: <ViewColumnIcon />,
      action: () => this.setState({ showCustomizeModal: true }),
      textKey: 'app.customizeColumns.title'
    };
    return (
      <Fragment>
        <DataTable
          {...props}
          translationKey={translationKey}
          columns={currentColumns}
          cellMapFunctions={currentCellMapFns}
          tableActions={tableActions ? [customizeAction].concat(tableActions) : tableActions}
        />
        {this.state.showCustomizeModal &&
          <CustomizeTableDialog
            columns={columns}
            currentSetup={currentSetup}
            translationKey={translationKey}
            onSave={columns => this.handleNewColumns(columns)}
            onCancel={() => this.setState({ showCustomizeModal: false })}
          />
        }
      </Fragment>
    );
  }

}

CustomizableDataTable.propTypes = Object.assign({}, DataTable.propTypes, {
  tableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

CustomizableDataTable.defaultProps = {
  tableActions: []
};

export { CustomizableDataTable };