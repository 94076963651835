import React from 'react';
import { BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { FormattedMessage } from 'react-intl';

export const WrongRoute = () => {
  return (
    <BlockHeader>
      <BlockTitle>
        <FormattedMessage id="app.badRoute" />
      </BlockTitle>
    </BlockHeader>
  );
};
