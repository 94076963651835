import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from '../../components/GridModules/Row';
import { IntlSwitch, IntlCheckbox } from '../../intl-components/Form';
import DayFormEntry from './DayFormEntry';
import { ValidationChain } from '../../components/Form/Validation/ValidationChain';

const StyledRow = styled(Row)`
  padding: ${props => props.theme.QuarterMarginSize} ${props => props.theme.HalfMarginSize};
  background-color: ${props => props.theme.BgLightGreyColor};
  margin-bottom: ${props => props.theme.MarginSize};
  &:first-of-type {
    margin-top: ${props => props.theme.MarginSize};
  }
`;

export const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

const TimeSelectorFormEntry = ({ timeSelector, validationChain, onChange }) => {
  const months = timeSelector.months || [...MONTHS.keys()];
  const daysOfWeek = timeSelector.daysOfWeek || [...DAYS.keys()];
  return (
    <Fragment>
      <StyledRow>
        <IntlSwitch
          label='models.calendars.start'
          checked={!!timeSelector.start}
          onChange={(_ev, enabled) => onChange({ ...timeSelector, start: enabled ? {} : null })}
        />
        {timeSelector.start &&
          <DayFormEntry
            day={timeSelector.start}
            validationChain={validationChain}
            onChange={day => onChange({ ...timeSelector, start: day })} />
        }
      </StyledRow>
      <StyledRow>
        <IntlSwitch
          label='models.calendars.end'
          checked={!!timeSelector.end}
          onChange={(_ev, enabled) => onChange({ ...timeSelector, end: enabled ? {} : null })}
        />
        {timeSelector.end &&
          <DayFormEntry
            day={timeSelector.end}
            validationChain={validationChain}
            onChange={day => onChange({ ...timeSelector, end: day })} />
        }
      </StyledRow >
      <StyledRow TwoColumns alignTop>
        <Row>
          {DAYS.map((day, i) => (
            <IntlCheckbox
              key={day}
              label={`app.days.${day}`}
              checked={daysOfWeek.includes(i)}
              onChange={(ev) => {
                const newDays = ev.target.checked ?
                  [...daysOfWeek, i] :
                  daysOfWeek.filter(d => d !== i);
                onChange({ ...timeSelector, daysOfWeek: newDays });
              }}
            />
          ))}
        </Row>
        <Row>
          {MONTHS.map((month, i) => (
            <IntlCheckbox
              key={month}
              label={`app.months.${month}`}
              checked={months.includes(i)}
              onChange={(ev) => {
                const newDays = ev.target.checked ?
                  [...months, i] :
                  months.filter(d => d !== i);
                onChange({ ...timeSelector, months: newDays });
              }}
            />
          ))}
        </Row>
      </StyledRow >
    </Fragment>
  );
};

TimeSelectorFormEntry.propTypes = {
  timeSelector: PropTypes.shape({
    start: DayFormEntry.propTypes.day,
    end: DayFormEntry.propTypes.day,
    daysOfWeek: PropTypes.arrayOf(PropTypes.number),
    month: PropTypes.arrayOf(PropTypes.number)
  }),
  onChange: PropTypes.func,
  validationChain: PropTypes.instanceOf(ValidationChain)
};

TimeSelectorFormEntry.defaultProps = {
  timeSelector: {
    daysOfWeek: [],
    months: []
  }
};

export default TimeSelectorFormEntry;
