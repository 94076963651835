import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

const FieldName = styled.span`
  font-weight: bold;
`;

export const FieldLabel = injectIntl(({ parentType, spec, intl }) => {
  const { name, type, required } = spec;
  const descTranslationKey = 'models.scripts.scriptsContext.types.' + parentType + '.fields.' + name;
  const desc = intl.messages[descTranslationKey] ? intl.formatMessage({ id: descTranslationKey }) : undefined;
  const text = <Fragment>
    <label>{name + ': ' + type + (required ? '*' : '')}</label>
  </Fragment>;
  return (
    <Fragment>
      {desc
        ? (<label><FieldName>{text}</FieldName>. {desc}</label>)
        : <FieldName>{text}</FieldName>}
    </Fragment >
  );
});

FieldLabel.propTypes = {
  type: PropTypes.string.isRequired,
  spec: PropTypes.shape({
    name: PropTypes.string.isRequired,
    signature: PropTypes.string,
    returnType: PropTypes.string
  }).isRequired
};