/**
 * View for experiments, mocks, code samples and testing.
 * TODO: remove in production!
 */
import React, { Component } from 'react';
import VariablesTable from './Variables/VariablesTable';
import { TableBlock } from '../components/GridModules/ComponentBlock/TableBlock';
import { Row, ActionButtonsRow } from '../components/GridModules/Row';
import { ComponentBlock, BlockHeader, BlockTitle, BlockActions, BlockBody, BlockFooter } from '../components/GridModules/ComponentBlock/ComponentBlock';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import DroppedMenu from '../components/GridModules/DroppedMenu';
import Tooltip from '@material-ui/core/Tooltip';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import { FixedActionBar, PrimaryActions, SecondaryActions } from '../components/GridModules/FixedActionBar';
import { Select, InputLabel, FormControl, FormHelperText } from '@material-ui/core';

class TestView extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <div>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="Pruebas" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title="Nuevo Dispositivo">
                  <IconButton >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <DroppedMenu />
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <VariablesTable />
            </BlockBody>
            <BlockFooter>
            </BlockFooter>
          </TableBlock>
        </Row>

        <Row>
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="Nuevo dispositivo" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>
              <Stepper orientation="vertical">
                <Step>
                  <StepLabel>Tipo de dispositivo</StepLabel>
                  <StepContent>
                    <form noValidate autoComplete="off">
                      <Row MultiColumns>

                        <TextField
                          select
                          label="Select"
                          helperText="Please select your currency"
                          margin="normal">
                          <MenuItem>Option</MenuItem>
                        </TextField>
                        <TextField
                          select
                          label="Select"
                          helperText="Please select your currency"
                          margin="normal">
                          <MenuItem>Option</MenuItem>
                        </TextField>
                        <TextField
                          select
                          label="Select"
                          helperText="Please select your currency"
                          margin="normal">
                          <MenuItem>Option</MenuItem>
                        </TextField>
                      </Row>
                      <Row ChildrenDefaultWidth>
                        <TextField
                          select
                          label="Select"
                          helperText="Please select your currency"
                          margin="normal">
                          <MenuItem>Option</MenuItem>
                        </TextField>
                      </Row>
                      <Row ChildrenDefaultWidth>
                        <TextField
                          select
                          label="Select"
                          helperText="Please select your currency"
                          margin="normal">
                          <MenuItem>Option</MenuItem>
                        </TextField>
                      </Row>
                      <ActionButtonsRow MultiColumns>
                        <Button variant="contained" color="primary">
                          Continuar
                          <ExpandMoreIcon />
                        </Button>
                        <Tooltip title="Nuevo Dispositivo">
                          <IconButton >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Fab color='primary' aria-label='Add'>
                          <AddIcon />
                        </Fab>
                      </ActionButtonsRow>
                    </form>
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>Parámetros básicos del dispositivo</StepLabel>
                  <StepContent>
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>Parámetros avanzados del dispositivo</StepLabel>
                  <StepContent>
                  </StepContent>
                </Step>
              </Stepper>
            </BlockBody>
          </ComponentBlock>
        </Row>

        <Row>
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="Nuevo dispositivo" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>

              <form noValidate autoComplete="off">
                <Row MultiColumns>

                  <TextField
                    select
                    label="Select"
                    helperText="Please select your currency"
                    margin="normal">
                    <MenuItem>Option</MenuItem>
                  </TextField>
                  <TextField
                    select
                    label="Select"
                    helperText="Please select your currency"
                    margin="normal">
                    <MenuItem>Option</MenuItem>
                  </TextField>
                  <TextField
                    select
                    label="Select"
                    helperText="Please select your currency"
                    margin="normal">
                    <MenuItem>Option</MenuItem>
                  </TextField>
                </Row>
                <Row ChildrenDefaultWidth>
                  <InputLabel htmlFor="age-helper">Age</InputLabel>
                  <Select
                    select
                    label="Select de verdad"
                    helperText="Esto usa un select"
                    margin="normal">
                    <MenuItem>Option</MenuItem>
                  </Select>
                </Row>
                <FormControl>
                  <InputLabel htmlFor="age-helper">Age</InputLabel>
                  <Select
                    value={this.state.age}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>Some important helper text</FormHelperText>
                </FormControl>

                <Row ChildrenDefaultWidth>
                  <TextField
                    select
                    label="Select"
                    helperText="Please select your currency"
                    margin="normal">
                    <MenuItem>Option</MenuItem>
                  </TextField>
                </Row>
              </form>

            </BlockBody>
          </ComponentBlock>
        </Row>

        <FixedActionBar>
          <PrimaryActions>
            <Button variant="contained" color="primary">
              Guardar
            </Button>
          </PrimaryActions>
          <SecondaryActions>
            <Button variant="outlined">
              Cancelar
            </Button>
            <Button variant="contained" color="secondary">
              Eliminar
            </Button>
          </SecondaryActions>
        </FixedActionBar>

      </div>
    );
  }
}

TestView.propTypes = {
  user: PropTypes.node
};

export default TestView;
