import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { IntlSwitch } from '../../../intl-components/Form';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import Button from '@material-ui/core/Button';
import { intervalsService } from '../../../lib/service';
import { getErrorsResume, handleAPIError } from '../../../util/forms';

class IntervalForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      interval: props.interval,
      errors: undefined
    };
  }

  updateInterval(field, value) {
    this.setState({
      interval: {
        ...this.state.interval,
        [field]: value
      },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  async onSave() {
    const errors = this.validationChain.getErrors();
    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      let interval = this.state.interval;
      try {
        interval.id
          ? await intervalsService.update(interval.id, interval)
          : await intervalsService.create(interval);
        this.setState({ errors: undefined });
        this.props.onSave(this.state.interval);
      } catch (response) {
        const errors = handleAPIError(response, 'models.intervals', this.props.intl);
        this.setState({ errors });
      } finally {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      }
    }
  }

  getErrorsResume(errors) {
    let translationsKey = 'models.intervals';
    let arrayAttributesTranslationKeys = {};
    return getErrorsResume(this.props.intl, errors, translationsKey, arrayAttributesTranslationKeys);
  }

  render() {
    const { interval, errors } = this.state;
    this.validationChain = new ValidationChain();

    return (
      <Dialog
        aria-labelledby="interval-form-title"
        aria-describedby="interval-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="interval-form-title">
          {interval.id ?
            <FormattedMessage id="models.intervals.edit" /> :
            <FormattedMessage id="models.intervals.new" />
          }
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row Multicolumns>
              <IntlSwitch
                label='models.intervals.active'
                checked={!!interval.active}
                onChange={(_ev, enabled) => this.updateInterval('active', enabled)}
              />
              <DynamicFormField
                field='timerName'
                fieldType={{ type: 'string', validators: ['required', 'identifier'] }}
                validationChain={this.validationChain}
                translationKey='models.intervals'
                value={interval.timerName}
                errors={get(errors, 'timerName')}
                onChange={n => this.updateInterval('timerName', n)}
              />
            </Row>
            <Row>
              <DynamicFormField
                field='milliseconds'
                fieldType='milliseconds'
                validators={['required']}
                validationChain={this.validationChain}
                translationKey='models.intervals'
                value={interval.milliseconds}
                errors={get(errors, 'milliseconds')}
                onChange={n => this.updateInterval('milliseconds', n)}
              />
            </Row>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

IntervalForm.propTypes = {
  interval: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default injectIntl(IntervalForm);