import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { MenuItem, ListItemIcon } from '@material-ui/core';
import { DropdownMenu } from '../../components/DropdownMenu';
import { confirmRemove } from '../../components/util';
import { injectIntl } from 'react-intl';
import { actionShape } from '../../components/Table/DataTable';
import { IntlFormHeaderIcon } from '../../intl-components/Form';

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

const IconsContainer = styled.div`
  width: ${props => `${props.iconsCount * 40}`}px;
`;

const CommonFormOptions = ({ onView, onEdit, onRemove, extraOptions, forceAllInsideMenu, intl }) => {
  if ((onView || onEdit || onRemove || extraOptions)) {
    const outMenuActions = [];
    const inMenuActions = [];

    if (onView) {
      const action = {
        icon: <ViewIcon />,
        action: onView,
        textKey: 'app.see'
      };
      if (forceAllInsideMenu) {
        inMenuActions.push(action);
      } else {
        outMenuActions.push(action);
      }
    }
    if (onEdit) {
      const action = {
        icon: <EditIcon />,
        action: onEdit,
        textKey: 'app.edit'
      };
      if (forceAllInsideMenu) {
        inMenuActions.push(action);
      } else {
        outMenuActions.push(action);
      }
    }
    if (onRemove) {
      inMenuActions.push({
        icon: <DeleteIcon />,
        action: () => confirmRemove(intl, onRemove),
        textKey: 'app.remove'
      });
    }
    if (extraOptions) {
      extraOptions.forEach(o => inMenuActions.push(o));
    }
    return (
      <IconsContainer iconsCount={outMenuActions.length + 1}>
        {outMenuActions.map((op, idx) =>
          <IntlFormHeaderIcon
            key={`${idx}`}
            icon={op.icon}
            tooltip={op.textKey}
            onClick={op.action}
          ></IntlFormHeaderIcon>
        )}
        {inMenuActions.length === 0 ? null :
          <DropdownMenu>
            {inMenuActions.map((op, idx) =>
              <MenuItem key={`option-${idx}`} onClick={op.action}>
                <StyledListItemIcon>
                  {op.icon}
                </StyledListItemIcon>
                <ListItemText>
                  <FormattedMessage id={op.textKey} />
                </ListItemText>
              </MenuItem>
            )}
          </DropdownMenu>
        }
      </IconsContainer>);
  }
};

CommonFormOptions.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  extraOptions: PropTypes.arrayOf(actionShape)
};

export default injectIntl(CommonFormOptions);