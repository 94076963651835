
import { getValueConvertersObject } from '../../../lib/deviceHelpers';

const dataTypesMap = {
  'BOOL': 'bool',

  'BYTE': 'uint8',
  'SINT': 'int8',
  'USINT': 'uint8',

  'INT': 'int16',
  'UINT': 'uint16',
  'WORD': 'uint16',

  'DINT': 'int32',
  'UDINT': 'uint32',
  'DWORD': 'uint32',
  'LWORD': 'uint32',
  'REAL': 'float32',

  'LINT': 'int64',
  'ULINT': 'uint64',
  'LREAL': 'float64',
};

const inverseDataTypesMap = {
  'bool': 'BOOL',
  'uint8': 'BYTE',
  'int8': 'SINT',
  'int16': 'INT',
  'uint16': 'WORD',
  'int32': 'DINT',
  'uint32': 'UDINT',
  'int64': 'LINT',
  'uint64': 'ULINT',
  'float32': 'REAL',
  'float64': 'LREAL'
};

const dataTypeSize = {
  'bool': 1,
  'uint8': 8,
  'int8': 8,
  'int16': 16,
  'uint16': 16,
  'int32': 32,
  'uint32': 32,
  'int64': 64,
  'uint64': 64,
  'float32': 32,
  'float64': 64
};

const dataAddressLetter = {
  'bool': 'X',
  'uint8': 'B',
  'int8': 'B',
  'int16': 'W',
  'uint16': 'W',
  'int32': 'D',
  'uint32': 'D',
  'int64': 'D',
  'uint64': 'D',
  'float32': 'D',
  'float64': 'D'
};

const defaultsMap = {
  'humed': { type: 'humidity', units: 'percent' },
  'frecu': { type: 'frecuency', units: 'hertzs' },
  'tension': { type: 'voltage', units: 'volts' },
  'intensidad': { type: 'electric_current', units: 'amps' },
  'energ': { type: 'energy', units: null },
  'presi': { type: 'pressure', units: 'kilopascals' },
  'radiaci': { type: 'radiation', units: null },
  'temp': { type: 'temperature', units: 'celsius' }
};

function getDataType(plcType) {
  return dataTypesMap[plcType] || '';
}

function getUnits(code) {
  let lowerCode = code.toLowerCase();
  let units = 'no_units';
  Object.keys(defaultsMap).some(key => {
    if (lowerCode.includes(key)) {
      units = defaultsMap[key].units || units;
      return true;
    }
    return false;
  });
  return units;
}

function getType(code) {
  let lowerCode = code.toLowerCase();
  let type = 'generic';
  Object.keys(defaultsMap).some(key => {
    if (lowerCode.includes(key)) {
      type = defaultsMap[key].type;
      return true;
    }
    return false;
  });
  return type;
}

function plcst2variables(content, defaultModbusRegType, defaultCanWrite, sourceByteOrder, targetByteOrder) {
  let defaultValueConverters = [];

  let result = [];
  let variables = [];
  content.split('\n').forEach((line, index) => {

    const lineResult = { line, hasError: false, lineNumber: index + 1 };
    result.push(lineResult);
    const trimLine = line.trim();
    if ((trimLine.length === 0) || trimLine.startsWith('//') || (trimLine.startsWith('(*') && trimLine.endsWith('*)'))) {
      return;
    }

    let divisorCheck = /\*.*entre\s*([0-9.]+)([^0-9.].*)?\*/.exec(line);
    let valueDivisor = divisorCheck ? divisorCheck[1] : null;

    const lineRegex = /\s*([\d\w]*)\s*AT\s+%M(\w?)0\.(\d+)(\.(\d+))?\s*:\s*(\w+)\s*(:=[^;]+)?\s*;/;
    let match = lineRegex.exec(line);
    if (!match) {
      lineResult.hasError = true;
      return;
    }
    let code = match[1];
    let address_size = match[2];
    let address = match[3];
    let address_bit = match[5] || 0;
    let dataType = match[6];

    let position = undefined;
    let positionBit;
    let inverseByteOrder = sourceByteOrder !== targetByteOrder;

    switch (address_size) {
      case 'X':
        //size = 1;
        positionBit = Number(address) * 8 + Number(address_bit);
        position = `${Math.floor(positionBit / 16)}.${(positionBit + (inverseByteOrder ? 8 : 0)) % 16}`;
        break;
      case 'B':
        //size = 8;
        positionBit = Number(address) * 8 + Number(address_bit); //REVISE
        position = `${Math.floor(positionBit / 16)}.${(positionBit + (inverseByteOrder ? 8 : 0)) % 16}`;
        break;
      case 'W':
        //size = 16;
        position = `${Number(address)}`;
        break;
      case 'D':
        //size = 32;
        position = `${Number(address) * 2}`;
        break;
      default:
    }

    let varDataType = getDataType(dataType);

    let valueConverters = [];
    valueConverters = valueConverters.concat(defaultValueConverters);

    if (valueDivisor) {
      if (!varDataType.startsWith('float')) {
        valueConverters.push('cast(' + varDataType + ', float64)');
        varDataType = 'float64';
      }
      valueConverters.push('divide(' + valueDivisor + ')');
    }

    let variable = {
      modbusRegType: defaultModbusRegType,
      code: code,
      dataType: varDataType,
      modbusAddress: position,
      canRead: 1,
      canWrite: defaultCanWrite,
      type: getType(code),
      units: getUnits(code),
      valueConverters: valueConverters,
      name: code
    };

    variables.push(variable);
  });
  return {
    variables,
    result
  };
}

function variables2plcst(variables, sourceByteOrder, targetByteOrder) {
  let inverseByteOrder = sourceByteOrder !== targetByteOrder;

  const lines = variables.map(variable => {
    const code = (variable.code || variable.variable || '').replace(/[^a-zA-Z0-9_-]/g, '_');
    try {
      const protocolDataType = variable.protocolDataType();
      const dataType = inverseDataTypesMap[protocolDataType];
      const size = dataTypeSize[protocolDataType];
      const addressType = dataAddressLetter[protocolDataType];
      let address;
      switch (size) {
        case 1:
        case 8:
          const addressParts = variable.modbusAddress.split('.');
          let majorAddress = parseInt(addressParts[0]) * 2;
          let minorAddress = parseInt(addressParts[1] || '0');
          if (inverseByteOrder) {
            minorAddress = (minorAddress + 8) % 16;
          }
          if (minorAddress >= 8) {
            majorAddress++;
            minorAddress -= 8;
          }
          address = `${majorAddress}.${minorAddress}`;
          break;
        case 16:
          address = variable.modbusAddress;
          break;
        case 32:
        case 64:
          address = `${parseInt(variable.modbusAddress.split('.')[0]) / 2}`;
          break;
        default:
          break;
      }
      let comment = '';
      try {
        if (variable.valueConverters) {
          const divideConverter = getValueConvertersObject(variable.valueConverters).find(vc => vc.code === 'divide');
          if (divideConverter) {
            comment = `(*Dividir entre${divideConverter.params}*)`;
          }
        }
      } catch (err) {
        console.log('Error processing converters: ' + err);
      }

      return `  ${code.padEnd(32, ' ')} AT %M${addressType}0.${address} : ${dataType};  ${comment}`;
    } catch (err) {
      return `(* Variable ${code} could not be exported *)`;
    }
  });
  return lines.join('\n');
}

export { plcst2variables, variables2plcst };