import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import eventBus, { eventBusTopics } from './eventBus';

const endpoint = process.env.NODE_ENV === 'development' ? 'localhost:8080' : '';

export const client = feathers();

// This code is documenting use for REST API
// const restClient = feathers.rest(`http://${endpoint}/api/`);
// client.configure(restClient.fetch(window.fetch));
const basePath = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) + '/';
export const socket = io(endpoint, { path: basePath + 'api/ws/' });
client.configure(feathers.socketio(socket));
client.configure(feathers.authentication({
  storage: window.localStorage
}));

export const authentication = client.service('authentication');
export const usersService = client.service('users');
export const rapUsersService = client.service('rapUsers');
export const variablesService = client.service('variables');
export const hwPortsService = client.service('hwPorts');
export const moduleInstancesService = client.service('moduleInstances');
export const statsItemsService = client.service('statsItems');
export const emonItemsService = client.service('emonItems');
export const runningInstancesService = client.service('runningInstances');
export const devicesService = client.service('devices');
export const exportDevicesService = client.service('exportDevices');
export const devicesConfigService = client.service('deviceConfigs');
export const schedulesService = client.service('schedules');
export const calendarsService = client.service('calendars');
export const intervalsService = client.service('intervals');
export const scriptsService = client.service('scripts');
export const dataTypesService = client.service('dataTypes');
export const variableTypesService = client.service('variableTypes');
export const valueConvertersService = client.service('valueConverters');
export const unitsService = client.service('units');
export const alertsService = client.service('alerts');
export const logsService = client.service('logs');
export const timeService = client.service('time');
export const automaticAlertsService = client.service('automaticAlerts');
export const collectionsService = client.service('collections');
export const acsService = client.service('acs');
export const systemService = client.service('system');

Object.keys(client.services).forEach(path => client.service(path).timeout = 20000);

// Custom methods, the feathers-custom-methods library causes problems with minifying from webpack
devicesConfigService.checkPendingConfigurationChanges = () => devicesConfigService.create({ method: 'checkPendingConfigurationChanges' });
devicesConfigService.apply = () => devicesConfigService.create({ method: 'apply' });
devicesConfigService.revert = () => devicesConfigService.create({ method: 'revert' });
devicesConfigService.migrate = ({ type, json, code }) => devicesConfigService.create({ method: 'migrate', data: { type, json, code } });
devicesConfigService.autoConfigureIOPort = ({ port }) => devicesConfigService.create({ method: 'autoConfigureIOPort', data: { port } });
devicesConfigService.timeout = 30 * 1000;
variablesService.list = () => variablesService.create({ method: 'list' });
scriptsService.newScript = (data) => scriptsService.create({ method: 'newScript', data });
scriptsService.enableScriptWatch = (data) => scriptsService.create({ method: 'enableScriptWatch', data });
scriptsService.getStatuses = () => scriptsService.create({ method: 'getStatuses' });
scriptsService.restart = (data) => scriptsService.create({ method: 'restart', data });
runningInstancesService.start = (data) => runningInstancesService.create({ method: 'start', data });
runningInstancesService.stop = (data) => runningInstancesService.create({ method: 'stop', data });
runningInstancesService.restart = (data) => runningInstancesService.create({ method: 'restart', data });
moduleInstancesService.getAvailableTypes = () => moduleInstancesService.create({ method: 'getAvailableTypes' });
moduleInstancesService.scanDevices = (data) => moduleInstancesService.create({ method: 'scanDevices', data });
moduleInstancesService.timeout = 300 * 1000;
usersService.changePassword = data => usersService.create({ method: 'updatePassword', data });
rapUsersService.changePassword = data => rapUsersService.create({ method: 'updatePassword', data });
logsService.enableLogWatch = (data) => logsService.create({ method: 'enableLogWatch', data });
systemService.timeout = 60000;
systemService.operation = (method, data) => systemService.update(method, data);

export let schemaVersion = 5;
client.on('authenticated', () =>
  devicesConfigService.create({ method: 'schemaVersion' }).then(v => {
    if (v < schemaVersion) {
      eventBus.publish(eventBusTopics.DISABLE_UI_ERROR, { alertId: 'outdatedCore' });
    }
  })
);
