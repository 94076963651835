import StyleVariables from './StyleVariables';
import { lighten, darken } from 'polished';
import { createMuiTheme } from '@material-ui/core/styles';

export const MUIVariables = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: lighten(0.1, StyleVariables.PrimaryColor),
      main: StyleVariables.PrimaryColor,
      dark: darken(0.1, StyleVariables.PrimaryColor),
      contrastText: StyleVariables.CountertypeTextColor,
    },
    secondary: {
      light: lighten(0.1, StyleVariables.SecondaryColor),
      main: StyleVariables.SecondaryColor,
      dark: darken(0.1, StyleVariables.SecondaryColor),
      contrastText: StyleVariables.CountertypeTextColor,
    },
  },
});
