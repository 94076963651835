import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { Tooltip, IconButton } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import RefreshIcon from '@material-ui/icons/Refresh';
import { hwPortsService, devicesConfigService, moduleInstancesService } from '../../lib/service';
import { Row } from '../../components/GridModules/Row';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { BlockHeader, BlockTitle, BlockBody, BlockActions, ComponentBlock } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { SectionTitle } from '../../components/Layout/Page';
import { withRouter } from 'react-router';
import { IntlDataTable } from '../../intl-components/IntlDataTable';
import { sortBy } from 'lodash';
import { handleAPIError } from '../../util/forms';
import { confirm } from '../../components/util';
import { FAIconButton, ICONS } from '../../components/Table/FAIconButton';

const PORT_TYPES = ['IO', '485'];

class ExpansionViewBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = { loading: true, ports: [] };
  }

  pendingChangesEventHandler() {
    this.fetchData();
  }

  componentDidMount() {
    this.fetchData();
    devicesConfigService.on('PendingConfigurationChanges', this.pendingChangesEventHandler.bind(this));
    devicesConfigService.on('ExternalPortsChanges', this.pendingChangesEventHandler.bind(this));
  }

  componentWillUnmount() {
    devicesConfigService.removeListener('PendingConfigurationChanges', this.pendingChangesEventHandler.bind(this));
    devicesConfigService.removeListener('ExternalPortsChanges', this.pendingChangesEventHandler.bind(this));
  }

  async fetchData() {
    const { intl } = this.props;
    try {
      this.setState({ loading: true });
      const devConfig = devicesConfigService.find();
      const modConfig = moduleInstancesService.find();

      let ports = (await hwPortsService.find())
        .filter(p => PORT_TYPES.includes(p.type));
      ports = sortBy(ports, ['type', 'code']);

      const { instances } = await modConfig;
      const { devices } = await devConfig;

      ports.forEach(p => {
        const portInstances = instances.filter(i => i.port === p.code);
        const portInstanceCodes = portInstances.map(i => i.code);
        p.instances = portInstanceCodes;

        const portDevices = devices.filter(d => portInstanceCodes.includes(d.instance));
        p.devices = portDevices.map(i => i.code);

        p.showDeviceCreation = (p.devices.length === 0);
      });

      this.setState({ ports, devices, instances, loading: false });
    } catch (e) {
      handleAPIError(e, 'app', intl);
      this.setState({ loading: false });
    }
  }

  autoConfigure(port) {
    const { intl } = this.props;
    confirm(intl, 'system.expansions.autoConfigureConfirm', () => {
      devicesConfigService.autoConfigureIOPort({ port })
        .then(_ => {
          /*  this.fetchData();*/
        })
        .catch(response => {
          handleAPIError(response, 'system.expansions', intl);
        });
    });
  }

  renderInstances(row) {
    return (
      <ComponentBlock>
        {row.instances.map(instancesCode =>
          <div>
            <label>{`${instancesCode}`}</label>
            <FAIconButton
              icon={ICONS.faExternalLinkAlt}
              onClick={() => this.props.history.push(`/devices/module/${instancesCode}`)}
            />
          </div>
        )}
      </ComponentBlock>
    );
  }

  renderPort(row) {
    return (
      <>{`${row.code} (${row.port})`}</>
    );
  }

  renderDevices(row) {
    return (
      <ComponentBlock>
        {row.devices.map(deviceCode =>
          <div>
            <label>{`${deviceCode}`}</label>
            <FAIconButton
              icon={ICONS.faExternalLinkAlt}
              onClick={() => this.props.history.push(`/devices/device/${deviceCode}`)}
            />
          </div>
        )}
      </ComponentBlock>
    );
  }

  renderOptions(row) {
    if (row.showDeviceCreation && row.type === 'IO') {
      return (
        <ComponentBlock>
          <Button
            variant='contained'
            color='primary'
            onClick={() => this.autoConfigure(row.code)}>
            <FormattedMessage id={'system.expansions.autoConfigure'} />
          </Button>
        </ComponentBlock>
      );
    }
  }

  render() {
    const { loading, ports } = this.state;
    let content;
    const intl = this.props.intl;
    const columns = [
      { data: 'type' },
      {
        columnKey: 'port',
        data: this.renderPort.bind(this)
      },
      { data: 'product' },
      {
        columnKey: 'instances',
        data: this.renderInstances.bind(this)
      },
      {
        columnKey: 'devices',
        data: this.renderDevices.bind(this)
      },
      {
        columnKey: 'options',
        data: this.renderOptions.bind(this)
      }];

    const data = ports.map((p) => {
      return {
        ...p,
        options: null
      };
    });
    content = (
      <Row>
        <TableBlock>
          <BlockHeader>
            <BlockTitle>
            </BlockTitle>
            <BlockActions>
              <Tooltip title={intl.formatMessage({ id: 'app.refresh' })} >
                <IconButton onClick={() => this.fetchData()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <IntlDataTable
              onChange={() => { }}
              translationKey='system.expansions.columns'
              columnTranslationKeys={['system.expansions.types', null, null, null, null]}
              columns={columns}
              data={data}
              loading={loading}
            />
          </BlockBody>
        </TableBlock>
      </Row>);
    return (
      <Fragment>
        <SectionTitle id='system.expansions.title' />
        {content}
      </Fragment>
    );
  }
}

export const ExpansionView = withRouter(injectIntl(ExpansionViewBase));