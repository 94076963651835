import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { Row } from '../../../components/GridModules/Row';
import { IntlSelect, IntlCheckbox, IntlMonospacedTextField } from '../../../intl-components/Form';
import { AlertParagraph } from '../../../components/Form/Form';
import { plcst2variables } from './plcst_utils';
import ImportPLCErrorsDialog from './ImportPLCErrorsDialog';

const ADQUIO_BYTE_ORDER = 'little_endian';

class ImportPLCVariables extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      text: '// Sample:\n//\n//    VAR1   AT %MD0.2 : DINT;\n//    VAR2   AT %MB0.12.0 : SINT;\n//    VAR3   AT %MB0.12.8 : SINT;\n\n',
      modbusRegType: 'holding_register',
      byteOrder: 'big_endian',
      canWrite: true,

      showingErrors: false,
      errors: {
        lines: []
      }
    };
  }

  onImport() {
    const { text, modbusRegType, byteOrder, canWrite, showingErrors } = this.state;
    const finalCanWrite = canWrite && (modbusRegType !== 'input_register');
    const { variables, result } = plcst2variables(text, modbusRegType, finalCanWrite, byteOrder, ADQUIO_BYTE_ORDER);
    const errorLines = result.filter(({ hasError }) => hasError).map(({ line, lineNumber }) => `${lineNumber + 1}. ${line}`);
    if (showingErrors || (errorLines.length === 0)) {
      this.setState({ showingErrors: false });
      this.props.onImport(variables);
    } else {
      this.setState({ showingErrors: true, errors: { lines: errorLines } });
    }
  }

  render() {
    const { text, modbusRegType, canWrite, byteOrder, showingErrors, errors } = this.state;

    return (
      <>
        <Dialog
          aria-labelledby="import-plcst-form-title"
          aria-describedby="import-plcst-form-title"
          open={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="import-plcst-form-title">
            <FormattedMessage id="models.variables.plcst.import.title" />
          </DialogTitle>
          <DialogContent>
            <FormGroup>
              <Row>
                <IntlMonospacedTextField
                  label="models.variables.plcst.import.form.plcst"
                  helperText="models.variables.plcst.import.form.plcstHelperText"
                  multiline
                  rows="16"
                  margin="normal"
                  value={text}
                  onChange={ev => this.setState({ ...this.state, text: ev.target.value })}
                />
              </Row>
              <Row>
                <IntlSelect
                  value={modbusRegType}
                  onChange={ev => this.setState({ ...this.state, modbusRegType: ev.target.value })}
                  label="models.variables.plcst.import.form.modbusRegType"
                  margin="normal">
                  {['holding_register', 'input_register'].map(d =>
                    <MenuItem key={`modbus-regtype-${d}`} value={d}>{d}</MenuItem>
                  )}
                </IntlSelect>
              </Row>
              <Row>
                <IntlCheckbox
                  label='models.variables.plcst.import.form.canWrite'
                  checked={canWrite && (modbusRegType !== 'input_register')}
                  disabled={(modbusRegType === 'input_register')}
                  onChange={(ev, checked) => this.setState({ ...this.state, canWrite: checked })}
                />
              </Row>
              <Row>
                <IntlSelect
                  value={byteOrder}
                  onChange={ev => this.setState({ ...this.state, byteOrder: ev.target.value })}
                  label="models.variables.plcst.import.form.byteOrder"
                  helperText="models.variables.plcst.import.form.byteOrderHelperText"
                  margin="normal">
                  {['big_endian', 'little_endian'].map(d =>
                    <MenuItem key={`byte-order-${d}`} value={d}>{d}</MenuItem>
                  )}
                </IntlSelect>
              </Row>
              <AlertParagraph>
                <FormattedMessage id='models.variables.plcst.endianMessage' values={{ br: <br /> }} />
              </AlertParagraph>
            </FormGroup>
          </DialogContent >
          <DialogActions>
            <Button variant="outlined" onClick={this.props.onCancel}>
              <FormattedMessage id="app.cancel" />
            </Button>
            <Button variant="contained" color="primary" onClick={() => this.onImport()}>
              <FormattedMessage id={'app.import'} />
            </Button>
          </DialogActions>
        </Dialog >
        {showingErrors &&
          <ImportPLCErrorsDialog
            titleKey='models.variables.plcst.import.form.errors.title'
            messageKey='models.variables.plcst.import.form.errors.message'
            fieldKey='models.variables.plcst.import.form.errors.errors'
            onConfirm={() => this.onImport()}
            onCancel={() => this.setState({ showingErrors: false })}
            content={errors.lines.join('\n')}
          ></ImportPLCErrorsDialog>
        }
      </>
    );
  }
}

ImportPLCVariables.propTypes = {
  onImport: PropTypes.func,
  onClose: PropTypes.func
};

export default ImportPLCVariables;