import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { IntlCheckbox } from '../../../intl-components/Form';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import { timeZoneAware } from '../../../components/TimeZoneAware';

const allSections = ['id', 'net', 'devices', 'config', 'scripts', 'screens'];

export const BackupRestoreModal = ({ timestamp, mode, availableSections = allSections, onCancel, onConfirm, hidePassword }) => {
  const [sections, updateSections] = useState(availableSections.filter(s => s !== 'id'));
  const [pass, updatePass] = useState('');
  const [repeatPass, updateRepeatPass] = useState('');
  const vc = new ValidationChain();
  return (
    <Dialog
      open disableBackdropClick
      aria-labelledby="diag-title"
      aria-describedby="diag-title"
    >
      <DialogTitle id="diag-title">
        <FormattedMessage id={`system.${mode}.dialogTitle`} />
      </DialogTitle>
      <DialogContent>
        {timestamp &&
          <Row MultiColumns>
            <FormattedMessage id='system.restore.date' />
            <FormattedTimestamp timestamp={timestamp} />
          </Row>
        }
        <Row>
          {allSections.map(section => (
            <IntlCheckbox
              key={section}
              label={'system.backup.sections.' + section}
              checked={sections.includes(section)}
              disabled={!availableSections.includes(section)}
              onChange={(_ev, enabled) => {
                if (enabled) updateSections([...sections, section]);
                else updateSections(sections.filter(s => s !== section));
              }}
            />
          ))}
        </Row>
        {!hidePassword && <Row>
          <DynamicFormField
            value={pass}
            field='password'
            fieldType={{ type: 'string', props: { type: 'password' } }}
            translationKey='system.backup'
            onChange={updatePass}
          />
        </Row>}
        {!hidePassword && mode === 'backup' && <Row>
          <DynamicFormField
            value={repeatPass}
            field='repeatPassword'
            fieldType={{
              type: 'string',
              validators: [v => v !== pass && 'passwordDontMatch'],
              props: { type: 'password' }
            }}
            validationChain={vc}
            translationKey='system.backup'
            onChange={updateRepeatPass}
          />
        </Row>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          <FormattedMessage id="app.cancel" />
        </Button>
        <Button
          variant="contained" color="primary"
          disabled={sections.length === 0}
          onClick={() => Object.keys(vc.getAndDisplayErrors('system.backup')).length || onConfirm(sections, pass)}
        >
          <FormattedMessage id={'app.save'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BackupRestoreModal.propTypes = {
  timestamp: PropTypes.number,
  mode: PropTypes.oneOf(['backup', 'restore']).isRequired,
  availableSections: PropTypes.arrayOf(PropTypes.string),
  hidePassword: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const FormattedTimestamp = timeZoneAware(({ timezone, timestamp }) =>
  <FormattedDate
    timeZone={timezone}
    value={timestamp}
    year='numeric'
    month='2-digit'
    day='2-digit'
    hour='2-digit'
    minute='2-digit'
    second='2-digit'
  />);