import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TypeLabel } from './TypeLabel';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import SnippetsDialog from '../../../components/SnippetsDialog';

export const DescriptionText = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 16px;
  color: gray;
`;

export const MethodLabel = injectIntl(({ type, spec, intl }) => {
  const { name, signature, returnType, snippets } = spec;
  const descTranslationKey = type
    ? 'models.scripts.scriptsContext.types.' + type + '.methods.' + name
    : 'models.scripts.scriptsContext.methods.' + name;
  const desc = intl.messages[descTranslationKey] ? intl.formatMessage({ id: descTranslationKey }) : undefined;
  const text = <Fragment>
    <span>{name + '('}</span>{detectTypeLabels(signature || '')}{')'}
  </Fragment>;
  return (
    <Fragment>
      {<span>{text}</span>}
      {returnType && <TypeLabel
        template=': &'
        type={returnType}
      />}
      <DescriptionText>{desc}</DescriptionText>
      {snippets &&
        <SnippetsDialog data={{ text, returnType, desc, snippets }} />
      }
    </Fragment >
  );
});

MethodLabel.propTypes = {
  spec: PropTypes.shape({
    name: PropTypes.string.isRequired,
    signature: PropTypes.string,
    returnType: PropTypes.string
  }).isRequired
};

const detectTypeLabels = text => {
  const chunks = text.split('#');
  if (chunks.size < 2) return text;
  const res = chunks.map((chunk, i) => {
    if (i % 2) {
      return <TypeLabel key={i} type={chunk} />;
    } else {
      return <span key={i}>{chunk}</span>;
    }
  });
  return res;
};