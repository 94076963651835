import styled from 'styled-components';
import StyledTable from '@material-ui/core/Table';
import StyledTableHead from '@material-ui/core/TableHead';
import StyledTableBody from '@material-ui/core/TableBody';
import StyledTableFooter from '@material-ui/core/TableFooter';
import StyledTableRow from '@material-ui/core/TableRow';
import StyledTableCell from '@material-ui/core/TableCell';
import StyledTablePagination from '@material-ui/core/TablePagination';

// Extend styles

export const TableScrollerWrapper = styled.div`
    overflow-x: auto;
    padding: 3px; /* Fix cropped box-shadow */
`;

export const Table = styled(StyledTable)`
  padding: ${props => props.theme.QuarterMarginSize} ${props => props.theme.MarginSize};
`;

export const TableHead = styled(StyledTableHead)`
  background-color: ${props => props.theme.BgLightGreyColor};
  box-shadow: ${props => props.theme.ComponentBlockShadow};
  th {
    border-bottom: 0;
    padding: ${props => props.theme.QuarterMarginSize} ${props => props.theme.HalfMarginSize} !important;
  }
`;

export const TableBody = styled(StyledTableBody)`
  background-color: white;
  box-shadow: ${props => props.theme.ComponentBlockShadow};
  tr:after {
    width: 0px;
    content: '';
    float: left;
    overflow: hidden;
    height: ${props => props.theme.DoubleMarginSize};
    font-size: 0;
    rowspan: 2;
  }
  tr td {
    border-bottom-color: ${props => props.theme.TableCellBorderColor};
    padding: 0 ${props => props.theme.HalfMarginSize} 0 ${props => props.theme.HalfMarginSize};
  }
`;

export const TableFooter = styled(StyledTableFooter)``;
export const TableRow = styled(StyledTableRow)``;
export const TableCell = styled(StyledTableCell)``;
export const TablePagination = styled(StyledTablePagination)`
  && {
    > div {
      padding-left: 0;
    }
  }
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > button:first-of-type {
    margin-left: initial;
  }
  > button:last-of-type {
    margin-right: initial;
  }
`;
