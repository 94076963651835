import React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import RootRef from '@material-ui/core/RootRef';

const StyledIconButton = styled(IconButton)`
  && {
    padding: 10px;
    margin: -10px;
  }
`;

let idCount = 0;
class DropdownMenu extends React.Component {

  constructor(props) {
    super(props);
    this.idCount = idCount++;
    this.state = {};
    this.handleClickOutside = event => {
      if (this.menuRef && !this.menuRef.contains(event.target)) {
        this.setState({ anchor: undefined });
      }
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { children, icon = <MoreVertIcon /> } = this.props;
    const anchor = this.state.anchor;
    return [
      <StyledIconButton
        key="dropdown-menu-icon"
        aria-owns={anchor}
        aria-haspopup="true"
        onClick={ev => this.setState({ anchor: ev.currentTarget })}
      >
        {icon}
      </StyledIconButton>,
      this.state.anchor &&
      <RootRef rootRef={ref => this.menuRef = ref} key="dropdown-menu-menu">
        <Menu
          id={`dropdown-menu-${this.idCount}`}
          anchorEl={anchor}
          open={!!anchor}
          onClose={() => this.setState({ anchor: undefined })}
        >
          {React.Children.map(children, child =>
            child && React.cloneElement(child, {
              onClick: (...o) => {
                this.setState({ anchor: undefined });
                child.props.onClick(...o);
              }
            })
          )}
        </Menu>
      </RootRef>
    ];
  }

}

export { DropdownMenu };