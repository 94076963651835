import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const PageTitle = styled(Typography)`
  && {
    position: relative;
    float: left;
    width: 100%;
    margin-top: ${props => props.theme.MarginSize};
    margin-bottom: ${props => props.theme.QuarterMarginSize};
  }
`;

export { PageTitle };
