import { datasources, uiSection } from './common-spec';
import { validators } from '../../components/Form/Validation/validation';

import { pick, omit } from 'lodash';

export const DEVICE_STATUS = {
  WORKING: 'WORKING',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
  STOPPED: 'STOPPED',
  INIT: 'INIT'
};

const variableType = {
  type: 'select',
  validators: ['required'],
  values: []
};
const dataType = {
  type: 'select',
  validators: ['required'],
  values: []
};
const units = {
  type: 'select',
  validators: [],
  values: []
};
const statsItem = {
  type: 'select',
  validators: [],
  values: []
};
const emonItem = {
  type: 'select',
  validators: [],
  values: []
};
const modbusRegTypes = ['coil', 'discrete_input', 'holding_register', 'input_register'];
const modbusMemOrderTypes = ['big_endian', 'little_endian'];
const valueConverters = {
  type: 'array',
  items: 'importValueConverter',
  availableConverters: [],
  validators: ['valueConverters'],
  hideInTable: true
};
const instance = {
  type: 'select',
  validators: ['required'],
  values: []
};

export const deviceTypes = ['adquio_client', 'axis_people_counter', 'bacnetip',
  'bacnetmstp', 'emon', 'io', 'memory', 'mqtt', 'modbusrtu', 'modbustcp', 'stats', 'zennio', 'script', 'casambi'];

const baseVariable = {
  code: { type: 'string', validators: ['required', 'identifier'], ...uiSection('general', 1) },
  name: { type: 'string', validators: [], ...uiSection('general', 1) },
  description: { type: 'string', validators: [], hideInTable: true, ...uiSection('general', 5) },
  extra: { type: 'object', validators: [], hideInTable: true, ...uiSection('general', 10) },
  canRead: { type: 'boolean', validators: [], ...uiSection('general', 15) },
  pollInterval: { type: 'milliseconds', validators: ['integer', validators.minimum(50)], hideInTable: true, defaultValue: 1000, ...uiSection('general', 15) },
  canWrite: { type: 'boolean', validators: [], ...uiSection('general', 20) },
  type: { ...variableType, ...uiSection('general', 25) },
  units: { ...units, ...uiSection('general', 25) },
  protocolDataType: { type: 'label', validators: [], translationKey: 'models.variables.form.dataType', ...uiSection('general', 30) }, /* used to print in tables */
  valueConverters: { ...valueConverters, ...uiSection('general', 30) },
  dataType: { ...dataType, ...uiSection('general', 30) },
  valueMappings: { type: 'object', validators: [], hideInTable: true, ...uiSection('general', 35) },
  minValue: { type: 'number', validators: [], hideInTable: true, ...uiSection('general', 40) },
  maxValue: { type: 'number', validators: [], hideInTable: true, ...uiSection('general', 40) },
  valueOffset: { type: 'number', validators: [], hideInTable: true, ...uiSection('general', 45) },
  nullValues: { type: 'array', items: 'number', validators: ['numberArray'], hideInTable: true, ...uiSection('general', 45) }
};

const adquioClientVariable = {
  ...baseVariable
};

const bacnetVariable = {
  ...baseVariable,
  bacnetObjectType: {
    type: 'select',
    validators: ['required'],
    values: [
      { value: 'analog-input', shortText: 'AI' },
      { value: 'analog-output', shortText: 'AO' },
      { value: 'analog-value', shortText: 'AV' },
      { value: 'binary-input', shortText: 'BI' },
      { value: 'binary-output', shortText: 'BO' },
      { value: 'binary-value', shortText: 'BV' },
      { value: 'multi-state-input', shortText: 'MSI' },
      { value: 'multi-state-output', shortText: 'MSO' },
      { value: 'multi-state-value', shortText: 'MSV' }],
    ...uiSection('protocol', 1)
  },
  bacnetObjectInstance: { type: 'number', validators: ['required'], ...uiSection('protocol', 1) },
  bacnetPropertyId: { type: 'select', values: ['present-value', 'out-of-service'], validators: ['required'], ...uiSection('protocol', 1) },
  bacnetApplicationTag: {
    type: 'select',
    validators: [],
    values: ['Null', 'Boolean', 'Unsigned Int', 'Signed Int', 'Real', 'Double', 'Enumerated'],
    ...uiSection('protocol', 2)
  }
};

const modbusVariable = {
  ...baseVariable,
  modbusRegType: { type: modbusRegTypes, validators: ['required'], ...uiSection('protocol', 1) },
  modbusAddress: { type: 'string', validators: ['required', 'modbusAddress'], ...uiSection('protocol', 1) },
  modbusValueMask: { type: 'string', emptyToNull: true, validators: ['modbusMask'], hideInTable: true, ...uiSection('protocol', 2) },
  modbusMaskWriteFullAddress: { type: 'boolean', hideInTable: true, ...uiSection('protocol', 2) }
};

const ioVariableModes = ['digital_input', 'digital_output', 'analog_input_10v', 'analog_input_24v', 'analog_output', 'pt1000_input'];
const ioVariable = {
  ...baseVariable,
  ioId: { type: 'number', validators: ['required'], ...uiSection('protocol', 1) },
  ioMode: { type: 'select', values: ioVariableModes, validators: ['required'], ...uiSection('protocol', 1) }
};

const zennioVariable = {
  ...baseVariable,
  item: { type: 'string', validators: ['required'], ...uiSection('protocol', 1) },
  datasource: { type: 'string', validators: [], ...uiSection('protocol', 1) }
};

const mqttVariable = {
  ...baseVariable,
  mqttReadQos: { type: 'number', hideInTable: true, defaultValue: 0, validators: ['integer', validators.minimum(0), validators.maximum(2)], ...uiSection('mqttRead', 1) },
  mqttReadTopic: { type: 'string', hideInTable: true, ...uiSection('mqttRead', 1) },
  mqttReadEncoder: { type: 'jsFunctionContent', hideInTable: true, props: { multiline: true, InputProps: {} }, ...uiSection('mqttRead', 2) },
  mqttWriteQos: { type: 'number', hideInTable: true, defaultValue: 0, validators: ['integer', validators.minimum(0), validators.maximum(2)], ...uiSection('mqttWrite', 3) },
  mqttWriteTopic: { type: 'string', hideInTable: true, ...uiSection('mqttWrite', 3) },
  mqttWriteRetain: { type: 'boolean', hideInTable: true, defaultValue: 0, ...uiSection('mqttWrite', 4) },
  mqttWriteEncoder: { type: 'jsFunctionContent', hideInTable: true, props: { multiline: true, InputProps: {} }, ...uiSection('mqttWrite', 5) },
};

const axisPeopleCounterVariable = {
  ...pick(baseVariable, 'canRead', 'canWrite'),
  canReadAsync: { type: 'boolean', validators: [], ...uiSection('general', 15) },
  ...omit(baseVariable, 'canRead', 'canWrite'),
  item: { type: 'string', validators: ['required'], ...uiSection('protocol', 1) },
  datasource: { type: 'string', validators: [], ...uiSection('protocol', 1) }
};

const statsVariable = {
  ...baseVariable,
  item: { ...statsItem, ...uiSection('protocol', 1) },
  target: { type: 'string', validators: [], ...uiSection('protocol', 1) }
};

const emonVariable = {
  ...pick(baseVariable, 'canRead', 'canWrite'),
  canReadAsync: { type: 'boolean', validators: [], ...uiSection('general', 15) },
  ...omit(baseVariable, 'canRead', 'canWrite'),
  item: {
    ...emonItem, ...uiSection('protocol', 1)
  }
};

const scriptVariable = {
  ...omit(baseVariable, 'pollInterval', 'protocolDataType', 'valueConverters', 'nullValues')
};

export const defaultVariable = {
  type: 'generic',
  canWrite: true,
  canRead: true
};

export const virtualVariableSpec = {
  ...omit(baseVariable, 'protocolDataType', 'valueOffset'),
  defaultValue: { type: 'string', validators: [], hideInTable: true, ...uiSection('protocol', 1) },
  persistent: { type: 'boolean', validators: [], ...uiSection('protocol', 2) },
  autoIncrement: { type: 'boolean', validators: [], hideInTable: true, ...uiSection('protocol', 2) }
};

export const deviceIdentificationFields = {
  type: { type: 'select', values: deviceTypes, validators: ['required'] },
  code: { type: 'string', validators: ['required', 'identifier'] },
  name: { type: 'string', validators: [] },
};

const baseDevice = {
  description: { type: 'string', validators: [], props: { multiline: true, InputProps: { rows: '5' } } },
  extra: { type: 'object', validators: [] },
  pollInterval: { type: 'milliseconds', validators: ['integer', validators.minimum(50)], defaultValue: 1000 }
};

const replaceArrayValues = (array, values) => {
  if (array) {
    array.splice(0, array.length);
    if (values) {
      values.forEach(v => array.push(v));
    }
  }
};

export const devicesSpec = ({ variableTypes, dataTypes, unitsArray, valueConvertersArray, statsItems, emonItems, moduleInstances }) => {
  replaceArrayValues(variableType.values, variableTypes);
  replaceArrayValues(dataType.values, dataTypes);
  replaceArrayValues(units.values, unitsArray);
  replaceArrayValues(statsItem.values, statsItems);
  replaceArrayValues(emonItem.values, emonItems);
  replaceArrayValues(valueConverters.availableConverters, valueConvertersArray);
  replaceArrayValues(instance.values, moduleInstances);

  const commonModbusProperties = {
    modbusSlave: { type: 'string', validators: ['required'] },
    modbusAddressOffset: { type: 'number', validators: [] },
    modbusTimeout: { type: 'milliseconds', validators: [], defaultValue: 500 },
    modbusMaxLength: { type: 'number', validators: [] },
    modbusByteOrder: { type: modbusMemOrderTypes, validators: [], defaultValue: 'big_endian' },
    modbusWordOrder: { type: modbusMemOrderTypes, validators: [], defaultValue: 'little_endian' },
  };
  return {
    adquio_client: {
      general: {
        remoteDeviceCode: { type: 'string', emptyToNull: true, validators: ['identifier'] },
        ...baseDevice
      },
      connection: {
        instance
      },
      variables: adquioClientVariable,
      virtualVariables: false
    },
    memory: {
      general: baseDevice,
      connection: {
        instance
      },
      variables: false
    },
    io: {
      general: baseDevice,
      connection: {
        instance
      },
      variables: ioVariable
    },
    modbusrtu: {
      general: baseDevice,
      connection: {
        instance,
        ...commonModbusProperties
      },
      variables: modbusVariable
    },
    modbustcp: {
      general: baseDevice,
      connection: {
        instance,
        ...commonModbusProperties
      },
      variables: modbusVariable
    },
    bacnetip: {
      general: baseDevice,
      connection: {
        instance,
        bacnetMaxMultipleReadObjects: { type: 'number', description: 'Max variables to request in each multiple read operation' },
        bacnetDeviceInstance: { type: 'number', validators: ['required'] },
        bacnetDeviceMAC: { type: 'string', validators: ['MAC'], helper: 'bacnetMAC' }
      },
      variables: bacnetVariable
    },
    bacnetmstp: {
      general: baseDevice,
      connection: {
        instance,
        bacnetMaxMultipleReadObjects: { type: 'number', description: 'Max variables to request in each multiple read operation' },
        bacnetDeviceInstance: { type: 'number', validators: ['required'] },
      },
      variables: bacnetVariable
    },
    zennio: {
      general: baseDevice,
      connection: {
        instance,
        datasources
      },
      variables: zennioVariable
    },
    mqtt: {
      general: baseDevice,
      connection: {
        instance
      },
      variables: mqttVariable
    },
    axis_people_counter: {
      general: baseDevice,
      connection: {
        instance,
        datasources,
      },
      variables: axisPeopleCounterVariable
    },
    stats: {
      general: baseDevice,
      connection: {
        instance
      },
      variables: statsVariable
    },
    emon: {
      general: {
        ...omit(baseDevice, 'pollInterval',)
      },
      connection: {
        instance,
        ip: { type: 'string', validators: ['required'] },
        tcpPort: { type: 'number', validators: ['numeric'], hideInTable: true },
        deviceId: { type: 'string', description: 'The device identification (pe. 1A)', validators: ['required'] },
        pulseValue: { type: 'number', description: 'The pulse value of the device (usually 1.95312 or 3.90625)', validators: ['required'], hideInTable: true },
        setOfSensors: { type: 'number', description: 'The number of current sensors in the device', hideInTable: true },
        timeout: { type: 'milliseconds', description: 'Requests timeout.', hideInTable: true },
      },
      variables: emonVariable
    },
    script: {
      general: baseDevice,
      connection: {
        instance
      },
      variables: scriptVariable,
      virtualVariables: false
    },
    casambi: {
      general: baseDevice,
      connection: {
        instance
      },
      variables: scriptVariable,
      virtualVariables: false
    }
  };
};
