import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, omit } from 'lodash';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';

import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { Dialog } from '../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Row } from '../../components/GridModules/Row';
import { ValidationChain } from '../../components/Form/Validation/ValidationChain';
import { DynamicFormField } from '../../components/Dynamics/DynamicFormField';
import { automaticAlertsService } from '../../lib/service';
import { handleAPIError } from '../../util/forms';

class AutomaticAlertFormDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
  }

  update(field, value) {
    this.setState({
      data: {
        ...this.state.data,
        [field]: value
      },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  async onSave() {
    const errors = this.validationChain.getAndDisplayErrors('models.automaticAlerts');
    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      const automaticAlert = this.state.data;
      try {
        automaticAlert.id
          ? await automaticAlertsService.update(automaticAlert.id, automaticAlert)
          : await automaticAlertsService.create(automaticAlert);
        this.setState({ errors: undefined });
        this.props.onSave();
      } catch (response) {
        const errors = handleAPIError(response, 'models.automaticAlerts');
        this.setState({ errors });
      } finally {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      }
    }
  }

  render() {
    const { data, errors } = this.state;
    this.validationChain = new ValidationChain();

    return (
      <Dialog
        aria-labelledby="automaticalert-form-title"
        aria-describedby="automaticalert-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="automaticalert-form-title">
          {data.id ?
            <FormattedMessage id="models.automaticAlerts.edit" /> :
            <FormattedMessage id="models.automaticAlerts.new" />
          }
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row>
              <DynamicFormField
                field='type'
                fieldType={{ type: 'select', values: ['DeviceStatus'], validators: ['required'] }}
                translationKey='models.automaticAlerts'
                value={data.type}
                validationChain={this.validationChain}
                errors={get(errors, 'type')}
                onChange={n => this.update('type', n)}
              />
            </Row>
            <Row>
              <DynamicFormField
                field='object'
                fieldType={{ type: 'string', validators: ['required', 'identifier'] }}
                translationKey={'models.automaticAlerts'}
                value={data.object}
                validationChain={this.validationChain}
                errors={get(errors, 'object')}
                onChange={value => this.update('object', value)}
              />
            </Row>
            <Row>
              <DynamicFormField
                field='delay'
                fieldType={{ type: 'milliseconds', validators: ['required', 'integer'] }}
                translationKey={'models.automaticAlerts'}
                value={data.delay}
                validationChain={this.validationChain}
                errors={get(errors, 'delay')}
                onChange={ms => this.update('delay', ms)}
              />
            </Row>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

AutomaticAlertFormDialog.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default injectIntl(AutomaticAlertFormDialog);