import { withRouter } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlockActions, BlockBody, BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import { acsService } from '../../lib/service';
import { ValidationChain } from '../../components/Form/Validation/ValidationChain';
import { DynamicFormField } from '../../components/Dynamics/DynamicFormField';
import DnsListFormField from './DnsListFormField';
import { FormActions } from '../FormActions';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { handleAPIError } from '../../util/forms';
import { AlertParagraph } from '../../components/Form/Form';
import { SectionTitle } from '../../components/Layout/Page';

class NetconfigViewBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabledSection: false,
      acsError: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const updateAndCheckWanIp = async (forced) => {
        if (!forced && this.state.wanIpChanges) return;
        const wanIp = await acsService.get('wanIpConfig');
        this.refreshAddressTimeout = null;
        const wanIp4 = wanIp && wanIp.wanIp4;
        this.setState({ wanIp, wanIpChanges: false });
        if (!this.refreshAddressTimeout && wanIp4.adquisition === 'dhcp' && !wanIp4.address) {
          this.refreshAddressTimeout = setTimeout(() => {
            updateAndCheckWanIp().catch(err => console.log('Error getting wan dhcp ip', err));
          }, 2000);
        }
      };
      await updateAndCheckWanIp(true);
      const dns = await acsService.get('dnsConfig');
      this.setState({ loading: false, dns });
    } catch (e) {
      if (e.type === 'FeathersError' && e.code === 404) {
        this.setState({ loading: false, disabledSection: true });
      }
      if (e.name === 'AcsModuleError' && e.message === 'AcsOffline') {
        this.setState({ loading: false, acsError: true });
      }
      console.log(e);
    }
  }

  updateIp4Value(field, v) {
    const wanIp4 = { ...this.state.wanIp.wanIp4, [field]: v };
    if (field === 'adquisition' && v !== 'static') {
      delete wanIp4.address;
    }
    this.setState({ wanIpChanges: true, wanIp: { ...this.state.wanIp, wanIp4 } });
  }

  validateAndSaveChanges(goBack = false) {
    const errors = this.validationChain.getAndDisplayErrors('system.network');
    if (!Object.keys(errors).length) {
      this.saveChanges(goBack);
    } else {
      this.setState({ errors });
    }
  }

  async saveChanges(goBack) {
    const { wanIp, wanIpChanges, dns, dnsChanges } = this.state;
    try {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      if (wanIpChanges) {
        eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'system.network.savingMessage' }));
        const wanPromise = acsService.update('wanIpConfig', wanIp);
        wanPromise.catch(e => e).then(e =>
          eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'system.network.savingMessage' })));
        await wanPromise;
      }
      if (dnsChanges) {
        eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'system.dns.savingMessage' }));
        const dnsPromise = acsService.update('dnsConfig', dns);
        dnsPromise.catch(e => e).then(e =>
          eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'system.dns.savingMessage' })));
        await dnsPromise;
      }
      if (goBack) {
        this.props.history.goBack();
      } else {
        this.setState({ wanIpChanges: undefined, dnsChanges: undefined }, () => this.fetchData());
      }
    } catch (response) {
      const errors = handleAPIError(response, 'system.network', this.props.intl);
      this.setState({ errors });
    } finally {
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
    }
  }

  render() {
    if (this.state.loading) return false;
    const { intl } = this.props;
    const { wanIp, dns, wanIpChanges, dnsChanges, loading, disabledSection, acsError } = this.state;
    this.validationChain = new ValidationChain();
    if (disabledSection) {
      return (
        <Fragment>
          <SectionTitle id="system.network.title" />
          <BlockBody>
            <AlertParagraph>
              <FormattedMessage id="app.disabledSection" />
            </AlertParagraph>
          </BlockBody>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Row>
          <TableBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="system.network.title" />
              </BlockTitle>
              <BlockActions>
                <Tooltip title={intl.formatMessage({ id: 'app.refresh' })} >
                  <IconButton onClick={() => this.fetchData()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </BlockActions>
            </BlockHeader>
            {!acsError &&
              <BlockBody>
                <FormattedMessage id="system.network.ipv4" />
                <Row MultiColumns>
                  <DynamicFormField
                    value={wanIp.wanIp4.adquisition}
                    field='adquisition'
                    fieldType={{ type: 'select', values: ['none', 'dhcp', 'static', 'down'] }}
                    translationKey='system.network'
                    onChange={v => this.updateIp4Value('adquisition', v)}
                  />
                  <DynamicFormField
                    value={wanIp.wanIp4.address}
                    field='address'
                    fieldType={{ type: 'string', validators: ['required', 'IPv4CIDR'], helper: 'IPv4CIDR' }}
                    translationKey='system.network'
                    validationChain={this.validationChain}
                    disabled={wanIp.wanIp4.adquisition !== 'static'}
                    onChange={v => this.updateIp4Value('address', v)}
                  />
                  <DynamicFormField
                    value={wanIp.wanIp4.gateway}
                    field='gateway'
                    fieldType={{ type: 'string', validators: ['required', 'IPv4'] }}
                    translationKey='system.network'
                    validationChain={this.validationChain}
                    disabled={wanIp.wanIp4.adquisition === 'dhcp'}
                    onChange={v => this.updateIp4Value('gateway', v)}
                  />
                </Row>
              </BlockBody>
            }
            {acsError && <FormattedMessage id='system.acsError' />}
          </TableBlock>
        </Row>
        {!acsError && <Row>
          <DnsListFormField
            value={dns}
            field='dns'
            label='system.dns.title'
            validationChain={this.validationChain}
            onChange={l => this.setState({ dnsChanges: true, dns: l })}
          />
        </Row>}
        <FormActions key="form-actions"
          disableSaveAndBack
          hasChanges={!!(dnsChanges || wanIpChanges)}
          disableAll={loading}
          onSave={this.validateAndSaveChanges.bind(this)}
          onCancel={this.props.history.goBack} />
      </Fragment>
    );
  }

}

export const NetconfigView = injectIntl(withRouter(NetconfigViewBase));
