import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlockBody, BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { Row } from '../../components/GridModules/Row';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { systemService } from '../../lib/service';
import { downloadFromBase64, fileToBase64 } from '../../util/files';
import { BackupRestoreModal } from './components/BackupRestoreModal';
import { showErrorAlert } from '../../lib/apiHelpers';
import { SectionTitle } from '../../components/Layout/Page';

const doBackup = async (intl, sections, password) => {
  eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'system.backup.loading' }));
  try {
    const result = await systemService.find({ query: { type: 'backup', data: { password, sections } } });
    downloadFromBase64(result.data, result.equsboxId ? `adquio.${result.equsboxId}.backup` : 'adquio.backup');
  } catch (e) {
    console.error(e);
    showErrorAlert(e);
  }
  eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'system.backup.loading' }));
};

const doRestore = async (intl, id, sections, password) => {
  eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'system.restore.restoring' }));
  const serverConnectionSubs = eventBus.subscribe(eventBusTopics.SERVER_CONNECTION, (online) => {
    if (!online) return;
    eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'system.restore.restarting' }));
    eventBus.unsubscribe([serverConnectionSubs]);
  });
  const error = await systemService.update('backupRestore', { id, sections, password })
    .then(() => {
      eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'system.restore.restarting' }));
      return false;
    })
    .catch(e => {
      if (e.name === 'NotFound') {
        eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'BackupRestoreTimeout' });
        return false;
      }
      showErrorAlert(e);
      return true;
    });
  eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'system.restore.restoring' }));
  return error;
};

export const BackupView = injectIntl(({ intl }) => {
  const [modal, setModal] = useState(undefined);
  return (
    <Row>
      <SectionTitle id='section.backupRestore' />
      <TableBlock key='backup'>
        <BlockHeader>
          <BlockTitle>
            <FormattedMessage id='system.backup.title' />
          </BlockTitle>
        </BlockHeader>
        <BlockBody>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setModal({
              mode: 'backup',
              action: (sections, pass) => doBackup(intl, sections, pass)
            })
            }>
            <FormattedMessage id='system.backup.button' />
          </Button>
        </BlockBody>
      </TableBlock>
      <TableBlock key='restore'>
        <BlockHeader>
          <BlockTitle>
            <FormattedMessage id='system.restore.title' />
          </BlockTitle>
        </BlockHeader>
        <BlockBody>
          <input
            id="backup-upload-file"
            type='file'
            accept='.backup'
            style={{ display: 'none' }}
            onChange={async ({ target }) => {
              if (!target.files || target.files.length === 0) return;
              eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'system.restore.loading' }));
              const data = await fileToBase64(target.files[0]);
              // Clear input so next click on button will trigger again this function
              target.value = null;
              try {
                const response = await systemService.update('backupUpload', { data });
                setModal({
                  mode: 'restore',
                  timestamp: response.timestamp,
                  sections: response.sections,
                  hidePassword: !response.userPassword,
                  cancelAction: () => systemService.update('backupRestoreCancel', { id: response.id }),
                  action: (sections, pass) => doRestore(intl, response.id, sections, pass)
                });
              } catch (e) {
                console.error(e);
                eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'UnknownError' });
              }
              eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'system.restore.loading' }));
            }}
          />
          <label htmlFor='backup-upload-file'>
            <Button
              variant='contained'
              component='span'
              color='primary'
            >
              <FormattedMessage id='system.restore.button' />
            </Button>
          </label>
        </BlockBody>
      </TableBlock>
      {modal && <BackupRestoreModal
        onCancel={() => {
          if (modal.cancelAction) {
            modal.cancelAction();
          }
          setModal(undefined);
        }}
        mode={modal.mode}
        timestamp={modal.timestamp}
        availableSections={modal.sections}
        hidePassword={modal.hidePassword}
        onConfirm={async (...p) => {
          const keepModal = await modal.action(...p);
          if (!keepModal) setModal(undefined);
        }}
      />}
    </Row>
  );
});
