import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Dialog } from './custom-material-ui-core';
import { Row } from './GridModules/Row';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DescriptionText } from '../views/Scripts/ScriptHelp/MethodLabel';
import { DynamicFormField } from './Dynamics/DynamicFormField';
import { IntlTextField } from '../intl-components/Form';

const StyledIntlTextField = styled(IntlTextField)`
textarea {
  font-family: monospace;
  font-size: 13px;
  border: 1px solid lightgray;
  padding: 8px;
}`;

const TRANSLATION_KEY = 'models.scripts.scriptsContext.snippetsDialog';

class SnippetsDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showing: false,
      selectedSnippet: null
    };
  }

  render() {
    const { data } = this.props;
    const { showing, selectedSnippet } = this.state;
    const { text, returnType, desc, snippets } = data;

    const snippet = snippets.find(s => s.code === selectedSnippet) || snippets[0];
    const titleKey = TRANSLATION_KEY + '.title';
    return (
      <>
        <Button
          aria-label='snippet'
          variant='contained'
          color='default'
          onClick={() => this.setState({ showing: true })}>
          <FormattedMessage id={TRANSLATION_KEY + '.openExamples'} /></Button>
        { showing &&
          <Dialog
            aria-labelledby='snippets-dialog-title'
            aria-describedby='snippets-dialog-title'
            open={true}
            disableBackdropClick={true}
            maxWidth='lg'
            fullWidth={true}
          >
            <DialogTitle >
              <FormattedMessage id={titleKey} />
            </DialogTitle>
            <DialogContent>
              <Row>
                <p>{text}: {returnType}</p>
                <DescriptionText>{desc}</DescriptionText>
              </Row>
              <Row>
                <DynamicFormField
                  field='example'
                  fieldType={{ type: 'select', values: snippets.map(s => ({ value: s.code, text: s.code })) }}
                  value={snippet.code}
                  translationKey={TRANSLATION_KEY}
                  disabled={snippets.length < 2}
                  onChange={code => {
                    this.setState({ selectedSnippet: code });
                  }}
                />
              </Row>
              {snippet.description &&
                <DescriptionText>{snippet.description}</DescriptionText>
              }
              <Row>
                <StyledIntlTextField
                  readOnly
                  multiline
                  rows={10}
                  key='code'
                  label={TRANSLATION_KEY + '.code'}
                  value={snippet.content}
                  onChange={() => { }}
                />
              </Row>
            </DialogContent>
            <DialogActions>
              <CopyToClipboard onCopy={() => { console.log('copied'); }} text={snippet.content}>
                <Button variant='contained' color='primary'>
                  <AssignmentIcon /><FormattedMessage id='app.copyToClipboard' />
                </Button>
              </CopyToClipboard>
              <Button variant='outlined' onClick={() => this.setState({ showing: false })}>
                <FormattedMessage id='app.close' />
              </Button>
            </DialogActions>
          </Dialog >
        }
      </>
    );
  }
}

SnippetsDialog.propTypes = {
  data: PropTypes.any
};

export default injectIntl(SnippetsDialog);