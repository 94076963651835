import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Dialog } from './custom-material-ui-core';
import { Row } from './GridModules/Row';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IntlTextField } from '../intl-components/Form';

const StyledIntlTextField = styled(IntlTextField)`
textarea {
  font-family: monospace;
  font-size: 13px;
  border: 1px solid lightgray;
  padding: 8px;
}`;

class TextareaDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showing: false
    };
  }

  render() {
    const { titleKey, fieldKey, ItemGetter, data } = this.props;
    const { showing } = this.state;
    const content = (typeof data == 'function') ? data() : data;

    return (
      <>
        {ItemGetter(() => this.setState({ showing: true }))}
        { showing &&
          <Dialog
            aria-labelledby='snippets-dialog-title'
            aria-describedby='snippets-dialog-title'
            open={true}
            disableBackdropClick={true}
            maxWidth='md'
            fullWidth={true}
          >
            <DialogTitle >
              <FormattedMessage id={titleKey} />
            </DialogTitle>
            <DialogContent>
              <Row>
                <StyledIntlTextField
                  readOnly
                  multiline
                  rows={20}
                  key='content'
                  label={fieldKey}
                  value={content}
                  onChange={() => { }}
                />
              </Row>
            </DialogContent>
            <DialogActions>
              <CopyToClipboard onCopy={() => { console.log('copied: ' + content); }} text={content}>
                <Button variant='contained' color='primary'>
                  <AssignmentIcon /><FormattedMessage id='app.copyToClipboard' />
                </Button>
              </CopyToClipboard>
              <Button variant='outlined' onClick={() => this.setState({ showing: false })}>
                <FormattedMessage id='app.close' />
              </Button>
            </DialogActions>
          </Dialog >
        }
      </>
    );
  }
}

TextareaDialog.propTypes = {
  titleKey: PropTypes.string,
  fieldKey: PropTypes.string,
  data: PropTypes.any
};

export default injectIntl(TextareaDialog);