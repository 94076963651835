import { withRouter } from 'react-router-dom';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlockBody, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { Row } from '../../components/GridModules/Row';
import { systemService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { showErrorAlert } from '../../lib/apiHelpers';
import { confirm } from '../../components/util';
import { ComponentBlock } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { IntlSwitch } from '../../intl-components/Form';
import { handleAPIError } from '../../util/forms';
import { SectionTitle } from '../../components/Layout/Page';
import { AlertParagraph, LabeledText } from '../../components/Form/Form';

class WifiViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      config: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { intl } = this.props;

    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));
    systemService.get('wifiAPConfig')
      .then(config => { this.setState({ config }); })
      .catch(err => {
        this.setState({ config: null });
        showErrorAlert(err);
      })
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'app.loading' }));
        this.setState({ loading: false });
      });
  }

  saveConfiguration(config) {
    const { intl } = this.props;

    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
    systemService.update('wifiAPConfig', config)
      .then(_ => this.fetchData())
      .catch(response => handleAPIError(response, 'app', intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' })));
  }

  setWifiEnabled(enabled) {
    const { intl } = this.props;
    const apply = () => { this.saveConfiguration({ enabled }); };
    enabled ? apply() : confirm(intl, 'system.wifi.disableConfirm', () => apply());
  }

  render() {
    const { loading, config } = this.state;
    let content;
    console.log();
    if (loading || !config) {
      content = null;
    } else if (config.available !== true) {
      content = (
        <BlockBody>
          <AlertParagraph>
            <FormattedMessage id="app.disabledSection" />
          </AlertParagraph>
        </BlockBody>
      );
    } else {
      content = (
        <Fragment>
          <Row>
            <ComponentBlock>
              <BlockBody>
                <FormattedMessage id={'system.wifi.description'} />
              </BlockBody>
            </ComponentBlock>
          </Row>
          <Row>
            <ComponentBlock>
              <BlockTitle>
                <FormattedMessage id="system.wifi.general" />
              </BlockTitle>
              <BlockBody>
                <IntlSwitch
                  label='system.wifi.enabled'
                  checked={config.enabled}
                  onChange={(_ev, enabled) => this.setWifiEnabled(enabled)}
                />
                <LabeledText id='system.wifi.ssid' value={config.ssid} />
                <LabeledText id='system.wifi.address' value={config.ipv4} />

              </BlockBody>
            </ComponentBlock>
          </Row >
        </Fragment>);
    }
    return (
      <Fragment>
        <SectionTitle id="system.wifi.title" />
        {content}
      </Fragment>
    );
  }
}

export const WifiView = injectIntl(withRouter(WifiViewBase));
