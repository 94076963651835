import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { ScriptStatusMonitor } from './ScriptStatusMonitor';

import { Row } from '../../components/GridModules/Row';

import { ComponentBlock, BlockHeader, BlockActions, BlockBody } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { scriptsService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';

import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ScriptEditor } from './ScriptEditor';
import { handleAPIError } from '../../util/forms';
import { formatTime } from '../../util/time';
import { LogsComponentBlock } from '../../components/LogsComponentBlock';
import { LabeledText } from '../../components/Form/Form';

const WatchValueItemBase = styled.div`
  &.same_line {
    position: absolute;
    top: -16px;
  }

  padding: 0px 4px;
  width: 100%;

  span:first-child {
    white-space:pre;
  }
  span:nth-child(2) {
    padding: 0 4px;
  }
  span:last-child {
    float: right;
  }
`;

const WatchValueItem = styled(WatchValueItemBase)`
  background-color: #0095ff21;
  span:nth-child(2) {
    background-color: #0095ff42;
  }
`;

const WatchErrorItem = styled(WatchValueItemBase)`
  background-color: #ff000021;
  span:nth-child(2) {
    background-color: #ff000070;
  }
`;

const CustomAccordion = styled(Accordion)`
  [aria-expanded=true] {
    display: none;
  }
`;

class ScriptDebugger extends Component {

  rf = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      debugModeEnabled: true,
      repaintCounter: 0,
      logsExpanded: true
    };
    this.repaint = debounce(() => {
      this.setState({ repaintCounter: this.state.repaintCounter + 1 });
    }, 500);
  }

  componentDidMount() {
    scriptsService.on('ScriptWatchMessage', this.onWatch);
    this.fetchData();
  }

  componentWillUnmount() {
    scriptsService.removeListener('ScriptWatchMessage', this.onWatch);
    this.repaint.cancel();
  }

  close() {
    clearInterval(this.state.subscriptionInterval);
    this.props.close();
  }

  updateSubscription() {
    const { scriptId, intl } = this.props;
    scriptsService.enableScriptWatch({ scriptId: scriptId })
      .catch((response) => {
        this.close();
        handleAPIError(response, 'app', intl);
      });
  }

  restartScript() {
    const { scriptId, intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));
    scriptsService.restart({ scriptId: scriptId }).catch(response => handleAPIError(response, 'app', intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' }));
      });
  }

  async fetchData() {
    const { scriptId, intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START);
    scriptsService.get(scriptId).then(script => {
      let scriptContent = '';
      script.content.forEach((c) => {
        scriptContent += (c.preContent || '') + '\n';
        scriptContent += (c.content || '') + '\n';
        scriptContent += (c.postContent || '') + '\n';
      });
      this.setState({ script, scriptContent });
      return scriptsService.enableScriptWatch({ scriptId })
        .then(() => {
          this.setState({
            debugModeEnabled: true,
            subscriptionInterval: setInterval(() => this.updateSubscription(), 20000)
          });
        });
    }).catch(response => handleAPIError(response, 'app', intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' }));
      });
    eventBus.publish(eventBusTopics.LOADING_END);
  }

  onWatch = (data) => {
    const { scriptId } = this.props;
    if (data && (data.scriptId === scriptId) && this.state.debugModeEnabled) {
      const wd = data;

      const line = wd.line - 1;
      const lineInfo = this.clearLineWidgets(line);
      if (!lineInfo) {
        return;
      }
      const lineContent = lineInfo.text;
      let leftPadding = lineContent.length + 1;

      const isError = wd.error != null;
      let Item;
      if (isError) {
        Item = WatchErrorItem;
      } else {
        Item = WatchValueItem;
      }
      let showSameLine = true;

      const text = JSON.stringify(wd.error || wd.value) || '';
      if (leftPadding + text.length > 140) {
        leftPadding = 1;
        showSameLine = false;
      }
      const html = ReactDOMServer.renderToStaticMarkup((<Item className={showSameLine ? 'same_line' : ''}>
        <span>{''.padEnd(leftPadding, ' ')}</span><span>{text}</span><span>{formatTime(this.props.intl, wd.date)}</span>
      </Item>));
      var wrapper = document.createElement('div');
      wrapper.innerHTML = html;
      var node = wrapper.firstChild;
      this.codeMirror.addLineWidget(line, node, true);
      this.repaint();
    }
  }

  clearAllLineWidgets() {
    if (this.codeMirror) {
      let line = 0;
      while (this.clearLineWidgets(line)) {
        line++;
      }
    }
  }

  clearLineWidgets(line) {
    if (!this.codeMirror) {
      return;
    }
    const lineInfo = this.codeMirror.lineInfo(line);
    if (lineInfo) {
      (lineInfo.widgets || []).forEach(w => w.clear());
      return lineInfo;
    }
    return;
  }

  render() {
    const { intl, scriptId } = this.props;
    const { script, scriptContent, logsExpanded } = this.state;
    const label = 'debug-editor';
    if (this.rf && this.rf.current != null) {
      this.codeMirror = this.rf.current.codeMirror;
    }

    const blockKey = 'models.scripts.debug';
    const content = scriptContent;
    if (!content) {
      return null;
    }
    const showEventInfo = script.type === 'Event';

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    const logsTitle = (<a onClick={() => this.setState({ logsExpanded: false })}>
      <FormattedMessage id={'models.scripts.logs'} values={{ scriptId }} />
      <ExpandMoreIcon />
    </a>);
    return (
      <Dialog
        open keepMounted
        aria-labelledby={label}
        aria-describedby={label}
        disableBackdropClick={true}
        style={{ height: '100%' }}
        fullWidth={true}
        maxWidth={false}
      >
        <DialogTitle id={label}>
          <FormattedMessage id={blockKey + '.title'} values={{ scriptId }} />
        </DialogTitle>
        <DialogContent >

          <ComponentBlock >
            <BlockHeader style={{ paddingTop: 0 }}>
              <Row>
                <LabeledText id='models.scripts.name' value={script.name} />
                {showEventInfo &&
                  <LabeledText id='models.scripts.event' value={script.event.type + ' (' + script.event.object + ') ' + script.event.objectName} />
                }
                <ScriptStatusMonitor
                  scriptId={scriptId}
                />
              </Row>
              <BlockActions>
                <Button variant='contained' onClick={() => this.restartScript()}>
                  <FormattedMessage id='app.restart' />
                </Button>
              </BlockActions>
            </BlockHeader>
            <BlockBody>
              <ScriptEditor
                ref={this.rf}
                editorId='debug-editor-content'
                blockKey={blockKey}
                onChange={_ => { }}
                value={content}
                debugModeEnabled={true}
                allowHelp={false}
                intl={intl}
                editorOptions={{
                  readOnly: 'nocursor'
                }}
              />
            </BlockBody>
          </ComponentBlock>
        </DialogContent>
        <div>
          <CustomAccordion
            expanded={logsExpanded}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandLessIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => this.setState({ logsExpanded: true })}
            ><FormattedMessage id={'models.scripts.logs'} values={{ scriptId }} /></AccordionSummary>
            <AccordionDetails>
              <LogsComponentBlock
                logType='script'
                logId={scriptId}
                compactView
                titleElement={logsTitle}
              />
            </AccordionDetails>
          </CustomAccordion>
        </div>
        <DialogActions>
          <Button variant='outlined' onClick={() => this.close()}>
            <FormattedMessage id='app.close' />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ScriptDebugger.propTypes = {
  close: PropTypes.func.isRequired,
  scriptId: PropTypes.string.isRequired
};

const IntlScriptDebugger = injectIntl(ScriptDebugger);
export { IntlScriptDebugger as ScriptDebugger };
