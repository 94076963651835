import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './theme/reset.css';
import './theme/globals.css';
import { ThemeProvider } from 'styled-components';
import StyleVariables from './theme/StyleVariables';

import App from './App';
import { unregister } from './registerServiceWorker';

import { MUIVariables } from './theme/MUIVariables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { QueryStringProvider } from './components/queryUtils.js';
import { TimeZoneProvider } from './components/TimeZoneAware.jsx';
import { getLanguage } from './util/language';

// IE 11, Safary 12
if (!Intl.PluralRules) {
  require('intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/es');
}

// IE 11, Edge, Safary 12
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
}

const locale = getLanguage();
const lang = require(`./i18n/flattened/${locale}.json`);

ReactDOM.render(
  <IntlProvider locale={locale} messages={lang}>
    <HashRouter>
      <SnackbarProvider
        maxSnack={15}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiThemeProvider theme={MUIVariables}>
          <ThemeProvider theme={StyleVariables}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <QueryStringProvider>
                <TimeZoneProvider>
                  <App />
                </TimeZoneProvider>
              </QueryStringProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </SnackbarProvider>
    </HashRouter>
  </IntlProvider>, document.getElementById('root'));
unregister();