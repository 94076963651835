const loggerMethodSignature = {
  signature: 'message: string, [...params]',
};


const commonSnippets = {
  ScheduleManager: {
    default: {
      code: 'default',
      description: 'Utilizar un horario para encender y apagar las variables \'memory#out_keep\' y \'memory#out_no_keep\', la primera de ellas se sobreescribirá constantemente con el valor deseado, aunque se cambie su valor desde otro lugar',
      content: 'const { createScheduleManager } = require(\'adquio-schedule-manager\');\n\nconst sched = createScheduleManager({code: \'scheduler01\', interval: 60000});\n\nsched.addTimetable(\'default\', \'HorarioClima#Estado\', [\n  {\n    scheduleEnabledVarId: \'HorarioClima#{day}_Activo\',\n    scheduleVarId: \'HorarioClima#{day}_{on_off}_{hour_minute}\'\n  }\n]);\n\n\\\\You can configure variables to be managed by scheduler:\nsched.addVariables(\'default\', [\n  {\n    setVarId: \'memory#out_keep\',\n    modeVarId: \'memory#onoffauto\',\n    setValueOn: 2, /* optional, default value: 1 */\n    setValueOff: 0, /* optional, default value: 0 */\n    modeOff: 0, /* optional, default value: 0 */\n    modeOn: 1, /* optional, default value: 1 */\n    modeAuto: 2, /* optional, default value: 2 */\n    forceKeepValue: true  /* optional, default value: false */\n  },\n  {\n    setVarId: \'memory#out_no_keep\',\n    modeVarId: \'memory#onoffauto\',\n    forceKeepValue: false\n  }\n]);\n\n\\\\ You can also use events:\nevents.addListener({ \'eventName\': \'Timer\', \'timeObject\': \'ScheduleManager\', \'timerName\': \'default\', \'timerManager\': \'scheduler01\', \'timerEvent\': \'StartSchedule\' }, async (event) => {\n  logger.info(\'ON\');\n});\n\nevents.addListener({ \'eventName\': \'Timer\', \'timeObject\': \'ScheduleManager\', \'timerName\': \'default\', \'timerManager\': \'scheduler01\', \'timerEvent\': \'EndSchedule\' }, async (event) => {\n  logger.info(\'OFF\');\n});'
    },

    simpleEvent: {
      code: 'one_time_event',
      description: 'Utilizar un horario para apagar las luces (variables \'Luces#luz1\' y \'Luces#luz2\') a una hora del día',
      content: 'const { createScheduleManager } = require(\'adquio-schedule-manager\');\n\nconst sched = createScheduleManager({code: \'scheduler01\', interval: 60000});\n\nsched.addTimetable(\'schedule\', null, [\n  {\n    scheduleEnabledVarId: \'HorarioLuces#Activo\',\n    scheduleVarId: \'HorarioLuces#OFF_{hour_minute}\'\n  }\n]);\n\n\\\\You can configure variables to be managed by scheduler:\nsched.addVariables(\'schedule\', [\n  {\n    setVarId: \'Luces#luz1\',\n    setValueOn: 0, // on calendar event, 0 will be written to the variable. Note that\n                   // setValueOn means to then \'on\' of the time event, not the variable.\n    setValueOff: null, // it\'s is important to set off value to null because scheduler\n                       // will also trigger off event 1 minute after on\n  },\n  {\n    setVarId: \'Luces#luz2\',\n    setValueOn: 0,\n    setValueOff: null,\n  }\n]);\n\n\\\\ You can also use events:\nevents.addListener({ \'eventName\': \'Timer\', \'timeObject\': \'ScheduleManager\', \'timerName\': \'default\', \'timerManager\': \'scheduler01\', \'timerEvent\': \'StartSchedule\' }, async (event) => {\n  logger.info(\'ON\');\n});'
    }
  }
};

export const scriptContext = {
  objects: {
    logger: 'Logger',
    devices: 'DevicesProxy',
    modules: 'ModulesProxy',
    alerts: 'AlertsProxy',
    events: 'EventsProxy',
    localData: '{}',
    globalData: 'GlobalScriptsData',
    equs: 'EqusScriptsAPI',
    utils: 'Utils'
  },
  methods: [
    {
      name: 'setTimeout', signature: 'callback: function(), timeoutMs: number', returnType: 'TimerId',
      snippets: [
        {
          code: 'default',
          content: 'const timeoutId = setTimeout(() => {\n  /*insert code here*/\n}, 1000);'
        }
      ]
    },
    {
      name: 'clearTimeout', signature: 'timer: TimerId',
      snippets: [
        {
          code: 'default',
          content: 'clearTimeout(timeoutId);'
        }
      ]
    },
    {
      name: 'setInterval', signature: 'callback: function(), periodMs: number', returnType: 'TimerId',
      snippets: [
        {
          code: 'default',
          content: 'const intervalId = setInterval(() => {\n  /*insert code here*/\n}, 5000);'
        }
      ]
    },
    {
      name: 'clearInterval', signature: 'timer: TimerId',
      snippets: [
        {
          code: 'default',
          content: 'clearInterval(intervalId);'
        }
      ]
    },
    {
      name: 'formula', signature: 'formula: string', returnType: 'Formula',
      snippets: [
        {
          code: 'default',
          content: 'const form = formula(\'$<change_device_code>#<change_variable_code>$ + 3\');\nconst value = form.eval();'
        }
      ]
    },
    {
      name: 'calc', signature: 'formula: string', returnType: 'any',
      snippets: [
        {
          code: 'default',
          content: 'const value = calc(\'$<change_device_code>#<change_variable_code>$ + 3\');\nlogger.info(\'Calculated value\' + value);'
        }
      ]
    },
    {
      name: 'watch', signature: 'value: any',
      snippets: [
        {
          code: 'default',
          content: 'watch(1234);\nwatch(\'Hello World!\');\nwatch({field1: 1, field2: \'s\', now: +new Date()});'
        }
      ]
    },
    {
      name: 'delay', signature: 'value: number',
      snippets: [
        {
          code: 'default',
          content: 'delay(2000);'
        }
      ]
    },
    {
      name: 'require', signature: 'package: string',
      showExternalLibraryDialog: true,
      snippets: [
        {
          code: 'adquio-schedule-manager',
          content: 'const { createScheduleManager } = require(\'adquio-schedule-manager\');\n\nconst sched = createScheduleManager({code: \'scheduler01\', interval: 60000});\n\nsched.addTimetable(\'schedule\', null, [\n  {\n    scheduleEnabledVarId: \'HorarioLuces#Activo\',\n    scheduleVarId: \'HorarioLuces#OFF_{hour_minute}\'\n  }\n]);\n\n\\\\You can configure variables to be managed by scheduler:\nsched.addVariables(\'schedule\', [\n  {\n    setVarId: \'Luces#luz1\',\n    setValueOn: 0, // on calendar event, 0 will be written to the variable. Note that\n                   // setValueOn means to then \'on\' of the time event, not the variable.\n    setValueOff: null, // it\'s is important to set off value to null because scheduler\n                       // will also trigger off event 1 minute after on\n  },\n  {\n    setVarId: \'Luces#luz2\',\n    setValueOn: 0,\n    setValueOff: null,\n  }\n]);\n\n\\\\ You can also use events:\nevents.addListener({ \'eventName\': \'Timer\', \'timeObject\': \'ScheduleManager\', \'timerName\': \'default\', \'timerManager\': \'scheduler01\', \'timerEvent\': \'StartSchedule\' }, async (event) => {\n  logger.info(\'ON\');\n});'
        },
        {
          code: 'adquio-one-shot-variable',
          content: 'const { configureOneShotVariable } = require(\'adquio-one-shot-variable\');\n\nconfigureOneShotVariable({\n  variableId: \'memory#int32\',\n  onValue: undefined, //default, undefined means any distinct 0 will trigger the variable\n  offValue: 0, //default, the value to reset the variable\n  timeout: 1000 //default, the time the variable should keep active before reset (ms)\n}, () => {\n  logger.debug(\'One Shot called\');\n  const newValue = devices.read(\'memory\', \'variable1\') + 1;\n  devices.writeFuture(\'memory\', \'variable1\', newValue).catch(logger.err);\n})'
        },
        {
          code: 'axios',
          content: 'const axios = require(\'axios\');\n\naxios.get(\'https://api.sampleapis.com/countries/countries\').then( (response) => {\n  const { status, data } = response;\n  \n  const espana = data.find(pais => pais.abbreviation === \'ES\');\n  \n  logger.info(\'Estado: \' + status);\n  logger.info(JSON.stringify(espana, null ,2));\n});\n\n\n// See: https://www.npmjs.com/package/axios\n'
        },
        {
          code: 'jsonwebtoken',
          content: 'const jwt = require(\'jsonwebtoken\');\n\nconst token = jwt.sign({ foo: \'bar\' }, \'shhhhh\');\n\nlogger.info(\'Token jwt: \' + token);\n\n\n// See: https://www.npmjs.com/package/jsonwebtoken\n'
        },
        {
          code: 'lodash',
          content: 'const _ = require(\'lodash\');\n\nconst chunked = _.chunk([\'a\', \'b\', \'c\', \'d\'], 2);\nlogger.info(\'chunk: \' + JSON.stringify(chunked)); // prints: [["a","b"],["c","d"]]\n\nconst last = _.last([1, 2, 3]);\nlogger.info(\'last: \' + last); // prints: 3\n\nconst obj1 = {\'name\': \'colin\', \'car\': \'suzuki\', \'age\': 17};\nconst obj2 = _.omit(obj1, [\'car\', \'age\']);\nlogger.info(\'omit: \' + JSON.stringify(obj2)); // prints: {"name":"colin"}\nconst obj3 = _.pick(obj1, [\'car\', \'age\']);\nlogger.info(\'pick: \' + JSON.stringify(obj3)); // prints: {"car":"suzuki","age":17} \n\nconst random = _.sample([1, 2, 3]);\nlogger.info(\'random: \' + JSON.stringify(random)); // prints: any of the objects\n\n\n// See: https://www.npmjs.com/package/lodash\n'
        },
        {
          code: 'moment',
          content: 'const moment = require(\'moment\');\n\nconst now = moment();\nlogger.info(\'now: \' + now.format());\n\nconst day = moment(\'1995-12-25\');\nlogger.info(\'day: \' + day.format());\n\n\n// See: https://www.npmjs.com/package/moment\n'
        },
        {
          code: '@mariusrumpf/pid-controller',
          content: 'const { PIDController } = require(\'@mariusrumpf/pid-controller\');\n\nconst ctr = new PIDController({\n  p: 0.25, i: 0.01, d: 0.01,\n  target: 120,\n  sampleTime: 2000, // in ms\n  outputMin: 0,\n  outputMax: 240,\n});\n\nfunction measureFromSomeSensor() {\n  // your code\n}\n\nfunction applyInputToActuator(value) {\n  // your code\n}\n\nsetInterval(() => {\n  let output = measureFromSomeSensor();\n  let input  = ctr.update(output);\n  logger.info(\'Valor actual: \' + output + \', ajuste: \' + input);\n  applyInputToActuator(input);\n}, 2000);\n\n\n// See: https://www.npmjs.com/package/@mariusrumpf/pid-controller\n'
        },
        {
          code: 'node-pid-controller',
          content: 'const Controller = require(\'node-pid-controller\');\n\nconst ctr = new Controller({\n  k_p: 0.25,\n  k_i: 0.01,\n  k_d: 0.01,\n  dt: 2\n});\nctr.setTarget(120);\n\nfunction measureFromSomeSensor() {\n  // your code\n}\n\nfunction applyInputToActuator(value) {\n  // your code\n}\n\nsetInterval(() => {\n  let output = measureFromSomeSensor();\n  let input  = ctr.update(output);\n  logger.info(\'Valor actual: \' + output + \', ajuste: \' + input);\n  applyInputToActuator(input);\n}, 2000);\n\n\n// See: https://www.npmjs.com/package/node-pid-controller\n'
        },
        {
          code: 'ping',
          content: 'const ping = require(\'ping\');\n\nconst hosts = [\'google.com\', \'notexistingdomain.cooom\'];\nhosts.forEach(function(host){\n  ping.sys.probe(host, function(isAlive){\n    if(isAlive) {\n      logger.info(\'host \' + host + \' is alive\');\n    } else {\n      logger.warn(\'host \' + host + \' is dead\');\n    }\n  });\n});\n\n\n// See: https://www.npmjs.com/package/ping\n'
        },
        {
          code: 'uuid',
          content: 'const { v4: uuidv4 } = require(\'uuid\');\n\nconst id = uuidv4();\nlogger.info(\'uuid: \' + id);\n\n\n// See: https://www.npmjs.com/package/uuid\n'
        },
        {
          code: 'url',
          content: 'const url = require(\'url\');\n\nconst urlParts = url.parse(\'http://user:pass@host.com:8080/p/a/t/h?query=string#hash\');\n\nlogger.info(\'Url parts: \' + JSON.stringify(urlParts, null, 2));\n\n\n// See: https://nodejs.org/docs/latest-v12.x/api/url.html\n'
        },
        {
          code: 'ws',
          content: 'const WebSocket = require(\'ws\');\n\nconst ws = new WebSocket(\'wss://echo.websocket.org\');\nws.once(\'open\', () => {\n  ws.send(\'something\');\n});\nws.once(\'close\', () => {\n  console.log(\'disconnected\');\n});\nws.once(\'message\', (msg)=> {\n  console.log(\'message received: \' + msg);\n  ws.close();\n});\n\n\n// See: https://www.npmjs.com/package/ws\n'
        },
        {
          code: 'mqtt',
          content: 'const mqtt = require(\'mqtt\');\nconst client  = mqtt.connect(\'mqtt://test.mosquitto.org\');\n\nclient.on(\'connect\', function () {\n  client.subscribe(\'presence\', function (err) {\n    if (!err) {\n      client.publish(\'presence\', \'Hello mqtt\');\n    }\n  });\n});\n\nclient.on(\'message\', function (topic, message) {\n  // message is Buffer\n  console.log(message.toString());\n  client.end();\n});\n'
        },
        {
          code: 'async-mqtt',
          content: 'const mqtt = require(\'async-mqtt\');\nconst client = mqtt.connect(\'mqtt://test.mosquitto.org\');\n\nclient.on(\'message\', function (topic, message) {\n  console.log(\'Received message: \' + message.toString());\n});\n\nclient.on(\'connect\', async function () {\n  try {\n    await client.subscribe(\'presence\');\n    await client.publish(\'presence\', \'Hello mqtt\');\n    await client.end();\n    console.log(\'Done!\');\n  }catch(err) {\n    console.error(\'Error in mqtt\', err);\n  }\n});\n\nclient.on(\'error\', function (err) {\n  console.error(\'Mqtt client error\', err);\n});\n'
        }
      ]
    }
  ],
  types: {
    Formula: {
      methods: [
        { name: 'eval', signature: '', returnType: 'any' }
      ]
    },
    Logger: {
      methods: [
        { name: 'debug', ...loggerMethodSignature },
        { name: 'warn', ...loggerMethodSignature },
        { name: 'error', ...loggerMethodSignature }
      ]
    },
    EventsProxy: {
      methods: [
        {
          name: 'addListener', signature: 'filter: function | object, handler: function(eventData)',
          snippets: [
            {
              code: 'interval',
              content: 'events.addListener({ eventName: \'Timer\', \'timerObject\': \'Interval\', \'timerName\': \'<change_timer_name>\', \'timerEvent\': \'Trigger\' }, async (event) => {\n  /*insert code here*/\n});'
            },
            {
              code: 'startSchedule',
              content: 'events.addListener({ eventName: \'Timer\', \'timerObject\': \'Schedule\', \'timerName\': \'<change_timer_name>\', \'timerEvent\': \'StartSchedule\' }, async (event) => {\n  /*insert code here*/\n});'
            },
            {
              code: 'EndSchedule',
              content: 'events.addListener({ eventName: \'Timer\', \'timerObject\': \'Schedule\', \'timerName\': \'<change_timer_name>\', \'timerEvent\': \'EndSchedule\' }, async (event) => {\n  /*insert code here*/\n});'
            },
            {
              code: 'variableChange',
              // eslint-disable-next-line no-template-curly-in-string
              content: 'events.addListener({ eventName: \'VariableChange\', deviceCode: \'<change_device_name>\', variableCode: \'<change_variable_name>\' }, async (event) => {\n  logger.info(`Variable ${event.deviceCode}#${event.variableCode} changed value to ${event.newValue}`); \n});'
            },
            {
              code: 'historicAggregation',
              content: 'events.addListener({ eventName: \'NewVariablesHistoric\' }, async (event) => {\n  if (event.period = \'5m\') {\n    /*insert code here*/\n  }\n});'
            }
          ]
        },
        { name: 'clearListeners', signature: '' },
        {
          name: 'emit', signature: 'eventData',
          snippets: [
            {
              code: 'default',
              content: '// Add event handler\nevents.addListener({ eventName: \'MyCustomEvent\'}, async (event) => {\n  logger.info(\'Event received \' + JSON.stringify(event));\n});\n\n// emit event every 4 seconds\nlet counter = 0;\nsetInterval(() => {\n  const now = +new Date();\n  logger.info(\'Event emitted with counter \' + counter + \' at \' + now);\n  events.emit({eventName: \'MyCustomEvent\', value: counter, date: now});\n  counter++;\n}, 4000);\n'
            }
          ]
        }
      ]
    },
    ModulesProxy: {
      methods: [
        {
          name: 'all',
          signature: '',
          returnType: 'ModuleStatus[]',
          snippets: [
            {
              code: 'default',
              content: 'const modulesStatus = modules.all();\nmodulesStatus.forEach((mod) => {\n  const status = mod.status;\n})'
            }
          ]
        },
        {
          name: 'find',
          signature: 'moduleCode: string',
          returnType: 'ModuleStatus',
          snippets: [
            {
              code: 'default',
              content: 'const mod = modules.find(\'<change_module_code>\');\nconst status = mod.status;'
            }
          ]
        },
        {
          name: 'start',
          signature: 'moduleCode: string',
          returnType: 'void',
          snippets: [
            {
              code: 'default',
              content: 'modules.start(\'<change_module_code>\');'
            }
          ]
        },
        {
          name: 'restart',
          signature: 'moduleCode: string',
          returnType: 'void',
          snippets: [
            {
              code: 'default',
              content: 'modules.restart(\'<change_module_code>\');'
            }
          ]
        },
        {
          name: 'stop',
          signature: 'moduleCode: string',
          returnType: 'void',
          snippets: [
            {
              code: 'default',
              content: 'modules.stop(\'<change_module_code>\');'
            }
          ]
        }
      ]
    },
    DevicesProxy: {
      methods: [
        {
          name: 'find',
          signature: 'deviceCode: string',
          returnType: 'DeviceProxy',
          snippets: [
            {
              code: 'default',
              content: 'const dev = devices.find(\'<change_device_code>\');\nconst variable = dev.findVariable(\'<change_variable_code>\');\nconst value = variable.read();'
            }
          ]
        },
        {
          name: 'findVariable',
          signature: 'deviceCode: string, variableCode: string',
          returnType: 'VariableProxy',
          snippets: [
            {
              code: 'default',
              content: 'const variable = devices.findVariable(\'<change_device_code>\', \'<change_variable_code>\');\nconst value = variable.read();'
            }
          ]
        },
        {
          name: 'write',
          signature: 'deviceCode: string, variableCode: string, value: any',
          snippets: [
            {
              code: 'default',
              content: 'const newValue = 123;\ntry {\n  devices.write(\'<change_device_code>\', \'<change_variable_code>\', newValue);\n} catch(err) {\n  logger.error(\'Error writing value to device\', err);\n}'
            }
          ]
        },
        {
          name: 'read',
          signature: 'deviceCode: string, variableCode: string',
          returnType: 'value: any',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const value = devices.read(\'<change_device_code>\', \'<change_variable_code>\');\n  /* do something with value*/\n} catch(err) {\n  logger.error(\'Error reading value from device\', err);\n}'
            }
          ]
        },
        {
          name: 'writeFuture',
          signature: 'deviceCode: string, variableCode: string, value: any',
          snippets: [
            {
              code: 'default',
              content: 'const newValue = 123;\ntry {\n  await devices.writeFuture(\'<change_device_code>\', \'<change_variable_code>\', newValue);\n} catch(err) {\n  logger.error(\'Error writing value to device\', err);\n}'
            }
          ]
        },
        {
          name: 'readFuture',
          signature: 'deviceCode: string, variableCode: string',
          returnType: 'value: any',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const value = await devices.read(\'<change_device_code>\', \'<change_variable_code>\');\n  /* do something with value*/\n} catch(err) {\n  logger.error(\'Error reading value from device\', err);\n}'
            }
          ]
        },
        {
          name: 'deviceStatus',
          signature: 'deviceCode: string',
          returnType: 'DeviceStatus',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const status = devices.deviceStatus(\'<change_device_code>\');\n  console.log(\'Status: \' + status.status);\n} catch(err) {\n  logger.error(\'Error reading status from device\', err);\n}'
            }
          ]
        },
        {
          name: 'devicesStatus',
          signature: '',
          returnType: 'DevicesStatus',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const statuses = devices.devicesStatus();\n  console.log(\'Statuses count: \' + Object.keys(statuses).length);\n  const firstDevice = Object.keys(statuses)[0];\n  console.log(\'First status: \' + JSON.stringify(statuses[firstDevice]));\n} catch(err) {\n  logger.error(\'Error reading status from device\', err);\n}'
            }
          ]
        }
      ]
    },
    DeviceProxy: {
      methods: [
        {
          name: 'findVariable',
          signature: 'variableCode: string',
          returnType: 'VariableProxy',
          snippets: [
            {
              code: 'default',
              content: 'const device = devices.find(\'<change_device_code>\');\nconst variable = device.findVariable(\'<change_variable_code>\');\nconst value = variable.read();'
            }
          ]
        },
        {
          name: 'write',
          signature: 'variableCode: string, value: any',
          snippets: [
            {
              code: 'default',
              content: 'const device = devices.find(\'<change_device_code>\');\nconst newValue = 123;\ntry {\n  device.write(\'<change_variable_code>\', newValue);\n} catch(err) {\n  logger.error(\'Error writing value to device\', err);\n}'
            }
          ]
        },
        {
          name: 'read',
          signature: 'variableCode: string',
          returnType: 'any',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const device = devices.find(\'<change_device_code>\');\n  const value = device.read(\'<change_variable_code>\');\n  /* do something with value*/\n} catch(err) {\n  logger.error(\'Error reading value from device\', err);\n}'
            }
          ]
        },
        {
          name: 'writeFuture',
          signature: 'variableCode: string, value: any',
          snippets: [
            {
              code: 'default',
              content: 'const device = devices.find(\'<change_device_code>\');\nconst newValue = 123;\ntry {\n  await device.writeFuture(\'<change_variable_code>\', newValue);\n} catch(err) {\n  logger.error(\'Error writing value to device\', err);\n}'
            }
          ]
        },
        {
          name: 'readFuture',
          signature: 'variableCode: string',
          returnType: 'Promise <any>',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const device = devices.find(\'<change_device_code>\');\n  const value = await device.readFuture(\'<change_variable_code>\');\n  /* do something with value*/\n} catch(err) {\n  logger.error(\'Error reading value from device\', err);\n}'
            }
          ]
        },
        {
          name: 'getStatus',
          signature: '',
          returnType: 'DeviceStatus',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const device = devices.find(\'<change_device_code>\');\n  const status = device.getStatus();\n  console.log(\'Status: \' + status.status);\n} catch(err) {\n  logger.error(\'Error reading status from device\', err);\n}'
            }
          ]
        }
      ]
    },
    VariableProxy: {
      methods: [
        {
          name: 'write',
          signature: 'value: any',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const variable = devices.find(\'<change_device_code>\', \'<change_variable_code>\');\n  const newValue = 123;\n  variable.write(newValue);\n} catch(err) {\n  logger.error(\'Error writing value to device\', err);\n}'
            }
          ]
        },
        {
          name: 'read',
          signature: '',
          returnType: 'value: any',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const variable = devices.find(\'<change_device_code>\', \'<change_variable_code>\');\n  const value = variable.read();\n} catch(err) {\n  logger.error(\'Error reading value from device\', err);\n}'
            }
          ]
        },
        {
          name: 'writeFuture',
          signature: 'value: any',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const variable = devices.find(\'<change_device_code>\', \'<change_variable_code>\');\n  const newValue = 123;\n  await variable.writeFuture(newValue);\n} catch(err) {\n  logger.error(\'Error writing value to device\', err);\n}'
            }
          ]
        },
        {
          name: 'readFuture',
          signature: '',
          returnType: 'Promise<value: any>',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const variable = devices.find(\'<change_device_code>\', \'<change_variable_code>\');\n  const value = await variable.readFuture();\n} catch(err) {\n  logger.error(\'Error reading value from device\', err);\n}'
            }
          ]
        },
        {
          name: 'getStatus',
          signature: '',
          returnType: 'VariableStatus',
          snippets: [
            {
              code: 'default',
              content: 'try {\n  const variable = devices.findVariable(\'<change_device_code>\', \'<change_variable_code>\');\n  const status = variable.getStatus();\n  console.log(\'Status: \' + status.status);\n} catch(err) {\n  logger.error(\'Error reading status from variable\', err);\n}'
            }
          ]
        }
      ]
    },
    AlertsProxy: {
      methods: [
        {
          name: 'create',
          signature: 'data: #AlertCreationData#',
          returnType: 'number'
        },
        {
          name: 'touchOrCreate',
          signature: 'data: #AlertCreationData#',
          returnType: 'number',
          snippets: [
            {
              code: 'default',
              content: 'alerts.touchOrCreate({\n  priority: 10,\n  type: \'Personalizado\',\n  payload: {\n    // El payload puede ser cualquier objeto serializable de JavaScript, se utiliza para verificar si dos alertas son equivalentes (es decir, a la hora de buscar una existente se compara este campo)\n    description: \'Alerta personalizada\'\n  },\n  dynamicPayload: {\n    // El campo dynamicPayload funciona como un payload que no altera la identidad de la alerta. Sirve para actualizar datos dinámicos de la alerta que queremos que siga siendo la misma (la función touchOrCreate va a actualizarla y no crear una nueva)\n    ultimoValor: devices.read(\'dispositivo\', \'variable\')\n  },\n  // Si la alerta no es tocada (touch) en 6 segundos, expira automáticamente\n  expireAfterMs: 6000\n});'
            },
            {
              code: 'stringCondition',
              description: 'Crear condición mediante una fórmula en texto',
              content: 'alerts.touchOrCreate({\n  priority: 10,\n  type: \'CondicionFormula\',\n  payload: \'Valor mayor que 19\',\n  expireAfterMs: 30000,\n  condition: \'$<change_device_code>#<change_variable_code>$ > 19\'\n});\n'
            },
            {
              code: 'functionCondition',
              description: 'Crear condición mediante una función',
              content: 'alerts.touchOrCreate({\n  priority: 10,\n  type: \'CondicionFuncion\',\n  payload: \'Puerta entrada abierta\',\n  expireAfterMs: 30000,\n  condition: () => devices.read(\'<change_device_code>\', \'<change_variable_code>\'),\n  onOutOfService: \'alert\'\n})'
            }
          ]
        },
        {
          name: 'find',
          signature: 'id: number',
          returnType: 'Alert'
        },
        {
          name: 'touch',
          signature: 'id: number',
          returnType: 'number'
        },
        {
          name: 'expire',
          signature: 'id: number'
        }
      ]
    },
    DeviceStatus: {
      fields: [
        { name: 'code', type: 'string', required: true },
        { name: 'status', type: 'Date', required: true },
        { name: 'timestamp', type: 'number', required: true }
      ]
    },
    ModuleStatus: {
      fields: [
        { name: 'code', type: 'string', required: true },
        { name: 'type', type: 'string', required: true },
        { name: 'status', type: 'Date', required: true },
        { name: 'statusTimestamp', type: 'number', required: false }
      ]
    },
    DevicesStatus: {
      fields: [
        { name: '<deviceCode>', type: 'DeviceStatus' }
      ]
    },
    VariableStatus: {
      fields: [
        { name: 'code', type: 'string', required: true },
        { name: 'status', type: 'Date', required: true },
        { name: 'timestamp', type: 'number', required: true }
      ]
    },
    AlertCreationData: {
      fields: [
        { name: 'priority', type: 'number', required: true },
        { name: 'type', type: 'string', required: true },
        { name: 'startTime', type: 'Date', },
        { name: 'endTime', type: 'Date' },
        { name: 'payload', type: 'any' },
        { name: 'dynamicPayload', type: 'any' },
        { name: 'expireAfterMs', type: 'number' },
        { name: 'tags', type: 'string[]' },
        { name: 'delay', type: 'number' },
        { name: 'condition', type: 'Formula|function' },
        { name: 'onConditionError', type: 'ON_ERROR_ACTIONS.Alert|ON_ERROR_ACTIONS.Ignore' },
        { name: 'onOutOfService', type: 'ON_ERROR_ACTIONS.Alert|ON_ERROR_ACTIONS.Ignore' }
      ]
    },
    GlobalScriptsData: {
      methods: [
        {
          name: 'registerVariableUse',
          signature: 'variable: string'
        },
        {
          name: 'deregisterVariableUse',
          signature: 'variable: string'
        }
      ]
    },
    VariableChangeEventData: {
      fields: [
        { name: 'deviceCode', type: 'string' },
        { name: 'variableCode', type: 'string' },
        { name: 'newValue', type: '{variable type}' }
      ]
    },
    VariableStatusChangeEventData: {
      fields: [
        { name: 'deviceCode', type: 'string' },
        { name: 'variableCode', type: 'string' },
        { name: 'newStatus', type: 'enum(INIT|WORKING|STOPPED|OUT_OF_SERVICE)' }
      ]
    },
    TimerEventData: {
      fields: [
        { name: 'timerObject', type: 'enum(Schedule|Interval)' },
        { name: 'timerName', type: 'string' },
        { name: 'timerEvent', type: 'enum(Trigger|StartSchedule|EndSchedule)' },
        { name: 'state', type: 'boolean' }
      ]
    },
    NewVariablesHistoricEventData: {
      fields: [
        { name: 'jobId', type: 'integer' },
        { name: 'aggregated', type: 'boolean' },
        { name: 'date', type: 'Date' },
        { name: 'period', type: 'string' }
      ],
      methods: [
        {
          name: 'getVariableValues',
          signature: '[deviceCode: string]',
          returnType: 'object',
          snippets: [
            {
              code: 'default',
              content: 'events.addListener({ eventName: \'NewVariablesHistoric\' }, async (event) => {\n  const values = event.getVariableValues(\'<change_device_code>\');\n  logger.info(\'Variable value: \' + values[\'<change_variable_code>\']);\n});'
            }
          ]
        },
        {
          name: 'getVariableValues',
          signature: 'deviceCode: string, variableCode: string',
          returnType: 'value: any',
          snippets: [
            {
              code: 'default',
              content: 'events.addListener({ eventName: \'NewVariablesHistoric\' }, async (event) => {\n  const value = event.getVariableValues(\'<change_device_code>\', \'<change_variable_code>\');\n  logger.info(\'Variable value: \' + value);\n});'
            }
          ]
        }
      ]
    },
    AlertEventData: {
      fields: [
        { name: 'action', type: 'enum(New|Update|Expire)' },
        { name: 'alert', type: '#AlertCreationData#' }
      ]
    },
    EqusScriptsAPI: {
      methods: [
        {
          name: 'alert',
          signature: 'alertCode: string, priority: number, text: string, value: any, expireAfterSecs: number, tags: string[]',
          snippets: [
            {
              code: 'default',
              content: 'const temp = devices.read(\'<change_device_code>\', \'<change_variable_code>\');\nif (temp > 30) {\n  equs.alert(\'alert1\', 1, \'Warning, high temperature!\', temp, 3600, [\'temperature\', \'rooms\']);\n}'
            }
          ]
        },
        {
          name: 'alert',
          signature: 'text: string, value: any, expireAfterSecs: number, extraOptions: #EqusAlertExtraOptions#',
          snippets: [
            {
              code: 'stringCondition',
              content: 'equs.alert(\'Warning, high temperature!\', temp, 3600, {\n  condition: \'$<change_device_code>#<change_variable_code>$ > 30.5\',\n  delay: 30000, /* if the value keeps high more than 30 secs */\n  onOutOfServiceError: \'ignore\', /* if the variable is out of service, do nothing */\n  onConditionError: \'alert\', /* if error calculating condition, create error alert */\n  priority: 1,\n  tags: [\'temperature\', \'rooms\']\n});'
            },
            {
              code: 'functionCondition',
              content: 'equs.alert(\'Warning, high temperature!\', temp, 3600, {\n  condition: () => { devices.read(\'<change_device_code>\', \'<change_variable_code>\') > 30.5},\n  delay: 30000, /* if the value keeps high more than 30 secs */\n  onOutOfServiceError: \'ignore\', /* if the variable is out of service, do nothing */\n  onConditionError: \'alert\', /* if error calculating condition, create error alert */\n  priority: 1,\n  tags: [\'temperature\', \'rooms\']\n});'
            }
          ]
        }
      ]
    },
    EqusAlertExtraOptions: {
      fields: [
        { name: 'priority', type: 'number' },
        { name: 'tags', type: 'string[]' },
        { name: 'condition', type: 'string|function' },
        { name: 'onConditionError', type: 'ON_ERROR_ACTIONS.Alert|ON_ERROR_ACTIONS.Ignore' },
        { name: 'onOutOfServiceError', type: 'ON_ERROR_ACTIONS.Alert|ON_ERROR_ACTIONS.Ignore' },
        { name: 'delay', type: 'number' }
      ]
    },
    TimerId: {},
    Utils: {
      methods: [
        {
          name: 'createScheduleManager',
          signature: 'options: ScheduleManagerOptions',
          returnType: 'ScheduleManager',
          snippets: [
            commonSnippets.ScheduleManager.default,
            commonSnippets.ScheduleManager.simpleEvent
          ]
        },
        {
          name: 'configureOneShotVariable',
          signature: 'variableConfig: OneShotVariableConfigData, callback: function()',
          snippets: [
            {
              code: 'default',
              content: 'const { configureOneShotVariable } = require(\'adquio-one-shot-variable\');\n\nconfigureOneShotVariable({ variableId: \'memory#int32\' }, () => {\n  logger.debug(\'One Shot called\');\n  const newValue = devices.read(\'memory\', \'variable1\') + 1;\n  devices.writeFuture(\'memory\', \'variable1\', newValue).catch(logger.err);\n})'
            },

            {
              code: 'one_time_event',
              content: 'const { configureOneShotVariable } = require(\'adquio-one-shot-variable\');\n\nconfigureOneShotVariable({\n  variableId: \'memory#int32\',\n  onValue: undefined, //default, undefined means any distinct 0 will trigger the variable\n  offValue: 0, //default, the value to reset the variable\n  timeout: 1000 //default, the time the variable should keep active before reset (ms)\n}, () => {\n  logger.debug(\'One Shot called\');\n  const newValue = devices.read(\'memory\', \'variable1\') + 1;\n  devices.writeFuture(\'memory\', \'variable1\', newValue).catch(logger.err);\n})'
            }
          ]
        }
      ]
    },
    OneShotVariableConfigData: {
      fields: [
        { name: 'variableId', type: 'string' },
        { name: 'onValue', type: 'number' },
        { name: 'offValue', type: 'number' },
        { name: 'timeout', type: 'number' }
      ]
    },
    ScheduleManager: {
      methods: [
        {
          name: 'addTimetable',
          signature: 'code: string, currentStatusVarId: string, variableDefs: TimetableVariableSelector[], options: TimetableOptions',
          snippets: [
            commonSnippets.ScheduleManager.default,
            commonSnippets.ScheduleManager.simpleEvent
          ]
        },
        {
          name: 'addVariables',
          signature: 'timetableCode: string, variables: TimetableVariableConfiguration[]',
          snippets: [
            commonSnippets.ScheduleManager.default,
            commonSnippets.ScheduleManager.simpleEvent
          ]
        },
        {
          name: 'recalculate',
          signature: ''
        },
        {
          name: 'start',
          signature: ''
        },
        {
          name: 'stop',
          signature: ''
        },
      ]
    },
    ScheduleManagerOptions: {
      fields: [
        { name: 'code', type: 'string' },
        { name: 'interval', type: 'number' },
      ]
    },
    TimetableVariableSelector: {
      fields: [
        { name: 'scheduleEnabledVarId', type: 'string' },
        { name: 'scheduleVarId', type: 'string' },
      ]
    },
    TimetableOptions: {
      fields: [
        { name: 'replacers', type: 'Object' }
      ]
    },
    TimetableVariableConfiguration: {
      fields: [
        { name: 'setVarId', type: 'string' },
        { name: 'modeVarId', type: 'number' },
        { name: 'setValueOn', type: 'number' },
        { name: 'setValueOff', type: 'number' },
        { name: 'modeOff', type: 'number' },
        { name: 'modeOn', type: 'number' },
        { name: 'modeAuto', type: 'number' },
        { name: 'forceKeepValue', type: 'boolean' }
      ]
    }
  }
};