import React, { Fragment } from 'react';
import { confirm } from '../../components/util';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { systemService } from '../../lib/service';
import { Row } from '../../components/GridModules/Row';
import { BlockBody } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { showErrorAlert } from '../../lib/apiHelpers';
import { COLORS } from '../../components/ColorCircle';
import { pick } from 'lodash';
import { SectionTitle } from '../../components/Layout/Page';
import { LabeledText } from '../../components/Form/Form';
import { StatusMonitor } from '../../components/StatusMonitor';
import { IntlSwitch } from '../../intl-components/Form';
import { handleAPIError } from '../../util/forms';

const getStatusCircleData = (status) => {
  const title = 'system.mqttServer.status.' + status;
  switch (status) {
    case 'ENABLED':
      return {
        color: COLORS.GREEN,
        title
      };
    case 'DISABLED':
      return {
        color: COLORS.RED,
        title
      };
    default:
      return {
        color: COLORS.GRAY,
        title: 'app.unknown'
      };
  }
};

class MqttServerViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      status: null,
      config: {
        enabled: false,
        port: null,
        username: null,
        password: null
      }
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    eventBus.publish(eventBusTopics.LOADING_START);
    systemService.get('mqttServerStatus')
      .then(result => {
        const values = pick(result.data, ['status', 'config']);
        this.setState({
          ...values,
          loading: false
        });
      })
      .catch(err => showErrorAlert(err))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END));
  }

  saveConfiguration(config) {
    const { intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
    systemService.update('mqttServer', config)
      .catch(response => handleAPIError(response, 'app', intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' }));
        this.fetchData();
      });
  }

  setMqttServerEnabled(enabled) {
    const { intl } = this.props;
    const apply = () => { this.saveConfiguration({ enabled }); };
    enabled ? apply() : confirm(intl, 'system.mqttServer.disableConfirm', () => apply());
  }

  render() {
    const { loading, status, config } = this.state;

    if (loading) {
      return null;
    }
    const rtStatusData = getStatusCircleData(status);
    const statusMonitor = (
      <StatusMonitor
        titleKey={rtStatusData.title}
        color={rtStatusData.color}
        messageKey={rtStatusData.title}
      />);

    return (
      <Fragment>
        <SectionTitle id='system.mqttServer.title' />
        <Row>
          <BlockBody>
            <IntlSwitch
              label='system.mqttServer.enabled'
              checked={config.enabled}
              onChange={(_ev, enabled) => this.setMqttServerEnabled(enabled)}
            />
            <LabeledText id='app.status' item={statusMonitor} />
            <LabeledText id='system.mqttServer.info.port' value={config.port} />
          </BlockBody>
        </Row >
      </Fragment >
    );
  }
}

export const MqttServerView = injectIntl(withRouter(MqttServerViewBase));