import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from '@material-ui/core/InputAdornment';
import VariablesSelectorDialog from '../../../views/components/VariablesSelectorDialog';
import { IntlTextField } from '../../../intl-components/Form';
import { SmallIconButton } from '../../Icons';


class VariableFormField extends React.Component {

  state = {
    showingDialog: false
  }

  variableSelected(variables) {
    const variable = variables[0];
    if (!!variable) {
      const key = `${variable.deviceCode}#${variable.variableCode}`;
      this.changeValue(key);
    }
  }

  changeValue(value) {
    this.props.onChange(value);
    this.setState({ showingDialog: false });
  }

  render() {
    const { tableMode, value, disabled, field, translationKey, availableVariables, allDeviceCodes, allDataTypes } = this.props;
    const margin = tableMode ? 'dense' : 'normal';
    let content = value || '';;

    return (
      <Fragment>
        <IntlTextField
          label={translationKey + '.' + field}
          value={content}
          margin={margin}
          onClick={() => this.setState({ showingDialog: true })}
          InputProps={{
            readOnly: true,
            disabled: false,
            endAdornment: (
              <InputAdornment key='variable-form-adornment' position='end'>
                <SmallIconButton
                  aria-owns='simple-popper'
                  aria-haspopup='true'
                  variant='contained'
                  disabled={disabled}
                >
                  <EditIcon />
                </SmallIconButton>
              </InputAdornment>
            )
          }}
        />
        {
          this.state.showingDialog &&
          <VariablesSelectorDialog
            key='form-variable-selector'
            enableMultipleSelection={false}
            availableVariables={availableVariables}
            allDeviceCodes={allDeviceCodes}
            allDataTypes={allDataTypes}
            translationKey='models.variableSelector'
            onSave={(data) => this.variableSelected(data)}
            onCancel={() => this.setState({ showingDialog: false })}
          />
        }
      </Fragment>
    );
  }
}

VariableFormField.propTypes = {
  availableVariables: PropTypes.array.isRequired,
  value: PropTypes.string
};

export default VariableFormField;
