import { datasources, uiSection } from '../common-spec';

const mqttBroker = {
  brokerHost: { type: 'string', validators: ['required'], ...uiSection(undefined, 1) },
  brokerTcpPort: { type: 'number', validators: ['required'], ...uiSection(undefined, 1) },
  /*brokerEnableTLS: { type: 'boolean', ...uiSection(undefined, 1) },*/
  brokerUsername: { type: 'string', ...uiSection(undefined, 2) },
  brokerPassword: { type: 'string', ...uiSection(undefined, 2) }
};

const serialPortParams = {
  baud: { type: 'number', defaultValue: 9600, ...uiSection(undefined, 2) },
  databits: { type: 'select', values: [8, 7, 6, 5], defaultValue: 8, ...uiSection(undefined, 2) },
  parity: { type: 'select', values: ['N', 'E', 'O'], defaultValue: 'N', ...uiSection(undefined, 2) },
  stopbits: { type: 'select', values: [0, 1, 2], defaultValue: 1, ...uiSection(undefined, 2) }
};

const portParam = (hwPorts, portType) => ({
  port: {
    type: 'select',
    validators: ['required'],
    values: hwPorts.filter(p => p.type === portType).map(p => ({ value: p.code, text: `${p.name || p.code || p.type} (${p.port})` })),
    ...uiSection(undefined, 1)
  }
});

const serialConnectionBase = hwPorts => ({
  ...portParam(hwPorts, 'IO'),
  /*...serialPortParams*/
});
const rs485ConnectionBase = hwPorts => ({
  ...portParam(hwPorts, '485'),
  ...serialPortParams
});
const netPortConnectionBase = hwPorts => ({
  ...portParam(hwPorts, 'eth')
});

const commonBacnetProperties = {
  bacnetDeviceInstance: { type: 'number', validators: ['required'], ...uiSection(undefined, 3) },
  bacnetAPDUTimeout: { type: 'number', ...uiSection(undefined, 4) },
  bacnetAPDURetries: { type: 'number', ...uiSection(undefined, 4) },
};
// adquio_client, io, memory, modbusrtu, modbustcp, bacnetip, bacnetmstp, stats, zennio, axis_people_counter
const connections = hwPorts => ({
  adquio_client: {
    ip: { type: 'string', validators: ['required'], ...uiSection(undefined, 1) },
    tcpPort: { type: 'number', validators: ['required'], ...uiSection(undefined, 1) },
    user: { type: 'string', validators: [], ...uiSection(undefined, 2) },
    password: { type: 'string', validators: [], ...uiSection(undefined, 2) }
  },
  casambi: {
    apiUrl: { type: 'string', defaultValue: 'https://door.casambi.com/' },
    socketUrl: { type: 'string', defaultValue: 'wss://door.casambi.com/v1/bridge/' },
    apiKey: { type: 'string', validators: ['required'] },
    email: { type: 'string', validators: ['required'] },
    password: { type: 'string', validators: ['required'] }
  },
  modbusrtu: {
    ...rs485ConnectionBase(hwPorts),
    modbusSilentInterval: { type: 'milliseconds', defaultValue: 5, ...uiSection(undefined, 3) }
  },
  mqtt: {
    ...mqttBroker
  },
  modbustcp: {
    ip: { type: 'string', validators: ['required'], ...uiSection(undefined, 1) },
    tcpPort: { type: 'number', validators: ['required'], ...uiSection(undefined, 1) },
    modbusSilentInterval: { type: 'milliseconds', defaultValue: 0, ...uiSection(undefined, 3) }
  },
  bacnetip: {
    ...netPortConnectionBase(hwPorts),
    ...commonBacnetProperties,
    bacnetNetworkPort: { type: 'binaryNumber', defaultValue: 47808, ...uiSection(undefined, 3) },
    bacnetBBMDAddress: { type: 'string', ...uiSection(undefined, 6) },
    bacnetBBMDPort: { type: 'binaryNumber', defaultValue: 47808, ...uiSection(undefined, 6) },
    bacnetBBMDTimeToLive: { type: 'number', ...uiSection(undefined, 6) },
  },
  bacnetmstp: {
    ...rs485ConnectionBase(hwPorts),
    ...commonBacnetProperties,
    bacnetMstpMacAddress: { type: 'number', ...uiSection(undefined, 3) },
    bacnetMstpMaxMaster: { type: 'number', ...uiSection(undefined, 3) },
    bacnetMstpMaxInfoFrames: { type: 'number', ...uiSection(undefined, 3) },
  },
  io: serialConnectionBase(hwPorts),
  zennio: { datasources },
  axis_people_counter: { datasources }
});

export const modulesSpec = (type, hwPorts) => {
  return { connection: connections(hwPorts)[type] };
};
