
const VALUE_CONVERTER_REGEX = /^([a-zA-Z0-9_-]+)(\([a-zA-Z0-9._-]+(\s*,\s*[a-zA-Z0-9._-]+)?\))?$/;
/**
 * Each validator returns one error identifier or false/undefined if value is correct
 */
export const validators = {
  required: value => (value == null || value === '') && 'required',
  numeric: value => !(isFinite(value) && !isNaN(Number(value))) && 'numeric',
  range: (min, max) => value => !!value && (Number(value) < min || Number(value) > max) && 'range',
  integer: value => !!value && (!Number.isInteger(Number(value))) && 'integer',
  minimum: min => value => !!value && (Number(value) < min) && 'minimum|' + min,
  maximum: max => value => !!value && (Number(value) > max) && 'maximum|' + max,
  identifier: value => !(/^[a-zA-Z][a-zA-z0-9_-]*$/.test(value)) && 'identifier',
  json: value => {
    try {
      !!value && JSON.parse(value);
    } catch (e) {
      return 'json';
    }
  },
  jsFunctionContent: value => {
    try {
      const code = '{ const alert=_=>{}; const console={log:_=>{}}; (payload) => {\n' + value + '\n}}';
      // eslint-disable-next-line no-eval
      eval(code);
    } catch (e) {
      return 'jsFunctionContent';
    }
  },
  //FIXME this is a fairly basic aproximation, but it can validate invalid cron sentences like *-5 or 1,5/6/9
  cron: value => !(/^(\s*([\d*]+([,-/]\d)*)\s*){5,6}$/.test(value)) && 'cron',
  integerCSV: value => !(/^\s*(-?\d+(\s*,\s*-?\d+)*)?\s*$/.test(value)) && 'integerCSV',
  numberCSV: value => !(/^\s*(-?\d+(\.\d+)?(\s*,\s*-?\d+(\.\d+)?)*)?\s*$/.test(value)) && 'numberCSV',
  numberArray: value => !(value == null ||
    (Array.isArray(value) && value.every(i => (i !== '') && (i !== null) && !isNaN(i) && isFinite(i)))) && 'numberArray',
  integerArray: value => !(value == null ||
    (Array.isArray(value) && value.every(i => Number.isInteger(i)))) && 'integerArray',
  stringArray: value => !(value == null ||
    (Array.isArray(value) && value.every(i => i.trim() !== ''))) && 'stringArray',
  valueConverters: value => !(value == null ||
    (Array.isArray(value) && value.every(i => VALUE_CONVERTER_REGEX.test(i)))) && 'valueConverters',
  datasources: value => !(value == null ||
    (Array.isArray(value))) && 'datasources',
  modbusAddress: value => // value1[.value2], with value1 hex (starting with '0x') or base 10
    !(/(^(0x)[0-9a-fA-F]+(\.[0-9a-fA-F]+)?$)|(^[0-9]+(\.[0-9]+)?$)/.test(value)) && 'modbusAddress',
  modbusMask: value => // 16 bit, binary or hex ('0x' + 4 hex chars) value
    (value != null) && !(/(^(0x)[0-9a-fA-F]{1,4}$)|(^[0-1]{1,16}$)/.test(value)) && 'modbusMask',
  MAC: value => !!value && !(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(value)) && 'MAC',
  IPv4: value => {
    if (!value) return;
    let bytes = value.split('.');
    if (bytes.includes('') || bytes.includes(' ')) return 'IPv4';
    bytes = bytes.map(Number);
    if (bytes.length !== 4) return 'IPv4';
    return bytes.some(n => isNaN(n) || !Number.isInteger(n) || n < 0 || n > 255) && 'IPv4';
  },
  IPv4CIDR: value => {
    if (value == null) return 'IPv4CIDR';
    const chunks = value.split('/');
    if (chunks.length !== 2) return 'IPv4CIDR';
    const mask = Number(chunks[1]);
    const ipFail = !!validators.IPv4(chunks[0]);
    const maskFail = isNaN(mask) || !Number.isInteger(mask) || mask < 0 || mask > 32;
    return (ipFail || maskFail) && 'IPv4CIDR';
  },
  netmask4: value => {
    if (!value) return;
    let bytes = value.split('.');
    if (bytes.includes('') || bytes.includes(' ')) return 'netmask4';
    bytes = bytes.map(Number);
    if (bytes.some((b, i) => i !== 0 && b > 0 && bytes[i - 1] < 255)) return 'netmask4';
    const bits = bytes.map(i => i.toString(2));
    if (bits.some(b => b.lastIndexOf('1') > b.indexOf('0') && b.indexOf('0') > 0)) return 'netmask4';
  }
};
