import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Row } from '../../components/GridModules/Row';
import { TableBlock } from '../../components/GridModules/ComponentBlock/TableBlock';
import { BlockTitle, BlockActions, BlockBody, BlockHeader } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import DataTable from '../../components/Table/DataTable';
import CommonFormOptions from '../components/CommonFormOptions';
import AutomaticAlertFormDialog from './AutomaticAlertFormDialog';
import { automaticAlertsService } from '../../lib/service';
import { msToLabel } from '../../lib/timeTools';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { handleAPIError } from '../../util/forms';

class AutomaticAlertsViewBase extends React.Component {
  state = {
    data: [],
    loading: true,
    editingAutomaticAlert: null
  }

  async componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const data = await automaticAlertsService.find();
    this.setState({ data, editingAutomaticAlert: null, loading: false });
  }

  removeAutomaticAlert({ id }) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    automaticAlertsService.remove(id)
      .then(() => this.fetchData())
      .catch(response => handleAPIError(response, 'models.automaticAlerts', this.props.intl))
      .finally(() => eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' })));
  }

  render() {
    return (
      <Row>
        <TableBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id="section.automaticAlerts" />
            </BlockTitle>
            <BlockActions>
              <Tooltip title={this.props.intl.formatMessage({ id: 'models.automaticAlerts.new' })} >
                <IconButton onClick={() => this.setState({ editingAutomaticAlert: {} })} >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <DataTable
              translationKey="models.automaticAlerts"
              columns={[
                { data: 'type', sortable: true },
                { data: 'object', sortable: true },
                { data: 'delay', sortable: true },
                {
                  data: row => (
                    <CommonFormOptions
                      onEdit={() => this.setState({ editingAutomaticAlert: row })}
                      onRemove={() => this.removeAutomaticAlert(row)}
                    />)
                }
              ]}
              rowMapFunction={({ delay, ...other }) => ({ delay: msToLabel(delay), ...other })}
              data={this.state.data}
              loading={this.state.loading}
            />
          </BlockBody>
        </TableBlock>
        {this.state.editingAutomaticAlert &&
          <AutomaticAlertFormDialog
            data={this.state.editingAutomaticAlert}
            onSave={() => this.fetchData()}
            onCancel={() => this.setState({ editingAutomaticAlert: null })}
          />
        }
      </Row>
    );
  }
}

const AutomaticAlertsView = injectIntl(AutomaticAlertsViewBase);
export default AutomaticAlertsView;