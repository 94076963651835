import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, ListItemIcon, ListItemText } from '@material-ui/core';
import { UserZoneLabel } from './UserZoneLabel';
import { ICONS } from '../../Table/FAIconButton';
import { Menu, MenuItem } from '@material-ui/core';
import { MenuBook, FiberNew } from '@material-ui/icons';
import { fetchText } from '../../../lib/fileReader';
import * as ReactMarkdown from 'react-markdown';

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

const StyledMenu = styled(Menu)`
  a {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
`;

const StyledMarkdown = styled(ReactMarkdown)`
ul {
  font-size: 100%;
  list-style: square;
  padding-left: 20px;
  li {
    margin: 5px 0;
  }
}
&>ul {
  margin: 15px 0;
}
p, h1 {
  margin: 15px 0;
  font-size: 100%;
}
h1 {
  font-size: 150%;
}
h2 {
  font-size: 140%;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}
h2:not(:first-child)::before {
  content: '';
  width: 100%;
  height: 1px;
  left: 0;
  display: block;
  clear: both;
  background-color: gray;
  margin-bottom: 1em;
}
h3 {
  font-size: 120%;
}
`;

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export class UserManual extends Component {

  state = {
    anchorEl: null,
    showChangelog: false
  }

  componentDidMount() {
    this.fetchChangelog();
  }

  fetchChangelog() {
    fetchText('changelog/es.md')
      .then(content => this.setState({ content }))
      .catch(err => {
        console.log('Error loading changelog', err);
        this.setState({ content: '' });
      });
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <Fragment>
        <UserZoneLabel
          aria-owns={anchorEl ? 'dropper-menu' : null}
          aria-haspopup='true'
          onClick={e => this.setState({ anchorEl: e.currentTarget })}
          icon={ICONS.faQuestionCircle}
        />
        <StyledMenu
          id='dropper-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          <MenuItem onClick={() => this.setState({ showChangelog: true })}>
            <StyledListItemIcon>
              <FiberNew />
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='changelog' />
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => openInNewTab('man/es/')}>
            <StyledListItemIcon>
              <MenuBook />
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='manual.spanishManual' />
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => openInNewTab('man/en/')}>
            <StyledListItemIcon>
              <MenuBook />
            </StyledListItemIcon>
            <ListItemText>
              <FormattedMessage id='manual.englishManual' />
            </ListItemText>
          </MenuItem>
        </StyledMenu>

        {this.state.showChangelog && <Dialog
          aria-labelledby="changelog-title"
          aria-describedby="changelog-title"
          open={true}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="changelog-title">
            <FormattedMessage id='changelog' />
          </DialogTitle>
          <DialogContent>
            <StyledMarkdown source={this.state.content} />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => this.setState({ showChangelog: false })}>
              <FormattedMessage id="app.close" />
            </Button>
          </DialogActions>
        </Dialog>
        }
      </Fragment >
    );
  }

}