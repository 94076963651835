import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList';
import Cancel from '@material-ui/icons/Cancel';
import { Select } from '../../Form/Form';
import { ColumnFilterFieldWrapper } from '../../GridModules/ComponentBlock/TableBlock';

class SelectFilterColumnHeader extends React.Component {

  cancelFilter() {
    this.props.onFilterChange(undefined);
  }

  render() {
    const { filterOptions, children, filterValue, onFilterChange, cellMapFunction } = this.props;
    if (filterOptions && filterValue !== undefined) {
      return [
        <ColumnFilterFieldWrapper key='filter_select_wrapper'>
          <Select
            value={filterValue}
            onChange={ev => onFilterChange(ev.target.value ? ev.target.value : undefined)}
          >
            {filterOptions.map(option => (
              <MenuItem key={option} value={option ? option : ''}>
                {cellMapFunction ? cellMapFunction(option) : option}
              </MenuItem>
            ))}
          </Select>
          <IconButton onClick={() => this.cancelFilter()}>
            <Cancel />
          </IconButton>
        </ColumnFilterFieldWrapper>
      ];
    } else if (filterOptions) {
      return [
        children,
        <IconButton key='filter_key' onClick={() => onFilterChange('')}>
          <FilterList />
        </IconButton>
      ];
    } else {
      return children;
    }
  }
}

SelectFilterColumnHeader.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.string,
  onFilterChange: PropTypes.func,
  columnTranslationKey: PropTypes.string
};

export default SelectFilterColumnHeader;