import React from 'react';
import styled from 'styled-components';
import eventBus, { eventBusTopics } from '../lib/eventBus';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { logsService } from '../lib/service';

import { ComponentBlock, BlockHeader, BlockTitle, BlockActions, BlockBody } from './GridModules/ComponentBlock/ComponentBlock';
import { DynamicFormField } from './Dynamics/DynamicFormField';
import { Tooltip, IconButton, LinearProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Row } from './GridModules/Row';

const MAX_LOGS_LENGTH = 1 * 1024 * 1024; // 1mb max logs length
const LogTextarea = styled.textarea`
  font-family: "Courier New", Courier, monospace;
`;

class LogsComponentBlockBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logs: '',
      loading: true,
      logLines: 16,
      subscriptionInterval: null,
      realTime: true
    };
    this.textRef = React.createRef();
  }

  componentDidMount() {
    this.updateLogs();
    this.registerWatch();
  }

  componentWillUnmount() {
    this.unregisterWatch();
  }

  changeRealTime(value) {
    const { realTime } = this.state;
    if (realTime !== !!value) {
      this.setState({ realTime: value }, () => {
        this.unregisterWatch();
        if (value) {
          this.registerWatch();
          this.updateLogs();
        }
      });
    }
  }

  updateLogs() {
    const { logType, logId, logLines } = this.props;
    this.setState({ loading: true }, () =>
      logsService.get({ type: logType, id: logId, logLines }).then(response => {
        this.setState({ logs: response.content, loading: false },
          () => this.textRef.current.scrollTop = this.textRef.current.scrollHeight);
      })
    );
  }

  registerWatch() {
    const { logType, logId, intl } = this.props;
    const { realTime } = this.state;
    if (!realTime) {
      return;
    }
    clearInterval(this.state.subscriptionInterval);
    logsService.enableLogWatch({ type: logType, id: logId })
      .then(() => {
        this.setState({
          subscriptionInterval: setInterval(() => {
            if (realTime) {
              this.updateSubscription();
            }
          }, 20000)
        });
      })
      .catch(err => {
        eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'LogSubscriptionError' });
        this.setState({ realTime: false });
        setTimeout(() =>
          this.addLogLine(`  [${intl.formatMessage({ id: 'components.logsComponentBlock.errors.subscribing' })}]`)
          , 1000);
      });
    logsService.on('LogLineMessage', this.onLog);
  }

  updateSubscription() {
    const { logType, logId, intl } = this.props;
    logsService.enableLogWatch({ type: logType, id: logId })
      .catch(() => {
        this.addLogLine(`  [${intl.formatMessage({ id: 'components.logsComponentBlock.errors.connectionLost' })}]`);
      });
  }

  unregisterWatch() {
    clearInterval(this.state.subscriptionInterval);
    logsService.removeListener('LogLineMessage', this.onLog);
  }

  onLog = (data) => {
    const { loading } = this.state;
    const { logType, logId } = this.props;
    if (!loading && (data.type === logType) && (data.id === logId)) {
      this.addLogLine(data.data);
    }
  }

  addLogLine(line) {
    const { logs } = this.state;
    let newLogs;
    if (logs.length > MAX_LOGS_LENGTH) {
      newLogs = logs.substring(logs.indexOf('\n') + 1);
    } else {
      newLogs = logs;
    }
    const requiresNewLine = !logs.endsWith('\n');
    newLogs += (requiresNewLine ? '\n' : '') + line;
    this.setState({ logs: newLogs },
      () => this.textRef.current.scrollTop = this.textRef.current.scrollHeight);
  }

  render() {
    const { titleKey, intl, compactView } = this.props;
    const { logs, logLines, realTime } = this.state;
    const titleElement = this.props.titleElement || (<FormattedMessage id={titleKey || 'components.logsComponentBlock.genericTitle'} />);
    return (
      <ComponentBlock style={{ width: '100%', marginBottom: compactView ? 0 : undefined }}>
        <BlockHeader style={{ padding: compactView ? 0 : undefined, minHeight: compactView ? 0 : undefined }}>
          <BlockTitle>
            {titleElement}
          </BlockTitle>
          <BlockActions>
            <DynamicFormField
              field='realTime'
              fieldType={{ type: 'boolean' }}
              value={realTime}
              translationKey='components.logsComponentBlock'
              onChange={x => this.changeRealTime(x)}
            />
            <DynamicFormField
              field='logLines'
              fieldType={{ type: 'select', values: ['16', '100', '1000', '10000'] }}
              value={logLines}
              translationKey='components.logsComponentBlock'
              onChange={x => this.setState({ logLines: x }, this.updateLogs())}
            />
            <Tooltip title={intl.formatMessage({ id: 'app.refresh' })} >
              <IconButton onClick={() => this.updateLogs()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </BlockActions>
        </BlockHeader>
        <BlockBody>
          {this.state.loading && <LinearProgress />}
          <Row>
            <LogTextarea value={logs} readOnly rows={12} ref={this.textRef} />
          </Row>
        </BlockBody>
      </ComponentBlock>
    );
  }
}

export const LogsComponentBlock = injectIntl(LogsComponentBlockBase);
LogsComponentBlock.propTypes = {
  titleKey: PropTypes.string,
  logType: PropTypes.string.isRequired,
  logId: PropTypes.string.isRequired,
  logsTitle: PropTypes.any
};