import React, { useState, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { omit } from 'lodash';

import { PropTypes } from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { List, Collapse, Menu, MenuItem } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';



const StyledList = styled(List)`
  overflow-x: hidden;
  color: ${props => props.theme.CountertypeTextColor};
`;

const StyledListItem = styled(ListItem)`
  && {
    padding-left: ${props => props.theme.MarginSize};
    padding-right: ${props => props.theme.MarginSize};
    &:hover {
      background-color: ${props => props.theme.BgDarkHoverColor};
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  && {
    color: ${props => props.theme.CountertypeTextColor};
    width: 16px;
  }
`;

export const AppMenuIcon = ({ icon }) => (
  <ListItemIcon>
    <StyledFontAwesomeIcon icon={icon} />
  </ListItemIcon>
);

const BasicNavMenuOption = ({ title, icon, IconComponent, open, routes, ViewComponent, ...other }) => (
  <StyledListItem button {...other}>
    {icon && <AppMenuIcon icon={icon} />}
    {IconComponent &&
      <IconComponent />
    }
    <ListItemText>
      <FormattedMessage id={`section.${title}`} />
    </ListItemText>
    {open != null && (open ? <ExpandLess /> : <ExpandMore />)}
  </StyledListItem>
);

const NavMenuOption = styled(BasicNavMenuOption)`
  &>.MuiListItemIcon-root {
    min-width: 50px;
  }
`;

const NestedNavMenuOption = styled(NavMenuOption)`
  padding-left: 42px !important;
  &>.MuiListItemIcon-root {
    min-width: 32px;
  }
`;

const NavMenuListOption = withRouter(({ history, title, path, routes, staticContext, ...other }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  return (
    <Fragment key={title}>
      <NavMenuOption
        {...other}
        title={title}
        onClick={ev => setAnchorEl(anchorEl ? undefined : ev.currentTarget)}
      />
      <Menu
        id='dropper-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        {routes.map(route => (
          <MenuItem key={route.path} onClick={() => {
            setAnchorEl(undefined);
            history.push(`/${path}/${route.path}`);
          }}>
            <ListItemText>
              <FormattedMessage id={`section.${route.title}`} />
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
});

const NestedNavMenu = withRouter(({ history, title, path, routes, staticContext, ...other }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <NavMenuOption
        {...other}
        title={title}
        open={open}
        onClick={() => setOpen(!open)}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {routes.map(route => <NestedNavMenuOption icon="chevron-right" key={route.title} {...route} path={`${path}/${route.path}`} onClick={() => history.push(`/${path}/${route.path}`)} />)}
      </Collapse>
    </Fragment>
  );
});

export const AppMenu = (props) => {
  return (
    <StyledList className={props.className}>
      {props.navigationLinks
        .map(route => omit(route, 'navigation', 'isRouter', 'ViewComponent'))
        .map(route => {
          if (route.routes) {
            return props.expanded
              ? <NestedNavMenu key={route.path} {...route} />
              : <NavMenuListOption key={route.path} {...route} />;
          } else {
            return <NavMenuOption key={route.path} {...route} component={Link} to={`/${route.path}`} />;
          }
        })}
    </StyledList>
  );
};

AppMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  navigationLinks: PropTypes.array.isRequired
};
