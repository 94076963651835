import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, omit } from 'lodash';

import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import { daySpec } from '../scheduler-specs';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import { DynamicFormTable } from '../../../components/Dynamics/DynamicFormTable';
import TimeSelectorFormEntry, { DAYS, MONTHS } from '../TimeSelectorFormEntry';
import { sortDays, apiDaysToUI, uiDaysToAPI } from '../SchedulerUiUtils';
import { calendarsService } from '../../../lib/service';
import { handleAPIError } from '../../../util/forms';

class CalendarForm extends React.Component {

  constructor(props) {
    super(props);
    const calendar = Object.assign({
      timeSelector: { daysOfWeek: [...DAYS.keys()], months: [...MONTHS.keys()] }
    }, props.calendar);
    this.state = {
      calendar,
      errors: undefined
    };
  }

  updateCalendar(field, value) {
    this.setState({
      calendar: {
        ...this.state.calendar,
        [field]: value
      },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  async onSave() {
    const errors = this.validationChain.getErrors();
    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      let calendar = this.state.calendar;
      try {
        calendar.id
          ? await calendarsService.update(calendar.id, calendar)
          : await calendarsService.create(calendar);
        this.props.onSave(this.state.calendar);
        this.setState({ errors: undefined });
      } catch (response) {
        const errors = handleAPIError(response, 'models.calendars', this.props.intl,
          { exclusions: 'models.calendars.days', inclusions: 'models.calendars.days' });
        this.setState({ errors });
      } finally {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      }
    }
  }

  render() {
    const { calendar, errors } = this.state;
    const inclusions = calendar.inclusions || [];
    const exclusions = calendar.exclusions || [];
    const allCalendars = [{ value: 'none', translationId: 'app.none' }, ...(this.props.allCalendars || []).map(c => { return { value: c.id, text: c.name }; })];
    this.validationChain = new ValidationChain();

    return (
      <Dialog
        aria-labelledby="calendar-form-title"
        aria-describedby="calendar-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="calendar-form-title">
          {calendar.id ?
            <FormattedMessage id="models.calendars.edit" /> :
            <FormattedMessage id="models.calendars.new" />
          }
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row>
              <DynamicFormField
                field='name'
                fieldType={{ type: 'string', validators: ['required', 'identifier'] }}
                translationKey='models.calendars'
                value={calendar.name}
                validationChain={this.validationChain}
                errors={get(errors, 'name')}
                onChange={n => this.updateCalendar('name', n)}
              />
            </Row>
            <Row>
              <DynamicFormField
                validationChain={this.validationChain}
                value={calendar.parentId || 'none'}
                field='parentId'
                fieldType={{ type: 'select', validators: ['required'], values: allCalendars.filter(c => c.value !== calendar.id) }}
                translationKey={'models.calendars'}
                onChange={value => this.updateCalendar('parentId', value === 'none' ? undefined : value)}
              />

            </Row>
            <Row>
              <TimeSelectorFormEntry
                validationChain={this.validationChain}
                timeSelector={calendar.timeSelector}
                onChange={data => this.updateCalendar('timeSelector', data)}
              />
            </Row>
            <Row>
              <DynamicFormTable
                label="models.calendars.inclusions"
                spec={daySpec}
                defaultEntry={{}}
                translationKey='models.calendars.days'
                entries={sortDays(apiDaysToUI(inclusions))}
                validationChain={this.validationChain}
                errors={get(errors, 'inclusions')}
                onChange={inclusions => this.updateCalendar('inclusions', uiDaysToAPI(inclusions))}
              />
            </Row>
            <Row>
              <DynamicFormTable
                label="models.calendars.exclusions"
                spec={daySpec}
                defaultEntry={{}}
                translationKey="models.calendars.days"
                entries={sortDays(apiDaysToUI(exclusions))}
                validationChain={this.validationChain}
                errors={get(errors, 'exclusions')}
                onChange={exclusions => this.updateCalendar('exclusions', uiDaysToAPI(exclusions))}
              />
            </Row>
          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

CalendarForm.propTypes = {
  calendar: PropTypes.object,
  allCalendars: PropTypes.arrayOf(PropTypes.object),
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default injectIntl(CalendarForm);