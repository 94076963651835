import React, { Fragment } from 'react';
import styled from 'styled-components';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { confirm } from '../../components/util';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { systemService, rapUsersService } from '../../lib/service';
import { Row } from '../../components/GridModules/Row';
import { BlockActions, BlockBody, BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { showErrorAlert } from '../../lib/apiHelpers';
import { COLORS } from '../../components/ColorCircle';
import { SectionTitle } from '../../components/Layout/Page';
import { LabeledText } from '../../components/Form/Form';
import { StatusMonitor } from '../../components/StatusMonitor';
import { IntlSwitch } from '../../intl-components/Form';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { handleAPIError } from '../../util/forms';
import { IntlDataTable } from '../../intl-components/IntlDataTable';
import EditUserDialog from '../../components/Layout/EditUserDialog';

const HiddenPasswordStyle = styled.div`
color: rgba(0, 0, 0, 0.54);
>.value {
  display: inline-block;
  min-width: 200px;
  vertical-align: super;
  padding: 0px 10px;
}
`;

class HiddenPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: false
    };
  }

  showPassword() {
    const { showing } = this.state;
    this.setState({ showing: !showing });
    if (!showing) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = setTimeout(() => this.setState({ showing: false }), 4000);
    }
  }

  render() {
    const { showing } = this.state;
    let value;
    if (showing) {
      value = this.props.value;
    } else {
      value = '********';
    }
    return (<HiddenPasswordStyle>
      <ViewIcon onClick={() => this.showPassword()} />
      <span className='value'>{value}</span>
    </HiddenPasswordStyle>);
  }
}

const getStatusCircleData = (status) => {
  const title = 'system.devicesServer.status.' + status;
  switch (status) {
    case 'ENABLED':
      return {
        color: COLORS.GREEN,
        title
      };
    case 'DISABLED':
      return {
        color: COLORS.GRAY,
        title
      };
    default:
      return {
        color: COLORS.GRAY,
        title: 'app.unknown'
      };
  }
};

class DevicesServerViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      devicesServer: {
        enabled: false,
        status: '',
        tcpPort: null
      },
      users: [],
      userEdition: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));
    systemService.get('devicesServerConfig')
      .then(result => {
        this.setState({
          devicesServer: result
        });
        return rapUsersService.find();
      }).then(users => {
        this.setState({
          users: users
        });
      })
      .catch(err => showErrorAlert(err))
      .finally(() => {
        this.setState({ loading: false });
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' }));
      });
  }

  saveConfiguration(config) {
    const { intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
    systemService.update('devicesServerConfig', config)
      .catch(err => {
        console.error('Error delete', err);
        handleAPIError(err, 'app', intl);
      })
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' }));
        this.fetchData();
      });
  }

  setDevicesServerEnabled(enabled) {
    this.saveConfiguration({ enabled });
  }

  addUser() {
    this.setState({ userEdition: { login: '', password: '' } });
  }

  editUser(row) {
    this.setState({ userEdition: { id: row.id, login: row.login, password: '' } });
  }

  deleteUser(row) {
    const { intl } = this.props;
    confirm(intl, 'app.confirmDelete', async () => {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.applying' }));
      try {
        await rapUsersService.remove(row.id);
      } catch (err) {
        console.error('Error delete', err);
        handleAPIError(err, 'app', this.props.intl);
      }
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.applying' }));
      this.fetchData();
    });
  }

  renderPassword(row) {
    return (<HiddenPassword
      value={row.password}
    />);
  }

  render() {
    const { intl } = this.props;
    const { loading, devicesServer, users, userEdition } = this.state;
    const { enabled, status, tcpPort } = devicesServer;

    const columns = [
      { data: 'login' },
      {
        columnKey: 'password',
        data: this.renderPassword.bind(this)
      }
    ];

    const statusData = getStatusCircleData(status);
    const statusMonitor = (
      <StatusMonitor
        titleKey={statusData.title}
        color={statusData.color}
        messageKey={statusData.title}
      />);

    return (
      <Fragment>
        <SectionTitle id='system.devicesServer.title' />
        <Row>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='system.devicesServer.general.title' />
            </BlockTitle>
            <BlockActions>
              <Tooltip title={intl.formatMessage({ id: 'app.refresh' })} >
                <IconButton onClick={() => this.fetchData()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </BlockActions>
          </BlockHeader>
          <BlockBody>
            <IntlSwitch
              label='app.enabled'
              checked={enabled}
              disabled={loading}
              onChange={(_ev, enabled) => this.setDevicesServerEnabled(enabled)}
            />
            <LabeledText id='app.status' item={statusMonitor} />
            <LabeledText id='system.devicesServer.general.tcpPort' value={tcpPort} />
            <FormattedMessage id='system.devicesServer.description' />
          </BlockBody>
        </Row>
        <Row>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id='system.devicesServer.access.title' />
            </BlockTitle>
          </BlockHeader>
          <BlockBody>
            <IntlDataTable
              titleKey='models.users.users'
              isCustomizable={false}
              onChange={() => { }}
              translationKey='system.devicesServer.columns'
              columnTranslationKeys={[null, null]}
              columns={columns}
              data={users}
              rowActions={[{
                icon: <EditIcon />,
                textKey: 'app.edit',
                action: (row) => this.editUser(row),
                outMenu: true
              }, {
                icon: <DeleteIcon />,
                textKey: 'app.remove',
                action: (row) => this.deleteUser(row)
              }]}
              tableActions={[{
                icon: <AddIcon />,
                textKey: 'app.create',
                action: () => this.addUser(),
                outMenu: true
              }]}
              loading={loading}
            />
          </BlockBody>
        </Row>
        {userEdition != null &&
          <EditUserDialog
            data={{ ...userEdition }}
            onClose={() => this.setState({ userEdition: null }, () => this.fetchData())}
          />
        }
      </Fragment >
    );
  }
}

export const DevicesServerView = injectIntl(withRouter(DevicesServerViewBase));