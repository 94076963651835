import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnsavedChangesIcon = styled.a`
display: flex;
align-items: center;
color: ${props => props.theme.CountertypeTextColor};
cursor: ${props => props.onClick ? 'pointer' : 'default'};
> svg {
  box-sizing: content-box;
  padding: 8px
  border-radius: 3px;
}
> span, > label {
  margin-left: ${props => props.theme.HalfMarginSize};
  margin-right: ${props => props.theme.HalfMarginSize};
  cursor: inherit;
  font-weight: 500;
}

background-color: #ed143d;
border-radius: 4px;
padding: 0 6px;
color: white;
text-transform: uppercase;
font-weight: bold;
`;


const UnsavedChangesLabel = ({ text, icon, children, ...other }) => (
  <UnsavedChangesIcon {...other}>
    <FontAwesomeIcon icon={icon} />
    {text && <label>{text}</label>}
    {children}
  </UnsavedChangesIcon >
);

export default UnsavedChangesLabel;
