import Button from '@material-ui/core/Button';
import { debounce, omit } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import DynamicFormGroup from '../../../components/Dynamics/DynamicFormGroup';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import { BlockBody, BlockHeader, BlockTitle, ComponentBlock } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import { Row } from '../../../components/GridModules/Row';
import { IntlTextField } from '../../../intl-components/Form';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { dataTypesService, devicesConfigService, emonItemsService, moduleInstancesService, statsItemsService, unitsService, valueConvertersService, variableTypesService, schemaVersion } from '../../../lib/service';
import { handleAPIError } from '../../../util/forms';
import { sortObjectKeys } from '../../../util/object-utils';
import { FormActions } from '../../FormActions';
import { devicesSpec, deviceTypes, defaultVariable, virtualVariableSpec } from '../devices-spec';
import DevicesFormIdentification from './DevicesFormIdentification';
import { VariableFormGroup } from './VariableFormGroup';
import { getValueConvertersObject, getInputProtocolDataType } from '../../../lib/deviceHelpers';
import { SectionTitle } from '../../../components/Layout/Page';

const StyledRow = styled(Row)`
  padding: ${props => props.theme.MarginSize};
  background-color: white;
  box-shadow: ${props => props.theme.ComponentBlockShadow};
  margin-top: ${props => props.theme.MarginSize};
`;

class DevicesFormViewBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      device: undefined,
      deviceVersion: 0,
      showJSON: false,
      deviceJSON: '{}',
      deviceJSONError: false,
      invalidExtra: false,
      errors: undefined,
      variableTypes: undefined,
      dataTypes: undefined,
      units: undefined,
      valueConverters: undefined,
      moduleInstances: undefined,
      statsItems: undefined,
      emonItems: undefined,
      loading: true
    };
  }

  debouncedUpdateDeviceFromJSON = debounce(async deviceJSON => {
    try {
      let device = JSON.parse(deviceJSON);
      if (device.schemaVersion > schemaVersion) {
        this.setState({ deviceJSONError: true, loading: false });
        return;
      } else if (device.schemaVersion < schemaVersion) {
        device = await devicesConfigService.migrate({
          code: device.code + ' (from UI)',
          json: device
        });
        deviceJSON = JSON.stringify(device, null, 2);
      }
      if (!deviceTypes.includes(device.type)) {
        throw Error('Invalid device type');
      }
      //TODO check variable types and other enum data?
      this.setState({
        deviceJSON,
        device,
        deviceVersion: this.state.deviceVersion + 1,
        deviceJSONError: false,
        hasChanges: true,
        loading: false
      });
    } catch (err) {
      this.setState({ deviceJSONError: true, loading: false });
    }
  }, 1250);

  debouncedUpdateJSON = debounce(() => {
    this.setState({
      deviceJSON: this.state.device == null ? null : JSON.stringify((sortObjectKeys(this.state.device)), null, 2),
      deviceJSONError: false
    });
  }, 1000);

  setStateAndJSON(newState, ...options) {
    if (!newState.deviceJSON && newState.device) {
      this.debouncedUpdateJSON();
    }
    return super.setState(newState, ...options);
  }

  getEditionCode() {
    const { match } = this.props;
    return match && match.params && match.params.code;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.debouncedUpdateDeviceFromJSON.cancel();
    this.debouncedUpdateJSON.cancel();
  }

  fillVariableProtocolDataType(variable) {
    variable.protocolDataType = () => {
      const valueConverters = getValueConvertersObject(variable.valueConverters || []);
      return getInputProtocolDataType(variable.dataType, valueConverters, this.state.valueConverters);
    };
  }

  async fetchData() {
    const s = this.state;
    if (!(s.variableTypes && s.dataTypes && s.units && s.valueConverters && s.moduleInstances && s.statsItems && s.emonItems)) {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.device' }));
      const variableTypes = await variableTypesService.find();
      const dataTypes = await dataTypesService.find();
      const units = await unitsService.find();
      const valueConverters = await valueConvertersService.find();
      const moduleInstances = (await moduleInstancesService.find()).instances;
      const statsItems = await statsItemsService.find();
      const emonItems = await emonItemsService.find();
      const state = {
        variableTypes, dataTypes, units, valueConverters, moduleInstances, statsItems, emonItems,
        hasChanges: false, loading: false
      };
      this.setState(state, () => this.fetchDevice());
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.device' }));
    } else {
      this.fetchDevice();
    }
  }

  async fetchDevice() {
    const editionCode = this.getEditionCode();
    let device = { schemaVersion, type: deviceTypes[0] };
    if (editionCode) {
      try {
        device = await devicesConfigService.get(editionCode);
        (device.variables || []).forEach(variable => {
          this.fillVariableProtocolDataType(variable);
        });
      } catch (err) {
        console.error(err);
        // TODO: display internal error!
      }
    }
    this.setStateAndJSON({ device, deviceVersion: this.state.deviceVersion + 1, loading: false, hasChanges: false }, () => {
      eventBus.publish(eventBusTopics.LOADING_END);
    });
  }

  changeDeviceField(field, value, forceUpdateVersion) {
    var newInstanceValue = this.state.device.instance;
    if (field === 'type' && value !== this.state.device.type) { // reset instance if device type is changed
      newInstanceValue = undefined;
    }
    if (field === 'variables') {
      (value || []).forEach(variable => {
        this.fillVariableProtocolDataType(variable);
      });
    }
    this.setStateAndJSON({
      deviceVersion: this.state.deviceVersion + (forceUpdateVersion ? 1 : 0),
      hasChanges: true,
      device: { ...this.state.device, instance: newInstanceValue, [field]: value },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  async removeDevice() {
    try {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
      await devicesConfigService.remove(this.props.match.params.code);
      this.props.history.goBack();
    } catch (response) {
      handleAPIError(response, 'models.devices');
    } finally {
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.removing' }));
    }
  }

  validateAndSaveChanges(goBack = false) {
    const errors = this.validationChain.getAndDisplayErrors('models.devices');
    if (!Object.keys(errors).length) {
      this.saveChanges(goBack);
    } else {
      this.setState({ errors });
    }
  }

  saveChanges(goBack) {
    const newData = this.state.device;
    const editionCode = this.getEditionCode();
    this.setState({ errors: undefined, loading: true }, async () => {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      try {
        editionCode
          ? await devicesConfigService.update(editionCode, newData)
          : await devicesConfigService.create(newData);
        if (goBack) {
          this.props.history.goBack();
        } else {
          this.props.history.replace(`/devices/device/${newData.code}`);
          this.fetchDevice();
        }
      } catch (response) {
        const errors = handleAPIError(response, 'models.devices', this.props.intl,
          { variables: 'models.variables', virtualVariables: 'models.variables' });
        this.setState({ loading: false, errors });
      } finally {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      }
    });
  }

  changeDeviceJSON(deviceJSON) {
    this.setState({ deviceJSON, hasChanges: false, loading: true }, () => this.debouncedUpdateDeviceFromJSON(deviceJSON));
  }

  render() {
    const isNew = !this.getEditionCode();
    const { device, deviceVersion, showJSON, deviceJSON, deviceJSONError, variableTypes, dataTypes, units,
      valueConverters, moduleInstances, statsItems, emonItems, loading, hasChanges } = this.state;

    if (device === undefined) {
      return null;
    }

    const filteredModuleInstances = moduleInstances.filter(i => i.type === device.type).map(m => m.code);

    const typeSpec = devicesSpec({
      variableTypes,
      dataTypes,
      unitsArray: units,
      valueConvertersArray: valueConverters,
      statsItems,
      emonItems,
      moduleInstances: filteredModuleInstances
    })[device.type] || {};
    const errors = this.state.errors;
    this.validationChain = new ValidationChain();

    return [
      <Row key="form-id">
        <SectionTitle id={isNew ? 'models.devices.new' : 'models.devices.edit'} />
        {typeSpec.general &&
          <DevicesFormIdentification
            device={device}
            validationChain={this.validationChain}
            formView={this}
            errors={errors}
            typeSpec={typeSpec}
          />
        }
      </Row >,
      <Row key="form-connection">
        {typeSpec.connection &&
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="models.devices.form.sections.connection" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>
              <DynamicFormGroup
                validationChain={this.validationChain}
                translationKey="models.devices"
                data={device}
                onChange={(field, value) => this.changeDeviceField(field, value)}
                spec={typeSpec.connection}
              />
            </BlockBody>
          </ComponentBlock>
        }
      </Row>,
      <Row key="form-datasources">
        {typeSpec.datasources &&
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="models.devices.form.sections.datasources" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>
              <DynamicFormGroup
                validationChain={this.validationChain}
                translationKey="models.devices"
                data={device}
                onChange={(field, value) => this.changeDeviceField(field, value)}
                spec={typeSpec.datasources}
              />
            </BlockBody>
          </ComponentBlock>
        }
      </Row>,
      <Row key="form-variables">
        <VariableFormGroup
          deviceType={device.type}
          dataVersion={deviceVersion}
          validationChain={this.validationChain}
          variables={device.variables || []}
          virtualVariables={device.virtualVariables || []}
          onChangeVariables={(variables, forceTableUpdate) => this.changeDeviceField('variables', variables, forceTableUpdate)}
          onUncommitedChange={(variable => this.fillVariableProtocolDataType(variable))}
          onChangeVirtualVariables={variables => this.changeDeviceField('virtualVariables', variables)}
          spec={typeSpec.variables}
          defaultVariable={defaultVariable}
          virtualVariableSpec={(typeSpec.virtualVariables === false) ? undefined : virtualVariableSpec}
          errors={errors}
        />
      </Row>,
      <Button
        key="showJSON_toggle"
        variant="outlined"
        onClick={() => this.setState({ showJSON: !showJSON })}>
        <FormattedMessage id={showJSON ? 'app.hideJSON' : 'app.showJSON'} />
      </Button>,
      showJSON &&
      <StyledRow key="form-advanced-json">
        <IntlTextField
          multiline
          key='json'
          error={deviceJSONError}
          label="models.devices.JSON"
          value={deviceJSON}
          onChange={ev => this.changeDeviceJSON(ev.target.value)}
        />
      </StyledRow>,
      <FormActions key="form-actions"
        hasChanges={hasChanges}
        disableAll={loading}
        onSave={this.validateAndSaveChanges.bind(this)}
        onCancel={this.props.history.goBack}
        onRemove={this.removeDevice.bind(this)}
        disableRemove={isNew} />
    ];
  }
}

const DevicesFormView = withRouter(injectIntl(DevicesFormViewBase));
export { DevicesFormView };

