
const availableLanguages = ['es', 'en', 'fr'];

export const getLanguage = () => {
  const language = localStorage.getItem('language') || navigator.language;
  if (!availableLanguages.includes(language)) {
    return availableLanguages[0];
  }
  return language;
};

export const setLanguage = (value) => {
  if (!availableLanguages.includes(value)) {
    return false;
  } else {
    localStorage.setItem('language', value);
    return true;
  }
};
