import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { TextField, BasicSelect } from '../Form/Form';
import { MenuItem, InputAdornment } from '@material-ui/core';
import { timeFactors, timeUnits } from '../../lib/timeTools';

const calculateMillis = (value, factor) => {
  const integerValue = Number(value);
  if (value && Number.isInteger(integerValue)) {
    return integerValue * factor;
  }
  return undefined;
};

class MillisecondsSelector extends React.Component {

  constructor(props) {
    super(props);
    const factorValue = props.value == null
      ? props.defaultValue : props.value;
    const factor = timeFactors.find(f => factorValue % f === 0) || 1000;
    const value = props.value && (props.value / factor);
    this.state = { factor, value };
    if (props.defaultValue !== undefined) {
      this.state.defValue = props.defaultValue / factor;
    }
  }

  onChangeFactor(factor) {
    const value = this.state.value;
    const defValue = this.state.defValue && this.props.defaultValue / factor;
    this.setState({ factor, defValue },
      () => this.props.onChange(calculateMillis(value, factor))
    );
  }

  onChangeValue(value) {
    const factor = this.state.factor;
    this.setState({ value },
      () => this.props.onChange(calculateMillis(value, factor))
    );
  }

  render() {
    const { error, disabled, ...props } = omit(this.props, 'defaultValue');
    const { factor, value, defValue } = this.state;
    return (
      <Fragment>
        <TextField
          {...props}
          value={value === undefined ? '' : value}
          disabled={disabled}
          error={error}
          placeholder={defValue === undefined ? '' : '' + defValue}
          onChange={ev => this.onChangeValue(ev.target.value, factor)}
          InputProps={{
            endAdornment: (
              <InputAdornment key='units-selector'>
                <BasicSelect
                  value={factor}
                  disabled={disabled}
                  error={error}
                  onChange={ev => this.onChangeFactor(Number(ev.target.value))}
                >
                  {timeUnits.map((unit, i) => (
                    <MenuItem key={unit} value={timeFactors[i]}>{unit}</MenuItem>
                  ))}
                </BasicSelect>
              </InputAdornment>
            )
          }}
        />
      </Fragment>
    );
  }

}

MillisecondsSelector.propTypes = {
  value: PropTypes.number,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.number
};

export { MillisecondsSelector };