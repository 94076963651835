import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dialog } from '../../custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { ValidationChain } from '../../Form/Validation/ValidationChain';
import { Row } from '../../GridModules/Row';
import { debounce } from 'lodash';
import { IntlTextField } from '../../../intl-components/Form';
import { DynamicFormTable } from '../../../components/Dynamics/DynamicFormTable';
import { getOutputProtocolDataType, getInputProtocolDataType, validateValueConverters, getValueConvertersObject, getValueConvertersStringArray } from '../../../lib/deviceHelpers';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';


class ValueConvertersEditorDialog extends React.Component {

  constructor(props) {
    super(props);
    const valuesArray = ((typeof props.value === 'string') ? JSON.parse(props.value) : props.value) || [];

    const object = getValueConvertersObject(valuesArray);
    this.state = {
      object: object,
      json: JSON.stringify(getValueConvertersStringArray(object, this.props.availableConverters), null, 2),
      showJSON: false,
      jsonError: false,
      errors: undefined
    };

    this.debouncedUpdateJSON = debounce(json => {
      try {
        this.setState({ object: getValueConvertersObject(JSON.parse(json)), jsonError: false });
      } catch (err) {
        this.setState({ jsonError: true });
      }
    }, 300);
  }


  onSave() {
    const errors = this.validationChain.getErrors();
    if (this.state.jsonError || Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      this.props.onSave(getValueConvertersStringArray(this.state.object, this.props.availableConverters));
    }
  }

  updateConverters(object) {
    this.setState({
      object,
      json: JSON.stringify(getValueConvertersStringArray(object, this.props.availableConverters), null, 2),
      jsonError: false
    });
  }

  changeJSON(json) {
    this.setState({ json });
    this.debouncedUpdateJSON(json);
  }

  toggleJson() {
    const { object, showJSON, jsonError } = this.state;
    if (showJSON && jsonError) {
      this.updateConverters(object);
    }
    this.setState({ showJSON: !showJSON });
  }

  render() {
    const { isImportValueConverter, intl, translationKey, availableConverters, dataType } = this.props;
    const { object, json, jsonError, showJSON, errors } = this.state;
    this.validationChain = new ValidationChain();

    let sourceDataType, targetDataType, protocolDatatype;
    if (isImportValueConverter) {
      protocolDatatype = getInputProtocolDataType(dataType, object, availableConverters);
      sourceDataType = protocolDatatype;
      targetDataType = dataType;
    } else {
      protocolDatatype = getOutputProtocolDataType(dataType, object, availableConverters);
      sourceDataType = dataType;
      targetDataType = protocolDatatype;
    }

    const convertersSpec = {
      code: {
        type: 'select',
        validators: ['required'],
        disableColumnFilter: true,
        translationKey: '',
        values: availableConverters.map(c => c.code)
      },
      params: {
        type: 'number',
        validators: ['required', 'numeric'],
        enabledCondition: (context) => !!context.requiresParam
      },
      inputDataType: {
        type: 'select',
        validators: ['required'],
        disableColumnFilter: true,
        translationKey: 'models.variables.form.dataType',
        values: (context) => context.inputDataTypes,
        enabledCondition: (context) => !!context.inputDataTypes
      },
      outputDataType: {
        type: 'select',
        validators: ['required'],
        disableColumnFilter: true,
        translationKey: 'models.variables.form.dataType',
        values: (context) => context.outputDataTypes,
        enabledCondition: (context) => !!context.outputDataTypes
      },
      description: { type: 'label', validators: [], translationKey: 'description' }
    };

    const tableObject = object.map(o => {
      let converter = this.props.availableConverters.find(c => c.code === o.code);
      if (converter) {
        const translationId = `valueConverters.descriptions.${o.code}`;
        const description = intl.messages[translationId]
          ? intl.formatMessage({ id: translationId })
          : '';
        return {
          ...o,
          description,
          inputDataTypes: converter.inputDataTypes,
          outputDataTypes: converter.outputDataTypes,
          requiresParam: converter.requiresParam
        };
      } else {
        return o;
      }
    });

    const protocolDataTypeField = (<DynamicFormField
      disabled={false}
      validationChain={this.validationChain}
      translationKey={translationKey}
      tableMode={true}
      fieldType={{ type: 'string', validators: [() => validateValueConverters(sourceDataType, targetDataType, object, availableConverters)] }}
      field='protocolDataType'
      value={protocolDatatype}
      errors={errors && errors['protocolDataType']}
      onChange={() => { }}
    />);

    const coreDataTypeField = (<DynamicFormField
      disabled={true}
      translationKey={translationKey}
      fieldType={{ type: 'string' }}
      field='coreDataType'
      value={dataType}
      onChange={() => { }}
    />);

    return (
      <Dialog
        aria-labelledby="value-converters-form-title"
        aria-describedby="value-converters-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="value-converters-form-title">
          <FormattedMessage id={`${translationKey}.title`} />
        </DialogTitle>
        <DialogContent>

          <FormGroup>
            <FormattedMessage id={`valueConverters.${isImportValueConverter ? 'input' : 'output'}.introduction`} values={{ br: <br /> }} />
            <br />
            {isImportValueConverter ? protocolDataTypeField : coreDataTypeField}

            <DynamicFormTable
              enableRowSorting
              enableColumnFilters={false}
              enableEdit={false}
              label='components.valueConvertersEditor.form.valueConverters'
              translationKey='models.valueConverters'
              onChange={this.updateConverters.bind(this)}
              entries={tableObject}
              defaultEntry={{ code: '' }}
              spec={convertersSpec}
              validationChain={this.validationChain}
              errors={errors}
            />
            <br />

            {isImportValueConverter ? coreDataTypeField : protocolDataTypeField}
            <br />

            <Button
              key="showJSON_toggle"
              variant="outlined"
              onClick={() => this.toggleJson()}>
              <FormattedMessage id={showJSON ? 'app.hideJSON' : 'app.showJSON'} />
            </Button>
            {showJSON &&
              <Row key="form-advanced-json">
                <IntlTextField
                  multiline
                  key='json'
                  error={jsonError}
                  label="models.devices.JSON"
                  value={json}
                  onChange={ev => this.changeJSON(ev.target.value)}
                />
              </Row>}

          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.onSave()}>
            <FormattedMessage id={'app.save'} />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ValueConvertersEditorDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  availableConverters: PropTypes.array.isRequired,
  isImportValueConverter: PropTypes.bool.isRequired
};

export default injectIntl(ValueConvertersEditorDialog);