import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import { IntlAlert } from '../../../intl-components/IntlAlert';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';
import Button from '@material-ui/core/Button';
import { getErrorsResume, } from '../../../util/forms';

class ModbusAddressCalculationDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: {
        addressRangeStartCoil: 0,
        addressRangeStartInputCoil: 0,
        addressRangeStartInputRegister: 0,
        addressRangeStartHoldingRegister: 0
      },
      errors: undefined
    };
  }

  updateField(field, value) {
    this.setState({
      object: {
        ...this.state.object,
        [field]: value
      },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  onSave() {
    const errors = this.validationChain.getErrors();
    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      this.props.onSave(this.state.object);
    }
  }

  getErrorsResume(errors) {
    let translationsKey = 'models.exportDevices.modbus.addressCalculation';
    let arrayAttributesTranslationKeys = {};
    return getErrorsResume(this.props.intl, errors, translationsKey, arrayAttributesTranslationKeys);
  }

  render() {
    const { object, errors } = this.state;
    this.validationChain = new ValidationChain();

    return (
      <Dialog
        aria-labelledby="modbus-addres-assign-form-title"
        aria-describedby="modbus-addres-assign-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="modbus-addres-assign-form-title">
          <FormattedMessage id="models.exportDevices.modbus.addressCalculation.title" />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            {this.props.showSections.length === 0 &&
              <Row>
                <FormattedMessage id="models.exportDevices.modbus.addressCalculation.emptySelection" />
              </Row>
            }
            {this.props.showSections.includes('coil') &&
              <Row>
                <DynamicFormField
                  field='addressRangeStartCoil'
                  fieldType={{ type: 'number', validators: ['required', 'integer'] }}
                  validationChain={this.validationChain}
                  translationKey='models.exportDevices.modbus.addressCalculation.form'
                  value={object.addressRangeStartCoil}
                  errors={get(errors, 'addressRangeStartCoil')}
                  onChange={n => this.updateField('addressRangeStartCoil', n)}
                />
              </Row>
            }
            {this.props.showSections.includes('input_register') &&
              <Row>
                <DynamicFormField
                  field='addressRangeStartInputCoil'
                  fieldType={{ type: 'number', validators: ['required', 'integer'] }}
                  validationChain={this.validationChain}
                  translationKey='models.exportDevices.modbus.addressCalculation.form'
                  value={object.addressRangeStartInputCoil}
                  errors={get(errors, 'addressRangeStartInputCoil')}
                  onChange={n => this.updateField('addressRangeStartInputCoil', n)}
                />
              </Row>
            }
            {this.props.showSections.includes('input_register') &&
              <Row>
                <DynamicFormField
                  field='addressRangeStartInputRegister'
                  fieldType={{ type: 'number', validators: ['required', 'integer'] }}
                  validationChain={this.validationChain}
                  translationKey='models.exportDevices.modbus.addressCalculation.form'
                  value={object.addressRangeStartInputRegister}
                  errors={get(errors, 'addressRangeStartInputRegister')}
                  onChange={n => this.updateField('addressRangeStartInputRegister', n)}
                />
              </Row>
            }
            {this.props.showSections.includes('holding_register') &&
              <Row>
                <DynamicFormField
                  field='addressRangeStartHoldingRegister'
                  fieldType={{ type: 'number', validators: ['required', 'integer'] }}
                  validationChain={this.validationChain}
                  translationKey='models.exportDevices.modbus.addressCalculation.form'
                  value={object.addressRangeStartHoldingRegister}
                  errors={get(errors, 'addressRangeStartHoldingRegister')}
                  onChange={n => this.updateField('addressRangeStartHoldingRegister', n)}
                />
              </Row>
            }
            {errors &&
              <IntlAlert key='error-modal' alertId='form' errors={errors ? this.getErrorsResume(this.state.errors) : undefined} />}

          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          {(this.props.showSections.length > 0) &&
            <Button variant="contained" color="primary" onClick={() => this.onSave(this.state.object)}>
              <FormattedMessage id={'app.save'} />
            </Button>
          }
        </DialogActions>
      </Dialog >
    );
  }
}

ModbusAddressCalculationDialog.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  showSections: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default injectIntl(ModbusAddressCalculationDialog);