import Button from '@material-ui/core/Button';
import { debounce, omit, isEqual } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import { COLORS } from '../../../components/ColorCircle';
import { IntlTextField } from '../../../intl-components/Form';
import DynamicFormGroup from '../../../components/Dynamics/DynamicFormGroup';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import { BlockBody, BlockHeader, BlockTitle, ComponentBlock } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import { Row } from '../../../components/GridModules/Row';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { variablesService, runningInstancesService, devicesConfigService, exportDevicesService, valueConvertersService, schemaVersion } from '../../../lib/service';
import { handleAPIError } from '../../../util/forms';
import { sortObjectKeys } from '../../../util/object-utils';
import { nullOrEmpty } from '../../../util/string-utils';
import { FormActions } from '../../FormActions';
import { exportDevicesSpec, defaultVariable } from '../export-devices-spec';
import { VariableFormGroup } from '../../Devices/DevicesForm/VariableFormGroup';
import VariablesSelectorDialog from '../../components/VariablesSelectorDialog';
import { getOutputProtocolDataType, getValueConvertersObject } from '../../../lib/deviceHelpers';
import { IntlFormHeaderIcon } from '../../../intl-components/Form';
import ModbusAddressCalculationDialog from '../Modbus/ModbusAddressCalculationDialog';
import { getModbusVariableBits, getUsedAddressBits, getFirstFreeAddress, addUsedAddressBits } from '../modbus-utils';
import { SectionTitle } from '../../../components/Layout/Page';
import { StatusMonitor } from '../../../components/StatusMonitor';


const StyledRow = styled(Row)`
  padding: ${props => props.theme.MarginSize};
  background-color: white;
  box-shadow: ${props => props.theme.ComponentBlockShadow};
  margin-top: ${props => props.theme.MarginSize};
`;

const getStatusCircleData = (status) => {
  const title = 'models.moduleInstances.status.' + status;
  switch (status) {
    case 'running':
      return {
        color: COLORS.GREEN,
        title
      };
    case 'restarting':
    case 'stopping':
      return {
        color: COLORS.ORANGE,
        title
      };
    case 'warning':
      return {
        color: COLORS.RED,
        title
      };
    case 'stopped':
      return {
        color: COLORS.BLACK,
        title
      };
    default:
      return {
        color: COLORS.GRAY,
        title: 'app.unknown'
      };
  }
};

class ExportDeviceViewBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      device: undefined,
      deviceVersion: 0,
      instanceStatus: { status: 'STOPPED' },
      showJSON: false,
      deviceJSON: '{}',
      deviceJSONError: false,
      showVariablesSelector: false,
      invalidExtra: false,
      errors: undefined,
      valueConverters: undefined,
      allVariables: undefined,
      calculateModbusAddressesRows: undefined,
      loading: true
    };
  }

  debouncedUpdateDeviceFromJSON = debounce(async deviceJSON => {
    try {
      let device = JSON.parse(deviceJSON);
      if (device.schemaVersion > schemaVersion) {
        this.setState({ deviceJSONError: true, loading: false });
        return;
      } else if (device.schemaVersion < schemaVersion) {
        device = await exportDevicesService.migrate({
          code: device.code + ' (from UI)',
          json: device
        });
        deviceJSON = JSON.stringify(device, null, 2);
      }

      let variableTypesAdded = false;
      (device.variables || []).forEach(variable => {
        this.fillVariableDataType(variable);
        variableTypesAdded = true;
      });
      if (variableTypesAdded) {
        deviceJSON = JSON.stringify(device, null, 2);
      }

      this.setState({
        deviceJSON,
        device,
        deviceVersion: this.state.deviceVersion + 1,
        deviceJSONError: false,
        hasChanges: true,
        loading: false
      });
    } catch (err) {
      this.setState({ deviceJSONError: true, loading: false });
    }
  }, 1250);

  debouncedUpdateJSON = debounce(() => {
    this.setState({
      deviceJSON: this.state.device == null ? null : JSON.stringify((sortObjectKeys(this.state.device)), null, 2),
      deviceJSONError: false
    });
  }, 1000);

  setStateAndJSON(newState, ...options) {
    if (!newState.deviceJSON && newState.device) {
      this.debouncedUpdateJSON();
    }
    return super.setState(newState, ...options);
  }

  getEditionCode() {
    const { match } = this.props;
    return match && match.params && match.params.code;
  }

  onInstanceStatusChange = data => {
    if (data.code === '_modbus_export_tcp') {
      this.setState({
        instanceStatus: {
          status: data.status,
          statusTimestamp: data.statusTimestamp,
          relativeDate: data.statusTimestamp ? new Date(data.statusTimestamp) : undefined
        }
      });
    }
  };

  componentDidMount() {
    this.fetchData();
    runningInstancesService.on('RunningInstanceStatusChange', this.onInstanceStatusChange);
  }

  componentWillUnmount() {
    runningInstancesService.removeListener('RunningInstanceStatusChange', this.onInstanceStatusChange);
    this.debouncedUpdateDeviceFromJSON.cancel();
    this.debouncedUpdateJSON.cancel();
  }

  async fetchData() {
    const s = this.state;
    if (!(s.valueConverters && s.allVariables)) {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.device' }));
      const valueConverters = await valueConvertersService.find();
      const allVariables = (await variablesService.list()).map(({ canWrite, ...other }) => { return { ...other, canWrite: !!canWrite }; });
      const dataTypes = Array.from(allVariables.reduce((set, variable) => { return set.add(variable.dataType); }, new Set()));
      const deviceCodes = Array.from(allVariables.reduce((set, variable) => { return set.add(variable.deviceCode); }, new Set()));
      const state = {
        valueConverters,
        allVariables,
        deviceCodes,
        dataTypes,
        hasChanges: false,
        loading: false
      };
      this.setState(state, () => this.fetchDevice());
      runningInstancesService.find().then((statuses) => statuses.forEach(s => this.onInstanceStatusChange(s)));
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.device' }));
    } else {
      this.fetchDevice();
    }
  }

  fillVariableDataType(variable) {
    if (!!variable.variable) {
      const variableData = this.state.allVariables.find(v => `${v.deviceCode}#${v.variableCode}` === variable.variable);
      if (!!variableData) {
        variable.dataType = variableData.dataType;
        this.fillVariableProtocolDataType(variable);
      }
    }
  }

  fillVariableProtocolDataType(variable) {
    if (!!variable.variable) {
      variable.protocolDataType = () => {
        const valueConverters = getValueConvertersObject(variable.valueConverters || []);
        return getOutputProtocolDataType(variable.dataType, valueConverters, this.state.valueConverters);
      };
    }
  }

  async fetchDevice() {
    const editionCode = this.getEditionCode();
    let device = { schemaVersion };
    if (editionCode) {
      try {
        device = await exportDevicesService.get(editionCode);

        (device.variables || []).forEach(variable => {
          this.fillVariableDataType(variable);
        });
      } catch (err) {
        console.error(err);
        // TODO: display internal error!
      }
    }
    this.setStateAndJSON({ device, deviceVersion: this.state.deviceVersion + 1, loading: false, hasChanges: false }, async () => {
      eventBus.publish(eventBusTopics.LOADING_END);

      const { devices } = await devicesConfigService.find();
      const device = devices.find(d => d.code === '_modbus_export_tcp');
      if (!!device) {
        this.setState({
          instanceStatus: {
            status: device.status,
            statusTimestamp: device.statusTimestamp,
            relativeDate: device.statusTimestamp ? new Date(device.statusTimestamp) : undefined
          }
        });
      }
    });
  }

  changeDeviceField(field, value, forceUpdateVersion) {
    var newInstanceValue = this.state.device.instance;
    if (field === 'type' && value !== this.state.device.type) { // reset instance if device type is changed
      newInstanceValue = undefined;
    }
    if (field === 'variables') {
      // we must update dataType only if a variableId is changed
      try {
        const stateVariables = this.state.device.variables;
        const newVariables = value;
        if (stateVariables.length === newVariables.length) {
          for (let i = 0; i < value.length; i++) {
            if (stateVariables[i].variable !== newVariables[i].variable) {
              value[i].dataType = undefined;
              this.fillVariableDataType(value[i]);
            } else if (!isEqual(stateVariables[i].valueConverters, newVariables[i].valueConverters)) {
              this.fillVariableProtocolDataType(value[i]);
            }
          }
        }
      } catch (err) { console.log('Error recalculating dataType: ' + err); }
    }
    this.setStateAndJSON({
      deviceVersion: this.state.deviceVersion + (forceUpdateVersion ? 1 : 0),
      hasChanges: true,
      device: { ...this.state.device, instance: newInstanceValue, [field]: value },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  validateAndSaveChanges() {
    const errors = this.validationChain.getAndDisplayErrors('models.devices');
    if (!Object.keys(errors).length) {
      this.saveChanges();
    } else {
      this.setState({ errors });
    }
  }

  onAddVariablesClick() {
    this.setState({ showVariablesSelector: true });
  }

  addVariables(data) {
    const variables = this.state.device.variables ? [...this.state.device.variables] : [];
    data.forEach((selection) => {
      const variable = {
        variable: `${selection.deviceCode}#${selection.variableCode}`,
        canWrite: !!selection.canWrite,
        modbusRegType: 'holding_register'
      };
      this.fillVariableDataType(variable);
      variables.push(variable);
    });
    const device = Object.assign({}, this.state.device, { variables });
    this.setStateAndJSON({
      deviceVersion: this.state.deviceVersion + 1,
      hasChanges: true,
      device,
      showVariablesSelector: false
    });
  }

  saveChanges() {
    const newData = this.state.device;
    const editionCode = this.getEditionCode();
    this.setState({ errors: undefined, loading: true }, async () => {
      eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
      try {
        await exportDevicesService.update(editionCode, newData);
        this.fetchDevice();
      } catch (response) {
        const errors = handleAPIError(response, 'models.devices', this.props.intl, { variables: 'models.variables' });
        this.setState({ loading: false, errors });
      } finally {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      }
    });
  }

  changeDeviceJSON(deviceJSON) {
    this.setState({ deviceJSON, hasChanges: false, loading: true }, () => this.debouncedUpdateDeviceFromJSON(deviceJSON));
  }

  showCalculateModbusAddresses(rows) {
    if (!Array.isArray(rows)) {
      rows = [rows];
    }
    this.setState({ calculateModbusAddressesRows: rows });
  }

  doCalculateModbusAddresses(configuration) {
    const { calculateModbusAddressesRows } = this.state;
    let hasChanges = false;
    const deviceCopy = { ...this.state.device, variables: [...this.state.device.variables] };

    ['coil', 'discrete_input', 'holding_register', 'input_register'].forEach(regType => {
      const filteredRows = calculateModbusAddressesRows.filter(r => (r.modbusRegType === regType) && nullOrEmpty(r.modbusAddress));
      if (filteredRows.length === 0) {
        return;
      }

      const otherVariables = deviceCopy.variables.filter(v => ((v.modbusRegType === regType) && !nullOrEmpty(v.modbusAddress)));

      let bitsPerAddress, minAddress;
      switch (regType) {
        case 'coil':
          bitsPerAddress = 1;
          minAddress = configuration.addressRangeStartCoil;
          break;
        case 'discrete_input':
          bitsPerAddress = 1;
          minAddress = configuration.addressRangeStartInputCoil;
          break;
        case 'holding_register':
          bitsPerAddress = 16;
          minAddress = configuration.addressRangeStartHoldingRegister;
          break;
        case 'input_register':
          bitsPerAddress = 16;
          minAddress = configuration.addressRangeStartInputRegister;
          break;
        default:
          return;
      }

      const usedAddressBits = getUsedAddressBits(otherVariables, bitsPerAddress);

      let startSearchingAddress = parseInt(minAddress);
      let lastLoopModbusBits = -1;
      filteredRows.forEach((variableA) => {
        const sourceVariableIndex = this.state.device.variables.indexOf(variableA);
        const variable = { ...deviceCopy.variables[sourceVariableIndex] };
        deviceCopy.variables[sourceVariableIndex] = variable;

        const modbusDataType = variable.protocolDataType();
        const modbusBits = getModbusVariableBits(modbusDataType, variable.modbusRegType);
        if (modbusBits !== lastLoopModbusBits) {
          startSearchingAddress = parseInt(minAddress);
        }
        lastLoopModbusBits = modbusBits;

        const address = getFirstFreeAddress(variable, modbusBits, otherVariables, bitsPerAddress, startSearchingAddress, usedAddressBits);
        if (address != null) {
          variable.modbusAddress = address;
          startSearchingAddress = parseInt(address.split('.')[0]);
          addUsedAddressBits(usedAddressBits, variable, bitsPerAddress);
          hasChanges = true;
          otherVariables.push(variable);
        }
      });
    });
    if (hasChanges) {
      this.setStateAndJSON({
        deviceVersion: this.state.deviceVersion + 1,
        hasChanges: true,
        device: deviceCopy,
        calculateModbusAddressesRows: undefined
      });
    } else {
      this.setState({
        calculateModbusAddressesRows: undefined
      });
    }
  }

  render() {
    const { instanceStatus, device, deviceVersion, showJSON, showVariablesSelector, deviceJSON, deviceJSONError, valueConverters, allVariables,
      deviceCodes, dataTypes, loading, hasChanges, calculateModbusAddressesRows } = this.state;

    if (device === undefined) {
      return null;
    }
    const deviceCode = this.getEditionCode();
    let typeSpec = exportDevicesSpec({
      valueConvertersArray: valueConverters,
      variables: allVariables,
      deviceCodes,
      dataTypes
    });
    typeSpec = typeSpec[deviceCode];
    if (typeSpec == null) {
      return null;
    }

    const extraTableActions = [];
    if (device.variables && device.variables.length) {
      extraTableActions.push(
        (<IntlFormHeaderIcon key='variables-action-calculate-modbus-addr'
          icon={< FormatListNumberedRtlIcon />}
          tooltip={'models.devices.calculateAddress'}
          onClick={() => this.showCalculateModbusAddresses(this.state.device.variables)}
        />)
      );
    }

    const extraRowActions = [{
      icon: (<FormatListNumberedRtlIcon />),
      textKey: 'models.devices.calculateAddress',
      action: (row) => this.showCalculateModbusAddresses(row)
    }];

    const errors = this.state.errors;
    this.validationChain = new ValidationChain();

    const statusData = getStatusCircleData(instanceStatus.status);

    return [
      <SectionTitle key="form-id" id='models.exportDevices.title' />,
      <Row key="form-status">
        <ComponentBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id="models.exportDevices.form.sections.status" />
            </BlockTitle>
          </BlockHeader>
          <BlockBody>
            <StatusMonitor
              titleKey={statusData.title}
              color={statusData.color}
              date={instanceStatus.relativeDate}
            />
          </BlockBody>
        </ComponentBlock>
      </Row>,
      <Row key="form-modbus-tcp">
        {typeSpec.modbusTcp &&
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="models.exportDevices.form.sections.modbusTcp" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>
              <FormattedMessage id="models.exportDevices.form.sections.descriptions.modbusTcp" />
              <DynamicFormGroup
                validationChain={this.validationChain}
                translationKey="models.exportDevices"
                data={device}
                onChange={(field, value) => this.changeDeviceField(field, value)}
                spec={typeSpec.modbusTcp}
              />
            </BlockBody>
          </ComponentBlock>
        }
      </Row>,
      <Row key="form-general">
        {typeSpec.general &&
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="models.exportDevices.form.sections.general" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>
              <DynamicFormGroup
                validationChain={this.validationChain}
                translationKey="models.exportDevices"
                data={device}
                onChange={(field, value) => this.changeDeviceField(field, value)}
                spec={typeSpec.general}
              />
            </BlockBody>
          </ComponentBlock>
        }
      </Row>,
      <Row key="form-variables">
        <VariableFormGroup
          deviceType='export_modbus'
          dataVersion={deviceVersion}
          validationChain={this.validationChain}
          variables={device.variables || []}
          enableDefaultAdd={false}
          onChangeVariables={(variables, forceTableUpdate) => this.changeDeviceField('variables', variables, forceTableUpdate)}
          onUncommitedChange={(variable => this.fillVariableDataType(variable))}
          spec={typeSpec.variables}
          defaultVariable={defaultVariable}
          variablesExtraRowActions={extraRowActions}
          onNewClick={this.onAddVariablesClick.bind(this)}
          extraTableActions={extraTableActions}
          errors={errors}
        />
      </Row>,
      <Button
        key="showJSON_toggle"
        variant="outlined"
        onClick={() => this.setState({ showJSON: !showJSON })}>
        <FormattedMessage id={showJSON ? 'app.hideJSON' : 'app.showJSON'} />
      </Button>,
      showJSON &&
      <StyledRow key="form-advanced-json">
        <IntlTextField
          multiline
          key='json'
          error={deviceJSONError}
          label="models.devices.JSON"
          value={deviceJSON}
          onChange={ev => this.changeDeviceJSON(ev.target.value)}
        />
      </StyledRow>,
      showVariablesSelector &&
      <VariablesSelectorDialog
        key='form-variable-selector'
        enableMultipleSelection
        availableVariables={this.state.allVariables}
        allDataTypes={this.state.dataTypes}
        allDeviceCodes={this.state.deviceCodes}
        translationKey='models.variableSelector'
        onSave={(data) => this.addVariables(data)}
        onCancel={() => this.setState({ showVariablesSelector: false })}
      />,
      calculateModbusAddressesRows &&
      <ModbusAddressCalculationDialog
        key='form-modbus-address-calculation'
        translationKey='models.variableSelector'
        showSections={[...new Set(calculateModbusAddressesRows.filter(v => nullOrEmpty(v.modbusAddress)).map(v => v.modbusRegType))]}
        onSave={(data) => this.doCalculateModbusAddresses(data)}
        onCancel={() => this.setState({ calculateModbusAddressesRows: undefined })}
      />
      ,
      <FormActions key="form-actions"
        hasChanges={hasChanges}
        disableAll={loading}
        onSave={this.validateAndSaveChanges.bind(this)}
        onCancel={this.props.history.goBack}
        disableRemove
        disableSaveAndBack />
    ];
  }
}

const ExportDeviceView = withRouter(injectIntl(ExportDeviceViewBase));
export { ExportDeviceView };

