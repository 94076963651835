import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { Row } from '../../../components/GridModules/Row';
import { IntlMonospacedTextField, IntlSelect } from '../../../intl-components/Form';
import { AlertParagraph } from '../../../components/Form/Form';
import { variables2plcst } from './plcst_utils';

const ADQUIO_BYTE_ORDER = 'little_endian';


class ExportPLCVariables extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      byteOrder: 'big_endian'
    };
  }

  render() {
    const { byteOrder } = this.state;
    const { variables } = this.props;

    return (
      <Dialog
        aria-labelledby="export-plcst-form-title"
        aria-describedby="export-plcst-form-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="export-plcst-form-title">
          <FormattedMessage id="models.variables.plcst.export.title" />
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Row>
            </Row>
            <Row>
              <IntlSelect
                value={byteOrder}
                onChange={ev => this.setState({ ...this.state, byteOrder: ev.target.value })}
                label="models.variables.plcst.export.form.byteOrder"
                helperText="models.variables.plcst.export.form.byteOrderHelperText"
                margin="normal">
                {['big_endian', 'little_endian'].map(d =>
                  <MenuItem key={`byte-order-${d}`} value={d}>{d}</MenuItem>
                )}
              </IntlSelect>
              <AlertParagraph>
                <FormattedMessage id='models.variables.plcst.endianMessage' values={{ br: <br /> }} />
              </AlertParagraph>
            </Row>
            <Row>
              <IntlMonospacedTextField
                label="models.variables.plcst.export.form.plcst"
                multiline
                rows="20"
                margin="normal"
                value={variables2plcst(variables, ADQUIO_BYTE_ORDER, byteOrder)}
                onChange={ev => this.setState({ ...this.state, text: ev.target.value })}
              />
            </Row>
          </FormGroup>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onClose}>
            <FormattedMessage id="app.close" />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ExportPLCVariables.propTypes = {
  onClose: PropTypes.func
};

export default ExportPLCVariables;