
const datasourceTypes = ['sql', 'http'];
const baseDatasourceSpec = {
  code: { type: 'string', validators: ['required', 'identifier'] },
  type: {
    type: 'select',
    validators: ['required'],
    values: datasourceTypes
  }
};

export const datasources = {
  type: 'array',
  items: 'datasource',
  datasourceTypes: datasourceTypes,
  specByType: {
    'undefined': baseDatasourceSpec,
    'sql': {
      ...baseDatasourceSpec,
      connectionString: { type: 'string', validators: ['required'] },
      query: { type: 'string', validators: ['required'] },
    },
    'http': {
      ...baseDatasourceSpec,
      url: { type: 'string', validators: ['required'] },
    },
  },
  validators: ['datasources']
};

const uiSections = {
  general: {
    code: 'general',
    order: 1
  },
  protocol: {
    code: 'protocol',
    order: 2
  },
  mqttRead: {
    code: 'mqttRead',
    order: 3
  },
  mqttWrite: {
    code: 'mqttWrite',
    order: 4
  },

};

export const uiSection = (section, row) => {
  return {
    uiSection: {
      section: uiSections[section],
      row
    }
  };
};