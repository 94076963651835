import React, { Fragment } from 'react';
import styled from 'styled-components';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import 'web-animations-js';

import { ScriptHelp } from './ScriptHelp';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/ArrowBack';

import { scriptContext } from './scripts-context';
import { FormattedMessage } from 'react-intl';


const HelpTitle = styled.div`
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  > span {
    color: rgba(0, 0, 0, 0.54);
    padding: 14px;
  }
`;

const ScriptHelpContent = styled.div`
  height: 100%;
  width: ${props => props.theme.ScriptHelpWidth};
  overflow: auto;
  overflow-x: hidden;
  padding: 0 ${props => props.theme.HalfMarginSize};
  border-left: 1px solid ${props => props.theme.BorderColor};
  &>:not(:last-child) {
    display: none;
  }
`;

export class ScriptHelpContainer extends React.Component {

  state = {
    showType: undefined
  }
  onShowType = showType => this.setState({ showType });

  componentDidMount() {
    this.showTypeSubscription = eventBus.subscribe(eventBusTopics.SCRIPTS_HELP_SHOW_TYPE,
      ({ editorId, showType }) => {
        if (editorId === this.props.editorId) {
          scriptContext.types[showType] && this.setState({ showType });
        }
      });
  }

  componentWillUnmount() {
    eventBus.unsubscribe(this.showTypeSubscription);
  }

  render() {
    const { showType } = this.state;
    return (
      <Fragment>
        <HelpTitle>
          {showType &&
            <IconButton onClick={() => this.setState({ showType: undefined })}>
              <BackIcon fontSize='small' />
            </IconButton>
          }
          <IconButton onClick={this.props.onClose.bind(this)}>
            <CloseIcon fontSize='small' />
          </IconButton>
          <span><FormattedMessage id='models.scripts.help' /></span>
        </HelpTitle>
        <ScriptHelpContent>
          {showType &&
            <p>showType</p>
          }
          <ScriptHelp showType={showType} />
        </ScriptHelpContent>
      </Fragment>
    );
  }
}