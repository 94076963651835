import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Tabs } from '@material-ui/core';

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #ddd;
`;

const TabContainer = props => (
  <Typography component="div">
    {props.children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StyledTabs, TabContainer };