import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import eventBus, { eventBusTopics } from '../lib/eventBus';

import { Button } from '@material-ui/core';
import { PrimaryActions, SecondaryActions } from '../components/GridModules/FixedActionBar';
import { confirmRemove, confirmCancel } from '../components/util';
import { FixedActionBar } from '../components/GridModules/FixedActionBar';

class FormActions extends React.Component {

  state = { connected: true }

  componentDidMount() {
    this.subscriptions = [
      eventBus.subscribe(eventBusTopics.SERVER_CONNECTION, connected => {
        connected !== this.state.connected && this.setState({ connected });
      })
    ];
  }

  componentWillUnmount() {
    eventBus.unsubscribe(this.subscriptions);
  }


  render() {
    const { saveLabel, saveAndBackLabel, cancelLabel, onSave, onCancel, onRemove, disableSaveAndBack, disableCancel, disableRemove, intl, extraButtons, hasChanges = true } = this.props;
    const disableAll = this.props.disableAll || !this.state.connected || false;
    return (
      <FixedActionBar key="view-actions" >
        <PrimaryActions>
          {onSave &&
            <Button disabled={!hasChanges || disableAll} variant="contained" color="primary" onClick={() => onSave(false)}>
              <FormattedMessage id={saveLabel || 'app.save'} />
            </Button>
          }
          {onSave && !disableSaveAndBack &&
            <Button disabled={!hasChanges || disableAll} variant="contained" onClick={() => onSave(true)}>
              <FormattedMessage id={saveAndBackLabel || 'app.saveAndBack'} />
            </Button>
          }
          {extraButtons}
        </PrimaryActions>
        <SecondaryActions key="secondary-actions">
          {onCancel && disableCancel !== true &&
            <Button variant="outlined"
              disabled={disableAll}
              onClick={() => {
                if (hasChanges) {
                  confirmCancel(intl, onCancel);
                } else {
                  onCancel();
                }
              }}>
              <FormattedMessage id={cancelLabel || 'app.back'} />
            </Button>
          }
          {onRemove && disableRemove !== true &&
            <Button
              variant="contained"
              color="secondary"
              disabled={disableAll}
              onClick={() => confirmRemove(intl, onRemove)}>
              <FormattedMessage id="app.remove" />
            </Button>
          }
        </SecondaryActions>
      </FixedActionBar >
    );
  }
}

const FormActionsIntl = injectIntl(FormActions);
export { FormActionsIntl as FormActions };