import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import EditableCell from './EditableCell';
import { TableCell } from '../Table';

const DataTableCellBase = ({ intl, cellMapFunction, value, isEditable, onValueEdit, dataType }) => {
  if (React.isValidElement(value)) {
    return <TableCell>{value}</TableCell>;
  }

  let valueMappings;
  if (value && typeof value === 'object') {
    valueMappings = value.valueMappings;
    value = value.value;
  }
  if (typeof value === 'boolean') {
    value = intl.formatMessage({ id: value ? 'app.yes' : 'app.no' });
  } else if (cellMapFunction) {
    value = cellMapFunction(value);
  }
  if (isEditable) {
    return (
      <EditableCell
        key={value}
        value={value}
        valueMappings={valueMappings}
        onValueEdit={onValueEdit}
        dataType={dataType}
        intl={intl}
      />
    );
  } else {
    return (
      <TableCell>
        {valueMappings && valueMappings[value] ? `${valueMappings[value]} (${value})` : value}
      </TableCell>
    );
  }
};

DataTableCellBase.propTypes = {
  cellMapFunction: PropTypes.func,
  value: PropTypes.any,
  isEditable: PropTypes.bool,
  onValueEdit: PropTypes.func
};

const DataTableCell = injectIntl(DataTableCellBase);

export { DataTableCell };