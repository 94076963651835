import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { debounce, omit, get } from 'lodash';

import Button from '@material-ui/core/Button';
import { BlockBody, BlockHeader, BlockTitle, ComponentBlock } from '../../../components/GridModules/ComponentBlock/ComponentBlock';
import { FormActions } from '../../FormActions';
import DynamicFormGroup from '../../../components/Dynamics/DynamicFormGroup';
import { Row } from '../../../components/GridModules/Row';
import { ValidationChain } from '../../../components/Form/Validation/ValidationChain';
import { IntlTextField, IntlSwitch } from '../../../intl-components/Form';
import { DynamicFormField } from '../../../components/Dynamics/DynamicFormField';

import { moduleInstancesService, hwPortsService, schemaVersion } from '../../../lib/service';
import { modulesSpec } from './modules-spec';
import { LogsComponentBlock } from '../../../components/LogsComponentBlock';
import eventBus, { eventBusTopics } from '../../../lib/eventBus';
import { handleAPIError } from '../../../util/forms';
import { SectionTitle } from '../../../components/Layout/Page';

const StyledRow = styled(Row)`
  padding: ${props => props.theme.MarginSize};
  background-color: white;
  box-shadow: ${props => props.theme.ComponentBlockShadow};
  margin-top: ${props => props.theme.MarginSize};
`;

const TRANSLATION_KEY = 'models.instances';

class ModulesFormViewBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      instance: undefined,
      showJSON: false,
      instanceJSON: '{}',
      instanceJSONError: false,
      errors: undefined,
      moduleTypes: [],
      loading: true
    };
    this.debouncedUpdateInstanceFromJSON = debounce(async instanceJSON => {
      try {
        let instance = JSON.parse(instanceJSON);
        if (instance.schemaVersion > schemaVersion) {
          this.setState({ instanceJSONError: true, loading: false });
          return;
        } else if (instance.schemaVersion < schemaVersion) {
          instance = await moduleInstancesService.migrate({
            code: instance.code + ' (from UI)',
            json: instance
          });
          instanceJSON = JSON.stringify(instance, null, 2);
        }
        this.setState({
          instanceJSON, instance,
          instanceJSONError: false,
          hasChanges: true,
          loading: false
        });
      } catch (err) {
        this.setState({ instanceJSONError: true, loading: false });
      }
    }, 1250);
  }

  setState(newState) {
    if (!newState.instanceJSON && newState.instance) {
      newState.instanceJSON = JSON.stringify(newState.instance, null, 2);
      newState.instanceJSONError = false;
    }
    return super.setState(newState);
  }

  getEditionCode() {
    const { match } = this.props;
    return match && match.params && match.params.code;
  }

  async componentDidMount() {
    eventBus.publish(eventBusTopics.LOADING_START);
    const moduleTypes = await moduleInstancesService.getAvailableTypes();
    moduleTypes.sort();
    const hwPorts = await hwPortsService.find();
    this.setState({ hwPorts, moduleTypes });
    await this.fetchData();
    eventBus.publish(eventBusTopics.LOADING_END);
  }

  async fetchData() {
    eventBus.publish(eventBusTopics.LOADING_START);
    const editionCode = this.getEditionCode();
    let instance = { autoStart: true, schemaVersion };
    if (editionCode) {
      try {
        instance = await moduleInstancesService.get(editionCode);
      } catch (er) {
        console.error(er);
      }
    }
    this.setState({ instance, loading: false, hasChanges: false });
    eventBus.publish(eventBusTopics.LOADING_END);
  }

  changeInstanceField(field, value) {
    var newPortValue = this.state.instance.port;
    if (field === 'type' && value !== this.state.instance.type) {
      newPortValue = undefined;
    }
    this.setState({
      hasChanges: true,
      instance: { ...this.state.instance, port: newPortValue, [field]: value },
      errors: this.state.errors ? omit(this.state.errors, field) : undefined
    });
  }

  validateAndSaveChanges(goBack = false) {
    const errors = this.validationChain.getAndDisplayErrors(TRANSLATION_KEY);
    if (!Object.keys(errors).length) {
      this.saveChanges(goBack);
    } else {
      this.setState({ errors });
    }
  }

  async saveChanges(goBack) {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
    const newData = this.state.instance;
    const editionCode = this.getEditionCode();
    this.setState({ errors: undefined });
    try {
      if (editionCode) {
        await moduleInstancesService.update(editionCode, newData);
      } else {
        await moduleInstancesService.create(newData);
      }
      if (goBack) {
        this.props.history.goBack();
      } else {
        this.props.history.replace(`/devices/module/${newData.code}`);
        this.fetchData();
      }
    } catch (response) {
      const errors = handleAPIError(response);
      this.setState({ errors });
    } finally {
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
    }
  }

  async removeInstance() {
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.removing' }));
    this.setState({ loading: true });
    try {
      await moduleInstancesService.remove(this.getEditionCode());
      this.props.history.goBack();
    } catch (err) {
      handleAPIError(err, 'models.instances', this.props.intl);
      this.setState({ loading: false });
    } finally {
      eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
    }
  }

  changeInstanceJSON(instanceJSON) {
    this.setState({ instanceJSON, hasChanges: false, loading: true });
    this.debouncedUpdateInstanceFromJSON(instanceJSON);
  }

  render() {
    const isNew = !this.getEditionCode();
    const { instance, showJSON, instanceJSON, instanceJSONError, loading, moduleTypes, hwPorts, hasChanges } = this.state;

    if (instance === undefined) {
      return null;
    }

    const typeSpec = modulesSpec(instance.type, hwPorts);
    const errors = this.state.errors;
    this.validationChain = new ValidationChain();

    return [
      <Row key="form-id">
        <SectionTitle id={isNew ? 'models.instances.new' : 'models.instances.edit'} />
        <ComponentBlock>
          <BlockHeader>
            <BlockTitle>
              <FormattedMessage id="models.instances.form.sections.id" />
            </BlockTitle>
          </BlockHeader>
          <BlockBody>
            <Row>
              <IntlSwitch
                label="models.moduleInstances.autoStart"
                checked={instance.autoStart}
                onChange={(_ev, checked) => this.changeInstanceField('autoStart', checked)}
              />
            </Row>
            <Row MultiColumns>
              <DynamicFormField
                validationChain={this.validationChain}
                value={instance.type}
                field='type'
                fieldType={{ type: 'select', values: moduleTypes, validators: ['required'], translationKey: 'type' }}
                translationKey={TRANSLATION_KEY}
                errors={get(errors, 'type')}
                onChange={value => this.changeInstanceField('type', value)}
              />
              <DynamicFormField
                validationChain={this.validationChain}
                value={instance.code}
                field='code'
                fieldType={{ type: 'string', validators: ['required', 'identifier'] }}
                translationKey={TRANSLATION_KEY}
                errors={get(errors, 'code')}
                onChange={value => this.changeInstanceField('code', value)}
              />
            </Row>
          </BlockBody>
        </ComponentBlock>
      </Row >,
      <Row key="form-connection">
        {typeSpec.connection &&
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id="models.instances.form.sections.connection" />
              </BlockTitle>
            </BlockHeader>
            <BlockBody>
              <DynamicFormGroup
                validationChain={this.validationChain}
                translationKey={TRANSLATION_KEY}
                data={instance}
                onChange={(field, value) => this.changeInstanceField(field, value)}
                spec={typeSpec.connection}
              />
            </BlockBody>
          </ComponentBlock>
        }
      </Row>,
      <Button
        key="showJSON_toggle"
        variant="outlined"
        onClick={() => this.setState({ showJSON: !showJSON })}>
        <FormattedMessage id={showJSON ? 'app.hideJSON' : 'app.showJSON'} />
      </Button>,
      showJSON &&
      <StyledRow key="form-advanced-json">
        <IntlTextField
          multiline
          key='json'
          error={instanceJSONError}
          label="models.devices.JSON"
          value={instanceJSON}
          onChange={ev => this.changeInstanceJSON(ev.target.value)}
        />
      </StyledRow>,
      !isNew &&
      <Row key='log'>
        <LogsComponentBlock
          titleKey='models.moduleInstances.logs'
          logType='moduleInstance'
          logId={this.getEditionCode()}
        />
      </Row>,
      <FormActions key="form-actions"
        hasChanges={hasChanges}
        disableAll={loading}
        onSave={this.validateAndSaveChanges.bind(this)}
        onCancel={this.props.history.goBack}
        onRemove={this.removeInstance.bind(this)}
        disableRemove={isNew} />
    ];
  }
}

ModulesFormViewBase.propTypes = {
  device: PropTypes.object
};

const ModulesFormView = withRouter(injectIntl(ModulesFormViewBase));
export { ModulesFormView };
