import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Dialog } from '../../../components/custom-material-ui-core';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { IntlMonospacedTextField } from '../../../intl-components/Form';

class ImportPLCErrorsDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { titleKey, fieldKey, messageKey, content } = this.props;

    return (
      <Dialog
        aria-labelledby="textarea-dialog-title"
        aria-describedby="textarea-dialog-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="textarea-dialog-title">
          <FormattedMessage id={titleKey} />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id={messageKey} />
          <IntlMonospacedTextField
            label={fieldKey}
            multiline
            rows="20"
            margin="normal"
            value={content}
            onChange={ev => this.setState({ ...this.state, text: ev.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.onCancel}>
            <FormattedMessage id="app.cancel" />
          </Button>
          <Button variant="contained" onClick={this.props.onConfirm}>
            <FormattedMessage id="app.confirm" />
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}

ImportPLCErrorsDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default injectIntl(ImportPLCErrorsDialog);