import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatLongDate } from '../util/time';

import { LabeledText } from '../components/Form/Form';
import { StatusMonitor } from '../components/StatusMonitor';


class BaseStatusMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: 'unknown',
      statusTimestamp: null
    };
  }

  componentDidMount() {
    throw new Error('Not implemented');
  }

  componentWillUnmount() {
    throw new Error('Not implemented');
  }

  async fetchData() {
    throw new Error('Not implemented');
  }

  getStatusCircleData(status) {
    throw new Error('Not implemented');
  }

  render() {
    const { intl, titleKey = 'app.status', statusMessageKey = 'app.longStatus' } = this.props;
    const { status, statusTimestamp } = this.state;

    const statusData = this.getStatusCircleData(status);
    const statusName = intl.formatMessage({ id: statusData.title });
    const statusDate = formatLongDate(intl, statusTimestamp, false);
    const statusMonitor = (
      <StatusMonitor
        titleKey={statusData.title}
        color={statusData.color}
        date={statusTimestamp}
        messageKey={statusMessageKey}
        messageValues={{ status: statusName, date: statusDate }}
      />);
    return (<LabeledText id={titleKey} item={statusMonitor} />);
  }
}

BaseStatusMonitor.propTypes = {
  titleKey: PropTypes.string,
  statusMessageKey: PropTypes.string,
};

export { BaseStatusMonitor };
