import React from 'react';
import { Tooltip } from '@material-ui/core';

/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
export const parseToSeconds = (timeString) => {
  if (typeof timeString === 'number') return timeString;
  const sufix = timeString.slice(-1);
  let number = Number(timeString.slice(0, -1));
  switch (sufix) {
    case 'M':
      number *= 30;
    case 'd':
      number *= 24;
    case 'h':
      number *= 60;
    case 'm':
      number *= 60;
  }
  return number;
};

export const roundDate = (period, date = new Date()) => {
  const stepMs = parseToSeconds(period) * 1000;
  return new Date(Math.floor(date / stepMs) * stepMs);
};

export const formatLongDate = (intl, date, includeWeekday = true) => intl.formatDate(date, {
  weekday: includeWeekday ? 'long' : undefined,
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
});

export const formatTime = (intl, date) => intl.formatDate(date, {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
});

let now = Math.trunc(+new Date() / 1000);
setInterval(() => now = Math.trunc(+new Date() / 1000));
export const renderDateColumn = (intl, date) => {
  if (date) {
    const formattedDate = formatLongDate(intl, date);
    date = Math.trunc(+date / 1000);
    if (shouldBeRelative(date)) {
      const { delta, unit } = relativeUnit(date);
      return (
        <Tooltip title={formattedDate}>
          <div>{intl.formatRelativeTime(delta, unit)}</div>
        </Tooltip>
      );
    } else {
      return formattedDate;
    }
  } else {
    return null;
  }
};

export const formatRelativeOrLongDate = (intl, date) => {
  if (shouldBeRelative(date)) {
    return formatRelativeDate(intl, date);
  } else {
    return formatLongDate(intl, date);
  }
};

export const formatRelativeDate = (intl, date) => {
  date = Math.trunc(+date / 1000);
  const { delta, unit } = relativeUnit(date);
  return intl.formatRelativeTime(delta, unit);
};

//Relative dates
let limitDateCache = null;
export const shouldBeRelative = date => {
  if (date instanceof Date) date = Math.trunc(+date / 1000);
  if (limitDateCache === null) {
    limitDateCache = now - (24 * 60 * 60);
    setTimeout(() => limitDateCache = null, 2000);
  }
  return !(date < limitDateCache);
};

const relativeUnit = date => {
  let delta = date - now;
  if (delta < -60) {
    if (delta < -3600) {
      return { unit: 'hours', delta: Math.round(delta / 3600) };
    } else {
      return { unit: 'minutes', delta: Math.round(delta / 60) };
    }
  } else {
    return { unit: 'seconds', delta };
  }
};
