import React from 'react';

const QueryContext = React.createContext({});

export class TimeZoneProvider extends React.Component {

  state = { timezone: 'UTC'}

  render() {
    const { children } = this.props;
    return (
      <QueryContext.Provider value={{
        timezone: this.state.timezone,
        updateTimezone: timezone => this.setState({timezone})
      }}>
        {children}
      </QueryContext.Provider>
    );
  }
}

export const timeZoneAware = Child => {
  const injectedChild = props => (
    <QueryContext.Consumer>
      {({ timezone, updateTimezone }) => <Child {...props} updateTimezone={updateTimezone} timezone={timezone} />}
    </QueryContext.Consumer>
  );
  return injectedChild;
};