import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row } from '../../components/GridModules/Row';
import { ComponentBlock } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import eventBus, { eventBusTopics } from '../../lib/eventBus';
import { showErrorAlert } from '../../lib/apiHelpers';
import { systemService } from '../../lib/service';
import { withRouter } from 'react-router-dom';
import { confirm } from '../../components/util';
import { SectionTitle } from '../../components/Layout/Page';

const askStatusManagement = (intl, mode) => {
  confirm(intl, `system.${mode}.executionConfirm`, () => {
    doStatusManagement(intl, mode);
  });
};

const doStatusManagement = (intl, mode) => {
  const loaderMessage = intl.formatMessage({ id: `system.${mode}.loading` });
  const loaderShowIcon = mode !== 'shutdown';

  const serverConnectionSubs = eventBus.subscribe(eventBusTopics.SERVER_CONNECTION, (online) => {
    if (!online) return;
    eventBus.publish(eventBusTopics.LOADING_END, { message: loaderMessage, showIcon: loaderShowIcon });
    eventBus.unsubscribe([serverConnectionSubs]);
  });
  eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'loading.applying' }));
  systemService.update(mode, {})
    .then(() => {
      eventBus.publish(eventBusTopics.LOADING_START, { message: loaderMessage, showIcon: loaderShowIcon });
      eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: mode });
      return false;
    })
    .catch(e => {
      if (e.name === 'NotFound') {
        eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: `system.${mode}.loading` });
      }
      showErrorAlert(e);
    }).finally(() => {
      eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'loading.applying' }));
    });
};


class RebootViewBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = { connected: true, loading: true, isAdquioHardware: false };
  }

  isAdquioHardware() {
    systemService.get('hardwareInfo')
      .then(version => {
        const isAdquioHardware = !!get(version, 'data.model.isAdquioHardware');
        this.setState({ isAdquioHardware, loading: false });
      })
      .catch(err => showErrorAlert(err));
  }

  componentDidMount() {
    this.isAdquioHardware();
  }

  render() {
    const { intl } = this.props;
    const { isAdquioHardware } = this.state;
    return (<Fragment>
      <SectionTitle id='system.reboot.title' />
      <Row>
        <ComponentBlock>
          <Button
            variant='contained'
            color='primary'
            onClick={() => askStatusManagement(intl, 'reboot')}>
            <FormattedMessage id={'system.reboot.button'} />
          </Button>
        </ComponentBlock>
      </Row>
      {isAdquioHardware &&
        <Row>
          <ComponentBlock>
            <Button
              variant='contained'
              color='primary'
              onClick={() => askStatusManagement(intl, 'shutdown')}>
              <FormattedMessage id={'system.shutdown.button'} />
            </Button>
          </ComponentBlock>
        </Row>
      }
    </Fragment >);
  }
}

export const RebootView = injectIntl(withRouter(RebootViewBase));