import React, { Fragment } from 'react';

import styled from 'styled-components';

import codemirror from 'codemirror';
import PropTypes from 'prop-types';
import { JSHINT } from 'jshint';
import 'codemirror/mode/javascript/javascript';

import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint.css';

import { adquioJsHint } from './script-hinter';
import { IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const EditorContext = React.createContext();

window.JSHINT = JSHINT;


const ScriptContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.BorderColor};
  .CodeMirror {
    flex-grow: 1;
    height: 100%;
  }
  .CodeMirror-scroll {
    min-height: ${props => props.minHeight};
  }
`;

const RightHelpButton = styled.div`
  position: relative;
  z-index: 100;
  left: calc(100% - 42px);
  width: 42px;
  margin-bottom: -42px;
`;

class ScriptEditorBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      valueIsSet: false
    };
  }

  shouldComponentUpdate(_nProps, _nState) {
    return !this.codeMirror
      || !this.state.valueIsSet;
  }

  prepareCodeMirror() {
    if (!this.fieldRef) return; // Dialog has some delay until content is mounted
    if (!this.codeMirror || this.fieldRef !== this.codeMirror.getTextArea()) {
      this.codeMirror = codemirror.fromTextArea(this.fieldRef, {
        ...codemirrorOptions,
        ...this.props.editorOptions,
        value: this.state.value
      });
      this.codeMirror.on('change', (cm, change) => {
        if (change.origin === 'setValue') return;
        const value = cm.getValue();
        this.setState({ value }, () =>
          this.props.onChange(value));
      });
    }
  }

  componentDidUpdate() {
    this.prepareCodeMirror();
  }

  componentDidMount() {
    this.prepareCodeMirror();
  }

  componentWillUnmount() {
    if (this.codeMirror) {
      this.codeMirror.toTextArea();
      this.codeMirror = null;
    }
  }

  render() {
    const { allowHelp, minHeight } = this.props;
    const { value } = this.state;
    return (
      <Fragment>
        {allowHelp &&
          <RightHelpButton>
            <IconButton onClick={() => this.props.onShowHelp()}>
              <HelpIcon fontSize='small' />
            </IconButton>
          </RightHelpButton>
        }
        <ScriptContainer minHeight={minHeight}>
          <textarea ref={ref => this.fieldRef = ref} defaultValue={value} />
        </ScriptContainer>
      </Fragment>
    );
  }

}

const jslintOptions = {
  getAnnotations: codemirror.lint.javascript,
  options: {
    asi: true,
    esversion: 9, //Needed to support async/await syntax
    strict: 'implied',
    undef: true,
    unused: true,
    varstmt: true,
    globals: {
      Units: false,
      VariableTypes: false,
      alerts: false,
      calc: false,
      clearInterval: false,
      clearTimeout: false,
      console: false,
      delay: false,
      devices: false,
      modules: false,
      equs: false,
      events: false,
      fiberize: false,
      formula: false,
      globalData: false,
      localData: false,
      logger: false,
      payload: false,
      process: false,
      require: false,
      runInFiber: false,
      setImmediate: false,
      setInterval: false,
      setTimeout: false,
      utils: false,
      watch: false,
    }
  }
};
// const func = () => {};
const codemirrorOptions = {
  mode: 'javascript',
  tabSize: 2,
  lineNumbers: true,
  matchBrackets: true,
  autoCloseBrackets: true,
  highlightSelectionMatches: true,
  lint: jslintOptions,
  gutters: ['CodeMirror-lint-markers'],
  extraKeys: { 'Ctrl-Space': 'autocomplete', 'Alt-F': 'findPersistent' },
};

codemirror.registerHelper('hint', 'adquioJSHint', adquioJsHint);
codemirror.commands.autocomplete = cm => cm.showHint({ hint: codemirror.hint.adquioJSHint });

ScriptEditorBase.propTypes = {
  editorId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editorOptions: PropTypes.object,
  blockKey: PropTypes.string.isRequired,
  debugModeEnabled: PropTypes.bool,
  minHeight: PropTypes.string,
  allowHelp: PropTypes.bool,
  onShowHelp: PropTypes.func
};

ScriptEditorBase.defaultProps = {
  allowHelp: false,
  minHeight: '400px'
};

const ScriptEditor = React.forwardRef((props, ref) => (<ScriptEditorBase ref={ref} {...props} />));
export { ScriptEditor, EditorContext };