import React, { Component } from 'react';
import { alertsService } from '../../lib/service';
import { formatSortParam } from '../../lib/apiHelpers';
import { get } from 'lodash';

import { IntlDataTable } from '../../intl-components/IntlDataTable';
import { injectIntl } from 'react-intl';
import { RowStatusValues } from '../../components/Table/DataTable';
import { Tooltip, Chip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';
import { darken } from 'polished';

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  > * {
    margin-right: ${props => props.theme.QuarterMarginSize};
    margin-bottom: ${props => props.theme.QuarterMarginSize};
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledChip = styled(Chip)`
  && {
    background-color: ${props => props.theme.BgMediumGreyColor};
    color: ${props => props.theme.PaleTextColor};
    color: ${props => darken(.2, props.theme.PaleTextColor)};
    font-size: 9px;
    height: 16px;
    letter-spacing: .25px;
    span {
      padding-left: ${props => props.theme.QuarterMarginSize};
      padding-right: ${props => props.theme.QuarterMarginSize};
    }
  }
`;

const SmallInfoIcon = styled(InfoIcon)`
  && {
    font-size: 20px;
    cursor: pointer;
  }
`;

const PayloadWrapper = styled.div`
  position: relative;
  > svg:first-child {
    right: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`;

const PayloadList = styled.ul`
  margin-right: 24px;
`;

const PayloadItem = styled.li`
  > span {
    margin-right: 4px;
    display: inline-block;
  }
`;

const ExtendedTooltip = withStyles((theme) => ({
  root: { backgroundColor: 'red' },
  tooltip: {
    'white-space': 'pre-wrap',
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class AlertsTableBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageData: {
        total: 0,
        limit: 25,
        skip: 0,
        data: []
      },
      sort: {
        active: 'desc',
        updateTime: 'desc'
      },
      query: props.query
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      query: props.query
    };
  }

  alertEventHandler = ({ action, alert }) => {
    const index = this.state.pageData.data.findIndex(a => a.id === alert.id);
    switch (action) {
      case 'New':
        this.fetchData();
        break;
      case 'Expire':
      case 'Update':
        if (index >= 0) {
          const newAlerts = [...this.state.pageData.data];
          newAlerts[index] = alert;
          this.setState({ pageData: { ...this.state.pageData, data: newAlerts } });
        }
        break;
      default:
        this.fetchData();
    }
  }

  componentDidMount() {
    this.onPaginationChange({ page: 0, rowsPerPage: 25 });
    this.variableChangeHandler = data => this.onVariableChange(data);
    alertsService.on('Alert', this.alertEventHandler);
    this.updateInterval = setInterval(() => this.forceUpdate(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
    alertsService.removeListener('Alert', this.alertEventHandler);
    if (this.variableChangeThrottle) {
      clearTimeout(this.variableChangeThrottle);
    }
  }

  componentDidUpdate(prevProps) {
    const prevQuery = prevProps.query || {};
    const queryChange =
      Object.keys(prevQuery).length !== Object.keys(this.props.query).length ||
      Object.entries(this.props.query).some(([key, value]) => value !== prevQuery[key]);
    if (queryChange) {
      this.fetchData();
    }
  }

  fetchData() {
    const query = { $limit: this.state.pageData.limit, $skip: this.state.pageData.skip, $sort: formatSortParam(this.state.sort) };
    Object.assign(query, this.props.query);
    Object.keys(this.props.query).forEach(k => {
      if (query[k] === '') {
        query[k] = undefined;
      }
    });
    if (query['tags']) {
      query['tags'] = query['tags'].split(',').map(t => t.trim());
    }
    alertsService.find({ query }).then((d) => this.fetchDataSuccess(d));
  }

  fetchDataSuccess(data) {
    this.setState({ pageData: data, loading: false });
  }

  onVariableChange(data) {
    if (this.state.loading) return;
    this.updateVariables[`${data.deviceCode}#${data.variableCode}`] = data.newValue;
    if (!this.variableChangeThrottle) {
      this.variableChangeThrottle = setTimeout(() => {
        const variableData = this.state.pageData.data;
        const variables = variableData.map(vd => {
          const newValue = this.updateVariables[`${vd.deviceCode}#${vd.variableCode}`];
          if (newValue === undefined) {
            return vd;
          } else {
            return {
              ...vd,
              value: newValue
            };
          }
        });
        this.updateVariables = {};
        this.setState({ ...this.state, pageData: { ...this.state.pageData, data: variables } });
        delete this.variableChangeThrottle;
      }, 250);
    }
  }

  onPaginationChange({ page, rowsPerPage }) {
    this.setState({
      loading: true, pageData: Object.assign(this.state.pageData, {
        limit: rowsPerPage, skip: rowsPerPage * page
      })
    }, this.fetchData);
  }

  onSortChange(columnName, order) {
    this.setState({
      loading: true,
      sort: { [columnName]: order }
    }, this.fetchData);
  }

  onFilterChange(columnName, value) {
    const query = { ...this.state.query, [columnName]: value };
    this.setState({
      loading: true, pageData: { ...this.state.pageData, skip: 0 }
    }, () => this.props.onQueryChange(query));
  }

  rowStatus(row) {
    return {
      status: row.active
        ? row.tags.includes('system')
          ? RowStatusValues.ERROR
          : RowStatusValues.WARNING
        : RowStatusValues.UNKNOWN,
      title: `models.alerts.status.${row.active ? 'active' : 'inactive'}`,
      relativeDate: row.updateTime && new Date(row.updateTime)
    };
  }

  renderPayload(payload) {
    if (['string', 'number'].includes(typeof payload)) {
      return payload;
    } else if (payload == null) {
      return '';
    } else if (typeof payload === 'object') {
      let title = JSON.stringify(payload, null, 2);
      if (payload.scriptId) {
        payload = {
          script: `${payload.scriptId} (${payload.zone})`,
          msg: get(payload, 'error.msg') || payload.error
        };
      }
      return (
        <PayloadWrapper>
          <ExtendedTooltip title={title}>
            <SmallInfoIcon />
          </ExtendedTooltip>
          <PayloadList>
            {Object.keys(payload).map((key, idx) => (
              <PayloadItem key={'payload-key-' + idx}><span>{key}:</span>{payload[key]}</PayloadItem>
            ))}
          </PayloadList>
        </PayloadWrapper >
      );
    }
    return JSON.stringify(payload);
  }

  renderTags(tags) {
    return (
      <TagsWrapper>
        {tags.map(tag => (
          <StyledChip key={tag} label={tag} />
        ))}
      </TagsWrapper>
    );
  }

  render() {
    return (
      <IntlDataTable
        translationKey="models.alerts"
        columns={[
          { data: 'priority', sortable: true, filter: '&' },
          { data: 'type', sortable: true, filter: '&*' },
          { data: 'startTime', sortable: true },
          { data: 'endTime', sortable: true },
          { data: 'updateTime', sortable: true },
          { data: 'expireAfterMs', sortable: true },
          { data: 'payload', filter: '*&*' },
          { data: 'dynamicPayload' },
          { data: 'tags', filter: '&' }
        ]}
        rowStatus={this.rowStatus}
        dateColumns={['startTime', 'endTime', 'updateTime']}
        rowMapFunction={({ payload, dynamicPayload, tags, ...other }) => ({
          ...other,
          tags: this.renderTags(tags),
          payload: this.renderPayload(payload),
          dynamicPayload: this.renderPayload(dynamicPayload)
        })}
        pageData={this.state.pageData}
        sort={this.state.sort}
        filters={this.state.query}
        onPaginationChange={(ev) => this.onPaginationChange(ev)}
        onFilterChange={(column, filter) => this.onFilterChange(column, filter)}
        onSortChange={(column, order) => this.onSortChange(column, order)}
        loading={this.state.loading} />
    );
  }

}

const AlertsTable = injectIntl(AlertsTableBase);
export default AlertsTable;
