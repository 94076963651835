import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Cancel from '@material-ui/icons/Cancel';
import { ColumnFilterFieldWrapper } from '../../GridModules/ComponentBlock/TableBlock';

class TextFilterColumnHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textFilter: false,
      filterValue: undefined
    };
  }

  cancelFilter() {
    this.debouncedFilterChange.cancel();
    if (this.state.textFilter) {
      this.setState({ textFilter: false, filterValue: undefined }, () => {
        if (this.props.filterValue) {
          this.props.onFilterChange(undefined);
        }
      });
    } else {
      this.props.onFilterChange(undefined);
    }
  }

  debouncedFilterChange = debounce(filterValue => {
    this.setState({ filterValue: undefined }, () => this.props.onFilterChange(filterValue));
  }, 500)

  onFilterChange(filterValue) {
    this.setState({ filterValue }, () => this.debouncedFilterChange(filterValue));
  }

  render() {
    const { enableFilter, children, filterPattern } = this.props;
    const filterValue = this.state.filterValue === undefined ? this.props.filterValue : this.state.filterValue;
    const { textFilter } = this.state;
    if (textFilter || filterValue) {
      const filterParts = filterPattern.split('&');
      let value = (filterValue || '');
      if (filterParts.length === 2) {
        value = value.substring(filterParts[0].length, value.length - filterParts[1].length);
      }
      return [
        <ColumnFilterFieldWrapper key='filter_text_wrapper'>
          <TextField
            value={value}
            onChange={ev => this.onFilterChange(filterPattern.replace('&', ev.target.value))}
          />
          <IconButton onClick={() => this.cancelFilter()}>
            <Cancel />
          </IconButton>
        </ColumnFilterFieldWrapper>
      ];
    } else if (enableFilter) {
      return [
        children,
        <IconButton key='filter_text_key' onClick={() => this.setState({ textFilter: true, filterValue: filterPattern.replace('&', '') })}>
          <Search />
        </IconButton>
      ];
    } else {
      return children;
    }
  }

}

TextFilterColumnHeader.propTypes = {
  enableFilter: PropTypes.bool,
  onFilterChange: PropTypes.func,
  filterValue: PropTypes.string,
  filterPattern: PropTypes.string
};

export default TextFilterColumnHeader;
