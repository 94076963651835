import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';

import FormGroup from '@material-ui/core/FormGroup';

import { scriptsService, runningInstancesService } from '../../lib/service';
import eventBus, { eventBusTopics } from '../../lib/eventBus';

import { BlockBody, ComponentBlock, BlockHeader, BlockTitle } from '../../components/GridModules/ComponentBlock/ComponentBlock';
import { Button, Drawer } from '@material-ui/core';
import { FormActions } from '../FormActions';
import { Row } from '../../components/GridModules/Row';
import { ValidationChain } from '../../components/Form/Validation/ValidationChain';
import { DynamicFormField } from '../../components/Dynamics/DynamicFormField';
import { EditorContext, ScriptEditor } from './ScriptEditor';
import ScriptBlock from './ScriptBlock';
import { LogsComponentBlock } from '../../components/LogsComponentBlock';
import { handleAPIError } from '../../util/forms';
import { SectionTitle } from '../../components/Layout/Page';
import { ScriptDebugger } from './ScriptDebugger';
import { ScriptStatusMonitor } from './ScriptStatusMonitor';
import { ModuleInstanceStatusMonitor } from '../Devices/ModuleInstanceStatusMonitor';

import { ScriptHelpContainer as ScriptHelpConteinerBase } from './ScriptHelpContainer';
import StyleVariables from '../../theme/StyleVariables';

const RightHelpDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    margin-top: ${StyleVariables.AppHeaderHeight};
    height: calc(100% - ${StyleVariables.AppHeaderHeight});
    padding-bottom: 90px;
    background-color: #f7f9f9;
    z-index: initial;
  }
`;

const ScriptFormPage = styled(Row)`
  width: calc(100% - ${props => props.helpExpanded ? '400px' : '0px'});
`;

const ScriptHelpContainer = styled(ScriptHelpConteinerBase)``;

const defaultScript = { enabled: true, type: 'Custom', content: [{ id: 'main', content: '' }], name: '', readOnly: false };

const stringLines = (string) => {
  return ((string || '').match(/\r?\n/g) || '').length + 1;
};

const TRANSLATION_KEY = 'models.scripts';

class ScriptForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      debugModeEnabled: false,
      script: defaultScript,
      errors: undefined,
      helpExpanded: false
    };
  }

  setDebugEnabled(enabled) {
    if (enabled) {
      if (this.state.hasChanges) {
        eventBus.publish(eventBusTopics.DISPLAY_ALERT_MODAL, { alertId: 'UnsavedScript' });
      } else {
        this.setState({ debugModeEnabled: true });
      }
    } else {
      this.disableDebug();
    }
  }

  disableDebug() {
    if (this.state.debugModeEnabled) {
      this.setState({ debugModeEnabled: false });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { match } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START);
    if (this.isEdition()) {
      const script = await scriptsService.get(match.params.code);
      this.setState({ script, hasChanges: false, errors: undefined });
    } else {
      const urlEvent = this.getUrlEvent();
      if (urlEvent) {
        const script = await scriptsService.newScript({ enabled: true, type: 'Event', event: urlEvent });
        this.setState({ script, hasChanges: false, errors: undefined });
      }
    }
    eventBus.publish(eventBusTopics.LOADING_END);
  }

  isEdition() {
    const { match } = this.props;
    return match && match.params && match.params.code;
  }

  getUrlEvent() {
    const { match } = this.props;
    if (match && match.params && match.params.type) {
      return {
        type: match.params.type,
        object: match.params.object,
        objectName: match.params.objectName
      };
    } else {
      return null;
    }
  }

  changeHelpVisibility(newVisibility) {
    const { helpExpanded } = this.state;
    if (newVisibility == null) {
      newVisibility = !helpExpanded;
    }
    this.setState({ helpExpanded: newVisibility });
  }

  changeScriptField(field, value) {
    this.setState({ hasChanges: true, script: { ...this.state.script, [field]: value } });
  }

  changeScriptContent(contentBlockId, value) {
    let script = { ...this.state.script }; // clone script, 1 level
    script.content = [...script.content]; // clone content array
    let blockIdx = script.content.findIndex(b => b.id === contentBlockId);
    let newBlock = { ...script.content[blockIdx] }; // clone content item to replace
    newBlock.content = value;
    script.content[blockIdx] = newBlock;
    this.setState({ script, hasChanges: true });
  }

  validateAndSaveChanges(goBack = false) {
    const errors = this.validationChain.getAndDisplayErrors(TRANSLATION_KEY);
    if (!Object.keys(errors).length) {
      this.saveChanges(goBack);
    }
  }

  restartModuleInstance(code) {
    const { intl } = this.props;
    eventBus.publish(eventBusTopics.LOADING_START, intl.formatMessage({ id: 'app.loading' }));
    runningInstancesService.restart(code).catch(response => handleAPIError(response, 'app', intl))
      .finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, intl.formatMessage({ id: 'app.loading' }));
      });
  }

  saveChanges(goBack) {
    let savePromise;
    eventBus.publish(eventBusTopics.LOADING_START, this.props.intl.formatMessage({ id: 'loading.saving' }));
    if (this.isEdition()) {
      savePromise = scriptsService.update(this.props.match.params.code, this.state.script);
    } else {
      savePromise = scriptsService.create(this.state.script);
    }
    savePromise
      .then(scriptId => {
        if (goBack) {
          this.props.history.goBack();
        } else {
          this.props.history.replace(`/scripts/edit/${scriptId}`);
          this.setState({ errors: undefined }, () => this.fetchData());
        }
      }).catch(response => {
        const errors = handleAPIError(response, TRANSLATION_KEY);
        this.setState({ errors });
      }).finally(() => {
        eventBus.publish(eventBusTopics.LOADING_END, this.props.intl.formatMessage({ id: 'loading.saving' }));
      });
  }

  render() {
    this.validationChain = new ValidationChain();
    const { script, helpExpanded } = this.state;
    const editorId = 'editorId';

    const readOnly = !!script.readOnly;
    let pageTitleKey;
    if (this.isEdition() && readOnly) {
      pageTitleKey = 'models.scripts.view';
    } else if (this.isEdition()) {
      pageTitleKey = 'models.scripts.edit';
    } else {
      pageTitleKey = 'models.scripts.new';
    }
    const showEventInfo = script.type === 'Event';
    const showScriptInfo = script.type === 'ScriptModule';
    const showEnableOption = !showScriptInfo;
    const disabledNameChange = showScriptInfo;

    let lineIndex = 1;
    const contentBlocks = get(script, 'content', []).map(c => {

      const blockKey = 'models.scripts.contentBlocks' + ((script.event && script.event.object) ? ('.' + script.event.object) : '') + '.' + c.id;
      const blockFirstLine = lineIndex + stringLines(c.preContent);
      lineIndex = blockFirstLine + stringLines(c.content) + stringLines(c.postContent);
      return (
        <Row key={'content-block-' + script.id + '-' + c.id}>
          <ComponentBlock>
            <BlockHeader>
              <BlockTitle>
                <FormattedMessage id={blockKey + '.title'} />
              </BlockTitle>
              <FormattedMessage id={blockKey + '.message'} />
            </BlockHeader>
            <ScriptBlock value={c.preContent}></ScriptBlock>
            <ScriptEditor
              editorId={script.id + '-' + c.id}
              blockKey={blockKey}
              onChange={x => this.changeScriptContent(c.id, x)}
              value={c.content || ''}
              allowHelp={true}
              onShowHelp={() => this.changeHelpVisibility()}
              editorOptions={{
                firstLineNumber: blockFirstLine,
                readOnly: readOnly ? 'nocursor' : false
              }}
            />
            <ScriptBlock value={c.postContent}></ScriptBlock>
          </ComponentBlock>
        </Row>
      );
    });

    return (
      <ScriptFormPage helpExpanded={helpExpanded}>
        <SectionTitle id={pageTitleKey} />
        <ComponentBlock>
          <BlockBody>
            <FormGroup>
              {showEnableOption &&
                <Row>
                  <DynamicFormField
                    field='enabled'
                    fieldType='boolean'
                    value={script.enabled}
                    translationKey={TRANSLATION_KEY}
                    validators={[]}
                    validationChain={this.validationChain}
                    onChange={x => this.changeScriptField('enabled', x)}
                    disabled={readOnly}
                  />
                </Row>
              }
              {(script.id != null) &&
                <Row>
                  <ScriptStatusMonitor
                    scriptId={script.id}
                  />
                </Row>
              }
              <Row>
                <DynamicFormField
                  field='name'
                  fieldType='string'
                  value={script.name}
                  translationKey={TRANSLATION_KEY}
                  validators={['required', 'identifier']}
                  validationChain={this.validationChain}
                  onChange={x => this.changeScriptField('name', x)}
                  errors={get(this.state.errors, 'name')}
                  disabled={readOnly || disabledNameChange}
                />
              </Row>
              {showEventInfo &&
                <Row MultiColumns>
                  <DynamicFormField
                    field='event'
                    fieldType='string'
                    value={script.event.type + ' (' + script.event.object + ')'}
                    translationKey={TRANSLATION_KEY}
                    onChange={() => { }}
                    inputProps={{ readOnly: true }}
                    disabled
                  />
                  <DynamicFormField
                    field='objectName'
                    fieldType='string'
                    value={script.event.objectName}
                    translationKey={TRANSLATION_KEY}
                    onChange={() => { }}
                    inputProps={{ readOnly: true }}
                    disabled
                  />
                </Row>
              }
              {showScriptInfo &&
                <Row MultiColumns>
                  <DynamicFormField
                    field='instance'
                    fieldType='string'
                    value={script.name}
                    translationKey={TRANSLATION_KEY}
                    inputProps={{ readOnly: true }}
                    onChange={() => { }}
                    disabled
                  />
                  <Button variant='contained' onClick={() => this.restartModuleInstance(script.name)}>
                    <FormattedMessage id='app.restartModuleInstance' />
                  </Button>
                  <ModuleInstanceStatusMonitor
                    titleKey='models.scripts.instanceStatus'
                    instanceId={script.name} />

                </Row>
              }
              {contentBlocks}
            </FormGroup>
          </BlockBody>
        </ComponentBlock>
        {this.isEdition() &&
          <LogsComponentBlock
            titleKey='models.scripts.logs'
            logType='script'
            logId={this.props.match.params.code}
          />
        }
        {this.isEdition() && this.state.debugModeEnabled &&
          <ScriptDebugger
            close={() => this.disableDebug()}
            scriptId={this.props.match.params.code}
          />
        }
        <FormActions key='form-actions'
          disableRemove
          hasChanges={this.state.hasChanges}
          disableSave={readOnly}
          cancelLabel={readOnly ? 'app.back' : 'app.cancel'}
          onSave={this.validateAndSaveChanges.bind(this)}
          onCancel={this.props.history.goBack}
          extraButtons={
            <Button
              variant='contained'
              color='default'
              onClick={() => this.setDebugEnabled(true)}>
              <FormattedMessage id='models.scripts.debug.open' />
            </Button>
          }
        />

        <RightHelpDrawer
          anchor='right'
          variant='persistent'
          open={helpExpanded}
        >
          <EditorContext.Provider value={{ editorId }}>
            <ScriptHelpContainer
              editorId={editorId}
              onClose={() => this.changeHelpVisibility(false)}
            />
          </EditorContext.Provider>
        </RightHelpDrawer>

      </ScriptFormPage >
    );
  }

}

ScriptForm.propTypes = {};

const ScriptFormRouted = withRouter(injectIntl(ScriptForm));
export { ScriptFormRouted as ScriptForm };
